import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { SOCIALS } from '@alpha-shares/config';

import { graphCMSClient } from '@alpha-shares/fetchers';

import { APP_BASE_PATH } from '@alpha-shares/config/constants/appBasePaths';

import { useGetFooterNavigationQuery } from '@alpha-shares/graphql-codegen/generated/graphCMS'

type IFooterMeduimProps = {
  className?: string;
};

export const FooterMedium: React.FC<IFooterMeduimProps> = ({ className }) => {
  const { data: footerNavigation } = useGetFooterNavigationQuery(graphCMSClient)
  const router = useRouter();
  const basePath = router.basePath;

  return (
    <footer className={`${className} relative z-20 border-t-4 border-dark-100 bg-dark-550`} aria-labelledby="footer-heading">
      <div className="container mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="xl:col-span-1 flex flex-col gap-2 align-top">
            <h3 className="text-sm font-bold text-gray-400 tracking-wider uppercase">
              Socials
            </h3>
            <div className="mt-4 flex items-center gap-4 pb-4 border-dark-100">
              {
              SOCIALS.items.map((element) => (
                <a className="text-gray-500" href={element.route} target="_blank" rel="noreferrer" key={element.route}>
                  <element.icon className="w-8 h-8" />
                </a>
              ))
            }
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-8">
              {
                footerNavigation && footerNavigation?.menus?.map((menu) => (
                <div key={menu.id} className='mb-6'>
                  <h3 className="text-sm font-bold text-gray-400 tracking-wider uppercase">
                    {menu?.menuSection}
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {menu?.navigationItems?.map(({ destinationAppId, navigationItemText, externalLink, internalSlug, iconComponent }) => {
                      
                        let internalSlugPath: string = '';
                        let multiAppRoute: string = '';
    
                        if (internalSlug && APP_BASE_PATH[destinationAppId] === basePath) {
                          internalSlugPath = `/${internalSlug}`
                        }
    
                        if (internalSlug && APP_BASE_PATH[destinationAppId] !== basePath) {
                          multiAppRoute = `${APP_BASE_PATH[destinationAppId]}/${internalSlug}`
                        }

                        return (
                          <li key={navigationItemText}>
                            {externalLink && (
                              <Link
                                href={externalLink}
                                className="text-base text-gray-500 hover:text-white"
                                target='_blank'
                              >
                                {navigationItemText}
                              </Link>
                            )}
                            {internalSlugPath && (
                              <Link
                                href={internalSlugPath}
                                className="text-base text-gray-500 hover:text-white"
                              >
                               
                                {navigationItemText}
                              </Link>
                            )}
                            {multiAppRoute && (
                              <a
                                href={multiAppRoute}
                                className="text-base text-gray-500 hover:text-white"
                              >
                                {navigationItemText}
                              </a>
                            )}
                          </li>
                        )
                      }
                    )}
                  </ul>
                  </div>
                ))
              }             
            </div>
          </div>
        </div>
        <div className="mt-6 pt-8">
          <p className="mt-6 text-sm text-gray-400 xl:text-center">
            &copy;&nbsp;
            {new Date().getFullYear()}
          &nbsp;AlphaShares.io All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
