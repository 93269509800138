// @ts-nocheck
import { GraphQLClient } from "graphql-request";
import { RequestInit } from "graphql-request/dist/types.dom";
import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit["headers"],
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Hex: { input: any; output: any };
  Json: { input: any; output: any };
  Long: { input: any; output: any };
  RGBAHue: { input: any; output: any };
  RGBATransparency: { input: any; output: any };
  RichTextAST: { input: any; output: any };
};

export type Aggregate = {
  __typename?: "Aggregate";
  count: Scalars["Int"]["output"];
};

export enum AlphaWolfTier {
  alpha = "alpha",
  apex = "apex",
  lone = "lone",
  ronin = "ronin",
}

/** Identifier for Alpha Shares Applications - use Global for anything that will be common to all apps */
export enum AppId {
  Global = "Global",
  MarketplaceLegacy = "MarketplaceLegacy",
  MarketplaceNext = "MarketplaceNext",
}

/** Asset system model */
export type Asset = Entity &
  Node & {
    __typename?: "Asset";
    backgroundImageMintingStat: Array<MintingStat>;
    chainNativeCurrencyIconChain: Array<Chain>;
    collectionCreatorBannerImageCollectionCreator: Array<CollectionCreator>;
    collectionCreatorThumbnailImageCollectionCreator: Array<CollectionCreator>;
    collectionFeaturedImageNftCollection: Array<NftCollection>;
    collectionHeaderImageLargeNftCollection: Array<NftCollection>;
    collectionSampleImagesMintingProject: Array<MintingProject>;
    collectionSampleImagesMultiChainMintingProject: Array<MultiChainMintingProject>;
    collectionThumbnailNftCollection: Array<NftCollection>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<Asset>;
    /** The file name */
    fileName: Scalars["String"]["output"];
    /** The file handle */
    handle: Scalars["String"]["output"];
    /** The height of the file */
    height?: Maybe<Scalars["Float"]["output"]>;
    heroBannerImageLandingPage: Array<LandingPage>;
    heroImageLeftMintingStat: Array<MintingStat>;
    heroImageMintingStat: Array<MintingStat>;
    /** List of Asset versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    imageCtaSection: Array<CtaSection>;
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Asset>;
    logoImageLandingPage: Array<LandingPage>;
    menuGroupIconMenu: Array<Menu>;
    /** The mime type of the file */
    mimeType?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    seoImageLandingPage: Array<LandingPage>;
    seoImageMultiChainMintingProject: Array<MultiChainMintingProject>;
    /** The file size */
    size?: Maybe<Scalars["Float"]["output"]>;
    /** System stage field */
    stage: Stage;
    tokenBannerTokenPrinterConfiguration: Array<TokenPrinterConfiguration>;
    tokenIconMintingCurrency: Array<MintingCurrency>;
    tokenThumbnailTokenPrinterConfiguration: Array<TokenPrinterConfiguration>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    /** Get the url for the asset with provided transformations applied. */
    url: Scalars["String"]["output"];
    videoHomePageVideoHero: Array<HomePageVideoHero>;
    videoPosterImageHomePageVideoHero: Array<HomePageVideoHero>;
    /** The file width */
    width?: Maybe<Scalars["Float"]["output"]>;
  };

/** Asset system model */
export type AssetBackgroundImageMintingStatArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingStatOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingStatWhereInput>;
};

/** Asset system model */
export type AssetChainNativeCurrencyIconChainArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ChainOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChainWhereInput>;
};

/** Asset system model */
export type AssetCollectionCreatorBannerImageCollectionCreatorArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionCreatorOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionCreatorWhereInput>;
};

/** Asset system model */
export type AssetCollectionCreatorThumbnailImageCollectionCreatorArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionCreatorOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionCreatorWhereInput>;
};

/** Asset system model */
export type AssetCollectionFeaturedImageNftCollectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

/** Asset system model */
export type AssetCollectionHeaderImageLargeNftCollectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

/** Asset system model */
export type AssetCollectionSampleImagesMintingProjectArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingProjectWhereInput>;
};

/** Asset system model */
export type AssetCollectionSampleImagesMultiChainMintingProjectArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MultiChainMintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

/** Asset system model */
export type AssetCollectionThumbnailNftCollectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

/** Asset system model */
export type AssetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

/** Asset system model */
export type AssetHeroBannerImageLandingPageArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LandingPageWhereInput>;
};

/** Asset system model */
export type AssetHeroImageLeftMintingStatArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingStatOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingStatWhereInput>;
};

/** Asset system model */
export type AssetHeroImageMintingStatArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingStatOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingStatWhereInput>;
};

/** Asset system model */
export type AssetHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

/** Asset system model */
export type AssetImageCtaSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CtaSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CtaSectionWhereInput>;
};

/** Asset system model */
export type AssetLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  locales?: Array<Locale>;
};

/** Asset system model */
export type AssetLogoImageLandingPageArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LandingPageWhereInput>;
};

/** Asset system model */
export type AssetMenuGroupIconMenuArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MenuOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MenuWhereInput>;
};

/** Asset system model */
export type AssetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Asset system model */
export type AssetSeoImageLandingPageArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LandingPageWhereInput>;
};

/** Asset system model */
export type AssetSeoImageMultiChainMintingProjectArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MultiChainMintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

/** Asset system model */
export type AssetTokenBannerTokenPrinterConfigurationArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TokenPrinterConfigurationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TokenPrinterConfigurationWhereInput>;
};

/** Asset system model */
export type AssetTokenIconMintingCurrencyArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingCurrencyOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingCurrencyWhereInput>;
};

/** Asset system model */
export type AssetTokenThumbnailTokenPrinterConfigurationArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TokenPrinterConfigurationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TokenPrinterConfigurationWhereInput>;
};

/** Asset system model */
export type AssetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetUrlArgs = {
  transformation?: InputMaybe<AssetTransformationInput>;
};

/** Asset system model */
export type AssetVideoHomePageVideoHeroArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<HomePageVideoHeroOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<HomePageVideoHeroWhereInput>;
};

/** Asset system model */
export type AssetVideoPosterImageHomePageVideoHeroArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<HomePageVideoHeroOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<HomePageVideoHeroWhereInput>;
};

export type AssetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AssetWhereUniqueInput;
};

/** A connection to a list of items. */
export type AssetConnection = {
  __typename?: "AssetConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AssetEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AssetCreateInput = {
  backgroundImageMintingStat?: InputMaybe<MintingStatCreateManyInlineInput>;
  chainNativeCurrencyIconChain?: InputMaybe<ChainCreateManyInlineInput>;
  collectionCreatorBannerImageCollectionCreator?: InputMaybe<CollectionCreatorCreateManyInlineInput>;
  collectionCreatorThumbnailImageCollectionCreator?: InputMaybe<CollectionCreatorCreateManyInlineInput>;
  collectionFeaturedImageNftCollection?: InputMaybe<NftCollectionCreateManyInlineInput>;
  collectionHeaderImageLargeNftCollection?: InputMaybe<NftCollectionCreateManyInlineInput>;
  collectionSampleImagesMintingProject?: InputMaybe<MintingProjectCreateManyInlineInput>;
  collectionSampleImagesMultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  collectionThumbnailNftCollection?: InputMaybe<NftCollectionCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName: Scalars["String"]["input"];
  handle: Scalars["String"]["input"];
  height?: InputMaybe<Scalars["Float"]["input"]>;
  heroBannerImageLandingPage?: InputMaybe<LandingPageCreateManyInlineInput>;
  heroImageLeftMintingStat?: InputMaybe<MintingStatCreateManyInlineInput>;
  heroImageMintingStat?: InputMaybe<MintingStatCreateManyInlineInput>;
  iconSmallIconHeadingText?: InputMaybe<SmallIconHeadingTextCreateManyInlineInput>;
  imageCtaSection?: InputMaybe<CtaSectionCreateManyInlineInput>;
  imageImageWithAltTextAndLink?: InputMaybe<ImageWithAltTextAndLinkCreateManyInlineInput>;
  imageTeamMemberImageNameTitle?: InputMaybe<TeamMemberImageNameTitleCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AssetCreateLocalizationsInput>;
  logoImageLandingPage?: InputMaybe<LandingPageCreateManyInlineInput>;
  menuGroupIconMenu?: InputMaybe<MenuCreateManyInlineInput>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  seoImageLandingPage?: InputMaybe<LandingPageCreateManyInlineInput>;
  seoImageMultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  tokenBannerTokenPrinterConfiguration?: InputMaybe<TokenPrinterConfigurationCreateManyInlineInput>;
  tokenIconMintingCurrency?: InputMaybe<MintingCurrencyCreateManyInlineInput>;
  tokenThumbnailTokenPrinterConfiguration?: InputMaybe<TokenPrinterConfigurationCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  videoHomePageVideoHero?: InputMaybe<HomePageVideoHeroCreateManyInlineInput>;
  videoPosterImageHomePageVideoHero?: InputMaybe<HomePageVideoHeroCreateManyInlineInput>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName: Scalars["String"]["input"];
  handle: Scalars["String"]["input"];
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetCreateLocalizationInput = {
  /** Localization input */
  data: AssetCreateLocalizationDataInput;
  locale: Locale;
};

export type AssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
};

export type AssetCreateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Create and connect multiple existing Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
};

export type AssetCreateOneInlineInput = {
  /** Connect one existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
};

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: "AssetEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Asset;
};

/** Identifies documents */
export type AssetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImageMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  backgroundImageMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  backgroundImageMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  chainNativeCurrencyIconChain_every?: InputMaybe<ChainWhereInput>;
  chainNativeCurrencyIconChain_none?: InputMaybe<ChainWhereInput>;
  chainNativeCurrencyIconChain_some?: InputMaybe<ChainWhereInput>;
  collectionCreatorBannerImageCollectionCreator_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorBannerImageCollectionCreator_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorBannerImageCollectionCreator_some?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_some?: InputMaybe<CollectionCreatorWhereInput>;
  collectionFeaturedImageNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionFeaturedImageNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionFeaturedImageNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  collectionSampleImagesMintingProject_every?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMintingProject_none?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMintingProject_some?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_every?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_none?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_some?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionThumbnailNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionThumbnailNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionThumbnailNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  heroBannerImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  heroBannerImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  heroBannerImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  heroImageLeftMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  heroImageLeftMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  heroImageLeftMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  heroImageMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  heroImageMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  heroImageMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  imageCtaSection_every?: InputMaybe<CtaSectionWhereInput>;
  imageCtaSection_none?: InputMaybe<CtaSectionWhereInput>;
  imageCtaSection_some?: InputMaybe<CtaSectionWhereInput>;
  logoImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  logoImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  logoImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  menuGroupIconMenu_every?: InputMaybe<MenuWhereInput>;
  menuGroupIconMenu_none?: InputMaybe<MenuWhereInput>;
  menuGroupIconMenu_some?: InputMaybe<MenuWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seoImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  seoImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  seoImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  seoImageMultiChainMintingProject_every?: InputMaybe<MultiChainMintingProjectWhereInput>;
  seoImageMultiChainMintingProject_none?: InputMaybe<MultiChainMintingProjectWhereInput>;
  seoImageMultiChainMintingProject_some?: InputMaybe<MultiChainMintingProjectWhereInput>;
  tokenBannerTokenPrinterConfiguration_every?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenBannerTokenPrinterConfiguration_none?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenBannerTokenPrinterConfiguration_some?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenIconMintingCurrency_every?: InputMaybe<MintingCurrencyWhereInput>;
  tokenIconMintingCurrency_none?: InputMaybe<MintingCurrencyWhereInput>;
  tokenIconMintingCurrency_some?: InputMaybe<MintingCurrencyWhereInput>;
  tokenThumbnailTokenPrinterConfiguration_every?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenThumbnailTokenPrinterConfiguration_none?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenThumbnailTokenPrinterConfiguration_some?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  videoHomePageVideoHero_every?: InputMaybe<HomePageVideoHeroWhereInput>;
  videoHomePageVideoHero_none?: InputMaybe<HomePageVideoHeroWhereInput>;
  videoHomePageVideoHero_some?: InputMaybe<HomePageVideoHeroWhereInput>;
  videoPosterImageHomePageVideoHero_every?: InputMaybe<HomePageVideoHeroWhereInput>;
  videoPosterImageHomePageVideoHero_none?: InputMaybe<HomePageVideoHeroWhereInput>;
  videoPosterImageHomePageVideoHero_some?: InputMaybe<HomePageVideoHeroWhereInput>;
};

export enum AssetOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  fileName_ASC = "fileName_ASC",
  fileName_DESC = "fileName_DESC",
  handle_ASC = "handle_ASC",
  handle_DESC = "handle_DESC",
  height_ASC = "height_ASC",
  height_DESC = "height_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  mimeType_ASC = "mimeType_ASC",
  mimeType_DESC = "mimeType_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  size_ASC = "size_ASC",
  size_DESC = "size_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  width_ASC = "width_ASC",
  width_DESC = "width_DESC",
}

/** Transformations for Assets */
export type AssetTransformationInput = {
  document?: InputMaybe<DocumentTransformationInput>;
  image?: InputMaybe<ImageTransformationInput>;
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssetUpdateInput = {
  backgroundImageMintingStat?: InputMaybe<MintingStatUpdateManyInlineInput>;
  chainNativeCurrencyIconChain?: InputMaybe<ChainUpdateManyInlineInput>;
  collectionCreatorBannerImageCollectionCreator?: InputMaybe<CollectionCreatorUpdateManyInlineInput>;
  collectionCreatorThumbnailImageCollectionCreator?: InputMaybe<CollectionCreatorUpdateManyInlineInput>;
  collectionFeaturedImageNftCollection?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  collectionHeaderImageLargeNftCollection?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  collectionSampleImagesMintingProject?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  collectionSampleImagesMultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  collectionThumbnailNftCollection?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  heroBannerImageLandingPage?: InputMaybe<LandingPageUpdateManyInlineInput>;
  heroImageLeftMintingStat?: InputMaybe<MintingStatUpdateManyInlineInput>;
  heroImageMintingStat?: InputMaybe<MintingStatUpdateManyInlineInput>;
  iconSmallIconHeadingText?: InputMaybe<SmallIconHeadingTextUpdateManyInlineInput>;
  imageCtaSection?: InputMaybe<CtaSectionUpdateManyInlineInput>;
  imageImageWithAltTextAndLink?: InputMaybe<ImageWithAltTextAndLinkUpdateManyInlineInput>;
  imageTeamMemberImageNameTitle?: InputMaybe<TeamMemberImageNameTitleUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AssetUpdateLocalizationsInput>;
  logoImageLandingPage?: InputMaybe<LandingPageUpdateManyInlineInput>;
  menuGroupIconMenu?: InputMaybe<MenuUpdateManyInlineInput>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  seoImageLandingPage?: InputMaybe<LandingPageUpdateManyInlineInput>;
  seoImageMultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  tokenBannerTokenPrinterConfiguration?: InputMaybe<TokenPrinterConfigurationUpdateManyInlineInput>;
  tokenIconMintingCurrency?: InputMaybe<MintingCurrencyUpdateManyInlineInput>;
  tokenThumbnailTokenPrinterConfiguration?: InputMaybe<TokenPrinterConfigurationUpdateManyInlineInput>;
  videoHomePageVideoHero?: InputMaybe<HomePageVideoHeroUpdateManyInlineInput>;
  videoPosterImageHomePageVideoHero?: InputMaybe<HomePageVideoHeroUpdateManyInlineInput>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetUpdateLocalizationDataInput = {
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetUpdateLocalizationInput = {
  data: AssetUpdateLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AssetUpsertLocalizationInput>>;
};

export type AssetUpdateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetConnectInput>>;
  /** Create and connect multiple Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
  /** Delete multiple Asset documents */
  delete?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Disconnect multiple Asset documents */
  disconnect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Update multiple Asset documents */
  update?: InputMaybe<Array<AssetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Asset documents */
  upsert?: InputMaybe<Array<AssetUpsertWithNestedWhereUniqueInput>>;
};

export type AssetUpdateManyInput = {
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AssetUpdateManyLocalizationsInput>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetUpdateManyLocalizationDataInput = {
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetUpdateManyLocalizationInput = {
  data: AssetUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateManyLocalizationInput>>;
};

export type AssetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AssetUpdateManyInput;
  /** Document search */
  where: AssetWhereInput;
};

export type AssetUpdateOneInlineInput = {
  /** Connect existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
  /** Delete currently connected Asset document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected Asset document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Asset document */
  update?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Asset document */
  upsert?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>;
};

export type AssetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AssetUpdateInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

export type AssetUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetCreateInput;
  /** Update document if it exists */
  update: AssetUpdateInput;
};

export type AssetUpsertLocalizationInput = {
  create: AssetCreateLocalizationDataInput;
  locale: Locale;
  update: AssetUpdateLocalizationDataInput;
};

export type AssetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AssetUpsertInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AssetWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type AssetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImageMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  backgroundImageMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  backgroundImageMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  chainNativeCurrencyIconChain_every?: InputMaybe<ChainWhereInput>;
  chainNativeCurrencyIconChain_none?: InputMaybe<ChainWhereInput>;
  chainNativeCurrencyIconChain_some?: InputMaybe<ChainWhereInput>;
  collectionCreatorBannerImageCollectionCreator_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorBannerImageCollectionCreator_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorBannerImageCollectionCreator_some?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_some?: InputMaybe<CollectionCreatorWhereInput>;
  collectionFeaturedImageNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionFeaturedImageNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionFeaturedImageNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  collectionSampleImagesMintingProject_every?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMintingProject_none?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMintingProject_some?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_every?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_none?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_some?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionThumbnailNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionThumbnailNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionThumbnailNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  fileName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  fileName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  fileName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  fileName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  fileName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  fileName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  fileName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  handle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  handle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  handle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  handle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  handle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  handle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  handle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  handle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  handle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  height_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  height_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  height_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  height_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  height_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  height_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  heroBannerImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  heroBannerImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  heroBannerImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  heroImageLeftMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  heroImageLeftMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  heroImageLeftMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  heroImageMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  heroImageMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  heroImageMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  imageCtaSection_every?: InputMaybe<CtaSectionWhereInput>;
  imageCtaSection_none?: InputMaybe<CtaSectionWhereInput>;
  imageCtaSection_some?: InputMaybe<CtaSectionWhereInput>;
  logoImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  logoImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  logoImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  menuGroupIconMenu_every?: InputMaybe<MenuWhereInput>;
  menuGroupIconMenu_none?: InputMaybe<MenuWhereInput>;
  menuGroupIconMenu_some?: InputMaybe<MenuWhereInput>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mimeType_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mimeType_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mimeType_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  mimeType_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mimeType_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mimeType_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mimeType_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mimeType_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seoImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  seoImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  seoImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  seoImageMultiChainMintingProject_every?: InputMaybe<MultiChainMintingProjectWhereInput>;
  seoImageMultiChainMintingProject_none?: InputMaybe<MultiChainMintingProjectWhereInput>;
  seoImageMultiChainMintingProject_some?: InputMaybe<MultiChainMintingProjectWhereInput>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  size_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  size_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  size_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  size_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  tokenBannerTokenPrinterConfiguration_every?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenBannerTokenPrinterConfiguration_none?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenBannerTokenPrinterConfiguration_some?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenIconMintingCurrency_every?: InputMaybe<MintingCurrencyWhereInput>;
  tokenIconMintingCurrency_none?: InputMaybe<MintingCurrencyWhereInput>;
  tokenIconMintingCurrency_some?: InputMaybe<MintingCurrencyWhereInput>;
  tokenThumbnailTokenPrinterConfiguration_every?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenThumbnailTokenPrinterConfiguration_none?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenThumbnailTokenPrinterConfiguration_some?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  videoHomePageVideoHero_every?: InputMaybe<HomePageVideoHeroWhereInput>;
  videoHomePageVideoHero_none?: InputMaybe<HomePageVideoHeroWhereInput>;
  videoHomePageVideoHero_some?: InputMaybe<HomePageVideoHeroWhereInput>;
  videoPosterImageHomePageVideoHero_every?: InputMaybe<HomePageVideoHeroWhereInput>;
  videoPosterImageHomePageVideoHero_none?: InputMaybe<HomePageVideoHeroWhereInput>;
  videoPosterImageHomePageVideoHero_some?: InputMaybe<HomePageVideoHeroWhereInput>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  width_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  width_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  width_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  width_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  width_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  width_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AssetWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AssetWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type BatchPayload = {
  __typename?: "BatchPayload";
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars["Long"]["output"];
};

export type Button = Entity & {
  __typename?: "Button";
  buttonLink?: Maybe<Scalars["String"]["output"]>;
  buttonText?: Maybe<Scalars["String"]["output"]>;
  buttonVariant?: Maybe<ButtonVariants>;
  externalLink?: Maybe<Scalars["Boolean"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** System stage field */
  stage: Stage;
};

export type ButtonConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ButtonWhereUniqueInput;
};

/** A connection to a list of items. */
export type ButtonConnection = {
  __typename?: "ButtonConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ButtonEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ButtonCreateInput = {
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  buttonVariant?: InputMaybe<ButtonVariants>;
  externalLink?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ButtonCreateManyInlineInput = {
  /** Create and connect multiple existing Button documents */
  create?: InputMaybe<Array<ButtonCreateInput>>;
};

export type ButtonCreateOneInlineInput = {
  /** Create and connect one Button document */
  create?: InputMaybe<ButtonCreateInput>;
};

export type ButtonCreateWithPositionInput = {
  /** Document to create */
  data: ButtonCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ButtonEdge = {
  __typename?: "ButtonEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Button;
};

/** Identifies documents */
export type ButtonManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ButtonWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  buttonLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  buttonLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  buttonLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  buttonLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  buttonLink_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  buttonLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  buttonText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  buttonText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  buttonText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  buttonText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  buttonText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  buttonText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  buttonVariant?: InputMaybe<ButtonVariants>;
  /** All values that are contained in given list. */
  buttonVariant_in?: InputMaybe<Array<InputMaybe<ButtonVariants>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonVariant_not?: InputMaybe<ButtonVariants>;
  /** All values that are not contained in given list. */
  buttonVariant_not_in?: InputMaybe<Array<InputMaybe<ButtonVariants>>>;
  externalLink?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  externalLink_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ButtonOrderByInput {
  buttonLink_ASC = "buttonLink_ASC",
  buttonLink_DESC = "buttonLink_DESC",
  buttonText_ASC = "buttonText_ASC",
  buttonText_DESC = "buttonText_DESC",
  buttonVariant_ASC = "buttonVariant_ASC",
  buttonVariant_DESC = "buttonVariant_DESC",
  externalLink_ASC = "externalLink_ASC",
  externalLink_DESC = "externalLink_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
}

export type ButtonParent = CtaSection | MintingStat;

export type ButtonParentConnectInput = {
  CtaSection?: InputMaybe<CtaSectionConnectInput>;
  MintingStat?: InputMaybe<MintingStatConnectInput>;
};

export type ButtonParentCreateInput = {
  CtaSection?: InputMaybe<CtaSectionCreateInput>;
  MintingStat?: InputMaybe<MintingStatCreateInput>;
};

export type ButtonParentCreateManyInlineInput = {
  /** Connect multiple existing ButtonParent documents */
  connect?: InputMaybe<Array<ButtonParentWhereUniqueInput>>;
  /** Create and connect multiple existing ButtonParent documents */
  create?: InputMaybe<Array<ButtonParentCreateInput>>;
};

export type ButtonParentCreateOneInlineInput = {
  /** Connect one existing ButtonParent document */
  connect?: InputMaybe<ButtonParentWhereUniqueInput>;
  /** Create and connect one ButtonParent document */
  create?: InputMaybe<ButtonParentCreateInput>;
};

export type ButtonParentUpdateInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateInput>;
  MintingStat?: InputMaybe<MintingStatUpdateInput>;
};

export type ButtonParentUpdateManyInlineInput = {
  /** Connect multiple existing ButtonParent documents */
  connect?: InputMaybe<Array<ButtonParentConnectInput>>;
  /** Create and connect multiple ButtonParent documents */
  create?: InputMaybe<Array<ButtonParentCreateInput>>;
  /** Delete multiple ButtonParent documents */
  delete?: InputMaybe<Array<ButtonParentWhereUniqueInput>>;
  /** Disconnect multiple ButtonParent documents */
  disconnect?: InputMaybe<Array<ButtonParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ButtonParent documents */
  set?: InputMaybe<Array<ButtonParentWhereUniqueInput>>;
  /** Update multiple ButtonParent documents */
  update?: InputMaybe<Array<ButtonParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ButtonParent documents */
  upsert?: InputMaybe<Array<ButtonParentUpsertWithNestedWhereUniqueInput>>;
};

export type ButtonParentUpdateManyWithNestedWhereInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateManyWithNestedWhereInput>;
  MintingStat?: InputMaybe<MintingStatUpdateManyWithNestedWhereInput>;
};

export type ButtonParentUpdateOneInlineInput = {
  /** Connect existing ButtonParent document */
  connect?: InputMaybe<ButtonParentWhereUniqueInput>;
  /** Create and connect one ButtonParent document */
  create?: InputMaybe<ButtonParentCreateInput>;
  /** Delete currently connected ButtonParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected ButtonParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ButtonParent document */
  update?: InputMaybe<ButtonParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ButtonParent document */
  upsert?: InputMaybe<ButtonParentUpsertWithNestedWhereUniqueInput>;
};

export type ButtonParentUpdateWithNestedWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateWithNestedWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatUpdateWithNestedWhereUniqueInput>;
};

export type ButtonParentUpsertWithNestedWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionUpsertWithNestedWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatUpsertWithNestedWhereUniqueInput>;
};

export type ButtonParentWhereInput = {
  CtaSection?: InputMaybe<CtaSectionWhereInput>;
  MintingStat?: InputMaybe<MintingStatWhereInput>;
};

export type ButtonParentWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatWhereUniqueInput>;
};

export type ButtonUpdateInput = {
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  buttonVariant?: InputMaybe<ButtonVariants>;
  externalLink?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ButtonUpdateManyInlineInput = {
  /** Create and connect multiple Button component instances */
  create?: InputMaybe<Array<ButtonCreateWithPositionInput>>;
  /** Delete multiple Button documents */
  delete?: InputMaybe<Array<ButtonWhereUniqueInput>>;
  /** Update multiple Button component instances */
  update?: InputMaybe<Array<ButtonUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Button component instances */
  upsert?: InputMaybe<Array<ButtonUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type ButtonUpdateManyInput = {
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  buttonVariant?: InputMaybe<ButtonVariants>;
  externalLink?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ButtonUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ButtonUpdateManyInput;
  /** Document search */
  where: ButtonWhereInput;
};

export type ButtonUpdateOneInlineInput = {
  /** Create and connect one Button document */
  create?: InputMaybe<ButtonCreateInput>;
  /** Delete currently connected Button document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Button document */
  update?: InputMaybe<ButtonUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Button document */
  upsert?: InputMaybe<ButtonUpsertWithNestedWhereUniqueInput>;
};

export type ButtonUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ButtonUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ButtonWhereUniqueInput;
};

export type ButtonUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ButtonUpdateInput;
  /** Unique document search */
  where: ButtonWhereUniqueInput;
};

export type ButtonUpsertInput = {
  /** Create document if it didn't exist */
  create: ButtonCreateInput;
  /** Update document if it exists */
  update: ButtonUpdateInput;
};

export type ButtonUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ButtonUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ButtonWhereUniqueInput;
};

export type ButtonUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ButtonUpsertInput;
  /** Unique document search */
  where: ButtonWhereUniqueInput;
};

export enum ButtonVariants {
  accent = "accent",
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
}

/** Identifies documents */
export type ButtonWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ButtonWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  buttonLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  buttonLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  buttonLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  buttonLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  buttonLink_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  buttonLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  buttonText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  buttonText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  buttonText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  buttonText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  buttonText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  buttonText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  buttonVariant?: InputMaybe<ButtonVariants>;
  /** All values that are contained in given list. */
  buttonVariant_in?: InputMaybe<Array<InputMaybe<ButtonVariants>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonVariant_not?: InputMaybe<ButtonVariants>;
  /** All values that are not contained in given list. */
  buttonVariant_not_in?: InputMaybe<Array<InputMaybe<ButtonVariants>>>;
  externalLink?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  externalLink_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

/** References Button record uniquely */
export type ButtonWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Model for EVM compatible blockchains. */
export type Chain = Entity &
  Node & {
    __typename?: "Chain";
    chainEnum?: Maybe<ChainIDs>;
    chainExplorerUrl?: Maybe<Scalars["String"]["output"]>;
    chainId?: Maybe<Scalars["Int"]["output"]>;
    chainName?: Maybe<Scalars["String"]["output"]>;
    chainNativeCurrencyIcon?: Maybe<Asset>;
    chainNativeCurrencySymbol?: Maybe<Scalars["String"]["output"]>;
    chainRpcEndpoint?: Maybe<Scalars["String"]["output"]>;
    chainSlug: Scalars["String"]["output"];
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<Chain>;
    /** List of Chain versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    mintingCurrencies: Array<MintingCurrency>;
    nftCollections: Array<NftCollection>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Model for EVM compatible blockchains. */
export type ChainChainNativeCurrencyIconArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Model for EVM compatible blockchains. */
export type ChainCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Model for EVM compatible blockchains. */
export type ChainDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

/** Model for EVM compatible blockchains. */
export type ChainHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

/** Model for EVM compatible blockchains. */
export type ChainMintingCurrenciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingCurrencyOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingCurrencyWhereInput>;
};

/** Model for EVM compatible blockchains. */
export type ChainNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

/** Model for EVM compatible blockchains. */
export type ChainPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Model for EVM compatible blockchains. */
export type ChainScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Model for EVM compatible blockchains. */
export type ChainUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ChainConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ChainWhereUniqueInput;
};

/** A connection to a list of items. */
export type ChainConnection = {
  __typename?: "ChainConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ChainEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ChainCreateInput = {
  chainEnum?: InputMaybe<ChainIDs>;
  chainExplorerUrl?: InputMaybe<Scalars["String"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
  chainName?: InputMaybe<Scalars["String"]["input"]>;
  chainNativeCurrencyIcon?: InputMaybe<AssetCreateOneInlineInput>;
  chainNativeCurrencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  chainRpcEndpoint?: InputMaybe<Scalars["String"]["input"]>;
  chainSlug: Scalars["String"]["input"];
  clqnyrusypnpp01uha9wscl5h?: InputMaybe<TrendingCollectionsCarouselCreateManyInlineInput>;
  clxyvqfbj04xj0dkc9qdte8ad?: InputMaybe<TokenPrinterConfigurationCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintingCurrencies?: InputMaybe<MintingCurrencyCreateManyInlineInput>;
  nftCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ChainCreateManyInlineInput = {
  /** Connect multiple existing Chain documents */
  connect?: InputMaybe<Array<ChainWhereUniqueInput>>;
  /** Create and connect multiple existing Chain documents */
  create?: InputMaybe<Array<ChainCreateInput>>;
};

export type ChainCreateOneInlineInput = {
  /** Connect one existing Chain document */
  connect?: InputMaybe<ChainWhereUniqueInput>;
  /** Create and connect one Chain document */
  create?: InputMaybe<ChainCreateInput>;
};

/** An edge in a connection. */
export type ChainEdge = {
  __typename?: "ChainEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Chain;
};

/** chain IDs */
export enum ChainIDs {
  ARBITRUM = "ARBITRUM",
  ARBITRUM_NOVA = "ARBITRUM_NOVA",
  ARBITRUM_ONE = "ARBITRUM_ONE",
  AVALANCHE = "AVALANCHE",
  AVAX = "AVAX",
  BASE = "BASE",
  BSC = "BSC",
  ETH = "ETH",
  ETHEREUM = "ETHEREUM",
  FANTOM = "FANTOM",
  LINEA = "LINEA",
  OPTIMISM = "OPTIMISM",
  POLYGON = "POLYGON",
  POLYGON_ZKEVM = "POLYGON_ZKEVM",
  SCROLL = "SCROLL",
  ZKSYNC_ERA = "ZKSYNC_ERA",
  ZORA = "ZORA",
}

/** Identifies documents */
export type ChainManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ChainWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ChainWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ChainWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chainEnum?: InputMaybe<ChainIDs>;
  /** All values that are contained in given list. */
  chainEnum_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainEnum_not?: InputMaybe<ChainIDs>;
  /** All values that are not contained in given list. */
  chainEnum_not_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  chainExplorerUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainExplorerUrl_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainExplorerUrl_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainExplorerUrl_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainExplorerUrl_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainExplorerUrl_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainExplorerUrl_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainExplorerUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainExplorerUrl_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainExplorerUrl_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  chainId_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  chainId_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  chainId_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  chainId_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  chainId_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  chainId_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  chainId_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  chainName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  chainName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainNativeCurrencyIcon?: InputMaybe<AssetWhereInput>;
  chainNativeCurrencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainNativeCurrencySymbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainNativeCurrencySymbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainNativeCurrencySymbol_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainNativeCurrencySymbol_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainNativeCurrencySymbol_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  chainNativeCurrencySymbol_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  chainNativeCurrencySymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainNativeCurrencySymbol_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  chainNativeCurrencySymbol_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  chainRpcEndpoint?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainRpcEndpoint_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainRpcEndpoint_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainRpcEndpoint_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainRpcEndpoint_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainRpcEndpoint_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainRpcEndpoint_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainRpcEndpoint_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainRpcEndpoint_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainRpcEndpoint_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  chainSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ChainWhereStageInput>;
  documentInStages_none?: InputMaybe<ChainWhereStageInput>;
  documentInStages_some?: InputMaybe<ChainWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mintingCurrencies_every?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_none?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_some?: InputMaybe<MintingCurrencyWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ChainOrderByInput {
  chainEnum_ASC = "chainEnum_ASC",
  chainEnum_DESC = "chainEnum_DESC",
  chainExplorerUrl_ASC = "chainExplorerUrl_ASC",
  chainExplorerUrl_DESC = "chainExplorerUrl_DESC",
  chainId_ASC = "chainId_ASC",
  chainId_DESC = "chainId_DESC",
  chainName_ASC = "chainName_ASC",
  chainName_DESC = "chainName_DESC",
  chainNativeCurrencySymbol_ASC = "chainNativeCurrencySymbol_ASC",
  chainNativeCurrencySymbol_DESC = "chainNativeCurrencySymbol_DESC",
  chainRpcEndpoint_ASC = "chainRpcEndpoint_ASC",
  chainRpcEndpoint_DESC = "chainRpcEndpoint_DESC",
  chainSlug_ASC = "chainSlug_ASC",
  chainSlug_DESC = "chainSlug_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type ChainUpdateInput = {
  chainEnum?: InputMaybe<ChainIDs>;
  chainExplorerUrl?: InputMaybe<Scalars["String"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
  chainName?: InputMaybe<Scalars["String"]["input"]>;
  chainNativeCurrencyIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  chainNativeCurrencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  chainRpcEndpoint?: InputMaybe<Scalars["String"]["input"]>;
  chainSlug?: InputMaybe<Scalars["String"]["input"]>;
  clqnyrusypnpp01uha9wscl5h?: InputMaybe<TrendingCollectionsCarouselUpdateManyInlineInput>;
  clxyvqfbj04xj0dkc9qdte8ad?: InputMaybe<TokenPrinterConfigurationUpdateManyInlineInput>;
  mintingCurrencies?: InputMaybe<MintingCurrencyUpdateManyInlineInput>;
  nftCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
};

export type ChainUpdateManyInlineInput = {
  /** Connect multiple existing Chain documents */
  connect?: InputMaybe<Array<ChainConnectInput>>;
  /** Create and connect multiple Chain documents */
  create?: InputMaybe<Array<ChainCreateInput>>;
  /** Delete multiple Chain documents */
  delete?: InputMaybe<Array<ChainWhereUniqueInput>>;
  /** Disconnect multiple Chain documents */
  disconnect?: InputMaybe<Array<ChainWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Chain documents */
  set?: InputMaybe<Array<ChainWhereUniqueInput>>;
  /** Update multiple Chain documents */
  update?: InputMaybe<Array<ChainUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Chain documents */
  upsert?: InputMaybe<Array<ChainUpsertWithNestedWhereUniqueInput>>;
};

export type ChainUpdateManyInput = {
  chainEnum?: InputMaybe<ChainIDs>;
  chainNativeCurrencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  chainSlug?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChainUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ChainUpdateManyInput;
  /** Document search */
  where: ChainWhereInput;
};

export type ChainUpdateOneInlineInput = {
  /** Connect existing Chain document */
  connect?: InputMaybe<ChainWhereUniqueInput>;
  /** Create and connect one Chain document */
  create?: InputMaybe<ChainCreateInput>;
  /** Delete currently connected Chain document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected Chain document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Chain document */
  update?: InputMaybe<ChainUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Chain document */
  upsert?: InputMaybe<ChainUpsertWithNestedWhereUniqueInput>;
};

export type ChainUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ChainUpdateInput;
  /** Unique document search */
  where: ChainWhereUniqueInput;
};

export type ChainUpsertInput = {
  /** Create document if it didn't exist */
  create: ChainCreateInput;
  /** Update document if it exists */
  update: ChainUpdateInput;
};

export type ChainUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ChainUpsertInput;
  /** Unique document search */
  where: ChainWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ChainWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type ChainWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ChainWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ChainWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ChainWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chainEnum?: InputMaybe<ChainIDs>;
  /** All values that are contained in given list. */
  chainEnum_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainEnum_not?: InputMaybe<ChainIDs>;
  /** All values that are not contained in given list. */
  chainEnum_not_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  chainExplorerUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainExplorerUrl_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainExplorerUrl_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainExplorerUrl_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainExplorerUrl_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainExplorerUrl_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainExplorerUrl_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainExplorerUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainExplorerUrl_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainExplorerUrl_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  chainId_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  chainId_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  chainId_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  chainId_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  chainId_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  chainId_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  chainId_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  chainName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  chainName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainNativeCurrencyIcon?: InputMaybe<AssetWhereInput>;
  chainNativeCurrencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainNativeCurrencySymbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainNativeCurrencySymbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainNativeCurrencySymbol_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainNativeCurrencySymbol_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainNativeCurrencySymbol_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  chainNativeCurrencySymbol_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  chainNativeCurrencySymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainNativeCurrencySymbol_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  chainNativeCurrencySymbol_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  chainRpcEndpoint?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainRpcEndpoint_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainRpcEndpoint_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainRpcEndpoint_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainRpcEndpoint_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainRpcEndpoint_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainRpcEndpoint_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainRpcEndpoint_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainRpcEndpoint_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainRpcEndpoint_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  chainSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ChainWhereStageInput>;
  documentInStages_none?: InputMaybe<ChainWhereStageInput>;
  documentInStages_some?: InputMaybe<ChainWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mintingCurrencies_every?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_none?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_some?: InputMaybe<MintingCurrencyWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ChainWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ChainWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ChainWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ChainWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ChainWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Chain record uniquely */
export type ChainWhereUniqueInput = {
  chainExplorerUrl?: InputMaybe<Scalars["String"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
  chainName?: InputMaybe<Scalars["String"]["input"]>;
  chainRpcEndpoint?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum CollectionCategories {
  alpha_project = "alpha_project",
  avatars = "avatars",
  coming_soon = "coming_soon",
  defi = "defi",
  featured = "featured",
  featured_mint = "featured_mint",
  fine_art = "fine_art",
  gamefi = "gamefi",
  launchpad = "launchpad",
  metaverse = "metaverse",
  mining = "mining",
  music = "music",
  utility = "utility",
}

export type CollectionCreator = Entity &
  Node & {
    __typename?: "CollectionCreator";
    /** What types of collections does this creator make? */
    categories: Array<CollectionCategories>;
    /**
     * This image is currently shown when configured as the SEO image.
     *
     * It should be 1200 X 630 pixels for best effect.
     */
    collectionCreatorBannerImage?: Maybe<Asset>;
    collectionCreatorBio?: Maybe<Scalars["String"]["output"]>;
    collectionCreatorName?: Maybe<Scalars["String"]["output"]>;
    collectionCreatorSlug?: Maybe<Scalars["String"]["output"]>;
    collectionCreatorThumbnailImage?: Maybe<Asset>;
    collectionCreatorUrl?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<CollectionCreator>;
    /** List of CollectionCreator versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    mintingProjects: Array<MintingProject>;
    nftCollections: Array<NftCollection>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type CollectionCreatorCollectionCreatorBannerImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CollectionCreatorCollectionCreatorThumbnailImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CollectionCreatorCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CollectionCreatorDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type CollectionCreatorHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type CollectionCreatorMintingProjectsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingProjectWhereInput>;
};

export type CollectionCreatorNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type CollectionCreatorPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CollectionCreatorScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type CollectionCreatorUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CollectionCreatorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CollectionCreatorWhereUniqueInput;
};

/** A connection to a list of items. */
export type CollectionCreatorConnection = {
  __typename?: "CollectionCreatorConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CollectionCreatorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CollectionCreatorCreateInput = {
  categories?: InputMaybe<Array<CollectionCategories>>;
  clrnt6xvj0gs201ka90e03qai?: InputMaybe<FeaturedCreatorGroupCreateManyInlineInput>;
  collectionCreatorBannerImage?: InputMaybe<AssetCreateOneInlineInput>;
  collectionCreatorBio?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorName?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorThumbnailImage?: InputMaybe<AssetCreateOneInlineInput>;
  collectionCreatorUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintingProjects?: InputMaybe<MintingProjectCreateManyInlineInput>;
  nftCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionCreatorCreateManyInlineInput = {
  /** Connect multiple existing CollectionCreator documents */
  connect?: InputMaybe<Array<CollectionCreatorWhereUniqueInput>>;
  /** Create and connect multiple existing CollectionCreator documents */
  create?: InputMaybe<Array<CollectionCreatorCreateInput>>;
};

export type CollectionCreatorCreateOneInlineInput = {
  /** Connect one existing CollectionCreator document */
  connect?: InputMaybe<CollectionCreatorWhereUniqueInput>;
  /** Create and connect one CollectionCreator document */
  create?: InputMaybe<CollectionCreatorCreateInput>;
};

/** An edge in a connection. */
export type CollectionCreatorEdge = {
  __typename?: "CollectionCreatorEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: CollectionCreator;
};

/** Identifies documents */
export type CollectionCreatorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  categories?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains *all* items provided to the filter */
  categories_contains_all?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  categories_contains_none?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains at least one item provided to the filter */
  categories_contains_some?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  categories_not?: InputMaybe<Array<CollectionCategories>>;
  collectionCreatorBannerImage?: InputMaybe<AssetWhereInput>;
  collectionCreatorBio?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorBio_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorBio_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorBio_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorBio_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorBio_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorBio_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorBio_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorBio_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionCreatorBio_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionCreatorName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorSlug_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorSlug_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionCreatorSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorThumbnailImage?: InputMaybe<AssetWhereInput>;
  collectionCreatorUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorUrl_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorUrl_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorUrl_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorUrl_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorUrl_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorUrl_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorUrl_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionCreatorUrl_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CollectionCreatorWhereStageInput>;
  documentInStages_none?: InputMaybe<CollectionCreatorWhereStageInput>;
  documentInStages_some?: InputMaybe<CollectionCreatorWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mintingProjects_every?: InputMaybe<MintingProjectWhereInput>;
  mintingProjects_none?: InputMaybe<MintingProjectWhereInput>;
  mintingProjects_some?: InputMaybe<MintingProjectWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum CollectionCreatorOrderByInput {
  categories_ASC = "categories_ASC",
  categories_DESC = "categories_DESC",
  collectionCreatorBio_ASC = "collectionCreatorBio_ASC",
  collectionCreatorBio_DESC = "collectionCreatorBio_DESC",
  collectionCreatorName_ASC = "collectionCreatorName_ASC",
  collectionCreatorName_DESC = "collectionCreatorName_DESC",
  collectionCreatorSlug_ASC = "collectionCreatorSlug_ASC",
  collectionCreatorSlug_DESC = "collectionCreatorSlug_DESC",
  collectionCreatorUrl_ASC = "collectionCreatorUrl_ASC",
  collectionCreatorUrl_DESC = "collectionCreatorUrl_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type CollectionCreatorUpdateInput = {
  categories?: InputMaybe<Array<CollectionCategories>>;
  clrnt6xvj0gs201ka90e03qai?: InputMaybe<FeaturedCreatorGroupUpdateManyInlineInput>;
  collectionCreatorBannerImage?: InputMaybe<AssetUpdateOneInlineInput>;
  collectionCreatorBio?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorName?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorThumbnailImage?: InputMaybe<AssetUpdateOneInlineInput>;
  collectionCreatorUrl?: InputMaybe<Scalars["String"]["input"]>;
  mintingProjects?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  nftCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
};

export type CollectionCreatorUpdateManyInlineInput = {
  /** Connect multiple existing CollectionCreator documents */
  connect?: InputMaybe<Array<CollectionCreatorConnectInput>>;
  /** Create and connect multiple CollectionCreator documents */
  create?: InputMaybe<Array<CollectionCreatorCreateInput>>;
  /** Delete multiple CollectionCreator documents */
  delete?: InputMaybe<Array<CollectionCreatorWhereUniqueInput>>;
  /** Disconnect multiple CollectionCreator documents */
  disconnect?: InputMaybe<Array<CollectionCreatorWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CollectionCreator documents */
  set?: InputMaybe<Array<CollectionCreatorWhereUniqueInput>>;
  /** Update multiple CollectionCreator documents */
  update?: InputMaybe<Array<CollectionCreatorUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CollectionCreator documents */
  upsert?: InputMaybe<Array<CollectionCreatorUpsertWithNestedWhereUniqueInput>>;
};

export type CollectionCreatorUpdateManyInput = {
  categories?: InputMaybe<Array<CollectionCategories>>;
  collectionCreatorBio?: InputMaybe<Scalars["String"]["input"]>;
};

export type CollectionCreatorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CollectionCreatorUpdateManyInput;
  /** Document search */
  where: CollectionCreatorWhereInput;
};

export type CollectionCreatorUpdateOneInlineInput = {
  /** Connect existing CollectionCreator document */
  connect?: InputMaybe<CollectionCreatorWhereUniqueInput>;
  /** Create and connect one CollectionCreator document */
  create?: InputMaybe<CollectionCreatorCreateInput>;
  /** Delete currently connected CollectionCreator document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected CollectionCreator document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single CollectionCreator document */
  update?: InputMaybe<CollectionCreatorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CollectionCreator document */
  upsert?: InputMaybe<CollectionCreatorUpsertWithNestedWhereUniqueInput>;
};

export type CollectionCreatorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CollectionCreatorUpdateInput;
  /** Unique document search */
  where: CollectionCreatorWhereUniqueInput;
};

export type CollectionCreatorUpsertInput = {
  /** Create document if it didn't exist */
  create: CollectionCreatorCreateInput;
  /** Update document if it exists */
  update: CollectionCreatorUpdateInput;
};

export type CollectionCreatorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CollectionCreatorUpsertInput;
  /** Unique document search */
  where: CollectionCreatorWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CollectionCreatorWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type CollectionCreatorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  categories?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains *all* items provided to the filter */
  categories_contains_all?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  categories_contains_none?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains at least one item provided to the filter */
  categories_contains_some?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  categories_not?: InputMaybe<Array<CollectionCategories>>;
  collectionCreatorBannerImage?: InputMaybe<AssetWhereInput>;
  collectionCreatorBio?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorBio_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorBio_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorBio_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorBio_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorBio_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorBio_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorBio_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorBio_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionCreatorBio_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionCreatorName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorSlug_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorSlug_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionCreatorSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorThumbnailImage?: InputMaybe<AssetWhereInput>;
  collectionCreatorUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorUrl_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorUrl_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorUrl_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorUrl_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorUrl_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorUrl_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorUrl_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionCreatorUrl_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CollectionCreatorWhereStageInput>;
  documentInStages_none?: InputMaybe<CollectionCreatorWhereStageInput>;
  documentInStages_some?: InputMaybe<CollectionCreatorWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mintingProjects_every?: InputMaybe<MintingProjectWhereInput>;
  mintingProjects_none?: InputMaybe<MintingProjectWhereInput>;
  mintingProjects_some?: InputMaybe<MintingProjectWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CollectionCreatorWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CollectionCreatorWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CollectionCreatorWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CollectionCreatorWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CollectionCreatorWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References CollectionCreator record uniquely */
export type CollectionCreatorWhereUniqueInput = {
  collectionCreatorName?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorUrl?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CollectionInfoTable = Entity & {
  __typename?: "CollectionInfoTable";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type CollectionInfoTableConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CollectionInfoTableWhereUniqueInput;
};

/** A connection to a list of items. */
export type CollectionInfoTableConnection = {
  __typename?: "CollectionInfoTableConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CollectionInfoTableEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CollectionInfoTableCreateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type CollectionInfoTableCreateManyInlineInput = {
  /** Create and connect multiple existing CollectionInfoTable documents */
  create?: InputMaybe<Array<CollectionInfoTableCreateInput>>;
};

export type CollectionInfoTableCreateOneInlineInput = {
  /** Create and connect one CollectionInfoTable document */
  create?: InputMaybe<CollectionInfoTableCreateInput>;
};

export type CollectionInfoTableCreateWithPositionInput = {
  /** Document to create */
  data: CollectionInfoTableCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CollectionInfoTableEdge = {
  __typename?: "CollectionInfoTableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: CollectionInfoTable;
};

/** Identifies documents */
export type CollectionInfoTableManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum CollectionInfoTableOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  value_ASC = "value_ASC",
  value_DESC = "value_DESC",
}

export type CollectionInfoTableParent =
  | MintingProject
  | MultiChainMintingProject;

export type CollectionInfoTableParentConnectInput = {
  MintingProject?: InputMaybe<MintingProjectConnectInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectConnectInput>;
};

export type CollectionInfoTableParentCreateInput = {
  MintingProject?: InputMaybe<MintingProjectCreateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateInput>;
};

export type CollectionInfoTableParentCreateManyInlineInput = {
  /** Connect multiple existing CollectionInfoTableParent documents */
  connect?: InputMaybe<Array<CollectionInfoTableParentWhereUniqueInput>>;
  /** Create and connect multiple existing CollectionInfoTableParent documents */
  create?: InputMaybe<Array<CollectionInfoTableParentCreateInput>>;
};

export type CollectionInfoTableParentCreateOneInlineInput = {
  /** Connect one existing CollectionInfoTableParent document */
  connect?: InputMaybe<CollectionInfoTableParentWhereUniqueInput>;
  /** Create and connect one CollectionInfoTableParent document */
  create?: InputMaybe<CollectionInfoTableParentCreateInput>;
};

export type CollectionInfoTableParentUpdateInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateInput>;
};

export type CollectionInfoTableParentUpdateManyInlineInput = {
  /** Connect multiple existing CollectionInfoTableParent documents */
  connect?: InputMaybe<Array<CollectionInfoTableParentConnectInput>>;
  /** Create and connect multiple CollectionInfoTableParent documents */
  create?: InputMaybe<Array<CollectionInfoTableParentCreateInput>>;
  /** Delete multiple CollectionInfoTableParent documents */
  delete?: InputMaybe<Array<CollectionInfoTableParentWhereUniqueInput>>;
  /** Disconnect multiple CollectionInfoTableParent documents */
  disconnect?: InputMaybe<Array<CollectionInfoTableParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CollectionInfoTableParent documents */
  set?: InputMaybe<Array<CollectionInfoTableParentWhereUniqueInput>>;
  /** Update multiple CollectionInfoTableParent documents */
  update?: InputMaybe<
    Array<CollectionInfoTableParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CollectionInfoTableParent documents */
  upsert?: InputMaybe<
    Array<CollectionInfoTableParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CollectionInfoTableParentUpdateManyWithNestedWhereInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateManyWithNestedWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyWithNestedWhereInput>;
};

export type CollectionInfoTableParentUpdateOneInlineInput = {
  /** Connect existing CollectionInfoTableParent document */
  connect?: InputMaybe<CollectionInfoTableParentWhereUniqueInput>;
  /** Create and connect one CollectionInfoTableParent document */
  create?: InputMaybe<CollectionInfoTableParentCreateInput>;
  /** Delete currently connected CollectionInfoTableParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected CollectionInfoTableParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single CollectionInfoTableParent document */
  update?: InputMaybe<CollectionInfoTableParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CollectionInfoTableParent document */
  upsert?: InputMaybe<CollectionInfoTableParentUpsertWithNestedWhereUniqueInput>;
};

export type CollectionInfoTableParentUpdateWithNestedWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>;
};

export type CollectionInfoTableParentUpsertWithNestedWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectUpsertWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type CollectionInfoTableParentWhereInput = {
  MintingProject?: InputMaybe<MintingProjectWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type CollectionInfoTableParentWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
};

export type CollectionInfoTableUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type CollectionInfoTableUpdateManyInlineInput = {
  /** Create and connect multiple CollectionInfoTable component instances */
  create?: InputMaybe<Array<CollectionInfoTableCreateWithPositionInput>>;
  /** Delete multiple CollectionInfoTable documents */
  delete?: InputMaybe<Array<CollectionInfoTableWhereUniqueInput>>;
  /** Update multiple CollectionInfoTable component instances */
  update?: InputMaybe<
    Array<CollectionInfoTableUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CollectionInfoTable component instances */
  upsert?: InputMaybe<
    Array<CollectionInfoTableUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CollectionInfoTableUpdateManyInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type CollectionInfoTableUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CollectionInfoTableUpdateManyInput;
  /** Document search */
  where: CollectionInfoTableWhereInput;
};

export type CollectionInfoTableUpdateOneInlineInput = {
  /** Create and connect one CollectionInfoTable document */
  create?: InputMaybe<CollectionInfoTableCreateInput>;
  /** Delete currently connected CollectionInfoTable document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single CollectionInfoTable document */
  update?: InputMaybe<CollectionInfoTableUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CollectionInfoTable document */
  upsert?: InputMaybe<CollectionInfoTableUpsertWithNestedWhereUniqueInput>;
};

export type CollectionInfoTableUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CollectionInfoTableUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CollectionInfoTableWhereUniqueInput;
};

export type CollectionInfoTableUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CollectionInfoTableUpdateInput;
  /** Unique document search */
  where: CollectionInfoTableWhereUniqueInput;
};

export type CollectionInfoTableUpsertInput = {
  /** Create document if it didn't exist */
  create: CollectionInfoTableCreateInput;
  /** Update document if it exists */
  update: CollectionInfoTableUpdateInput;
};

export type CollectionInfoTableUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CollectionInfoTableUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CollectionInfoTableWhereUniqueInput;
};

export type CollectionInfoTableUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CollectionInfoTableUpsertInput;
  /** Unique document search */
  where: CollectionInfoTableWhereUniqueInput;
};

/** Identifies documents */
export type CollectionInfoTableWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References CollectionInfoTable record uniquely */
export type CollectionInfoTableWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
  __typename?: "Color";
  css: Scalars["String"]["output"];
  hex: Scalars["Hex"]["output"];
  rgba: Rgba;
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: InputMaybe<Scalars["Hex"]["input"]>;
  rgba?: InputMaybe<RgbaInput>;
};

export type ColoredText = Entity & {
  __typename?: "ColoredText";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** System stage field */
  stage: Stage;
  tag?: Maybe<Tags>;
  text?: Maybe<Scalars["String"]["output"]>;
  textColor?: Maybe<Colors>;
};

export type ColoredTextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ColoredTextWhereUniqueInput;
};

/** A connection to a list of items. */
export type ColoredTextConnection = {
  __typename?: "ColoredTextConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ColoredTextEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ColoredTextCreateInput = {
  tag?: InputMaybe<Tags>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  textColor?: InputMaybe<Colors>;
};

export type ColoredTextCreateManyInlineInput = {
  /** Create and connect multiple existing ColoredText documents */
  create?: InputMaybe<Array<ColoredTextCreateInput>>;
};

export type ColoredTextCreateOneInlineInput = {
  /** Create and connect one ColoredText document */
  create?: InputMaybe<ColoredTextCreateInput>;
};

export type ColoredTextCreateWithPositionInput = {
  /** Document to create */
  data: ColoredTextCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ColoredTextEdge = {
  __typename?: "ColoredTextEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: ColoredText;
};

/** Identifies documents */
export type ColoredTextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  tag?: InputMaybe<Tags>;
  /** All values that are contained in given list. */
  tag_in?: InputMaybe<Array<InputMaybe<Tags>>>;
  /** Any other value that exists and is not equal to the given value. */
  tag_not?: InputMaybe<Tags>;
  /** All values that are not contained in given list. */
  tag_not_in?: InputMaybe<Array<InputMaybe<Tags>>>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  textColor?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  textColor_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  textColor_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  textColor_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ColoredTextOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  tag_ASC = "tag_ASC",
  tag_DESC = "tag_DESC",
  textColor_ASC = "textColor_ASC",
  textColor_DESC = "textColor_DESC",
  text_ASC = "text_ASC",
  text_DESC = "text_DESC",
}

export type ColoredTextParent = CtaSection | MintingStat;

export type ColoredTextParentConnectInput = {
  CtaSection?: InputMaybe<CtaSectionConnectInput>;
  MintingStat?: InputMaybe<MintingStatConnectInput>;
};

export type ColoredTextParentCreateInput = {
  CtaSection?: InputMaybe<CtaSectionCreateInput>;
  MintingStat?: InputMaybe<MintingStatCreateInput>;
};

export type ColoredTextParentCreateManyInlineInput = {
  /** Connect multiple existing ColoredTextParent documents */
  connect?: InputMaybe<Array<ColoredTextParentWhereUniqueInput>>;
  /** Create and connect multiple existing ColoredTextParent documents */
  create?: InputMaybe<Array<ColoredTextParentCreateInput>>;
};

export type ColoredTextParentCreateOneInlineInput = {
  /** Connect one existing ColoredTextParent document */
  connect?: InputMaybe<ColoredTextParentWhereUniqueInput>;
  /** Create and connect one ColoredTextParent document */
  create?: InputMaybe<ColoredTextParentCreateInput>;
};

export type ColoredTextParentUpdateInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateInput>;
  MintingStat?: InputMaybe<MintingStatUpdateInput>;
};

export type ColoredTextParentUpdateManyInlineInput = {
  /** Connect multiple existing ColoredTextParent documents */
  connect?: InputMaybe<Array<ColoredTextParentConnectInput>>;
  /** Create and connect multiple ColoredTextParent documents */
  create?: InputMaybe<Array<ColoredTextParentCreateInput>>;
  /** Delete multiple ColoredTextParent documents */
  delete?: InputMaybe<Array<ColoredTextParentWhereUniqueInput>>;
  /** Disconnect multiple ColoredTextParent documents */
  disconnect?: InputMaybe<Array<ColoredTextParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ColoredTextParent documents */
  set?: InputMaybe<Array<ColoredTextParentWhereUniqueInput>>;
  /** Update multiple ColoredTextParent documents */
  update?: InputMaybe<Array<ColoredTextParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ColoredTextParent documents */
  upsert?: InputMaybe<Array<ColoredTextParentUpsertWithNestedWhereUniqueInput>>;
};

export type ColoredTextParentUpdateManyWithNestedWhereInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateManyWithNestedWhereInput>;
  MintingStat?: InputMaybe<MintingStatUpdateManyWithNestedWhereInput>;
};

export type ColoredTextParentUpdateOneInlineInput = {
  /** Connect existing ColoredTextParent document */
  connect?: InputMaybe<ColoredTextParentWhereUniqueInput>;
  /** Create and connect one ColoredTextParent document */
  create?: InputMaybe<ColoredTextParentCreateInput>;
  /** Delete currently connected ColoredTextParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected ColoredTextParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ColoredTextParent document */
  update?: InputMaybe<ColoredTextParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ColoredTextParent document */
  upsert?: InputMaybe<ColoredTextParentUpsertWithNestedWhereUniqueInput>;
};

export type ColoredTextParentUpdateWithNestedWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateWithNestedWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatUpdateWithNestedWhereUniqueInput>;
};

export type ColoredTextParentUpsertWithNestedWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionUpsertWithNestedWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatUpsertWithNestedWhereUniqueInput>;
};

export type ColoredTextParentWhereInput = {
  CtaSection?: InputMaybe<CtaSectionWhereInput>;
  MintingStat?: InputMaybe<MintingStatWhereInput>;
};

export type ColoredTextParentWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatWhereUniqueInput>;
};

export type ColoredTextUpdateInput = {
  tag?: InputMaybe<Tags>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  textColor?: InputMaybe<Colors>;
};

export type ColoredTextUpdateManyInlineInput = {
  /** Create and connect multiple ColoredText component instances */
  create?: InputMaybe<Array<ColoredTextCreateWithPositionInput>>;
  /** Delete multiple ColoredText documents */
  delete?: InputMaybe<Array<ColoredTextWhereUniqueInput>>;
  /** Update multiple ColoredText component instances */
  update?: InputMaybe<
    Array<ColoredTextUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ColoredText component instances */
  upsert?: InputMaybe<
    Array<ColoredTextUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ColoredTextUpdateManyInput = {
  tag?: InputMaybe<Tags>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  textColor?: InputMaybe<Colors>;
};

export type ColoredTextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ColoredTextUpdateManyInput;
  /** Document search */
  where: ColoredTextWhereInput;
};

export type ColoredTextUpdateOneInlineInput = {
  /** Create and connect one ColoredText document */
  create?: InputMaybe<ColoredTextCreateInput>;
  /** Delete currently connected ColoredText document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ColoredText document */
  update?: InputMaybe<ColoredTextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ColoredText document */
  upsert?: InputMaybe<ColoredTextUpsertWithNestedWhereUniqueInput>;
};

export type ColoredTextUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ColoredTextUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ColoredTextWhereUniqueInput;
};

export type ColoredTextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ColoredTextUpdateInput;
  /** Unique document search */
  where: ColoredTextWhereUniqueInput;
};

export type ColoredTextUpsertInput = {
  /** Create document if it didn't exist */
  create: ColoredTextCreateInput;
  /** Update document if it exists */
  update: ColoredTextUpdateInput;
};

export type ColoredTextUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ColoredTextUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ColoredTextWhereUniqueInput;
};

export type ColoredTextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ColoredTextUpsertInput;
  /** Unique document search */
  where: ColoredTextWhereUniqueInput;
};

/** Identifies documents */
export type ColoredTextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  tag?: InputMaybe<Tags>;
  /** All values that are contained in given list. */
  tag_in?: InputMaybe<Array<InputMaybe<Tags>>>;
  /** Any other value that exists and is not equal to the given value. */
  tag_not?: InputMaybe<Tags>;
  /** All values that are not contained in given list. */
  tag_not_in?: InputMaybe<Array<InputMaybe<Tags>>>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  textColor?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  textColor_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  textColor_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  textColor_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References ColoredText record uniquely */
export type ColoredTextWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum Colors {
  accent = "accent",
  black = "black",
  dark = "dark",
  gray = "gray",
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  white = "white",
}

export enum ComponentName {
  LatestListingsCarousel = "LatestListingsCarousel",
  TopCollection = "TopCollection",
  TrendingCollectionsCarousel = "TrendingCollectionsCarousel",
  TrendingCollectionsGrid = "TrendingCollectionsGrid",
  TrendingCollectionsTable = "TrendingCollectionsTable",
}

export type ConnectPositionInput = {
  /** Connect document after specified document */
  after?: InputMaybe<Scalars["ID"]["input"]>;
  /** Connect document before specified document */
  before?: InputMaybe<Scalars["ID"]["input"]>;
  /** Connect document at last position */
  end?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Connect document at first position */
  start?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CtaSection = Entity &
  Node & {
    __typename?: "CtaSection";
    backgroundFrom?: Maybe<Colors>;
    backgroundTo?: Maybe<Colors>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<CtaSection>;
    /** List of CtaSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    image?: Maybe<Asset>;
    mainButton?: Maybe<Button>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    text: Array<CtaSectiontextUnion>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type CtaSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CtaSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type CtaSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type CtaSectionImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CtaSectionMainButtonArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CtaSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CtaSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type CtaSectionTextArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CtaSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CtaSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CtaSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type CtaSectionConnection = {
  __typename?: "CtaSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CtaSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CtaSectionCreateInput = {
  backgroundFrom?: InputMaybe<Colors>;
  backgroundTo?: InputMaybe<Colors>;
  cl4j5yie74k0l01yrcnvpe01m?: InputMaybe<PageCreateManyInlineInput>;
  cl80hlc9r07g801t316j76s3i?: InputMaybe<MintingProjectCreateManyInlineInput>;
  clpbort4i7heq01ui3tch3e14?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  clupv7ul401b008k54hvfdpjm?: InputMaybe<LandingPageCreateManyInlineInput>;
  clusr3adf0j2n07ixcgyh0sj6?: InputMaybe<LandingPageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  mainButton?: InputMaybe<ButtonCreateOneInlineInput>;
  text?: InputMaybe<CtaSectiontextUnionCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CtaSectionCreateManyInlineInput = {
  /** Connect multiple existing CtaSection documents */
  connect?: InputMaybe<Array<CtaSectionWhereUniqueInput>>;
  /** Create and connect multiple existing CtaSection documents */
  create?: InputMaybe<Array<CtaSectionCreateInput>>;
};

export type CtaSectionCreateOneInlineInput = {
  /** Connect one existing CtaSection document */
  connect?: InputMaybe<CtaSectionWhereUniqueInput>;
  /** Create and connect one CtaSection document */
  create?: InputMaybe<CtaSectionCreateInput>;
};

/** An edge in a connection. */
export type CtaSectionEdge = {
  __typename?: "CtaSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: CtaSection;
};

/** Identifies documents */
export type CtaSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundFrom?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  backgroundFrom_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundFrom_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  backgroundFrom_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  backgroundTo?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  backgroundTo_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundTo_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  backgroundTo_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CtaSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<CtaSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<CtaSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  mainButton?: InputMaybe<ButtonWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** All values in which the union is empty. */
  text_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  text_some?: InputMaybe<CtaSectiontextUnionWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum CtaSectionOrderByInput {
  backgroundFrom_ASC = "backgroundFrom_ASC",
  backgroundFrom_DESC = "backgroundFrom_DESC",
  backgroundTo_ASC = "backgroundTo_ASC",
  backgroundTo_DESC = "backgroundTo_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type CtaSectionUpdateInput = {
  backgroundFrom?: InputMaybe<Colors>;
  backgroundTo?: InputMaybe<Colors>;
  cl4j5yie74k0l01yrcnvpe01m?: InputMaybe<PageUpdateManyInlineInput>;
  cl80hlc9r07g801t316j76s3i?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  clpbort4i7heq01ui3tch3e14?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  clupv7ul401b008k54hvfdpjm?: InputMaybe<LandingPageUpdateManyInlineInput>;
  clusr3adf0j2n07ixcgyh0sj6?: InputMaybe<LandingPageUpdateManyInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  mainButton?: InputMaybe<ButtonUpdateOneInlineInput>;
  text?: InputMaybe<CtaSectiontextUnionUpdateManyInlineInput>;
};

export type CtaSectionUpdateManyInlineInput = {
  /** Connect multiple existing CtaSection documents */
  connect?: InputMaybe<Array<CtaSectionConnectInput>>;
  /** Create and connect multiple CtaSection documents */
  create?: InputMaybe<Array<CtaSectionCreateInput>>;
  /** Delete multiple CtaSection documents */
  delete?: InputMaybe<Array<CtaSectionWhereUniqueInput>>;
  /** Disconnect multiple CtaSection documents */
  disconnect?: InputMaybe<Array<CtaSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CtaSection documents */
  set?: InputMaybe<Array<CtaSectionWhereUniqueInput>>;
  /** Update multiple CtaSection documents */
  update?: InputMaybe<Array<CtaSectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CtaSection documents */
  upsert?: InputMaybe<Array<CtaSectionUpsertWithNestedWhereUniqueInput>>;
};

export type CtaSectionUpdateManyInput = {
  backgroundFrom?: InputMaybe<Colors>;
  backgroundTo?: InputMaybe<Colors>;
};

export type CtaSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CtaSectionUpdateManyInput;
  /** Document search */
  where: CtaSectionWhereInput;
};

export type CtaSectionUpdateOneInlineInput = {
  /** Connect existing CtaSection document */
  connect?: InputMaybe<CtaSectionWhereUniqueInput>;
  /** Create and connect one CtaSection document */
  create?: InputMaybe<CtaSectionCreateInput>;
  /** Delete currently connected CtaSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected CtaSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single CtaSection document */
  update?: InputMaybe<CtaSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CtaSection document */
  upsert?: InputMaybe<CtaSectionUpsertWithNestedWhereUniqueInput>;
};

export type CtaSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CtaSectionUpdateInput;
  /** Unique document search */
  where: CtaSectionWhereUniqueInput;
};

export type CtaSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: CtaSectionCreateInput;
  /** Update document if it exists */
  update: CtaSectionUpdateInput;
};

export type CtaSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CtaSectionUpsertInput;
  /** Unique document search */
  where: CtaSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CtaSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type CtaSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundFrom?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  backgroundFrom_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundFrom_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  backgroundFrom_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  backgroundTo?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  backgroundTo_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundTo_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  backgroundTo_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CtaSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<CtaSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<CtaSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  mainButton?: InputMaybe<ButtonWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** All values in which the union is empty. */
  text_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  text_some?: InputMaybe<CtaSectiontextUnionWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CtaSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CtaSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CtaSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CtaSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CtaSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References CtaSection record uniquely */
export type CtaSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CtaSectiontextUnion = ColoredText;

export type CtaSectiontextUnionConnectInput = {
  ColoredText?: InputMaybe<ColoredTextConnectInput>;
};

export type CtaSectiontextUnionCreateInput = {
  ColoredText?: InputMaybe<ColoredTextCreateInput>;
};

export type CtaSectiontextUnionCreateManyInlineInput = {
  /** Create and connect multiple existing CtaSectiontextUnion documents */
  create?: InputMaybe<Array<CtaSectiontextUnionCreateInput>>;
};

export type CtaSectiontextUnionCreateOneInlineInput = {
  /** Create and connect one CtaSectiontextUnion document */
  create?: InputMaybe<CtaSectiontextUnionCreateInput>;
};

export type CtaSectiontextUnionCreateWithPositionInput = {
  ColoredText?: InputMaybe<ColoredTextCreateWithPositionInput>;
};

export type CtaSectiontextUnionUpdateInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateInput>;
};

export type CtaSectiontextUnionUpdateManyInlineInput = {
  /** Create and connect multiple CtaSectiontextUnion component instances */
  create?: InputMaybe<Array<CtaSectiontextUnionCreateWithPositionInput>>;
  /** Delete multiple CtaSectiontextUnion documents */
  delete?: InputMaybe<Array<CtaSectiontextUnionWhereUniqueInput>>;
  /** Update multiple CtaSectiontextUnion component instances */
  update?: InputMaybe<
    Array<CtaSectiontextUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CtaSectiontextUnion component instances */
  upsert?: InputMaybe<
    Array<CtaSectiontextUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CtaSectiontextUnionUpdateManyWithNestedWhereInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateManyWithNestedWhereInput>;
};

export type CtaSectiontextUnionUpdateOneInlineInput = {
  /** Create and connect one CtaSectiontextUnion document */
  create?: InputMaybe<CtaSectiontextUnionCreateInput>;
  /** Delete currently connected CtaSectiontextUnion document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single CtaSectiontextUnion document */
  update?: InputMaybe<CtaSectiontextUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CtaSectiontextUnion document */
  upsert?: InputMaybe<CtaSectiontextUnionUpsertWithNestedWhereUniqueInput>;
};

export type CtaSectiontextUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CtaSectiontextUnionUpdateWithNestedWhereUniqueInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateWithNestedWhereUniqueInput>;
};

export type CtaSectiontextUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  ColoredText?: InputMaybe<ColoredTextUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CtaSectiontextUnionUpsertWithNestedWhereUniqueInput = {
  ColoredText?: InputMaybe<ColoredTextUpsertWithNestedWhereUniqueInput>;
};

export type CtaSectiontextUnionWhereInput = {
  ColoredText?: InputMaybe<ColoredTextWhereInput>;
};

export type CtaSectiontextUnionWhereUniqueInput = {
  ColoredText?: InputMaybe<ColoredTextWhereUniqueInput>;
};

export enum Currencies {
  ALPHA = "ALPHA",
  AVAX = "AVAX",
  BUSD = "BUSD",
  ETHER = "ETHER",
  USDC = "USDC",
  USDCe = "USDCe",
  USDT = "USDT",
  WAVAX = "WAVAX",
  WBNB = "WBNB",
  WETH = "WETH",
  WFTM = "WFTM",
  WMATIC = "WMATIC",
}

export enum DocumentFileTypes {
  doc = "doc",
  docx = "docx",
  html = "html",
  jpg = "jpg",
  odp = "odp",
  ods = "ods",
  odt = "odt",
  pdf = "pdf",
  png = "png",
  ppt = "ppt",
  pptx = "pptx",
  svg = "svg",
  txt = "txt",
  webp = "webp",
  xls = "xls",
  xlsx = "xlsx",
}

export type DocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   *
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: InputMaybe<DocumentFileTypes>;
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: InputMaybe<DocumentOutputInput>;
};

export type DocumentVersion = {
  __typename?: "DocumentVersion";
  createdAt: Scalars["DateTime"]["output"];
  data?: Maybe<Scalars["Json"]["output"]>;
  id: Scalars["ID"]["output"];
  revision: Scalars["Int"]["output"];
  stage: Stage;
};

/** An object with an ID */
export type Entity = {
  /** The id of the object. */
  id: Scalars["ID"]["output"];
  /** The Stage of an object */
  stage: Stage;
};

/** This enumeration holds all typenames that implement the Entity interface. Components and models implement the Entity interface. */
export enum EntityTypeName {
  /** Asset system model */
  Asset = "Asset",
  Button = "Button",
  /** Model for EVM compatible blockchains. */
  Chain = "Chain",
  CollectionCreator = "CollectionCreator",
  CollectionInfoTable = "CollectionInfoTable",
  ColoredText = "ColoredText",
  CtaSection = "CtaSection",
  FAQSection = "FAQSection",
  Faq = "Faq",
  FeaturedCollectionGroup = "FeaturedCollectionGroup",
  /** Set up an array of featured creators in a specified order for display on the marketplace. */
  FeaturedCreatorGroup = "FeaturedCreatorGroup",
  HomePageVideoHero = "HomePageVideoHero",
  ImageWithAltTextAndLink = "ImageWithAltTextAndLink",
  LandingPage = "LandingPage",
  Menu = "Menu",
  MiningContainer = "MiningContainer",
  MiningHeaderNotice = "MiningHeaderNotice",
  MintingCurrency = "MintingCurrency",
  MintingProject = "MintingProject",
  MintingStat = "MintingStat",
  MrBurnzToken = "MrBurnzToken",
  MultiChainMintingProject = "MultiChainMintingProject",
  NavigationItem = "NavigationItem",
  NftCollection = "NftCollection",
  Page = "Page",
  /** Scheduled Operation system model */
  ScheduledOperation = "ScheduledOperation",
  /** Scheduled Release system model */
  ScheduledRelease = "ScheduledRelease",
  SecurityInfo = "SecurityInfo",
  SmallIconHeadingText = "SmallIconHeadingText",
  TeamMemberImageNameTitle = "TeamMemberImageNameTitle",
  TokenPrinterConfiguration = "TokenPrinterConfiguration",
  TrendingCollectionsCarousel = "TrendingCollectionsCarousel",
  /** User system model */
  User = "User",
}

/** Allows to specify input to query models and components directly */
export type EntityWhereInput = {
  /** The ID of an object */
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Locale>;
  stage: Stage;
  /** The Type name of an object */
  typename: EntityTypeName;
};

export type FaqSection = Entity &
  Node & {
    __typename?: "FAQSection";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<FaqSection>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of FAQSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    questions: Array<Faq>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type FaqSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FaqSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type FaqSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type FaqSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FaqSectionQuestionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqWhereInput>;
};

export type FaqSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type FaqSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FaqSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FaqSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type FaqSectionConnection = {
  __typename?: "FAQSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FaqSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FaqSectionCreateInput = {
  cl4j4cnh64io001yr80fxbpn6?: InputMaybe<PageCreateManyInlineInput>;
  cl4n0tv8h8bz301yra637bcv3?: InputMaybe<PageCreateManyInlineInput>;
  cl83951bc0oze01ujgne6dg9h?: InputMaybe<MintingProjectCreateManyInlineInput>;
  clpboqtd17lkl01t6hy7g7us0?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  questions?: InputMaybe<FaqCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FaqSectionCreateManyInlineInput = {
  /** Connect multiple existing FAQSection documents */
  connect?: InputMaybe<Array<FaqSectionWhereUniqueInput>>;
  /** Create and connect multiple existing FAQSection documents */
  create?: InputMaybe<Array<FaqSectionCreateInput>>;
};

export type FaqSectionCreateOneInlineInput = {
  /** Connect one existing FAQSection document */
  connect?: InputMaybe<FaqSectionWhereUniqueInput>;
  /** Create and connect one FAQSection document */
  create?: InputMaybe<FaqSectionCreateInput>;
};

/** An edge in a connection. */
export type FaqSectionEdge = {
  __typename?: "FAQSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: FaqSection;
};

/** Identifies documents */
export type FaqSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FaqSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<FaqSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<FaqSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  questions_every?: InputMaybe<FaqWhereInput>;
  questions_none?: InputMaybe<FaqWhereInput>;
  questions_some?: InputMaybe<FaqWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FaqSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type FaqSectionUpdateInput = {
  cl4j4cnh64io001yr80fxbpn6?: InputMaybe<PageUpdateManyInlineInput>;
  cl4n0tv8h8bz301yra637bcv3?: InputMaybe<PageUpdateManyInlineInput>;
  cl83951bc0oze01ujgne6dg9h?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  clpboqtd17lkl01t6hy7g7us0?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  questions?: InputMaybe<FaqUpdateManyInlineInput>;
};

export type FaqSectionUpdateManyInlineInput = {
  /** Connect multiple existing FAQSection documents */
  connect?: InputMaybe<Array<FaqSectionConnectInput>>;
  /** Create and connect multiple FAQSection documents */
  create?: InputMaybe<Array<FaqSectionCreateInput>>;
  /** Delete multiple FAQSection documents */
  delete?: InputMaybe<Array<FaqSectionWhereUniqueInput>>;
  /** Disconnect multiple FAQSection documents */
  disconnect?: InputMaybe<Array<FaqSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FAQSection documents */
  set?: InputMaybe<Array<FaqSectionWhereUniqueInput>>;
  /** Update multiple FAQSection documents */
  update?: InputMaybe<Array<FaqSectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple FAQSection documents */
  upsert?: InputMaybe<Array<FaqSectionUpsertWithNestedWhereUniqueInput>>;
};

export type FaqSectionUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
};

export type FaqSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FaqSectionUpdateManyInput;
  /** Document search */
  where: FaqSectionWhereInput;
};

export type FaqSectionUpdateOneInlineInput = {
  /** Connect existing FAQSection document */
  connect?: InputMaybe<FaqSectionWhereUniqueInput>;
  /** Create and connect one FAQSection document */
  create?: InputMaybe<FaqSectionCreateInput>;
  /** Delete currently connected FAQSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected FAQSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single FAQSection document */
  update?: InputMaybe<FaqSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FAQSection document */
  upsert?: InputMaybe<FaqSectionUpsertWithNestedWhereUniqueInput>;
};

export type FaqSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FaqSectionUpdateInput;
  /** Unique document search */
  where: FaqSectionWhereUniqueInput;
};

export type FaqSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: FaqSectionCreateInput;
  /** Update document if it exists */
  update: FaqSectionUpdateInput;
};

export type FaqSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FaqSectionUpsertInput;
  /** Unique document search */
  where: FaqSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FaqSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type FaqSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FaqSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<FaqSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<FaqSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  questions_every?: InputMaybe<FaqWhereInput>;
  questions_none?: InputMaybe<FaqWhereInput>;
  questions_some?: InputMaybe<FaqWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FaqSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FaqSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References FAQSection record uniquely */
export type FaqSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Faq = Entity & {
  __typename?: "Faq";
  answer?: Maybe<Scalars["String"]["output"]>;
  answerRichText?: Maybe<RichText>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  question?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
};

export type FaqConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FaqWhereUniqueInput;
};

/** A connection to a list of items. */
export type FaqConnection = {
  __typename?: "FaqConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FaqEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FaqCreateInput = {
  answer?: InputMaybe<Scalars["String"]["input"]>;
  answerRichText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
};

export type FaqCreateManyInlineInput = {
  /** Create and connect multiple existing Faq documents */
  create?: InputMaybe<Array<FaqCreateInput>>;
};

export type FaqCreateOneInlineInput = {
  /** Create and connect one Faq document */
  create?: InputMaybe<FaqCreateInput>;
};

export type FaqCreateWithPositionInput = {
  /** Document to create */
  data: FaqCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type FaqEdge = {
  __typename?: "FaqEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Faq;
};

/** Identifies documents */
export type FaqManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  answer?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  answer_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  answer_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  answer_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  answer_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  answer_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  answer_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  answer_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  answer_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  answer_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  question_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  question_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  question_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  question_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  question_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  question_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  question_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  question_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  question_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum FaqOrderByInput {
  answer_ASC = "answer_ASC",
  answer_DESC = "answer_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  question_ASC = "question_ASC",
  question_DESC = "question_DESC",
}

export type FaqParent =
  | FaqSection
  | LandingPage
  | MintingProject
  | MultiChainMintingProject;

export type FaqParentConnectInput = {
  FAQSection?: InputMaybe<FaqSectionConnectInput>;
  LandingPage?: InputMaybe<LandingPageConnectInput>;
  MintingProject?: InputMaybe<MintingProjectConnectInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectConnectInput>;
};

export type FaqParentCreateInput = {
  FAQSection?: InputMaybe<FaqSectionCreateInput>;
  LandingPage?: InputMaybe<LandingPageCreateInput>;
  MintingProject?: InputMaybe<MintingProjectCreateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateInput>;
};

export type FaqParentCreateManyInlineInput = {
  /** Connect multiple existing FaqParent documents */
  connect?: InputMaybe<Array<FaqParentWhereUniqueInput>>;
  /** Create and connect multiple existing FaqParent documents */
  create?: InputMaybe<Array<FaqParentCreateInput>>;
};

export type FaqParentCreateOneInlineInput = {
  /** Connect one existing FaqParent document */
  connect?: InputMaybe<FaqParentWhereUniqueInput>;
  /** Create and connect one FaqParent document */
  create?: InputMaybe<FaqParentCreateInput>;
};

export type FaqParentUpdateInput = {
  FAQSection?: InputMaybe<FaqSectionUpdateInput>;
  LandingPage?: InputMaybe<LandingPageUpdateInput>;
  MintingProject?: InputMaybe<MintingProjectUpdateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateInput>;
};

export type FaqParentUpdateManyInlineInput = {
  /** Connect multiple existing FaqParent documents */
  connect?: InputMaybe<Array<FaqParentConnectInput>>;
  /** Create and connect multiple FaqParent documents */
  create?: InputMaybe<Array<FaqParentCreateInput>>;
  /** Delete multiple FaqParent documents */
  delete?: InputMaybe<Array<FaqParentWhereUniqueInput>>;
  /** Disconnect multiple FaqParent documents */
  disconnect?: InputMaybe<Array<FaqParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FaqParent documents */
  set?: InputMaybe<Array<FaqParentWhereUniqueInput>>;
  /** Update multiple FaqParent documents */
  update?: InputMaybe<Array<FaqParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple FaqParent documents */
  upsert?: InputMaybe<Array<FaqParentUpsertWithNestedWhereUniqueInput>>;
};

export type FaqParentUpdateManyWithNestedWhereInput = {
  FAQSection?: InputMaybe<FaqSectionUpdateManyWithNestedWhereInput>;
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>;
  MintingProject?: InputMaybe<MintingProjectUpdateManyWithNestedWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyWithNestedWhereInput>;
};

export type FaqParentUpdateOneInlineInput = {
  /** Connect existing FaqParent document */
  connect?: InputMaybe<FaqParentWhereUniqueInput>;
  /** Create and connect one FaqParent document */
  create?: InputMaybe<FaqParentCreateInput>;
  /** Delete currently connected FaqParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected FaqParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single FaqParent document */
  update?: InputMaybe<FaqParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FaqParent document */
  upsert?: InputMaybe<FaqParentUpsertWithNestedWhereUniqueInput>;
};

export type FaqParentUpdateWithNestedWhereUniqueInput = {
  FAQSection?: InputMaybe<FaqSectionUpdateWithNestedWhereUniqueInput>;
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>;
  MintingProject?: InputMaybe<MintingProjectUpdateWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>;
};

export type FaqParentUpsertWithNestedWhereUniqueInput = {
  FAQSection?: InputMaybe<FaqSectionUpsertWithNestedWhereUniqueInput>;
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>;
  MintingProject?: InputMaybe<MintingProjectUpsertWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type FaqParentWhereInput = {
  FAQSection?: InputMaybe<FaqSectionWhereInput>;
  LandingPage?: InputMaybe<LandingPageWhereInput>;
  MintingProject?: InputMaybe<MintingProjectWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type FaqParentWhereUniqueInput = {
  FAQSection?: InputMaybe<FaqSectionWhereUniqueInput>;
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>;
  MintingProject?: InputMaybe<MintingProjectWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
};

export type FaqUpdateInput = {
  answer?: InputMaybe<Scalars["String"]["input"]>;
  answerRichText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
};

export type FaqUpdateManyInlineInput = {
  /** Create and connect multiple Faq component instances */
  create?: InputMaybe<Array<FaqCreateWithPositionInput>>;
  /** Delete multiple Faq documents */
  delete?: InputMaybe<Array<FaqWhereUniqueInput>>;
  /** Update multiple Faq component instances */
  update?: InputMaybe<Array<FaqUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Faq component instances */
  upsert?: InputMaybe<Array<FaqUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type FaqUpdateManyInput = {
  answer?: InputMaybe<Scalars["String"]["input"]>;
  answerRichText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
};

export type FaqUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FaqUpdateManyInput;
  /** Document search */
  where: FaqWhereInput;
};

export type FaqUpdateOneInlineInput = {
  /** Create and connect one Faq document */
  create?: InputMaybe<FaqCreateInput>;
  /** Delete currently connected Faq document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Faq document */
  update?: InputMaybe<FaqUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Faq document */
  upsert?: InputMaybe<FaqUpsertWithNestedWhereUniqueInput>;
};

export type FaqUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FaqUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FaqWhereUniqueInput;
};

export type FaqUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FaqUpdateInput;
  /** Unique document search */
  where: FaqWhereUniqueInput;
};

export type FaqUpsertInput = {
  /** Create document if it didn't exist */
  create: FaqCreateInput;
  /** Update document if it exists */
  update: FaqUpdateInput;
};

export type FaqUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FaqUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FaqWhereUniqueInput;
};

export type FaqUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FaqUpsertInput;
  /** Unique document search */
  where: FaqWhereUniqueInput;
};

/** Identifies documents */
export type FaqWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  answer?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  answer_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  answer_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  answer_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  answer_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  answer_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  answer_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  answer_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  answer_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  answer_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  question_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  question_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  question_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  question_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  question_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  question_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  question_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  question_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  question_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References Faq record uniquely */
export type FaqWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type FeaturedCollectionGroup = Entity &
  Node & {
    __typename?: "FeaturedCollectionGroup";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<FeaturedCollectionGroup>;
    groupName?: Maybe<Scalars["String"]["output"]>;
    /** List of FeaturedCollectionGroup versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    nftCollections: Array<NftCollection>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type FeaturedCollectionGroupCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FeaturedCollectionGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type FeaturedCollectionGroupHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type FeaturedCollectionGroupNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type FeaturedCollectionGroupPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FeaturedCollectionGroupScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type FeaturedCollectionGroupUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FeaturedCollectionGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FeaturedCollectionGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type FeaturedCollectionGroupConnection = {
  __typename?: "FeaturedCollectionGroupConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FeaturedCollectionGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FeaturedCollectionGroupCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  nftCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FeaturedCollectionGroupCreateManyInlineInput = {
  /** Connect multiple existing FeaturedCollectionGroup documents */
  connect?: InputMaybe<Array<FeaturedCollectionGroupWhereUniqueInput>>;
  /** Create and connect multiple existing FeaturedCollectionGroup documents */
  create?: InputMaybe<Array<FeaturedCollectionGroupCreateInput>>;
};

export type FeaturedCollectionGroupCreateOneInlineInput = {
  /** Connect one existing FeaturedCollectionGroup document */
  connect?: InputMaybe<FeaturedCollectionGroupWhereUniqueInput>;
  /** Create and connect one FeaturedCollectionGroup document */
  create?: InputMaybe<FeaturedCollectionGroupCreateInput>;
};

/** An edge in a connection. */
export type FeaturedCollectionGroupEdge = {
  __typename?: "FeaturedCollectionGroupEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: FeaturedCollectionGroup;
};

/** Identifies documents */
export type FeaturedCollectionGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  groupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  groupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  groupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  groupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  groupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  groupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  groupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FeaturedCollectionGroupOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  groupName_ASC = "groupName_ASC",
  groupName_DESC = "groupName_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type FeaturedCollectionGroupUpdateInput = {
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  nftCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
};

export type FeaturedCollectionGroupUpdateManyInlineInput = {
  /** Connect multiple existing FeaturedCollectionGroup documents */
  connect?: InputMaybe<Array<FeaturedCollectionGroupConnectInput>>;
  /** Create and connect multiple FeaturedCollectionGroup documents */
  create?: InputMaybe<Array<FeaturedCollectionGroupCreateInput>>;
  /** Delete multiple FeaturedCollectionGroup documents */
  delete?: InputMaybe<Array<FeaturedCollectionGroupWhereUniqueInput>>;
  /** Disconnect multiple FeaturedCollectionGroup documents */
  disconnect?: InputMaybe<Array<FeaturedCollectionGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FeaturedCollectionGroup documents */
  set?: InputMaybe<Array<FeaturedCollectionGroupWhereUniqueInput>>;
  /** Update multiple FeaturedCollectionGroup documents */
  update?: InputMaybe<
    Array<FeaturedCollectionGroupUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple FeaturedCollectionGroup documents */
  upsert?: InputMaybe<
    Array<FeaturedCollectionGroupUpsertWithNestedWhereUniqueInput>
  >;
};

export type FeaturedCollectionGroupUpdateManyInput = {
  groupName?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeaturedCollectionGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FeaturedCollectionGroupUpdateManyInput;
  /** Document search */
  where: FeaturedCollectionGroupWhereInput;
};

export type FeaturedCollectionGroupUpdateOneInlineInput = {
  /** Connect existing FeaturedCollectionGroup document */
  connect?: InputMaybe<FeaturedCollectionGroupWhereUniqueInput>;
  /** Create and connect one FeaturedCollectionGroup document */
  create?: InputMaybe<FeaturedCollectionGroupCreateInput>;
  /** Delete currently connected FeaturedCollectionGroup document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected FeaturedCollectionGroup document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single FeaturedCollectionGroup document */
  update?: InputMaybe<FeaturedCollectionGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FeaturedCollectionGroup document */
  upsert?: InputMaybe<FeaturedCollectionGroupUpsertWithNestedWhereUniqueInput>;
};

export type FeaturedCollectionGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FeaturedCollectionGroupUpdateInput;
  /** Unique document search */
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type FeaturedCollectionGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: FeaturedCollectionGroupCreateInput;
  /** Update document if it exists */
  update: FeaturedCollectionGroupUpdateInput;
};

export type FeaturedCollectionGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FeaturedCollectionGroupUpsertInput;
  /** Unique document search */
  where: FeaturedCollectionGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FeaturedCollectionGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type FeaturedCollectionGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  groupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  groupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  groupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  groupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  groupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  groupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  groupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FeaturedCollectionGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCollectionGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCollectionGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCollectionGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FeaturedCollectionGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References FeaturedCollectionGroup record uniquely */
export type FeaturedCollectionGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroup = Entity &
  Node & {
    __typename?: "FeaturedCreatorGroup";
    /** Add featured creators here and specify order to be displayed. */
    collectionCreators: Array<CollectionCreator>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<FeaturedCreatorGroup>;
    groupName?: Maybe<Scalars["String"]["output"]>;
    /** List of FeaturedCreatorGroup versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupCollectionCreatorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionCreatorOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionCreatorWhereInput>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FeaturedCreatorGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FeaturedCreatorGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type FeaturedCreatorGroupConnection = {
  __typename?: "FeaturedCreatorGroupConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FeaturedCreatorGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FeaturedCreatorGroupCreateInput = {
  collectionCreators?: InputMaybe<CollectionCreatorCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FeaturedCreatorGroupCreateManyInlineInput = {
  /** Connect multiple existing FeaturedCreatorGroup documents */
  connect?: InputMaybe<Array<FeaturedCreatorGroupWhereUniqueInput>>;
  /** Create and connect multiple existing FeaturedCreatorGroup documents */
  create?: InputMaybe<Array<FeaturedCreatorGroupCreateInput>>;
};

export type FeaturedCreatorGroupCreateOneInlineInput = {
  /** Connect one existing FeaturedCreatorGroup document */
  connect?: InputMaybe<FeaturedCreatorGroupWhereUniqueInput>;
  /** Create and connect one FeaturedCreatorGroup document */
  create?: InputMaybe<FeaturedCreatorGroupCreateInput>;
};

/** An edge in a connection. */
export type FeaturedCreatorGroupEdge = {
  __typename?: "FeaturedCreatorGroupEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: FeaturedCreatorGroup;
};

/** Identifies documents */
export type FeaturedCreatorGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreators_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreators_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreators_some?: InputMaybe<CollectionCreatorWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  groupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  groupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  groupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  groupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  groupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  groupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  groupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FeaturedCreatorGroupOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  groupName_ASC = "groupName_ASC",
  groupName_DESC = "groupName_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type FeaturedCreatorGroupUpdateInput = {
  collectionCreators?: InputMaybe<CollectionCreatorUpdateManyInlineInput>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeaturedCreatorGroupUpdateManyInlineInput = {
  /** Connect multiple existing FeaturedCreatorGroup documents */
  connect?: InputMaybe<Array<FeaturedCreatorGroupConnectInput>>;
  /** Create and connect multiple FeaturedCreatorGroup documents */
  create?: InputMaybe<Array<FeaturedCreatorGroupCreateInput>>;
  /** Delete multiple FeaturedCreatorGroup documents */
  delete?: InputMaybe<Array<FeaturedCreatorGroupWhereUniqueInput>>;
  /** Disconnect multiple FeaturedCreatorGroup documents */
  disconnect?: InputMaybe<Array<FeaturedCreatorGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FeaturedCreatorGroup documents */
  set?: InputMaybe<Array<FeaturedCreatorGroupWhereUniqueInput>>;
  /** Update multiple FeaturedCreatorGroup documents */
  update?: InputMaybe<
    Array<FeaturedCreatorGroupUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple FeaturedCreatorGroup documents */
  upsert?: InputMaybe<
    Array<FeaturedCreatorGroupUpsertWithNestedWhereUniqueInput>
  >;
};

export type FeaturedCreatorGroupUpdateManyInput = {
  groupName?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeaturedCreatorGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FeaturedCreatorGroupUpdateManyInput;
  /** Document search */
  where: FeaturedCreatorGroupWhereInput;
};

export type FeaturedCreatorGroupUpdateOneInlineInput = {
  /** Connect existing FeaturedCreatorGroup document */
  connect?: InputMaybe<FeaturedCreatorGroupWhereUniqueInput>;
  /** Create and connect one FeaturedCreatorGroup document */
  create?: InputMaybe<FeaturedCreatorGroupCreateInput>;
  /** Delete currently connected FeaturedCreatorGroup document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected FeaturedCreatorGroup document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single FeaturedCreatorGroup document */
  update?: InputMaybe<FeaturedCreatorGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FeaturedCreatorGroup document */
  upsert?: InputMaybe<FeaturedCreatorGroupUpsertWithNestedWhereUniqueInput>;
};

export type FeaturedCreatorGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FeaturedCreatorGroupUpdateInput;
  /** Unique document search */
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type FeaturedCreatorGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: FeaturedCreatorGroupCreateInput;
  /** Update document if it exists */
  update: FeaturedCreatorGroupUpdateInput;
};

export type FeaturedCreatorGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FeaturedCreatorGroupUpsertInput;
  /** Unique document search */
  where: FeaturedCreatorGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FeaturedCreatorGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type FeaturedCreatorGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreators_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreators_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreators_some?: InputMaybe<CollectionCreatorWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  groupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  groupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  groupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  groupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  groupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  groupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  groupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FeaturedCreatorGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCreatorGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCreatorGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCreatorGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FeaturedCreatorGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References FeaturedCreatorGroup record uniquely */
export type FeaturedCreatorGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Registered Component Icons */
export enum HeroMenuIcons {
  AlphaDex = "AlphaDex",
  AlphaMeteor = "AlphaMeteor",
  AlphaWallet = "AlphaWallet",
  AlphaWolfIcon = "AlphaWolfIcon",
  AtomIcon = "AtomIcon",
  BitcoinIcon = "BitcoinIcon",
  CalendarIcon = "CalendarIcon",
  ChartBarIcon = "ChartBarIcon",
  ChartPieIcon = "ChartPieIcon",
  CollectionIcon = "CollectionIcon",
  DocumentDuplicateIcon = "DocumentDuplicateIcon",
  FolderIcon = "FolderIcon",
  HomeIcon = "HomeIcon",
  MainLogoIcon = "MainLogoIcon",
  NFTIcon = "NFTIcon",
  PhotographIcon = "PhotographIcon",
  SearchIcon = "SearchIcon",
  ShieldCheckIcon = "ShieldCheckIcon",
  UsersIcon = "UsersIcon",
  ZoomInIcon = "ZoomInIcon",
}

export type HomePageVideoHero = Entity &
  Node & {
    __typename?: "HomePageVideoHero";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<HomePageVideoHero>;
    /** List of HomePageVideoHero versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    video: Asset;
    videoPosterImage: Asset;
  };

export type HomePageVideoHeroCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type HomePageVideoHeroDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type HomePageVideoHeroHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type HomePageVideoHeroPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type HomePageVideoHeroScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type HomePageVideoHeroUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type HomePageVideoHeroVideoArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type HomePageVideoHeroVideoPosterImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type HomePageVideoHeroConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: HomePageVideoHeroWhereUniqueInput;
};

/** A connection to a list of items. */
export type HomePageVideoHeroConnection = {
  __typename?: "HomePageVideoHeroConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<HomePageVideoHeroEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type HomePageVideoHeroCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  video: AssetCreateOneInlineInput;
  videoPosterImage: AssetCreateOneInlineInput;
};

export type HomePageVideoHeroCreateManyInlineInput = {
  /** Connect multiple existing HomePageVideoHero documents */
  connect?: InputMaybe<Array<HomePageVideoHeroWhereUniqueInput>>;
  /** Create and connect multiple existing HomePageVideoHero documents */
  create?: InputMaybe<Array<HomePageVideoHeroCreateInput>>;
};

export type HomePageVideoHeroCreateOneInlineInput = {
  /** Connect one existing HomePageVideoHero document */
  connect?: InputMaybe<HomePageVideoHeroWhereUniqueInput>;
  /** Create and connect one HomePageVideoHero document */
  create?: InputMaybe<HomePageVideoHeroCreateInput>;
};

/** An edge in a connection. */
export type HomePageVideoHeroEdge = {
  __typename?: "HomePageVideoHeroEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: HomePageVideoHero;
};

/** Identifies documents */
export type HomePageVideoHeroManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HomePageVideoHeroWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HomePageVideoHeroWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HomePageVideoHeroWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<HomePageVideoHeroWhereStageInput>;
  documentInStages_none?: InputMaybe<HomePageVideoHeroWhereStageInput>;
  documentInStages_some?: InputMaybe<HomePageVideoHeroWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  video?: InputMaybe<AssetWhereInput>;
  videoPosterImage?: InputMaybe<AssetWhereInput>;
};

export enum HomePageVideoHeroOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type HomePageVideoHeroUpdateInput = {
  video?: InputMaybe<AssetUpdateOneInlineInput>;
  videoPosterImage?: InputMaybe<AssetUpdateOneInlineInput>;
};

export type HomePageVideoHeroUpdateManyInlineInput = {
  /** Connect multiple existing HomePageVideoHero documents */
  connect?: InputMaybe<Array<HomePageVideoHeroConnectInput>>;
  /** Create and connect multiple HomePageVideoHero documents */
  create?: InputMaybe<Array<HomePageVideoHeroCreateInput>>;
  /** Delete multiple HomePageVideoHero documents */
  delete?: InputMaybe<Array<HomePageVideoHeroWhereUniqueInput>>;
  /** Disconnect multiple HomePageVideoHero documents */
  disconnect?: InputMaybe<Array<HomePageVideoHeroWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing HomePageVideoHero documents */
  set?: InputMaybe<Array<HomePageVideoHeroWhereUniqueInput>>;
  /** Update multiple HomePageVideoHero documents */
  update?: InputMaybe<Array<HomePageVideoHeroUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple HomePageVideoHero documents */
  upsert?: InputMaybe<Array<HomePageVideoHeroUpsertWithNestedWhereUniqueInput>>;
};

export type HomePageVideoHeroUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars["String"]["input"]>;
};

export type HomePageVideoHeroUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HomePageVideoHeroUpdateManyInput;
  /** Document search */
  where: HomePageVideoHeroWhereInput;
};

export type HomePageVideoHeroUpdateOneInlineInput = {
  /** Connect existing HomePageVideoHero document */
  connect?: InputMaybe<HomePageVideoHeroWhereUniqueInput>;
  /** Create and connect one HomePageVideoHero document */
  create?: InputMaybe<HomePageVideoHeroCreateInput>;
  /** Delete currently connected HomePageVideoHero document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected HomePageVideoHero document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single HomePageVideoHero document */
  update?: InputMaybe<HomePageVideoHeroUpdateWithNestedWhereUniqueInput>;
  /** Upsert single HomePageVideoHero document */
  upsert?: InputMaybe<HomePageVideoHeroUpsertWithNestedWhereUniqueInput>;
};

export type HomePageVideoHeroUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HomePageVideoHeroUpdateInput;
  /** Unique document search */
  where: HomePageVideoHeroWhereUniqueInput;
};

export type HomePageVideoHeroUpsertInput = {
  /** Create document if it didn't exist */
  create: HomePageVideoHeroCreateInput;
  /** Update document if it exists */
  update: HomePageVideoHeroUpdateInput;
};

export type HomePageVideoHeroUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HomePageVideoHeroUpsertInput;
  /** Unique document search */
  where: HomePageVideoHeroWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type HomePageVideoHeroWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type HomePageVideoHeroWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HomePageVideoHeroWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HomePageVideoHeroWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HomePageVideoHeroWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<HomePageVideoHeroWhereStageInput>;
  documentInStages_none?: InputMaybe<HomePageVideoHeroWhereStageInput>;
  documentInStages_some?: InputMaybe<HomePageVideoHeroWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  video?: InputMaybe<AssetWhereInput>;
  videoPosterImage?: InputMaybe<AssetWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type HomePageVideoHeroWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HomePageVideoHeroWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HomePageVideoHeroWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HomePageVideoHeroWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<HomePageVideoHeroWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References HomePageVideoHero record uniquely */
export type HomePageVideoHeroWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  clip = "clip",
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  crop = "crop",
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  max = "max",
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  scale = "scale",
}

export enum ImageHosting {
  joepegs = "joepegs",
}

export type ImageResizeInput = {
  /** The default value for the fit parameter is fit:clip. */
  fit?: InputMaybe<ImageFit>;
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: InputMaybe<Scalars["Int"]["input"]>;
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum ImageSide {
  left = "left",
  right = "right",
}

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Resizes the image */
  resize?: InputMaybe<ImageResizeInput>;
};

export type ImageWithAltTextAndLink = Entity & {
  __typename?: "ImageWithAltTextAndLink";
  altText?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  image?: Maybe<Asset>;
  link?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
};

export type ImageWithAltTextAndLinkImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ImageWithAltTextAndLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ImageWithAltTextAndLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type ImageWithAltTextAndLinkConnection = {
  __typename?: "ImageWithAltTextAndLinkConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ImageWithAltTextAndLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ImageWithAltTextAndLinkCreateInput = {
  altText?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  link?: InputMaybe<Scalars["String"]["input"]>;
};

export type ImageWithAltTextAndLinkCreateManyInlineInput = {
  /** Create and connect multiple existing ImageWithAltTextAndLink documents */
  create?: InputMaybe<Array<ImageWithAltTextAndLinkCreateInput>>;
};

export type ImageWithAltTextAndLinkCreateOneInlineInput = {
  /** Create and connect one ImageWithAltTextAndLink document */
  create?: InputMaybe<ImageWithAltTextAndLinkCreateInput>;
};

export type ImageWithAltTextAndLinkCreateWithPositionInput = {
  /** Document to create */
  data: ImageWithAltTextAndLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ImageWithAltTextAndLinkEdge = {
  __typename?: "ImageWithAltTextAndLinkEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: ImageWithAltTextAndLink;
};

/** Identifies documents */
export type ImageWithAltTextAndLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  altText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  altText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  altText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  altText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  altText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  altText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  altText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  altText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  altText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  link?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ImageWithAltTextAndLinkOrderByInput {
  altText_ASC = "altText_ASC",
  altText_DESC = "altText_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  link_ASC = "link_ASC",
  link_DESC = "link_DESC",
}

export type ImageWithAltTextAndLinkUpdateInput = {
  altText?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  link?: InputMaybe<Scalars["String"]["input"]>;
};

export type ImageWithAltTextAndLinkUpdateManyInlineInput = {
  /** Create and connect multiple ImageWithAltTextAndLink component instances */
  create?: InputMaybe<Array<ImageWithAltTextAndLinkCreateWithPositionInput>>;
  /** Delete multiple ImageWithAltTextAndLink documents */
  delete?: InputMaybe<Array<ImageWithAltTextAndLinkWhereUniqueInput>>;
  /** Update multiple ImageWithAltTextAndLink component instances */
  update?: InputMaybe<
    Array<ImageWithAltTextAndLinkUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ImageWithAltTextAndLink component instances */
  upsert?: InputMaybe<
    Array<ImageWithAltTextAndLinkUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ImageWithAltTextAndLinkUpdateManyInput = {
  altText?: InputMaybe<Scalars["String"]["input"]>;
};

export type ImageWithAltTextAndLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ImageWithAltTextAndLinkUpdateManyInput;
  /** Document search */
  where: ImageWithAltTextAndLinkWhereInput;
};

export type ImageWithAltTextAndLinkUpdateOneInlineInput = {
  /** Create and connect one ImageWithAltTextAndLink document */
  create?: InputMaybe<ImageWithAltTextAndLinkCreateInput>;
  /** Delete currently connected ImageWithAltTextAndLink document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ImageWithAltTextAndLink document */
  update?: InputMaybe<ImageWithAltTextAndLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ImageWithAltTextAndLink document */
  upsert?: InputMaybe<ImageWithAltTextAndLinkUpsertWithNestedWhereUniqueInput>;
};

export type ImageWithAltTextAndLinkUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<ImageWithAltTextAndLinkUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: ImageWithAltTextAndLinkWhereUniqueInput;
  };

export type ImageWithAltTextAndLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ImageWithAltTextAndLinkUpdateInput;
  /** Unique document search */
  where: ImageWithAltTextAndLinkWhereUniqueInput;
};

export type ImageWithAltTextAndLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: ImageWithAltTextAndLinkCreateInput;
  /** Update document if it exists */
  update: ImageWithAltTextAndLinkUpdateInput;
};

export type ImageWithAltTextAndLinkUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<ImageWithAltTextAndLinkUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: ImageWithAltTextAndLinkWhereUniqueInput;
  };

export type ImageWithAltTextAndLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ImageWithAltTextAndLinkUpsertInput;
  /** Unique document search */
  where: ImageWithAltTextAndLinkWhereUniqueInput;
};

/** Identifies documents */
export type ImageWithAltTextAndLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  altText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  altText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  altText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  altText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  altText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  altText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  altText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  altText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  altText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  link?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References ImageWithAltTextAndLink record uniquely */
export type ImageWithAltTextAndLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  link?: InputMaybe<Scalars["String"]["input"]>;
};

export type LandingPage = Entity &
  Node & {
    __typename?: "LandingPage";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    ctaSection?: Maybe<CtaSection>;
    ctaSectionBottom?: Maybe<CtaSection>;
    /** Get the document in other stages */
    documentInStages: Array<LandingPage>;
    faQs: Array<Faq>;
    faqSectionTitle?: Maybe<Scalars["String"]["output"]>;
    heroBannerImage?: Maybe<Asset>;
    heroText?: Maybe<RichText>;
    /** List of LandingPage versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    logoImage?: Maybe<Asset>;
    pageTitle?: Maybe<Scalars["String"]["output"]>;
    pressSectionTitle?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    seoDescription?: Maybe<Scalars["String"]["output"]>;
    seoImage?: Maybe<Asset>;
    seoPageTitle?: Maybe<Scalars["String"]["output"]>;
    slug?: Maybe<Scalars["String"]["output"]>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type LandingPageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageCtaSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageCtaSectionBottomArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type LandingPageFaQsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqWhereInput>;
};

export type LandingPageHeroBannerImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type LandingPageLogoImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type LandingPageSeoImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LandingPageWhereUniqueInput;
};

/** A connection to a list of items. */
export type LandingPageConnection = {
  __typename?: "LandingPageConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LandingPageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LandingPageCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  ctaSection?: InputMaybe<CtaSectionCreateOneInlineInput>;
  ctaSectionBottom?: InputMaybe<CtaSectionCreateOneInlineInput>;
  faQs?: InputMaybe<FaqCreateManyInlineInput>;
  faqSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  heroBannerImage?: InputMaybe<AssetCreateOneInlineInput>;
  heroText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  logoImage?: InputMaybe<AssetCreateOneInlineInput>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  pressSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetCreateOneInlineInput>;
  seoPageTitle?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type LandingPageCreateManyInlineInput = {
  /** Connect multiple existing LandingPage documents */
  connect?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Create and connect multiple existing LandingPage documents */
  create?: InputMaybe<Array<LandingPageCreateInput>>;
};

export type LandingPageCreateOneInlineInput = {
  /** Connect one existing LandingPage document */
  connect?: InputMaybe<LandingPageWhereUniqueInput>;
  /** Create and connect one LandingPage document */
  create?: InputMaybe<LandingPageCreateInput>;
};

/** An edge in a connection. */
export type LandingPageEdge = {
  __typename?: "LandingPageEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: LandingPage;
};

/** Identifies documents */
export type LandingPageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  ctaSectionBottom?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_none?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_some?: InputMaybe<LandingPageWhereStageInput>;
  faQs_every?: InputMaybe<FaqWhereInput>;
  faQs_none?: InputMaybe<FaqWhereInput>;
  faQs_some?: InputMaybe<FaqWhereInput>;
  faqSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  faqSectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  faqSectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  faqSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  faqSectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  faqSectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  faqSectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  faqSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  faqSectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  faqSectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  heroBannerImage?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  logoImage?: InputMaybe<AssetWhereInput>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pressSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pressSectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pressSectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pressSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  pressSectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pressSectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pressSectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pressSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  pressSectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pressSectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetWhereInput>;
  seoPageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoPageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoPageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoPageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoPageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoPageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoPageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoPageTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoPageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoPageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum LandingPageOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  faqSectionTitle_ASC = "faqSectionTitle_ASC",
  faqSectionTitle_DESC = "faqSectionTitle_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  pageTitle_ASC = "pageTitle_ASC",
  pageTitle_DESC = "pageTitle_DESC",
  pressSectionTitle_ASC = "pressSectionTitle_ASC",
  pressSectionTitle_DESC = "pressSectionTitle_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  seoDescription_ASC = "seoDescription_ASC",
  seoDescription_DESC = "seoDescription_DESC",
  seoPageTitle_ASC = "seoPageTitle_ASC",
  seoPageTitle_DESC = "seoPageTitle_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type LandingPageUpdateInput = {
  ctaSection?: InputMaybe<CtaSectionUpdateOneInlineInput>;
  ctaSectionBottom?: InputMaybe<CtaSectionUpdateOneInlineInput>;
  faQs?: InputMaybe<FaqUpdateManyInlineInput>;
  faqSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  heroBannerImage?: InputMaybe<AssetUpdateOneInlineInput>;
  heroText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  logoImage?: InputMaybe<AssetUpdateOneInlineInput>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  pressSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetUpdateOneInlineInput>;
  seoPageTitle?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type LandingPageUpdateManyInlineInput = {
  /** Connect multiple existing LandingPage documents */
  connect?: InputMaybe<Array<LandingPageConnectInput>>;
  /** Create and connect multiple LandingPage documents */
  create?: InputMaybe<Array<LandingPageCreateInput>>;
  /** Delete multiple LandingPage documents */
  delete?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Disconnect multiple LandingPage documents */
  disconnect?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing LandingPage documents */
  set?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Update multiple LandingPage documents */
  update?: InputMaybe<Array<LandingPageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple LandingPage documents */
  upsert?: InputMaybe<Array<LandingPageUpsertWithNestedWhereUniqueInput>>;
};

export type LandingPageUpdateManyInput = {
  faqSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  heroText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  pressSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoPageTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type LandingPageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LandingPageUpdateManyInput;
  /** Document search */
  where: LandingPageWhereInput;
};

export type LandingPageUpdateOneInlineInput = {
  /** Connect existing LandingPage document */
  connect?: InputMaybe<LandingPageWhereUniqueInput>;
  /** Create and connect one LandingPage document */
  create?: InputMaybe<LandingPageCreateInput>;
  /** Delete currently connected LandingPage document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected LandingPage document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single LandingPage document */
  update?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LandingPage document */
  upsert?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>;
};

export type LandingPageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LandingPageUpdateInput;
  /** Unique document search */
  where: LandingPageWhereUniqueInput;
};

export type LandingPageUpsertInput = {
  /** Create document if it didn't exist */
  create: LandingPageCreateInput;
  /** Update document if it exists */
  update: LandingPageUpdateInput;
};

export type LandingPageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LandingPageUpsertInput;
  /** Unique document search */
  where: LandingPageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type LandingPageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type LandingPageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  ctaSectionBottom?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_none?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_some?: InputMaybe<LandingPageWhereStageInput>;
  faQs_every?: InputMaybe<FaqWhereInput>;
  faQs_none?: InputMaybe<FaqWhereInput>;
  faQs_some?: InputMaybe<FaqWhereInput>;
  faqSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  faqSectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  faqSectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  faqSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  faqSectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  faqSectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  faqSectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  faqSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  faqSectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  faqSectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  heroBannerImage?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  logoImage?: InputMaybe<AssetWhereInput>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pressSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pressSectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pressSectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pressSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  pressSectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pressSectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pressSectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pressSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  pressSectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pressSectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetWhereInput>;
  seoPageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoPageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoPageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoPageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoPageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoPageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoPageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoPageTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoPageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoPageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type LandingPageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<LandingPageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References LandingPage record uniquely */
export type LandingPageWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

/** Locale system enumeration */
export enum Locale {
  /** System locale */
  en = "en",
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
  __typename?: "Location";
  distance: Scalars["Float"]["output"];
  latitude: Scalars["Float"]["output"];
  longitude: Scalars["Float"]["output"];
};

/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
  from: LocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
};

export type Menu = Entity &
  Node & {
    __typename?: "Menu";
    applicationId: AppId;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<Menu>;
    /** List of Menu versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    menuGroupIcon?: Maybe<Asset>;
    menuGroupName?: Maybe<Scalars["String"]["output"]>;
    menuSection: MenuType;
    navigationItems: Array<NavigationItem>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MenuCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MenuDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MenuHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MenuMenuGroupIconArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MenuNavigationItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NavigationItemOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NavigationItemWhereInput>;
};

export type MenuPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MenuScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MenuUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MenuConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MenuWhereUniqueInput;
};

/** A connection to a list of items. */
export type MenuConnection = {
  __typename?: "MenuConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MenuEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MenuCreateInput = {
  applicationId: AppId;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  menuGroupIcon?: InputMaybe<AssetCreateOneInlineInput>;
  menuGroupName?: InputMaybe<Scalars["String"]["input"]>;
  menuSection: MenuType;
  navigationItems?: InputMaybe<NavigationItemCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MenuCreateManyInlineInput = {
  /** Connect multiple existing Menu documents */
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Create and connect multiple existing Menu documents */
  create?: InputMaybe<Array<MenuCreateInput>>;
};

export type MenuCreateOneInlineInput = {
  /** Connect one existing Menu document */
  connect?: InputMaybe<MenuWhereUniqueInput>;
  /** Create and connect one Menu document */
  create?: InputMaybe<MenuCreateInput>;
};

/** An edge in a connection. */
export type MenuEdge = {
  __typename?: "MenuEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Menu;
};

/** Identifies documents */
export type MenuManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MenuWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  applicationId?: InputMaybe<AppId>;
  /** All values that are contained in given list. */
  applicationId_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  /** Any other value that exists and is not equal to the given value. */
  applicationId_not?: InputMaybe<AppId>;
  /** All values that are not contained in given list. */
  applicationId_not_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MenuWhereStageInput>;
  documentInStages_none?: InputMaybe<MenuWhereStageInput>;
  documentInStages_some?: InputMaybe<MenuWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  menuGroupIcon?: InputMaybe<AssetWhereInput>;
  menuGroupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  menuGroupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  menuGroupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  menuGroupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  menuGroupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  menuGroupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  menuGroupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  menuGroupName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  menuGroupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  menuGroupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  menuSection?: InputMaybe<MenuType>;
  /** All values that are contained in given list. */
  menuSection_in?: InputMaybe<Array<InputMaybe<MenuType>>>;
  /** Any other value that exists and is not equal to the given value. */
  menuSection_not?: InputMaybe<MenuType>;
  /** All values that are not contained in given list. */
  menuSection_not_in?: InputMaybe<Array<InputMaybe<MenuType>>>;
  navigationItems_every?: InputMaybe<NavigationItemWhereInput>;
  navigationItems_none?: InputMaybe<NavigationItemWhereInput>;
  navigationItems_some?: InputMaybe<NavigationItemWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MenuOrderByInput {
  applicationId_ASC = "applicationId_ASC",
  applicationId_DESC = "applicationId_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  menuGroupName_ASC = "menuGroupName_ASC",
  menuGroupName_DESC = "menuGroupName_DESC",
  menuSection_ASC = "menuSection_ASC",
  menuSection_DESC = "menuSection_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Denotes the position this menu will show up in the sidebar navigation component on alpha shares sites */
export enum MenuType {
  AlphaSharesCollections = "AlphaSharesCollections",
  Ecosystem = "Ecosystem",
  Help = "Help",
  Legal = "Legal",
  Main = "Main",
  Security = "Security",
}

export type MenuUpdateInput = {
  applicationId?: InputMaybe<AppId>;
  menuGroupIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  menuGroupName?: InputMaybe<Scalars["String"]["input"]>;
  menuSection?: InputMaybe<MenuType>;
  navigationItems?: InputMaybe<NavigationItemUpdateManyInlineInput>;
};

export type MenuUpdateManyInlineInput = {
  /** Connect multiple existing Menu documents */
  connect?: InputMaybe<Array<MenuConnectInput>>;
  /** Create and connect multiple Menu documents */
  create?: InputMaybe<Array<MenuCreateInput>>;
  /** Delete multiple Menu documents */
  delete?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Disconnect multiple Menu documents */
  disconnect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Menu documents */
  set?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Update multiple Menu documents */
  update?: InputMaybe<Array<MenuUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Menu documents */
  upsert?: InputMaybe<Array<MenuUpsertWithNestedWhereUniqueInput>>;
};

export type MenuUpdateManyInput = {
  applicationId?: InputMaybe<AppId>;
  menuGroupName?: InputMaybe<Scalars["String"]["input"]>;
  menuSection?: InputMaybe<MenuType>;
};

export type MenuUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MenuUpdateManyInput;
  /** Document search */
  where: MenuWhereInput;
};

export type MenuUpdateOneInlineInput = {
  /** Connect existing Menu document */
  connect?: InputMaybe<MenuWhereUniqueInput>;
  /** Create and connect one Menu document */
  create?: InputMaybe<MenuCreateInput>;
  /** Delete currently connected Menu document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected Menu document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Menu document */
  update?: InputMaybe<MenuUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Menu document */
  upsert?: InputMaybe<MenuUpsertWithNestedWhereUniqueInput>;
};

export type MenuUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MenuUpdateInput;
  /** Unique document search */
  where: MenuWhereUniqueInput;
};

export type MenuUpsertInput = {
  /** Create document if it didn't exist */
  create: MenuCreateInput;
  /** Update document if it exists */
  update: MenuUpdateInput;
};

export type MenuUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MenuUpsertInput;
  /** Unique document search */
  where: MenuWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MenuWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MenuWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MenuWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  applicationId?: InputMaybe<AppId>;
  /** All values that are contained in given list. */
  applicationId_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  /** Any other value that exists and is not equal to the given value. */
  applicationId_not?: InputMaybe<AppId>;
  /** All values that are not contained in given list. */
  applicationId_not_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MenuWhereStageInput>;
  documentInStages_none?: InputMaybe<MenuWhereStageInput>;
  documentInStages_some?: InputMaybe<MenuWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  menuGroupIcon?: InputMaybe<AssetWhereInput>;
  menuGroupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  menuGroupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  menuGroupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  menuGroupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  menuGroupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  menuGroupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  menuGroupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  menuGroupName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  menuGroupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  menuGroupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  menuSection?: InputMaybe<MenuType>;
  /** All values that are contained in given list. */
  menuSection_in?: InputMaybe<Array<InputMaybe<MenuType>>>;
  /** Any other value that exists and is not equal to the given value. */
  menuSection_not?: InputMaybe<MenuType>;
  /** All values that are not contained in given list. */
  menuSection_not_in?: InputMaybe<Array<InputMaybe<MenuType>>>;
  navigationItems_every?: InputMaybe<NavigationItemWhereInput>;
  navigationItems_none?: InputMaybe<NavigationItemWhereInput>;
  navigationItems_some?: InputMaybe<NavigationItemWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MenuWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MenuWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MenuWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MenuWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MenuWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Menu record uniquely */
export type MenuWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningContainer = Entity &
  Node & {
    __typename?: "MiningContainer";
    containerDisplayTitle?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    deployedMiners?: Maybe<Scalars["Int"]["output"]>;
    /** Get the document in other stages */
    documentInStages: Array<MiningContainer>;
    /** List of MiningContainer versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    miningPoolUsername?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningContainerCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningContainerDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningContainerHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningContainerPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningContainerScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningContainerUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningContainerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningContainerWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningContainerConnection = {
  __typename?: "MiningContainerConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningContainerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningContainerCreateInput = {
  containerDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  deployedMiners?: InputMaybe<Scalars["Int"]["input"]>;
  miningPoolUsername?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningContainerCreateManyInlineInput = {
  /** Connect multiple existing MiningContainer documents */
  connect?: InputMaybe<Array<MiningContainerWhereUniqueInput>>;
  /** Create and connect multiple existing MiningContainer documents */
  create?: InputMaybe<Array<MiningContainerCreateInput>>;
};

export type MiningContainerCreateOneInlineInput = {
  /** Connect one existing MiningContainer document */
  connect?: InputMaybe<MiningContainerWhereUniqueInput>;
  /** Create and connect one MiningContainer document */
  create?: InputMaybe<MiningContainerCreateInput>;
};

/** An edge in a connection. */
export type MiningContainerEdge = {
  __typename?: "MiningContainerEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningContainer;
};

/** Identifies documents */
export type MiningContainerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  containerDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  containerDisplayTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  containerDisplayTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  containerDisplayTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  containerDisplayTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  containerDisplayTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  containerDisplayTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  containerDisplayTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  containerDisplayTitle_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  containerDisplayTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  deployedMiners?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  deployedMiners_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  deployedMiners_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  deployedMiners_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  deployedMiners_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  deployedMiners_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  deployedMiners_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  deployedMiners_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  documentInStages_every?: InputMaybe<MiningContainerWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningContainerWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningContainerWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  miningPoolUsername?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  miningPoolUsername_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  miningPoolUsername_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  miningPoolUsername_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  miningPoolUsername_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  miningPoolUsername_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  miningPoolUsername_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  miningPoolUsername_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  miningPoolUsername_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  miningPoolUsername_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningContainerOrderByInput {
  containerDisplayTitle_ASC = "containerDisplayTitle_ASC",
  containerDisplayTitle_DESC = "containerDisplayTitle_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  deployedMiners_ASC = "deployedMiners_ASC",
  deployedMiners_DESC = "deployedMiners_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  miningPoolUsername_ASC = "miningPoolUsername_ASC",
  miningPoolUsername_DESC = "miningPoolUsername_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningContainerUpdateInput = {
  containerDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  deployedMiners?: InputMaybe<Scalars["Int"]["input"]>;
  miningPoolUsername?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningContainerUpdateManyInlineInput = {
  /** Connect multiple existing MiningContainer documents */
  connect?: InputMaybe<Array<MiningContainerConnectInput>>;
  /** Create and connect multiple MiningContainer documents */
  create?: InputMaybe<Array<MiningContainerCreateInput>>;
  /** Delete multiple MiningContainer documents */
  delete?: InputMaybe<Array<MiningContainerWhereUniqueInput>>;
  /** Disconnect multiple MiningContainer documents */
  disconnect?: InputMaybe<Array<MiningContainerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningContainer documents */
  set?: InputMaybe<Array<MiningContainerWhereUniqueInput>>;
  /** Update multiple MiningContainer documents */
  update?: InputMaybe<Array<MiningContainerUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MiningContainer documents */
  upsert?: InputMaybe<Array<MiningContainerUpsertWithNestedWhereUniqueInput>>;
};

export type MiningContainerUpdateManyInput = {
  containerDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  deployedMiners?: InputMaybe<Scalars["Int"]["input"]>;
  miningPoolUsername?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningContainerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningContainerUpdateManyInput;
  /** Document search */
  where: MiningContainerWhereInput;
};

export type MiningContainerUpdateOneInlineInput = {
  /** Connect existing MiningContainer document */
  connect?: InputMaybe<MiningContainerWhereUniqueInput>;
  /** Create and connect one MiningContainer document */
  create?: InputMaybe<MiningContainerCreateInput>;
  /** Delete currently connected MiningContainer document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningContainer document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningContainer document */
  update?: InputMaybe<MiningContainerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningContainer document */
  upsert?: InputMaybe<MiningContainerUpsertWithNestedWhereUniqueInput>;
};

export type MiningContainerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningContainerUpdateInput;
  /** Unique document search */
  where: MiningContainerWhereUniqueInput;
};

export type MiningContainerUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningContainerCreateInput;
  /** Update document if it exists */
  update: MiningContainerUpdateInput;
};

export type MiningContainerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningContainerUpsertInput;
  /** Unique document search */
  where: MiningContainerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningContainerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningContainerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  containerDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  containerDisplayTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  containerDisplayTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  containerDisplayTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  containerDisplayTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  containerDisplayTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  containerDisplayTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  containerDisplayTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  containerDisplayTitle_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  containerDisplayTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  deployedMiners?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  deployedMiners_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  deployedMiners_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  deployedMiners_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  deployedMiners_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  deployedMiners_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  deployedMiners_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  deployedMiners_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  documentInStages_every?: InputMaybe<MiningContainerWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningContainerWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningContainerWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  miningPoolUsername?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  miningPoolUsername_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  miningPoolUsername_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  miningPoolUsername_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  miningPoolUsername_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  miningPoolUsername_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  miningPoolUsername_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  miningPoolUsername_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  miningPoolUsername_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  miningPoolUsername_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningContainerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningContainerWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningContainerWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningContainerWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningContainerWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningContainer record uniquely */
export type MiningContainerWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningHeaderNotice = Entity &
  Node & {
    __typename?: "MiningHeaderNotice";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningHeaderNotice>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of MiningHeaderNotice versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    notice?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningHeaderNoticeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningHeaderNoticeDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningHeaderNoticeHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningHeaderNoticePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningHeaderNoticeScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningHeaderNoticeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningHeaderNoticeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningHeaderNoticeWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningHeaderNoticeConnection = {
  __typename?: "MiningHeaderNoticeConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningHeaderNoticeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningHeaderNoticeCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  notice?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningHeaderNoticeCreateManyInlineInput = {
  /** Connect multiple existing MiningHeaderNotice documents */
  connect?: InputMaybe<Array<MiningHeaderNoticeWhereUniqueInput>>;
  /** Create and connect multiple existing MiningHeaderNotice documents */
  create?: InputMaybe<Array<MiningHeaderNoticeCreateInput>>;
};

export type MiningHeaderNoticeCreateOneInlineInput = {
  /** Connect one existing MiningHeaderNotice document */
  connect?: InputMaybe<MiningHeaderNoticeWhereUniqueInput>;
  /** Create and connect one MiningHeaderNotice document */
  create?: InputMaybe<MiningHeaderNoticeCreateInput>;
};

/** An edge in a connection. */
export type MiningHeaderNoticeEdge = {
  __typename?: "MiningHeaderNoticeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningHeaderNotice;
};

/** Identifies documents */
export type MiningHeaderNoticeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  notice?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  notice_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  notice_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  notice_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  notice_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  notice_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  notice_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  notice_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  notice_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  notice_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningHeaderNoticeOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  notice_ASC = "notice_ASC",
  notice_DESC = "notice_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningHeaderNoticeUpdateInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  notice?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningHeaderNoticeUpdateManyInlineInput = {
  /** Connect multiple existing MiningHeaderNotice documents */
  connect?: InputMaybe<Array<MiningHeaderNoticeConnectInput>>;
  /** Create and connect multiple MiningHeaderNotice documents */
  create?: InputMaybe<Array<MiningHeaderNoticeCreateInput>>;
  /** Delete multiple MiningHeaderNotice documents */
  delete?: InputMaybe<Array<MiningHeaderNoticeWhereUniqueInput>>;
  /** Disconnect multiple MiningHeaderNotice documents */
  disconnect?: InputMaybe<Array<MiningHeaderNoticeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningHeaderNotice documents */
  set?: InputMaybe<Array<MiningHeaderNoticeWhereUniqueInput>>;
  /** Update multiple MiningHeaderNotice documents */
  update?: InputMaybe<
    Array<MiningHeaderNoticeUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningHeaderNotice documents */
  upsert?: InputMaybe<
    Array<MiningHeaderNoticeUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningHeaderNoticeUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  notice?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningHeaderNoticeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningHeaderNoticeUpdateManyInput;
  /** Document search */
  where: MiningHeaderNoticeWhereInput;
};

export type MiningHeaderNoticeUpdateOneInlineInput = {
  /** Connect existing MiningHeaderNotice document */
  connect?: InputMaybe<MiningHeaderNoticeWhereUniqueInput>;
  /** Create and connect one MiningHeaderNotice document */
  create?: InputMaybe<MiningHeaderNoticeCreateInput>;
  /** Delete currently connected MiningHeaderNotice document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningHeaderNotice document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningHeaderNotice document */
  update?: InputMaybe<MiningHeaderNoticeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningHeaderNotice document */
  upsert?: InputMaybe<MiningHeaderNoticeUpsertWithNestedWhereUniqueInput>;
};

export type MiningHeaderNoticeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningHeaderNoticeUpdateInput;
  /** Unique document search */
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MiningHeaderNoticeUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningHeaderNoticeCreateInput;
  /** Update document if it exists */
  update: MiningHeaderNoticeUpdateInput;
};

export type MiningHeaderNoticeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningHeaderNoticeUpsertInput;
  /** Unique document search */
  where: MiningHeaderNoticeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningHeaderNoticeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningHeaderNoticeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  notice?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  notice_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  notice_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  notice_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  notice_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  notice_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  notice_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  notice_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  notice_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  notice_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningHeaderNoticeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningHeaderNoticeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningHeaderNoticeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningHeaderNoticeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningHeaderNoticeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningHeaderNotice record uniquely */
export type MiningHeaderNoticeWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MintingCurrency = Entity &
  Node & {
    __typename?: "MintingCurrency";
    chain?: Maybe<Chain>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    decimals?: Maybe<Scalars["Int"]["output"]>;
    /** Get the document in other stages */
    documentInStages: Array<MintingCurrency>;
    /** List of MintingCurrency versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    tokenAddress: Scalars["String"]["output"];
    tokenIcon?: Maybe<Asset>;
    tokenName?: Maybe<Scalars["String"]["output"]>;
    tokenSymbol?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MintingCurrencyChainArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingCurrencyCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingCurrencyDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MintingCurrencyHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MintingCurrencyPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingCurrencyScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MintingCurrencyTokenIconArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingCurrencyUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingCurrencyConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MintingCurrencyWhereUniqueInput;
};

/** A connection to a list of items. */
export type MintingCurrencyConnection = {
  __typename?: "MintingCurrencyConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MintingCurrencyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MintingCurrencyCreateInput = {
  chain?: InputMaybe<ChainCreateOneInlineInput>;
  clp90tubd2og001t9glx64li5?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  cls3dkic301hm01j1axgddhv5?: InputMaybe<MintingProjectCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  tokenAddress: Scalars["String"]["input"];
  tokenIcon?: InputMaybe<AssetCreateOneInlineInput>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MintingCurrencyCreateManyInlineInput = {
  /** Connect multiple existing MintingCurrency documents */
  connect?: InputMaybe<Array<MintingCurrencyWhereUniqueInput>>;
  /** Create and connect multiple existing MintingCurrency documents */
  create?: InputMaybe<Array<MintingCurrencyCreateInput>>;
};

export type MintingCurrencyCreateOneInlineInput = {
  /** Connect one existing MintingCurrency document */
  connect?: InputMaybe<MintingCurrencyWhereUniqueInput>;
  /** Create and connect one MintingCurrency document */
  create?: InputMaybe<MintingCurrencyCreateInput>;
};

/** An edge in a connection. */
export type MintingCurrencyEdge = {
  __typename?: "MintingCurrencyEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MintingCurrency;
};

/** Identifies documents */
export type MintingCurrencyManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  decimals_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  decimals_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  decimals_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  decimals_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  decimals_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  decimals_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  decimals_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  documentInStages_every?: InputMaybe<MintingCurrencyWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingCurrencyWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingCurrencyWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tokenAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenAddress_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenAddress_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenIcon?: InputMaybe<AssetWhereInput>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  tokenName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenSymbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenSymbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenSymbol_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenSymbol_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenSymbol_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenSymbol_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenSymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenSymbol_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenSymbol_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MintingCurrencyOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  decimals_ASC = "decimals_ASC",
  decimals_DESC = "decimals_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  tokenAddress_ASC = "tokenAddress_ASC",
  tokenAddress_DESC = "tokenAddress_DESC",
  tokenName_ASC = "tokenName_ASC",
  tokenName_DESC = "tokenName_DESC",
  tokenSymbol_ASC = "tokenSymbol_ASC",
  tokenSymbol_DESC = "tokenSymbol_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MintingCurrencyUpdateInput = {
  chain?: InputMaybe<ChainUpdateOneInlineInput>;
  clp90tubd2og001t9glx64li5?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  cls3dkic301hm01j1axgddhv5?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  tokenAddress?: InputMaybe<Scalars["String"]["input"]>;
  tokenIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingCurrencyUpdateManyInlineInput = {
  /** Connect multiple existing MintingCurrency documents */
  connect?: InputMaybe<Array<MintingCurrencyConnectInput>>;
  /** Create and connect multiple MintingCurrency documents */
  create?: InputMaybe<Array<MintingCurrencyCreateInput>>;
  /** Delete multiple MintingCurrency documents */
  delete?: InputMaybe<Array<MintingCurrencyWhereUniqueInput>>;
  /** Disconnect multiple MintingCurrency documents */
  disconnect?: InputMaybe<Array<MintingCurrencyWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MintingCurrency documents */
  set?: InputMaybe<Array<MintingCurrencyWhereUniqueInput>>;
  /** Update multiple MintingCurrency documents */
  update?: InputMaybe<Array<MintingCurrencyUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MintingCurrency documents */
  upsert?: InputMaybe<Array<MintingCurrencyUpsertWithNestedWhereUniqueInput>>;
};

export type MintingCurrencyUpdateManyInput = {
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingCurrencyUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MintingCurrencyUpdateManyInput;
  /** Document search */
  where: MintingCurrencyWhereInput;
};

export type MintingCurrencyUpdateOneInlineInput = {
  /** Connect existing MintingCurrency document */
  connect?: InputMaybe<MintingCurrencyWhereUniqueInput>;
  /** Create and connect one MintingCurrency document */
  create?: InputMaybe<MintingCurrencyCreateInput>;
  /** Delete currently connected MintingCurrency document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MintingCurrency document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MintingCurrency document */
  update?: InputMaybe<MintingCurrencyUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MintingCurrency document */
  upsert?: InputMaybe<MintingCurrencyUpsertWithNestedWhereUniqueInput>;
};

export type MintingCurrencyUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MintingCurrencyUpdateInput;
  /** Unique document search */
  where: MintingCurrencyWhereUniqueInput;
};

export type MintingCurrencyUpsertInput = {
  /** Create document if it didn't exist */
  create: MintingCurrencyCreateInput;
  /** Update document if it exists */
  update: MintingCurrencyUpdateInput;
};

export type MintingCurrencyUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MintingCurrencyUpsertInput;
  /** Unique document search */
  where: MintingCurrencyWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MintingCurrencyWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MintingCurrencyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  decimals_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  decimals_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  decimals_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  decimals_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  decimals_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  decimals_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  decimals_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  documentInStages_every?: InputMaybe<MintingCurrencyWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingCurrencyWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingCurrencyWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tokenAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenAddress_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenAddress_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenIcon?: InputMaybe<AssetWhereInput>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  tokenName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenSymbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenSymbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenSymbol_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenSymbol_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenSymbol_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenSymbol_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenSymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenSymbol_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenSymbol_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MintingCurrencyWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingCurrencyWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingCurrencyWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingCurrencyWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MintingCurrencyWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MintingCurrency record uniquely */
export type MintingCurrencyWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  tokenAddress?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingProject = Entity &
  Node & {
    __typename?: "MintingProject";
    aboutSectionAccordions: Array<Faq>;
    aboutSectionHeader?: Maybe<Scalars["String"]["output"]>;
    aboutSectionText?: Maybe<Scalars["String"]["output"]>;
    allocation?: Maybe<Scalars["Int"]["output"]>;
    alphaSharesProject: Scalars["Boolean"]["output"];
    collectionCreator?: Maybe<CollectionCreator>;
    collectionInfoTable: Array<CollectionInfoTable>;
    collectionSampleImages: Array<Asset>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    ctaSection?: Maybe<CtaSection>;
    currency?: Maybe<Currencies>;
    /** Get the document in other stages */
    documentInStages: Array<MintingProject>;
    dynamicPricingGetterFunction?: Maybe<Scalars["String"]["output"]>;
    fAQSection?: Maybe<FaqSection>;
    featured: Scalars["Boolean"]["output"];
    hasDynamicPricing: Scalars["Boolean"]["output"];
    /** List of MintingProject versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    maxMintPerTransaction: Scalars["Int"]["output"];
    merkleRoot?: Maybe<Scalars["Json"]["output"]>;
    /** If the minting collection is making use of Metafuse, we need the metafuse project ID */
    metafuseProjectId?: Maybe<Scalars["String"]["output"]>;
    mintDate?: Maybe<Scalars["DateTime"]["output"]>;
    mintInfoTable: Array<CollectionInfoTable>;
    mintPrice?: Maybe<Scalars["Float"]["output"]>;
    mintedNfTsCarouselHeader?: Maybe<Scalars["String"]["output"]>;
    mintedNfTsCarouselSubHeader?: Maybe<Scalars["String"]["output"]>;
    mintingContractAbi?: Maybe<Scalars["Json"]["output"]>;
    mintingContractAddress?: Maybe<Scalars["String"]["output"]>;
    /** Mint Currency, if not native gas token, then must fill out currency contract address as well. */
    mintingCurrency: Scalars["String"]["output"];
    /** Fill this out if the minting currency is not native gas token. */
    mintingCurrencyContractAddress?: Maybe<Scalars["String"]["output"]>;
    mintingCurrencyDecimals?: Maybe<Scalars["Int"]["output"]>;
    mintingCurrencyInformation?: Maybe<MintingCurrency>;
    mintingFunctionName?: Maybe<Scalars["String"]["output"]>;
    mintingStat?: Maybe<MintingStat>;
    nftCollection?: Maybe<NftCollection>;
    paperCreditCardMintingLink?: Maybe<Scalars["String"]["output"]>;
    presaleCheckerFunction?: Maybe<Scalars["String"]["output"]>;
    presaleHeroSectionCopy?: Maybe<Scalars["String"]["output"]>;
    presaleMaxMintGetter?: Maybe<Scalars["String"]["output"]>;
    presaleMintingFunctionName?: Maybe<Scalars["String"]["output"]>;
    presalePriceGetter?: Maybe<Scalars["String"]["output"]>;
    priceQueryFunctionName?: Maybe<Scalars["String"]["output"]>;
    priceQueryRequiresAccount: Scalars["Boolean"]["output"];
    projectName: Scalars["String"]["output"];
    projectSecurityTags: Array<ProjectSecurityTags>;
    publicSaleMaxMintGetter?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    securityLineItems: Array<SecurityInfo>;
    seoDescription?: Maybe<Scalars["String"]["output"]>;
    seoTitle?: Maybe<Scalars["String"]["output"]>;
    /** Defaults to false. If true, the revealed images will be public on mint. */
    shouldMetafuseRevealOnMint: Scalars["Boolean"]["output"];
    /** url for minting project */
    slug?: Maybe<Scalars["String"]["output"]>;
    soldOut?: Maybe<Scalars["Boolean"]["output"]>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    whitelistChecker?: Maybe<Scalars["String"]["output"]>;
  };

export type MintingProjectAboutSectionAccordionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqWhereInput>;
};

export type MintingProjectCollectionCreatorArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectCollectionInfoTableArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionInfoTableOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionInfoTableWhereInput>;
};

export type MintingProjectCollectionSampleImagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type MintingProjectCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectCtaSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MintingProjectFAqSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MintingProjectMintInfoTableArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionInfoTableOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionInfoTableWhereInput>;
};

export type MintingProjectMintingCurrencyInformationArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectMintingStatArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectNftCollectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MintingProjectSecurityLineItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SecurityInfoOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SecurityInfoWhereInput>;
};

export type MintingProjectUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MintingProjectWhereUniqueInput;
};

/** A connection to a list of items. */
export type MintingProjectConnection = {
  __typename?: "MintingProjectConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MintingProjectEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MintingProjectCreateInput = {
  aboutSectionAccordions?: InputMaybe<FaqCreateManyInlineInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  allocation?: InputMaybe<Scalars["Int"]["input"]>;
  alphaSharesProject: Scalars["Boolean"]["input"];
  collectionCreator?: InputMaybe<CollectionCreatorCreateOneInlineInput>;
  collectionInfoTable?: InputMaybe<CollectionInfoTableCreateManyInlineInput>;
  collectionSampleImages?: InputMaybe<AssetCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  ctaSection?: InputMaybe<CtaSectionCreateOneInlineInput>;
  currency?: InputMaybe<Currencies>;
  dynamicPricingGetterFunction?: InputMaybe<Scalars["String"]["input"]>;
  fAQSection?: InputMaybe<FaqSectionCreateOneInlineInput>;
  featured: Scalars["Boolean"]["input"];
  hasDynamicPricing: Scalars["Boolean"]["input"];
  maxMintPerTransaction: Scalars["Int"]["input"];
  merkleRoot?: InputMaybe<Scalars["Json"]["input"]>;
  metafuseProjectId?: InputMaybe<Scalars["String"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintInfoTable?: InputMaybe<CollectionInfoTableCreateManyInlineInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintingContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  mintingContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrency: Scalars["String"]["input"];
  mintingCurrencyContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyDecimals?: InputMaybe<Scalars["Int"]["input"]>;
  mintingCurrencyInformation?: InputMaybe<MintingCurrencyCreateOneInlineInput>;
  mintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  mintingStat?: InputMaybe<MintingStatCreateOneInlineInput>;
  nftCollection?: InputMaybe<NftCollectionCreateOneInlineInput>;
  paperCreditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  presaleCheckerFunction?: InputMaybe<Scalars["String"]["input"]>;
  presaleHeroSectionCopy?: InputMaybe<Scalars["String"]["input"]>;
  presaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  presaleMintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  presalePriceGetter?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryRequiresAccount: Scalars["Boolean"]["input"];
  projectName: Scalars["String"]["input"];
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  publicSaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  securityLineItems?: InputMaybe<SecurityInfoCreateManyInlineInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  shouldMetafuseRevealOnMint: Scalars["Boolean"]["input"];
  slug?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  whitelistChecker?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingProjectCreateManyInlineInput = {
  /** Connect multiple existing MintingProject documents */
  connect?: InputMaybe<Array<MintingProjectWhereUniqueInput>>;
  /** Create and connect multiple existing MintingProject documents */
  create?: InputMaybe<Array<MintingProjectCreateInput>>;
};

export type MintingProjectCreateOneInlineInput = {
  /** Connect one existing MintingProject document */
  connect?: InputMaybe<MintingProjectWhereUniqueInput>;
  /** Create and connect one MintingProject document */
  create?: InputMaybe<MintingProjectCreateInput>;
};

/** An edge in a connection. */
export type MintingProjectEdge = {
  __typename?: "MintingProjectEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MintingProject;
};

/** Identifies documents */
export type MintingProjectManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionAccordions_every?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_none?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_some?: InputMaybe<FaqWhereInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionHeader_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionHeader_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionHeader_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  allocation?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  allocation_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  allocation_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  allocation_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  allocation_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  allocation_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  allocation_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  allocation_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  alphaSharesProject_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionCreator?: InputMaybe<CollectionCreatorWhereInput>;
  collectionInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionSampleImages_every?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_none?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_some?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  currency?: InputMaybe<Currencies>;
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Currencies>;
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  documentInStages_every?: InputMaybe<MintingProjectWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingProjectWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingProjectWhereStageInput>;
  dynamicPricingGetterFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  dynamicPricingGetterFunction_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  dynamicPricingGetterFunction_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  dynamicPricingGetterFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  dynamicPricingGetterFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  dynamicPricingGetterFunction_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  dynamicPricingGetterFunction_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  dynamicPricingGetterFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  dynamicPricingGetterFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  dynamicPricingGetterFunction_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  fAQSection?: InputMaybe<FaqSectionWhereInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  featured_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDynamicPricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  hasDynamicPricing_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  maxMintPerTransaction_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  maxMintPerTransaction_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  maxMintPerTransaction_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  maxMintPerTransaction_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  maxMintPerTransaction_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  maxMintPerTransaction_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  maxMintPerTransaction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values containing the given json path. */
  merkleRoot_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  merkleRoot_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  metafuseProjectId?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  metafuseProjectId_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  metafuseProjectId_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  metafuseProjectId_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  metafuseProjectId_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  metafuseProjectId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  metafuseProjectId_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  metafuseProjectId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  metafuseProjectId_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  metafuseProjectId_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  mintDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  mintDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  mintDate_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  mintDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  mintDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  mintDate_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  mintInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  mintPrice_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  mintPrice_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  mintPrice_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  mintPrice_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  mintPrice_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintPrice_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  mintPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedNfTsCarouselHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselSubHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselSubHeader_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintedNfTsCarouselSubHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselSubHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselSubHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselSubHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselSubHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselSubHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselSubHeader_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values containing the given json path. */
  mintingContractAbi_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  mintingContractAbi_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  mintingContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingContractAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingContractAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintingContractAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrency?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingCurrencyContractAddress_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  mintingCurrencyContractAddress_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintingCurrencyContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrencyContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingCurrencyContractAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintingCurrencyContractAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintingCurrencyContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingCurrencyContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintingCurrencyContractAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  mintingCurrencyDecimals?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  mintingCurrencyDecimals_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  mintingCurrencyDecimals_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  mintingCurrencyDecimals_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  mintingCurrencyDecimals_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  mintingCurrencyDecimals_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrencyDecimals_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  mintingCurrencyDecimals_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  mintingCurrencyInformation?: InputMaybe<MintingCurrencyWhereInput>;
  /** All values containing the given string. */
  mintingCurrency_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingCurrency_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingCurrency_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrency_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingCurrency_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingCurrency_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingCurrency_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingCurrency_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintingCurrency_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingFunctionName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingFunctionName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingFunctionName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintingFunctionName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  nftCollection?: InputMaybe<NftCollectionWhereInput>;
  paperCreditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  paperCreditCardMintingLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  paperCreditCardMintingLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  paperCreditCardMintingLink_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  paperCreditCardMintingLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  paperCreditCardMintingLink_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  paperCreditCardMintingLink_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  paperCreditCardMintingLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  paperCreditCardMintingLink_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  paperCreditCardMintingLink_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  presaleCheckerFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleCheckerFunction_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleCheckerFunction_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleCheckerFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleCheckerFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleCheckerFunction_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleCheckerFunction_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleCheckerFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleCheckerFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleCheckerFunction_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleHeroSectionCopy?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleHeroSectionCopy_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleHeroSectionCopy_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleHeroSectionCopy_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleHeroSectionCopy_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleHeroSectionCopy_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleHeroSectionCopy_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleHeroSectionCopy_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleHeroSectionCopy_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleHeroSectionCopy_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleMaxMintGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleMaxMintGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleMaxMintGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleMaxMintGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleMaxMintGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleMaxMintGetter_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleMaxMintGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleMaxMintGetter_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  presaleMaxMintGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleMintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleMintingFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleMintingFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleMintingFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleMintingFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleMintingFunctionName_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  presaleMintingFunctionName_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  presaleMintingFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleMintingFunctionName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleMintingFunctionName_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  presalePriceGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presalePriceGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presalePriceGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presalePriceGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presalePriceGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presalePriceGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presalePriceGetter_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presalePriceGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presalePriceGetter_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  presalePriceGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  priceQueryFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  priceQueryFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  priceQueryFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  priceQueryFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  priceQueryFunctionName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  priceQueryFunctionName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  priceQueryFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  priceQueryFunctionName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  priceQueryFunctionName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryRequiresAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  priceQueryRequiresAccount_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  projectName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  projectName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  projectName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  projectName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  projectName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  projectName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  projectName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  projectName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  projectName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains *all* items provided to the filter */
  projectSecurityTags_contains_all?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  projectSecurityTags_contains_none?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains at least one item provided to the filter */
  projectSecurityTags_contains_some?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  projectSecurityTags_not?: InputMaybe<Array<ProjectSecurityTags>>;
  publicSaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  publicSaleMaxMintGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  publicSaleMaxMintGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  publicSaleMaxMintGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  publicSaleMaxMintGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  publicSaleMaxMintGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  publicSaleMaxMintGetter_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  publicSaleMaxMintGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  publicSaleMaxMintGetter_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  publicSaleMaxMintGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  securityLineItems_every?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_none?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_some?: InputMaybe<SecurityInfoWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  seoTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  shouldMetafuseRevealOnMint?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  shouldMetafuseRevealOnMint_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  soldOut_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  whitelistChecker?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  whitelistChecker_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  whitelistChecker_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  whitelistChecker_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  whitelistChecker_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  whitelistChecker_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  whitelistChecker_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  whitelistChecker_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  whitelistChecker_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  whitelistChecker_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum MintingProjectOrderByInput {
  aboutSectionHeader_ASC = "aboutSectionHeader_ASC",
  aboutSectionHeader_DESC = "aboutSectionHeader_DESC",
  aboutSectionText_ASC = "aboutSectionText_ASC",
  aboutSectionText_DESC = "aboutSectionText_DESC",
  allocation_ASC = "allocation_ASC",
  allocation_DESC = "allocation_DESC",
  alphaSharesProject_ASC = "alphaSharesProject_ASC",
  alphaSharesProject_DESC = "alphaSharesProject_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  currency_ASC = "currency_ASC",
  currency_DESC = "currency_DESC",
  dynamicPricingGetterFunction_ASC = "dynamicPricingGetterFunction_ASC",
  dynamicPricingGetterFunction_DESC = "dynamicPricingGetterFunction_DESC",
  featured_ASC = "featured_ASC",
  featured_DESC = "featured_DESC",
  hasDynamicPricing_ASC = "hasDynamicPricing_ASC",
  hasDynamicPricing_DESC = "hasDynamicPricing_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  maxMintPerTransaction_ASC = "maxMintPerTransaction_ASC",
  maxMintPerTransaction_DESC = "maxMintPerTransaction_DESC",
  metafuseProjectId_ASC = "metafuseProjectId_ASC",
  metafuseProjectId_DESC = "metafuseProjectId_DESC",
  mintDate_ASC = "mintDate_ASC",
  mintDate_DESC = "mintDate_DESC",
  mintPrice_ASC = "mintPrice_ASC",
  mintPrice_DESC = "mintPrice_DESC",
  mintedNfTsCarouselHeader_ASC = "mintedNfTsCarouselHeader_ASC",
  mintedNfTsCarouselHeader_DESC = "mintedNfTsCarouselHeader_DESC",
  mintedNfTsCarouselSubHeader_ASC = "mintedNfTsCarouselSubHeader_ASC",
  mintedNfTsCarouselSubHeader_DESC = "mintedNfTsCarouselSubHeader_DESC",
  mintingContractAddress_ASC = "mintingContractAddress_ASC",
  mintingContractAddress_DESC = "mintingContractAddress_DESC",
  mintingCurrencyContractAddress_ASC = "mintingCurrencyContractAddress_ASC",
  mintingCurrencyContractAddress_DESC = "mintingCurrencyContractAddress_DESC",
  mintingCurrencyDecimals_ASC = "mintingCurrencyDecimals_ASC",
  mintingCurrencyDecimals_DESC = "mintingCurrencyDecimals_DESC",
  mintingCurrency_ASC = "mintingCurrency_ASC",
  mintingCurrency_DESC = "mintingCurrency_DESC",
  mintingFunctionName_ASC = "mintingFunctionName_ASC",
  mintingFunctionName_DESC = "mintingFunctionName_DESC",
  paperCreditCardMintingLink_ASC = "paperCreditCardMintingLink_ASC",
  paperCreditCardMintingLink_DESC = "paperCreditCardMintingLink_DESC",
  presaleCheckerFunction_ASC = "presaleCheckerFunction_ASC",
  presaleCheckerFunction_DESC = "presaleCheckerFunction_DESC",
  presaleHeroSectionCopy_ASC = "presaleHeroSectionCopy_ASC",
  presaleHeroSectionCopy_DESC = "presaleHeroSectionCopy_DESC",
  presaleMaxMintGetter_ASC = "presaleMaxMintGetter_ASC",
  presaleMaxMintGetter_DESC = "presaleMaxMintGetter_DESC",
  presaleMintingFunctionName_ASC = "presaleMintingFunctionName_ASC",
  presaleMintingFunctionName_DESC = "presaleMintingFunctionName_DESC",
  presalePriceGetter_ASC = "presalePriceGetter_ASC",
  presalePriceGetter_DESC = "presalePriceGetter_DESC",
  priceQueryFunctionName_ASC = "priceQueryFunctionName_ASC",
  priceQueryFunctionName_DESC = "priceQueryFunctionName_DESC",
  priceQueryRequiresAccount_ASC = "priceQueryRequiresAccount_ASC",
  priceQueryRequiresAccount_DESC = "priceQueryRequiresAccount_DESC",
  projectName_ASC = "projectName_ASC",
  projectName_DESC = "projectName_DESC",
  projectSecurityTags_ASC = "projectSecurityTags_ASC",
  projectSecurityTags_DESC = "projectSecurityTags_DESC",
  publicSaleMaxMintGetter_ASC = "publicSaleMaxMintGetter_ASC",
  publicSaleMaxMintGetter_DESC = "publicSaleMaxMintGetter_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  seoDescription_ASC = "seoDescription_ASC",
  seoDescription_DESC = "seoDescription_DESC",
  seoTitle_ASC = "seoTitle_ASC",
  seoTitle_DESC = "seoTitle_DESC",
  shouldMetafuseRevealOnMint_ASC = "shouldMetafuseRevealOnMint_ASC",
  shouldMetafuseRevealOnMint_DESC = "shouldMetafuseRevealOnMint_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  soldOut_ASC = "soldOut_ASC",
  soldOut_DESC = "soldOut_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  whitelistChecker_ASC = "whitelistChecker_ASC",
  whitelistChecker_DESC = "whitelistChecker_DESC",
}

export type MintingProjectUpdateInput = {
  aboutSectionAccordions?: InputMaybe<FaqUpdateManyInlineInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  allocation?: InputMaybe<Scalars["Int"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionCreator?: InputMaybe<CollectionCreatorUpdateOneInlineInput>;
  collectionInfoTable?: InputMaybe<CollectionInfoTableUpdateManyInlineInput>;
  collectionSampleImages?: InputMaybe<AssetUpdateManyInlineInput>;
  ctaSection?: InputMaybe<CtaSectionUpdateOneInlineInput>;
  currency?: InputMaybe<Currencies>;
  dynamicPricingGetterFunction?: InputMaybe<Scalars["String"]["input"]>;
  fAQSection?: InputMaybe<FaqSectionUpdateOneInlineInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDynamicPricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  merkleRoot?: InputMaybe<Scalars["Json"]["input"]>;
  metafuseProjectId?: InputMaybe<Scalars["String"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintInfoTable?: InputMaybe<CollectionInfoTableUpdateManyInlineInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintingContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  mintingContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrency?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyDecimals?: InputMaybe<Scalars["Int"]["input"]>;
  mintingCurrencyInformation?: InputMaybe<MintingCurrencyUpdateOneInlineInput>;
  mintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  mintingStat?: InputMaybe<MintingStatUpdateOneInlineInput>;
  nftCollection?: InputMaybe<NftCollectionUpdateOneInlineInput>;
  paperCreditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  presaleCheckerFunction?: InputMaybe<Scalars["String"]["input"]>;
  presaleHeroSectionCopy?: InputMaybe<Scalars["String"]["input"]>;
  presaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  presaleMintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  presalePriceGetter?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryRequiresAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  publicSaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  securityLineItems?: InputMaybe<SecurityInfoUpdateManyInlineInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  shouldMetafuseRevealOnMint?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  whitelistChecker?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingProjectUpdateManyInlineInput = {
  /** Connect multiple existing MintingProject documents */
  connect?: InputMaybe<Array<MintingProjectConnectInput>>;
  /** Create and connect multiple MintingProject documents */
  create?: InputMaybe<Array<MintingProjectCreateInput>>;
  /** Delete multiple MintingProject documents */
  delete?: InputMaybe<Array<MintingProjectWhereUniqueInput>>;
  /** Disconnect multiple MintingProject documents */
  disconnect?: InputMaybe<Array<MintingProjectWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MintingProject documents */
  set?: InputMaybe<Array<MintingProjectWhereUniqueInput>>;
  /** Update multiple MintingProject documents */
  update?: InputMaybe<Array<MintingProjectUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MintingProject documents */
  upsert?: InputMaybe<Array<MintingProjectUpsertWithNestedWhereUniqueInput>>;
};

export type MintingProjectUpdateManyInput = {
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  allocation?: InputMaybe<Scalars["Int"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  currency?: InputMaybe<Currencies>;
  dynamicPricingGetterFunction?: InputMaybe<Scalars["String"]["input"]>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDynamicPricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  merkleRoot?: InputMaybe<Scalars["Json"]["input"]>;
  metafuseProjectId?: InputMaybe<Scalars["String"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintingContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  mintingContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrency?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyDecimals?: InputMaybe<Scalars["Int"]["input"]>;
  mintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  presaleCheckerFunction?: InputMaybe<Scalars["String"]["input"]>;
  presaleHeroSectionCopy?: InputMaybe<Scalars["String"]["input"]>;
  presaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  presaleMintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  presalePriceGetter?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryRequiresAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  publicSaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  shouldMetafuseRevealOnMint?: InputMaybe<Scalars["Boolean"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  whitelistChecker?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingProjectUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MintingProjectUpdateManyInput;
  /** Document search */
  where: MintingProjectWhereInput;
};

export type MintingProjectUpdateOneInlineInput = {
  /** Connect existing MintingProject document */
  connect?: InputMaybe<MintingProjectWhereUniqueInput>;
  /** Create and connect one MintingProject document */
  create?: InputMaybe<MintingProjectCreateInput>;
  /** Delete currently connected MintingProject document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MintingProject document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MintingProject document */
  update?: InputMaybe<MintingProjectUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MintingProject document */
  upsert?: InputMaybe<MintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type MintingProjectUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MintingProjectUpdateInput;
  /** Unique document search */
  where: MintingProjectWhereUniqueInput;
};

export type MintingProjectUpsertInput = {
  /** Create document if it didn't exist */
  create: MintingProjectCreateInput;
  /** Update document if it exists */
  update: MintingProjectUpdateInput;
};

export type MintingProjectUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MintingProjectUpsertInput;
  /** Unique document search */
  where: MintingProjectWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MintingProjectWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MintingProjectWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionAccordions_every?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_none?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_some?: InputMaybe<FaqWhereInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionHeader_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionHeader_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionHeader_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  allocation?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  allocation_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  allocation_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  allocation_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  allocation_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  allocation_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  allocation_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  allocation_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  alphaSharesProject_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionCreator?: InputMaybe<CollectionCreatorWhereInput>;
  collectionInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionSampleImages_every?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_none?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_some?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  currency?: InputMaybe<Currencies>;
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Currencies>;
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  documentInStages_every?: InputMaybe<MintingProjectWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingProjectWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingProjectWhereStageInput>;
  dynamicPricingGetterFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  dynamicPricingGetterFunction_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  dynamicPricingGetterFunction_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  dynamicPricingGetterFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  dynamicPricingGetterFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  dynamicPricingGetterFunction_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  dynamicPricingGetterFunction_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  dynamicPricingGetterFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  dynamicPricingGetterFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  dynamicPricingGetterFunction_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  fAQSection?: InputMaybe<FaqSectionWhereInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  featured_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDynamicPricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  hasDynamicPricing_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  maxMintPerTransaction_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  maxMintPerTransaction_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  maxMintPerTransaction_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  maxMintPerTransaction_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  maxMintPerTransaction_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  maxMintPerTransaction_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  maxMintPerTransaction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values containing the given json path. */
  merkleRoot_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  merkleRoot_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  metafuseProjectId?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  metafuseProjectId_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  metafuseProjectId_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  metafuseProjectId_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  metafuseProjectId_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  metafuseProjectId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  metafuseProjectId_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  metafuseProjectId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  metafuseProjectId_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  metafuseProjectId_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  mintDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  mintDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  mintDate_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  mintDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  mintDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  mintDate_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  mintInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  mintPrice_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  mintPrice_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  mintPrice_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  mintPrice_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  mintPrice_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintPrice_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  mintPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedNfTsCarouselHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselSubHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselSubHeader_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintedNfTsCarouselSubHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselSubHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselSubHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselSubHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselSubHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselSubHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselSubHeader_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values containing the given json path. */
  mintingContractAbi_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  mintingContractAbi_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  mintingContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingContractAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingContractAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintingContractAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrency?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingCurrencyContractAddress_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  mintingCurrencyContractAddress_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintingCurrencyContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrencyContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingCurrencyContractAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintingCurrencyContractAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintingCurrencyContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingCurrencyContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintingCurrencyContractAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  mintingCurrencyDecimals?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  mintingCurrencyDecimals_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  mintingCurrencyDecimals_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  mintingCurrencyDecimals_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  mintingCurrencyDecimals_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  mintingCurrencyDecimals_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrencyDecimals_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  mintingCurrencyDecimals_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  mintingCurrencyInformation?: InputMaybe<MintingCurrencyWhereInput>;
  /** All values containing the given string. */
  mintingCurrency_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingCurrency_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingCurrency_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrency_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingCurrency_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingCurrency_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingCurrency_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingCurrency_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintingCurrency_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingFunctionName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingFunctionName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingFunctionName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintingFunctionName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  nftCollection?: InputMaybe<NftCollectionWhereInput>;
  paperCreditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  paperCreditCardMintingLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  paperCreditCardMintingLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  paperCreditCardMintingLink_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  paperCreditCardMintingLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  paperCreditCardMintingLink_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  paperCreditCardMintingLink_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  paperCreditCardMintingLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  paperCreditCardMintingLink_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  paperCreditCardMintingLink_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  presaleCheckerFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleCheckerFunction_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleCheckerFunction_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleCheckerFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleCheckerFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleCheckerFunction_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleCheckerFunction_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleCheckerFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleCheckerFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleCheckerFunction_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleHeroSectionCopy?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleHeroSectionCopy_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleHeroSectionCopy_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleHeroSectionCopy_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleHeroSectionCopy_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleHeroSectionCopy_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleHeroSectionCopy_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleHeroSectionCopy_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleHeroSectionCopy_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleHeroSectionCopy_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleMaxMintGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleMaxMintGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleMaxMintGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleMaxMintGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleMaxMintGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleMaxMintGetter_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleMaxMintGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleMaxMintGetter_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  presaleMaxMintGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleMintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleMintingFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleMintingFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleMintingFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleMintingFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleMintingFunctionName_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  presaleMintingFunctionName_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  presaleMintingFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleMintingFunctionName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleMintingFunctionName_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  presalePriceGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presalePriceGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presalePriceGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presalePriceGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presalePriceGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presalePriceGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presalePriceGetter_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presalePriceGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presalePriceGetter_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  presalePriceGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  priceQueryFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  priceQueryFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  priceQueryFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  priceQueryFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  priceQueryFunctionName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  priceQueryFunctionName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  priceQueryFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  priceQueryFunctionName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  priceQueryFunctionName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryRequiresAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  priceQueryRequiresAccount_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  projectName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  projectName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  projectName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  projectName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  projectName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  projectName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  projectName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  projectName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  projectName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains *all* items provided to the filter */
  projectSecurityTags_contains_all?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  projectSecurityTags_contains_none?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains at least one item provided to the filter */
  projectSecurityTags_contains_some?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  projectSecurityTags_not?: InputMaybe<Array<ProjectSecurityTags>>;
  publicSaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  publicSaleMaxMintGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  publicSaleMaxMintGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  publicSaleMaxMintGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  publicSaleMaxMintGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  publicSaleMaxMintGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  publicSaleMaxMintGetter_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  publicSaleMaxMintGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  publicSaleMaxMintGetter_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  publicSaleMaxMintGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  securityLineItems_every?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_none?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_some?: InputMaybe<SecurityInfoWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  seoTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  shouldMetafuseRevealOnMint?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  shouldMetafuseRevealOnMint_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  soldOut_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  whitelistChecker?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  whitelistChecker_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  whitelistChecker_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  whitelistChecker_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  whitelistChecker_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  whitelistChecker_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  whitelistChecker_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  whitelistChecker_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  whitelistChecker_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  whitelistChecker_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MintingProjectWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingProjectWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingProjectWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingProjectWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MintingProjectWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MintingProject record uniquely */
export type MintingProjectWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  paperCreditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingStat = Entity &
  Node & {
    __typename?: "MintingStat";
    backgroundImage?: Maybe<Asset>;
    button?: Maybe<Button>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MintingStat>;
    feesInformationText?: Maybe<Scalars["String"]["output"]>;
    flexibleText?: Maybe<ColoredText>;
    heading?: Maybe<Scalars["String"]["output"]>;
    heroImage?: Maybe<Asset>;
    heroImageLeft?: Maybe<Asset>;
    /** List of MintingStat versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    inProgressText?: Maybe<Scalars["String"]["output"]>;
    mintedCountText?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    subHeading?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MintingStatBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatButtonArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MintingStatFlexibleTextArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatHeroImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatHeroImageLeftArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MintingStatPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MintingStatUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MintingStatWhereUniqueInput;
};

/** A connection to a list of items. */
export type MintingStatConnection = {
  __typename?: "MintingStatConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MintingStatEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MintingStatCreateInput = {
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  button?: InputMaybe<ButtonCreateOneInlineInput>;
  cl4cbjotb3puu01xud88bcwdi?: InputMaybe<PageCreateManyInlineInput>;
  cl80ihw7j09gi01t13voa75rh?: InputMaybe<MintingProjectCreateManyInlineInput>;
  clpbp452i7nv801ukfih32fqd?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  feesInformationText?: InputMaybe<Scalars["String"]["input"]>;
  flexibleText?: InputMaybe<ColoredTextCreateOneInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  heroImage?: InputMaybe<AssetCreateOneInlineInput>;
  heroImageLeft?: InputMaybe<AssetCreateOneInlineInput>;
  inProgressText?: InputMaybe<Scalars["String"]["input"]>;
  mintedCountText?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MintingStatCreateManyInlineInput = {
  /** Connect multiple existing MintingStat documents */
  connect?: InputMaybe<Array<MintingStatWhereUniqueInput>>;
  /** Create and connect multiple existing MintingStat documents */
  create?: InputMaybe<Array<MintingStatCreateInput>>;
};

export type MintingStatCreateOneInlineInput = {
  /** Connect one existing MintingStat document */
  connect?: InputMaybe<MintingStatWhereUniqueInput>;
  /** Create and connect one MintingStat document */
  create?: InputMaybe<MintingStatCreateInput>;
};

/** An edge in a connection. */
export type MintingStatEdge = {
  __typename?: "MintingStatEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MintingStat;
};

/** Identifies documents */
export type MintingStatManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  button?: InputMaybe<ButtonWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MintingStatWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingStatWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingStatWhereStageInput>;
  feesInformationText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  feesInformationText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  feesInformationText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  feesInformationText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  feesInformationText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  feesInformationText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  feesInformationText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  feesInformationText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  feesInformationText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  feesInformationText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  flexibleText?: InputMaybe<ColoredTextWhereInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  heroImage?: InputMaybe<AssetWhereInput>;
  heroImageLeft?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  inProgressText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  inProgressText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  inProgressText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  inProgressText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  inProgressText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  inProgressText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  inProgressText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  inProgressText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  inProgressText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  inProgressText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedCountText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedCountText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedCountText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedCountText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedCountText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedCountText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintedCountText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintedCountText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedCountText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintedCountText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MintingStatOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  feesInformationText_ASC = "feesInformationText_ASC",
  feesInformationText_DESC = "feesInformationText_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  inProgressText_ASC = "inProgressText_ASC",
  inProgressText_DESC = "inProgressText_DESC",
  mintedCountText_ASC = "mintedCountText_ASC",
  mintedCountText_DESC = "mintedCountText_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  subHeading_ASC = "subHeading_ASC",
  subHeading_DESC = "subHeading_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MintingStatUpdateInput = {
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  button?: InputMaybe<ButtonUpdateOneInlineInput>;
  cl4cbjotb3puu01xud88bcwdi?: InputMaybe<PageUpdateManyInlineInput>;
  cl80ihw7j09gi01t13voa75rh?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  clpbp452i7nv801ukfih32fqd?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  feesInformationText?: InputMaybe<Scalars["String"]["input"]>;
  flexibleText?: InputMaybe<ColoredTextUpdateOneInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  heroImage?: InputMaybe<AssetUpdateOneInlineInput>;
  heroImageLeft?: InputMaybe<AssetUpdateOneInlineInput>;
  inProgressText?: InputMaybe<Scalars["String"]["input"]>;
  mintedCountText?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingStatUpdateManyInlineInput = {
  /** Connect multiple existing MintingStat documents */
  connect?: InputMaybe<Array<MintingStatConnectInput>>;
  /** Create and connect multiple MintingStat documents */
  create?: InputMaybe<Array<MintingStatCreateInput>>;
  /** Delete multiple MintingStat documents */
  delete?: InputMaybe<Array<MintingStatWhereUniqueInput>>;
  /** Disconnect multiple MintingStat documents */
  disconnect?: InputMaybe<Array<MintingStatWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MintingStat documents */
  set?: InputMaybe<Array<MintingStatWhereUniqueInput>>;
  /** Update multiple MintingStat documents */
  update?: InputMaybe<Array<MintingStatUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MintingStat documents */
  upsert?: InputMaybe<Array<MintingStatUpsertWithNestedWhereUniqueInput>>;
};

export type MintingStatUpdateManyInput = {
  feesInformationText?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  inProgressText?: InputMaybe<Scalars["String"]["input"]>;
  mintedCountText?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingStatUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MintingStatUpdateManyInput;
  /** Document search */
  where: MintingStatWhereInput;
};

export type MintingStatUpdateOneInlineInput = {
  /** Connect existing MintingStat document */
  connect?: InputMaybe<MintingStatWhereUniqueInput>;
  /** Create and connect one MintingStat document */
  create?: InputMaybe<MintingStatCreateInput>;
  /** Delete currently connected MintingStat document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MintingStat document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MintingStat document */
  update?: InputMaybe<MintingStatUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MintingStat document */
  upsert?: InputMaybe<MintingStatUpsertWithNestedWhereUniqueInput>;
};

export type MintingStatUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MintingStatUpdateInput;
  /** Unique document search */
  where: MintingStatWhereUniqueInput;
};

export type MintingStatUpsertInput = {
  /** Create document if it didn't exist */
  create: MintingStatCreateInput;
  /** Update document if it exists */
  update: MintingStatUpdateInput;
};

export type MintingStatUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MintingStatUpsertInput;
  /** Unique document search */
  where: MintingStatWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MintingStatWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MintingStatWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  button?: InputMaybe<ButtonWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MintingStatWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingStatWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingStatWhereStageInput>;
  feesInformationText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  feesInformationText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  feesInformationText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  feesInformationText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  feesInformationText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  feesInformationText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  feesInformationText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  feesInformationText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  feesInformationText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  feesInformationText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  flexibleText?: InputMaybe<ColoredTextWhereInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  heroImage?: InputMaybe<AssetWhereInput>;
  heroImageLeft?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  inProgressText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  inProgressText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  inProgressText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  inProgressText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  inProgressText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  inProgressText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  inProgressText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  inProgressText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  inProgressText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  inProgressText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedCountText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedCountText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedCountText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedCountText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedCountText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedCountText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintedCountText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintedCountText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedCountText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintedCountText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MintingStatWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingStatWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingStatWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingStatWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MintingStatWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MintingStat record uniquely */
export type MintingStatWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MrBurnzToken = Entity &
  Node & {
    __typename?: "MrBurnzToken";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MrBurnzToken>;
    /** List of MrBurnzToken versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    tokenPrinterConfigurations: Array<TokenPrinterConfiguration>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MrBurnzTokenCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MrBurnzTokenDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MrBurnzTokenHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MrBurnzTokenPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MrBurnzTokenScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MrBurnzTokenTokenPrinterConfigurationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TokenPrinterConfigurationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TokenPrinterConfigurationWhereInput>;
};

export type MrBurnzTokenUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MrBurnzTokenConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MrBurnzTokenWhereUniqueInput;
};

/** A connection to a list of items. */
export type MrBurnzTokenConnection = {
  __typename?: "MrBurnzTokenConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MrBurnzTokenEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MrBurnzTokenCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  tokenPrinterConfigurations?: InputMaybe<TokenPrinterConfigurationCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MrBurnzTokenCreateManyInlineInput = {
  /** Connect multiple existing MrBurnzToken documents */
  connect?: InputMaybe<Array<MrBurnzTokenWhereUniqueInput>>;
  /** Create and connect multiple existing MrBurnzToken documents */
  create?: InputMaybe<Array<MrBurnzTokenCreateInput>>;
};

export type MrBurnzTokenCreateOneInlineInput = {
  /** Connect one existing MrBurnzToken document */
  connect?: InputMaybe<MrBurnzTokenWhereUniqueInput>;
  /** Create and connect one MrBurnzToken document */
  create?: InputMaybe<MrBurnzTokenCreateInput>;
};

/** An edge in a connection. */
export type MrBurnzTokenEdge = {
  __typename?: "MrBurnzTokenEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MrBurnzToken;
};

/** Identifies documents */
export type MrBurnzTokenManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MrBurnzTokenWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MrBurnzTokenWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MrBurnzTokenWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MrBurnzTokenWhereStageInput>;
  documentInStages_none?: InputMaybe<MrBurnzTokenWhereStageInput>;
  documentInStages_some?: InputMaybe<MrBurnzTokenWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tokenPrinterConfigurations_every?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenPrinterConfigurations_none?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenPrinterConfigurations_some?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MrBurnzTokenOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MrBurnzTokenUpdateInput = {
  tokenPrinterConfigurations?: InputMaybe<TokenPrinterConfigurationUpdateManyInlineInput>;
};

export type MrBurnzTokenUpdateManyInlineInput = {
  /** Connect multiple existing MrBurnzToken documents */
  connect?: InputMaybe<Array<MrBurnzTokenConnectInput>>;
  /** Create and connect multiple MrBurnzToken documents */
  create?: InputMaybe<Array<MrBurnzTokenCreateInput>>;
  /** Delete multiple MrBurnzToken documents */
  delete?: InputMaybe<Array<MrBurnzTokenWhereUniqueInput>>;
  /** Disconnect multiple MrBurnzToken documents */
  disconnect?: InputMaybe<Array<MrBurnzTokenWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MrBurnzToken documents */
  set?: InputMaybe<Array<MrBurnzTokenWhereUniqueInput>>;
  /** Update multiple MrBurnzToken documents */
  update?: InputMaybe<Array<MrBurnzTokenUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MrBurnzToken documents */
  upsert?: InputMaybe<Array<MrBurnzTokenUpsertWithNestedWhereUniqueInput>>;
};

export type MrBurnzTokenUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars["String"]["input"]>;
};

export type MrBurnzTokenUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MrBurnzTokenUpdateManyInput;
  /** Document search */
  where: MrBurnzTokenWhereInput;
};

export type MrBurnzTokenUpdateOneInlineInput = {
  /** Connect existing MrBurnzToken document */
  connect?: InputMaybe<MrBurnzTokenWhereUniqueInput>;
  /** Create and connect one MrBurnzToken document */
  create?: InputMaybe<MrBurnzTokenCreateInput>;
  /** Delete currently connected MrBurnzToken document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MrBurnzToken document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MrBurnzToken document */
  update?: InputMaybe<MrBurnzTokenUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MrBurnzToken document */
  upsert?: InputMaybe<MrBurnzTokenUpsertWithNestedWhereUniqueInput>;
};

export type MrBurnzTokenUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MrBurnzTokenUpdateInput;
  /** Unique document search */
  where: MrBurnzTokenWhereUniqueInput;
};

export type MrBurnzTokenUpsertInput = {
  /** Create document if it didn't exist */
  create: MrBurnzTokenCreateInput;
  /** Update document if it exists */
  update: MrBurnzTokenUpdateInput;
};

export type MrBurnzTokenUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MrBurnzTokenUpsertInput;
  /** Unique document search */
  where: MrBurnzTokenWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MrBurnzTokenWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MrBurnzTokenWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MrBurnzTokenWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MrBurnzTokenWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MrBurnzTokenWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MrBurnzTokenWhereStageInput>;
  documentInStages_none?: InputMaybe<MrBurnzTokenWhereStageInput>;
  documentInStages_some?: InputMaybe<MrBurnzTokenWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tokenPrinterConfigurations_every?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenPrinterConfigurations_none?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  tokenPrinterConfigurations_some?: InputMaybe<TokenPrinterConfigurationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MrBurnzTokenWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MrBurnzTokenWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MrBurnzTokenWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MrBurnzTokenWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MrBurnzTokenWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MrBurnzToken record uniquely */
export type MrBurnzTokenWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MultiChainMintingProject = Entity &
  Node & {
    __typename?: "MultiChainMintingProject";
    aboutSectionAccordions: Array<Faq>;
    aboutSectionHeader?: Maybe<Scalars["String"]["output"]>;
    aboutSectionText?: Maybe<Scalars["String"]["output"]>;
    alphaSharesProject: Scalars["Boolean"]["output"];
    collectionDescription?: Maybe<Scalars["String"]["output"]>;
    collectionInfoTable: Array<CollectionInfoTable>;
    collectionSampleImages: Array<Asset>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    ctaSection?: Maybe<CtaSection>;
    /** Get the document in other stages */
    documentInStages: Array<MultiChainMintingProject>;
    fAQSections: Array<FaqSection>;
    featured: Scalars["Boolean"]["output"];
    globalMintContractAbi?: Maybe<Scalars["Json"]["output"]>;
    globalMintContractMintFunction?: Maybe<Scalars["String"]["output"]>;
    /** List of MultiChainMintingProject versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    maxMintPerTransaction?: Maybe<Scalars["Int"]["output"]>;
    mintDate?: Maybe<Scalars["DateTime"]["output"]>;
    mintInfoTable: Array<CollectionInfoTable>;
    mintPrice?: Maybe<Scalars["Float"]["output"]>;
    mintedNfTsCarouselHeader?: Maybe<Scalars["String"]["output"]>;
    mintedNfTsCarouselSubHeader?: Maybe<Scalars["String"]["output"]>;
    mintingCurrencies: Array<MintingCurrency>;
    mintingStat?: Maybe<MintingStat>;
    nftCollections: Array<NftCollection>;
    projectName: Scalars["String"]["output"];
    projectSecurityTags: Array<ProjectSecurityTags>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    securityLineItems: Array<SecurityInfo>;
    seoDescription?: Maybe<Scalars["String"]["output"]>;
    seoImage?: Maybe<Asset>;
    seoTitle?: Maybe<Scalars["String"]["output"]>;
    /** url for minting project */
    slug?: Maybe<Scalars["String"]["output"]>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MultiChainMintingProjectAboutSectionAccordionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqWhereInput>;
};

export type MultiChainMintingProjectCollectionInfoTableArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionInfoTableOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionInfoTableWhereInput>;
};

export type MultiChainMintingProjectCollectionSampleImagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type MultiChainMintingProjectCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectCtaSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MultiChainMintingProjectFAqSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqSectionWhereInput>;
};

export type MultiChainMintingProjectHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MultiChainMintingProjectMintInfoTableArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionInfoTableOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionInfoTableWhereInput>;
};

export type MultiChainMintingProjectMintingCurrenciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingCurrencyOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingCurrencyWhereInput>;
};

export type MultiChainMintingProjectMintingStatArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type MultiChainMintingProjectPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MultiChainMintingProjectSecurityLineItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SecurityInfoOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SecurityInfoWhereInput>;
};

export type MultiChainMintingProjectSeoImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MultiChainMintingProjectWhereUniqueInput;
};

/** A connection to a list of items. */
export type MultiChainMintingProjectConnection = {
  __typename?: "MultiChainMintingProjectConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MultiChainMintingProjectEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MultiChainMintingProjectCreateInput = {
  aboutSectionAccordions?: InputMaybe<FaqCreateManyInlineInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  alphaSharesProject: Scalars["Boolean"]["input"];
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  collectionInfoTable?: InputMaybe<CollectionInfoTableCreateManyInlineInput>;
  collectionSampleImages?: InputMaybe<AssetCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  ctaSection?: InputMaybe<CtaSectionCreateOneInlineInput>;
  fAQSections?: InputMaybe<FaqSectionCreateManyInlineInput>;
  featured: Scalars["Boolean"]["input"];
  globalMintContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  globalMintContractMintFunction?: InputMaybe<Scalars["String"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintInfoTable?: InputMaybe<CollectionInfoTableCreateManyInlineInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencies?: InputMaybe<MintingCurrencyCreateManyInlineInput>;
  mintingStat?: InputMaybe<MintingStatCreateOneInlineInput>;
  nftCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  projectName: Scalars["String"]["input"];
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  securityLineItems?: InputMaybe<SecurityInfoCreateManyInlineInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetCreateOneInlineInput>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MultiChainMintingProjectCreateManyInlineInput = {
  /** Connect multiple existing MultiChainMintingProject documents */
  connect?: InputMaybe<Array<MultiChainMintingProjectWhereUniqueInput>>;
  /** Create and connect multiple existing MultiChainMintingProject documents */
  create?: InputMaybe<Array<MultiChainMintingProjectCreateInput>>;
};

export type MultiChainMintingProjectCreateOneInlineInput = {
  /** Connect one existing MultiChainMintingProject document */
  connect?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
  /** Create and connect one MultiChainMintingProject document */
  create?: InputMaybe<MultiChainMintingProjectCreateInput>;
};

/** An edge in a connection. */
export type MultiChainMintingProjectEdge = {
  __typename?: "MultiChainMintingProjectEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MultiChainMintingProject;
};

/** Identifies documents */
export type MultiChainMintingProjectManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionAccordions_every?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_none?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_some?: InputMaybe<FaqWhereInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionHeader_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionHeader_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionHeader_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  alphaSharesProject_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDescription_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionSampleImages_every?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_none?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_some?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  documentInStages_none?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  documentInStages_some?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  fAQSections_every?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_none?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_some?: InputMaybe<FaqSectionWhereInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  featured_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** All values containing the given json path. */
  globalMintContractAbi_json_path_exists?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  globalMintContractAbi_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  globalMintContractMintFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  globalMintContractMintFunction_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  globalMintContractMintFunction_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  globalMintContractMintFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  globalMintContractMintFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  globalMintContractMintFunction_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  globalMintContractMintFunction_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  globalMintContractMintFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  globalMintContractMintFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  globalMintContractMintFunction_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  maxMintPerTransaction_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  maxMintPerTransaction_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  maxMintPerTransaction_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  maxMintPerTransaction_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  maxMintPerTransaction_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  maxMintPerTransaction_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  maxMintPerTransaction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  mintDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  mintDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  mintDate_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  mintDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  mintDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  mintDate_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  mintInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  mintPrice_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  mintPrice_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  mintPrice_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  mintPrice_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  mintPrice_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintPrice_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  mintPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedNfTsCarouselHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselSubHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselSubHeader_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintedNfTsCarouselSubHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselSubHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselSubHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselSubHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselSubHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselSubHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselSubHeader_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  mintingCurrencies_every?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_none?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_some?: InputMaybe<MintingCurrencyWhereInput>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  projectName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  projectName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  projectName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  projectName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  projectName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  projectName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  projectName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  projectName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  projectName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains *all* items provided to the filter */
  projectSecurityTags_contains_all?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  projectSecurityTags_contains_none?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains at least one item provided to the filter */
  projectSecurityTags_contains_some?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  projectSecurityTags_not?: InputMaybe<Array<ProjectSecurityTags>>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  securityLineItems_every?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_none?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_some?: InputMaybe<SecurityInfoWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetWhereInput>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  seoTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MultiChainMintingProjectOrderByInput {
  aboutSectionHeader_ASC = "aboutSectionHeader_ASC",
  aboutSectionHeader_DESC = "aboutSectionHeader_DESC",
  aboutSectionText_ASC = "aboutSectionText_ASC",
  aboutSectionText_DESC = "aboutSectionText_DESC",
  alphaSharesProject_ASC = "alphaSharesProject_ASC",
  alphaSharesProject_DESC = "alphaSharesProject_DESC",
  collectionDescription_ASC = "collectionDescription_ASC",
  collectionDescription_DESC = "collectionDescription_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  featured_ASC = "featured_ASC",
  featured_DESC = "featured_DESC",
  globalMintContractMintFunction_ASC = "globalMintContractMintFunction_ASC",
  globalMintContractMintFunction_DESC = "globalMintContractMintFunction_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  maxMintPerTransaction_ASC = "maxMintPerTransaction_ASC",
  maxMintPerTransaction_DESC = "maxMintPerTransaction_DESC",
  mintDate_ASC = "mintDate_ASC",
  mintDate_DESC = "mintDate_DESC",
  mintPrice_ASC = "mintPrice_ASC",
  mintPrice_DESC = "mintPrice_DESC",
  mintedNfTsCarouselHeader_ASC = "mintedNfTsCarouselHeader_ASC",
  mintedNfTsCarouselHeader_DESC = "mintedNfTsCarouselHeader_DESC",
  mintedNfTsCarouselSubHeader_ASC = "mintedNfTsCarouselSubHeader_ASC",
  mintedNfTsCarouselSubHeader_DESC = "mintedNfTsCarouselSubHeader_DESC",
  projectName_ASC = "projectName_ASC",
  projectName_DESC = "projectName_DESC",
  projectSecurityTags_ASC = "projectSecurityTags_ASC",
  projectSecurityTags_DESC = "projectSecurityTags_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  seoDescription_ASC = "seoDescription_ASC",
  seoDescription_DESC = "seoDescription_DESC",
  seoTitle_ASC = "seoTitle_ASC",
  seoTitle_DESC = "seoTitle_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MultiChainMintingProjectUpdateInput = {
  aboutSectionAccordions?: InputMaybe<FaqUpdateManyInlineInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  collectionInfoTable?: InputMaybe<CollectionInfoTableUpdateManyInlineInput>;
  collectionSampleImages?: InputMaybe<AssetUpdateManyInlineInput>;
  ctaSection?: InputMaybe<CtaSectionUpdateOneInlineInput>;
  fAQSections?: InputMaybe<FaqSectionUpdateManyInlineInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  globalMintContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  globalMintContractMintFunction?: InputMaybe<Scalars["String"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintInfoTable?: InputMaybe<CollectionInfoTableUpdateManyInlineInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencies?: InputMaybe<MintingCurrencyUpdateManyInlineInput>;
  mintingStat?: InputMaybe<MintingStatUpdateOneInlineInput>;
  nftCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  securityLineItems?: InputMaybe<SecurityInfoUpdateManyInlineInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetUpdateOneInlineInput>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type MultiChainMintingProjectUpdateManyInlineInput = {
  /** Connect multiple existing MultiChainMintingProject documents */
  connect?: InputMaybe<Array<MultiChainMintingProjectConnectInput>>;
  /** Create and connect multiple MultiChainMintingProject documents */
  create?: InputMaybe<Array<MultiChainMintingProjectCreateInput>>;
  /** Delete multiple MultiChainMintingProject documents */
  delete?: InputMaybe<Array<MultiChainMintingProjectWhereUniqueInput>>;
  /** Disconnect multiple MultiChainMintingProject documents */
  disconnect?: InputMaybe<Array<MultiChainMintingProjectWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MultiChainMintingProject documents */
  set?: InputMaybe<Array<MultiChainMintingProjectWhereUniqueInput>>;
  /** Update multiple MultiChainMintingProject documents */
  update?: InputMaybe<
    Array<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MultiChainMintingProject documents */
  upsert?: InputMaybe<
    Array<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>
  >;
};

export type MultiChainMintingProjectUpdateManyInput = {
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  globalMintContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  globalMintContractMintFunction?: InputMaybe<Scalars["String"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type MultiChainMintingProjectUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MultiChainMintingProjectUpdateManyInput;
  /** Document search */
  where: MultiChainMintingProjectWhereInput;
};

export type MultiChainMintingProjectUpdateOneInlineInput = {
  /** Connect existing MultiChainMintingProject document */
  connect?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
  /** Create and connect one MultiChainMintingProject document */
  create?: InputMaybe<MultiChainMintingProjectCreateInput>;
  /** Delete currently connected MultiChainMintingProject document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MultiChainMintingProject document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MultiChainMintingProject document */
  update?: InputMaybe<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MultiChainMintingProject document */
  upsert?: InputMaybe<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type MultiChainMintingProjectUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MultiChainMintingProjectUpdateInput;
  /** Unique document search */
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MultiChainMintingProjectUpsertInput = {
  /** Create document if it didn't exist */
  create: MultiChainMintingProjectCreateInput;
  /** Update document if it exists */
  update: MultiChainMintingProjectUpdateInput;
};

export type MultiChainMintingProjectUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MultiChainMintingProjectUpsertInput;
  /** Unique document search */
  where: MultiChainMintingProjectWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MultiChainMintingProjectWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MultiChainMintingProjectWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionAccordions_every?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_none?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_some?: InputMaybe<FaqWhereInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionHeader_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionHeader_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionHeader_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  alphaSharesProject_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDescription_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionSampleImages_every?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_none?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_some?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  documentInStages_none?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  documentInStages_some?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  fAQSections_every?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_none?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_some?: InputMaybe<FaqSectionWhereInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  featured_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** All values containing the given json path. */
  globalMintContractAbi_json_path_exists?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  globalMintContractAbi_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  globalMintContractMintFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  globalMintContractMintFunction_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  globalMintContractMintFunction_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  globalMintContractMintFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  globalMintContractMintFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  globalMintContractMintFunction_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  globalMintContractMintFunction_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  globalMintContractMintFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  globalMintContractMintFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  globalMintContractMintFunction_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  maxMintPerTransaction_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  maxMintPerTransaction_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  maxMintPerTransaction_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  maxMintPerTransaction_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  maxMintPerTransaction_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  maxMintPerTransaction_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  maxMintPerTransaction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  mintDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  mintDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  mintDate_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  mintDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  mintDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  mintDate_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  mintInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  mintPrice_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  mintPrice_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  mintPrice_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  mintPrice_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  mintPrice_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintPrice_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  mintPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedNfTsCarouselHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselSubHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselSubHeader_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintedNfTsCarouselSubHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselSubHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselSubHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselSubHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselSubHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselSubHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselSubHeader_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  mintingCurrencies_every?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_none?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_some?: InputMaybe<MintingCurrencyWhereInput>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  projectName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  projectName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  projectName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  projectName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  projectName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  projectName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  projectName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  projectName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  projectName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains *all* items provided to the filter */
  projectSecurityTags_contains_all?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  projectSecurityTags_contains_none?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains at least one item provided to the filter */
  projectSecurityTags_contains_some?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  projectSecurityTags_not?: InputMaybe<Array<ProjectSecurityTags>>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  securityLineItems_every?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_none?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_some?: InputMaybe<SecurityInfoWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetWhereInput>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  seoTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MultiChainMintingProjectWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MultiChainMintingProjectWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MultiChainMintingProjectWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MultiChainMintingProjectWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MultiChainMintingProjectWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MultiChainMintingProject record uniquely */
export type MultiChainMintingProjectWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  /**
   * Create one asset
   * @deprecated Asset mutations will be overhauled soon
   */
  createAsset?: Maybe<Asset>;
  /** Create one chain */
  createChain?: Maybe<Chain>;
  /** Create one collectionCreator */
  createCollectionCreator?: Maybe<CollectionCreator>;
  /** Create one ctaSection */
  createCtaSection?: Maybe<CtaSection>;
  /** Create one fAQSection */
  createFAQSection?: Maybe<FaqSection>;
  /** Create one featuredCollectionGroup */
  createFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Create one featuredCreatorGroup */
  createFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Create one homePageVideoHero */
  createHomePageVideoHero?: Maybe<HomePageVideoHero>;
  /** Create one landingPage */
  createLandingPage?: Maybe<LandingPage>;
  /** Create one menu */
  createMenu?: Maybe<Menu>;
  /** Create one miningContainer */
  createMiningContainer?: Maybe<MiningContainer>;
  /** Create one miningHeaderNotice */
  createMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Create one mintingCurrency */
  createMintingCurrency?: Maybe<MintingCurrency>;
  /** Create one mintingProject */
  createMintingProject?: Maybe<MintingProject>;
  /** Create one mintingStat */
  createMintingStat?: Maybe<MintingStat>;
  /** Create one mrBurnzToken */
  createMrBurnzToken?: Maybe<MrBurnzToken>;
  /** Create one multiChainMintingProject */
  createMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Create one nftCollection */
  createNftCollection?: Maybe<NftCollection>;
  /** Create one page */
  createPage?: Maybe<Page>;
  /** Create one scheduledRelease */
  createScheduledRelease?: Maybe<ScheduledRelease>;
  /** Create one tokenPrinterConfiguration */
  createTokenPrinterConfiguration?: Maybe<TokenPrinterConfiguration>;
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset?: Maybe<Asset>;
  /** Delete one chain from _all_ existing stages. Returns deleted document. */
  deleteChain?: Maybe<Chain>;
  /** Delete one collectionCreator from _all_ existing stages. Returns deleted document. */
  deleteCollectionCreator?: Maybe<CollectionCreator>;
  /** Delete one ctaSection from _all_ existing stages. Returns deleted document. */
  deleteCtaSection?: Maybe<CtaSection>;
  /** Delete one fAQSection from _all_ existing stages. Returns deleted document. */
  deleteFAQSection?: Maybe<FaqSection>;
  /** Delete one featuredCollectionGroup from _all_ existing stages. Returns deleted document. */
  deleteFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Delete one featuredCreatorGroup from _all_ existing stages. Returns deleted document. */
  deleteFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Delete one homePageVideoHero from _all_ existing stages. Returns deleted document. */
  deleteHomePageVideoHero?: Maybe<HomePageVideoHero>;
  /** Delete one landingPage from _all_ existing stages. Returns deleted document. */
  deleteLandingPage?: Maybe<LandingPage>;
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: BatchPayload;
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: AssetConnection;
  /**
   * Delete many Chain documents
   * @deprecated Please use the new paginated many mutation (deleteManyChainsConnection)
   */
  deleteManyChains: BatchPayload;
  /** Delete many Chain documents, return deleted documents */
  deleteManyChainsConnection: ChainConnection;
  /**
   * Delete many CollectionCreator documents
   * @deprecated Please use the new paginated many mutation (deleteManyCollectionCreatorsConnection)
   */
  deleteManyCollectionCreators: BatchPayload;
  /** Delete many CollectionCreator documents, return deleted documents */
  deleteManyCollectionCreatorsConnection: CollectionCreatorConnection;
  /**
   * Delete many CtaSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyCtaSectionsConnection)
   */
  deleteManyCtaSections: BatchPayload;
  /** Delete many CtaSection documents, return deleted documents */
  deleteManyCtaSectionsConnection: CtaSectionConnection;
  /**
   * Delete many FAQSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyFAQSectionsConnection)
   */
  deleteManyFAQSections: BatchPayload;
  /** Delete many FAQSection documents, return deleted documents */
  deleteManyFAQSectionsConnection: FaqSectionConnection;
  /**
   * Delete many FeaturedCollectionGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyFeaturedCollectionGroupsConnection)
   */
  deleteManyFeaturedCollectionGroups: BatchPayload;
  /** Delete many FeaturedCollectionGroup documents, return deleted documents */
  deleteManyFeaturedCollectionGroupsConnection: FeaturedCollectionGroupConnection;
  /**
   * Delete many FeaturedCreatorGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyFeaturedCreatorGroupsConnection)
   */
  deleteManyFeaturedCreatorGroups: BatchPayload;
  /** Delete many FeaturedCreatorGroup documents, return deleted documents */
  deleteManyFeaturedCreatorGroupsConnection: FeaturedCreatorGroupConnection;
  /**
   * Delete many HomePageVideoHero documents
   * @deprecated Please use the new paginated many mutation (deleteManyHomePageVideoHeroesConnection)
   */
  deleteManyHomePageVideoHeroes: BatchPayload;
  /** Delete many HomePageVideoHero documents, return deleted documents */
  deleteManyHomePageVideoHeroesConnection: HomePageVideoHeroConnection;
  /**
   * Delete many LandingPage documents
   * @deprecated Please use the new paginated many mutation (deleteManyLandingPagesConnection)
   */
  deleteManyLandingPages: BatchPayload;
  /** Delete many LandingPage documents, return deleted documents */
  deleteManyLandingPagesConnection: LandingPageConnection;
  /**
   * Delete many Menu documents
   * @deprecated Please use the new paginated many mutation (deleteManyMenusConnection)
   */
  deleteManyMenus: BatchPayload;
  /** Delete many Menu documents, return deleted documents */
  deleteManyMenusConnection: MenuConnection;
  /**
   * Delete many MiningContainer documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningContainersConnection)
   */
  deleteManyMiningContainers: BatchPayload;
  /** Delete many MiningContainer documents, return deleted documents */
  deleteManyMiningContainersConnection: MiningContainerConnection;
  /**
   * Delete many MiningHeaderNotice documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningHeaderNoticesConnection)
   */
  deleteManyMiningHeaderNotices: BatchPayload;
  /** Delete many MiningHeaderNotice documents, return deleted documents */
  deleteManyMiningHeaderNoticesConnection: MiningHeaderNoticeConnection;
  /**
   * Delete many MintingCurrency documents
   * @deprecated Please use the new paginated many mutation (deleteManyMintingCurrenciesConnection)
   */
  deleteManyMintingCurrencies: BatchPayload;
  /** Delete many MintingCurrency documents, return deleted documents */
  deleteManyMintingCurrenciesConnection: MintingCurrencyConnection;
  /**
   * Delete many MintingProject documents
   * @deprecated Please use the new paginated many mutation (deleteManyMintingProjectsConnection)
   */
  deleteManyMintingProjects: BatchPayload;
  /** Delete many MintingProject documents, return deleted documents */
  deleteManyMintingProjectsConnection: MintingProjectConnection;
  /**
   * Delete many MintingStat documents
   * @deprecated Please use the new paginated many mutation (deleteManyMintingStatsConnection)
   */
  deleteManyMintingStats: BatchPayload;
  /** Delete many MintingStat documents, return deleted documents */
  deleteManyMintingStatsConnection: MintingStatConnection;
  /**
   * Delete many MrBurnzToken documents
   * @deprecated Please use the new paginated many mutation (deleteManyMrBurnzTokensConnection)
   */
  deleteManyMrBurnzTokens: BatchPayload;
  /** Delete many MrBurnzToken documents, return deleted documents */
  deleteManyMrBurnzTokensConnection: MrBurnzTokenConnection;
  /**
   * Delete many MultiChainMintingProject documents
   * @deprecated Please use the new paginated many mutation (deleteManyMultiChainMintingProjectsConnection)
   */
  deleteManyMultiChainMintingProjects: BatchPayload;
  /** Delete many MultiChainMintingProject documents, return deleted documents */
  deleteManyMultiChainMintingProjectsConnection: MultiChainMintingProjectConnection;
  /**
   * Delete many NftCollection documents
   * @deprecated Please use the new paginated many mutation (deleteManyNftCollectionsConnection)
   */
  deleteManyNftCollections: BatchPayload;
  /** Delete many NftCollection documents, return deleted documents */
  deleteManyNftCollectionsConnection: NftCollectionConnection;
  /**
   * Delete many Page documents
   * @deprecated Please use the new paginated many mutation (deleteManyPagesConnection)
   */
  deleteManyPages: BatchPayload;
  /** Delete many Page documents, return deleted documents */
  deleteManyPagesConnection: PageConnection;
  /**
   * Delete many TokenPrinterConfiguration documents
   * @deprecated Please use the new paginated many mutation (deleteManyTokenPrinterConfigurationsConnection)
   */
  deleteManyTokenPrinterConfigurations: BatchPayload;
  /** Delete many TokenPrinterConfiguration documents, return deleted documents */
  deleteManyTokenPrinterConfigurationsConnection: TokenPrinterConfigurationConnection;
  /** Delete one menu from _all_ existing stages. Returns deleted document. */
  deleteMenu?: Maybe<Menu>;
  /** Delete one miningContainer from _all_ existing stages. Returns deleted document. */
  deleteMiningContainer?: Maybe<MiningContainer>;
  /** Delete one miningHeaderNotice from _all_ existing stages. Returns deleted document. */
  deleteMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Delete one mintingCurrency from _all_ existing stages. Returns deleted document. */
  deleteMintingCurrency?: Maybe<MintingCurrency>;
  /** Delete one mintingProject from _all_ existing stages. Returns deleted document. */
  deleteMintingProject?: Maybe<MintingProject>;
  /** Delete one mintingStat from _all_ existing stages. Returns deleted document. */
  deleteMintingStat?: Maybe<MintingStat>;
  /** Delete one mrBurnzToken from _all_ existing stages. Returns deleted document. */
  deleteMrBurnzToken?: Maybe<MrBurnzToken>;
  /** Delete one multiChainMintingProject from _all_ existing stages. Returns deleted document. */
  deleteMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Delete one nftCollection from _all_ existing stages. Returns deleted document. */
  deleteNftCollection?: Maybe<NftCollection>;
  /** Delete one page from _all_ existing stages. Returns deleted document. */
  deletePage?: Maybe<Page>;
  /** Delete and return scheduled operation */
  deleteScheduledOperation?: Maybe<ScheduledOperation>;
  /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
  deleteScheduledRelease?: Maybe<ScheduledRelease>;
  /** Delete one tokenPrinterConfiguration from _all_ existing stages. Returns deleted document. */
  deleteTokenPrinterConfiguration?: Maybe<TokenPrinterConfiguration>;
  /** Publish one asset */
  publishAsset?: Maybe<Asset>;
  /** Publish one chain */
  publishChain?: Maybe<Chain>;
  /** Publish one collectionCreator */
  publishCollectionCreator?: Maybe<CollectionCreator>;
  /** Publish one ctaSection */
  publishCtaSection?: Maybe<CtaSection>;
  /** Publish one fAQSection */
  publishFAQSection?: Maybe<FaqSection>;
  /** Publish one featuredCollectionGroup */
  publishFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Publish one featuredCreatorGroup */
  publishFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Publish one homePageVideoHero */
  publishHomePageVideoHero?: Maybe<HomePageVideoHero>;
  /** Publish one landingPage */
  publishLandingPage?: Maybe<LandingPage>;
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: BatchPayload;
  /** Publish many Asset documents */
  publishManyAssetsConnection: AssetConnection;
  /**
   * Publish many Chain documents
   * @deprecated Please use the new paginated many mutation (publishManyChainsConnection)
   */
  publishManyChains: BatchPayload;
  /** Publish many Chain documents */
  publishManyChainsConnection: ChainConnection;
  /**
   * Publish many CollectionCreator documents
   * @deprecated Please use the new paginated many mutation (publishManyCollectionCreatorsConnection)
   */
  publishManyCollectionCreators: BatchPayload;
  /** Publish many CollectionCreator documents */
  publishManyCollectionCreatorsConnection: CollectionCreatorConnection;
  /**
   * Publish many CtaSection documents
   * @deprecated Please use the new paginated many mutation (publishManyCtaSectionsConnection)
   */
  publishManyCtaSections: BatchPayload;
  /** Publish many CtaSection documents */
  publishManyCtaSectionsConnection: CtaSectionConnection;
  /**
   * Publish many FAQSection documents
   * @deprecated Please use the new paginated many mutation (publishManyFAQSectionsConnection)
   */
  publishManyFAQSections: BatchPayload;
  /** Publish many FAQSection documents */
  publishManyFAQSectionsConnection: FaqSectionConnection;
  /**
   * Publish many FeaturedCollectionGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyFeaturedCollectionGroupsConnection)
   */
  publishManyFeaturedCollectionGroups: BatchPayload;
  /** Publish many FeaturedCollectionGroup documents */
  publishManyFeaturedCollectionGroupsConnection: FeaturedCollectionGroupConnection;
  /**
   * Publish many FeaturedCreatorGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyFeaturedCreatorGroupsConnection)
   */
  publishManyFeaturedCreatorGroups: BatchPayload;
  /** Publish many FeaturedCreatorGroup documents */
  publishManyFeaturedCreatorGroupsConnection: FeaturedCreatorGroupConnection;
  /**
   * Publish many HomePageVideoHero documents
   * @deprecated Please use the new paginated many mutation (publishManyHomePageVideoHeroesConnection)
   */
  publishManyHomePageVideoHeroes: BatchPayload;
  /** Publish many HomePageVideoHero documents */
  publishManyHomePageVideoHeroesConnection: HomePageVideoHeroConnection;
  /**
   * Publish many LandingPage documents
   * @deprecated Please use the new paginated many mutation (publishManyLandingPagesConnection)
   */
  publishManyLandingPages: BatchPayload;
  /** Publish many LandingPage documents */
  publishManyLandingPagesConnection: LandingPageConnection;
  /**
   * Publish many Menu documents
   * @deprecated Please use the new paginated many mutation (publishManyMenusConnection)
   */
  publishManyMenus: BatchPayload;
  /** Publish many Menu documents */
  publishManyMenusConnection: MenuConnection;
  /**
   * Publish many MiningContainer documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningContainersConnection)
   */
  publishManyMiningContainers: BatchPayload;
  /** Publish many MiningContainer documents */
  publishManyMiningContainersConnection: MiningContainerConnection;
  /**
   * Publish many MiningHeaderNotice documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningHeaderNoticesConnection)
   */
  publishManyMiningHeaderNotices: BatchPayload;
  /** Publish many MiningHeaderNotice documents */
  publishManyMiningHeaderNoticesConnection: MiningHeaderNoticeConnection;
  /**
   * Publish many MintingCurrency documents
   * @deprecated Please use the new paginated many mutation (publishManyMintingCurrenciesConnection)
   */
  publishManyMintingCurrencies: BatchPayload;
  /** Publish many MintingCurrency documents */
  publishManyMintingCurrenciesConnection: MintingCurrencyConnection;
  /**
   * Publish many MintingProject documents
   * @deprecated Please use the new paginated many mutation (publishManyMintingProjectsConnection)
   */
  publishManyMintingProjects: BatchPayload;
  /** Publish many MintingProject documents */
  publishManyMintingProjectsConnection: MintingProjectConnection;
  /**
   * Publish many MintingStat documents
   * @deprecated Please use the new paginated many mutation (publishManyMintingStatsConnection)
   */
  publishManyMintingStats: BatchPayload;
  /** Publish many MintingStat documents */
  publishManyMintingStatsConnection: MintingStatConnection;
  /**
   * Publish many MrBurnzToken documents
   * @deprecated Please use the new paginated many mutation (publishManyMrBurnzTokensConnection)
   */
  publishManyMrBurnzTokens: BatchPayload;
  /** Publish many MrBurnzToken documents */
  publishManyMrBurnzTokensConnection: MrBurnzTokenConnection;
  /**
   * Publish many MultiChainMintingProject documents
   * @deprecated Please use the new paginated many mutation (publishManyMultiChainMintingProjectsConnection)
   */
  publishManyMultiChainMintingProjects: BatchPayload;
  /** Publish many MultiChainMintingProject documents */
  publishManyMultiChainMintingProjectsConnection: MultiChainMintingProjectConnection;
  /**
   * Publish many NftCollection documents
   * @deprecated Please use the new paginated many mutation (publishManyNftCollectionsConnection)
   */
  publishManyNftCollections: BatchPayload;
  /** Publish many NftCollection documents */
  publishManyNftCollectionsConnection: NftCollectionConnection;
  /**
   * Publish many Page documents
   * @deprecated Please use the new paginated many mutation (publishManyPagesConnection)
   */
  publishManyPages: BatchPayload;
  /** Publish many Page documents */
  publishManyPagesConnection: PageConnection;
  /**
   * Publish many TokenPrinterConfiguration documents
   * @deprecated Please use the new paginated many mutation (publishManyTokenPrinterConfigurationsConnection)
   */
  publishManyTokenPrinterConfigurations: BatchPayload;
  /** Publish many TokenPrinterConfiguration documents */
  publishManyTokenPrinterConfigurationsConnection: TokenPrinterConfigurationConnection;
  /** Publish one menu */
  publishMenu?: Maybe<Menu>;
  /** Publish one miningContainer */
  publishMiningContainer?: Maybe<MiningContainer>;
  /** Publish one miningHeaderNotice */
  publishMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Publish one mintingCurrency */
  publishMintingCurrency?: Maybe<MintingCurrency>;
  /** Publish one mintingProject */
  publishMintingProject?: Maybe<MintingProject>;
  /** Publish one mintingStat */
  publishMintingStat?: Maybe<MintingStat>;
  /** Publish one mrBurnzToken */
  publishMrBurnzToken?: Maybe<MrBurnzToken>;
  /** Publish one multiChainMintingProject */
  publishMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Publish one nftCollection */
  publishNftCollection?: Maybe<NftCollection>;
  /** Publish one page */
  publishPage?: Maybe<Page>;
  /** Publish one tokenPrinterConfiguration */
  publishTokenPrinterConfiguration?: Maybe<TokenPrinterConfiguration>;
  /** Schedule to publish one asset */
  schedulePublishAsset?: Maybe<Asset>;
  /** Schedule to publish one chain */
  schedulePublishChain?: Maybe<Chain>;
  /** Schedule to publish one collectionCreator */
  schedulePublishCollectionCreator?: Maybe<CollectionCreator>;
  /** Schedule to publish one ctaSection */
  schedulePublishCtaSection?: Maybe<CtaSection>;
  /** Schedule to publish one fAQSection */
  schedulePublishFAQSection?: Maybe<FaqSection>;
  /** Schedule to publish one featuredCollectionGroup */
  schedulePublishFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Schedule to publish one featuredCreatorGroup */
  schedulePublishFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Schedule to publish one homePageVideoHero */
  schedulePublishHomePageVideoHero?: Maybe<HomePageVideoHero>;
  /** Schedule to publish one landingPage */
  schedulePublishLandingPage?: Maybe<LandingPage>;
  /** Schedule to publish one menu */
  schedulePublishMenu?: Maybe<Menu>;
  /** Schedule to publish one miningContainer */
  schedulePublishMiningContainer?: Maybe<MiningContainer>;
  /** Schedule to publish one miningHeaderNotice */
  schedulePublishMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Schedule to publish one mintingCurrency */
  schedulePublishMintingCurrency?: Maybe<MintingCurrency>;
  /** Schedule to publish one mintingProject */
  schedulePublishMintingProject?: Maybe<MintingProject>;
  /** Schedule to publish one mintingStat */
  schedulePublishMintingStat?: Maybe<MintingStat>;
  /** Schedule to publish one mrBurnzToken */
  schedulePublishMrBurnzToken?: Maybe<MrBurnzToken>;
  /** Schedule to publish one multiChainMintingProject */
  schedulePublishMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Schedule to publish one nftCollection */
  schedulePublishNftCollection?: Maybe<NftCollection>;
  /** Schedule to publish one page */
  schedulePublishPage?: Maybe<Page>;
  /** Schedule to publish one tokenPrinterConfiguration */
  schedulePublishTokenPrinterConfiguration?: Maybe<TokenPrinterConfiguration>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAsset?: Maybe<Asset>;
  /** Unpublish one chain from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishChain?: Maybe<Chain>;
  /** Unpublish one collectionCreator from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCollectionCreator?: Maybe<CollectionCreator>;
  /** Unpublish one ctaSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCtaSection?: Maybe<CtaSection>;
  /** Unpublish one fAQSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFAQSection?: Maybe<FaqSection>;
  /** Unpublish one featuredCollectionGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Unpublish one featuredCreatorGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Unpublish one homePageVideoHero from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishHomePageVideoHero?: Maybe<HomePageVideoHero>;
  /** Unpublish one landingPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishLandingPage?: Maybe<LandingPage>;
  /** Unpublish one menu from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMenu?: Maybe<Menu>;
  /** Unpublish one miningContainer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningContainer?: Maybe<MiningContainer>;
  /** Unpublish one miningHeaderNotice from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Unpublish one mintingCurrency from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMintingCurrency?: Maybe<MintingCurrency>;
  /** Unpublish one mintingProject from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMintingProject?: Maybe<MintingProject>;
  /** Unpublish one mintingStat from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMintingStat?: Maybe<MintingStat>;
  /** Unpublish one mrBurnzToken from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMrBurnzToken?: Maybe<MrBurnzToken>;
  /** Unpublish one multiChainMintingProject from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Unpublish one nftCollection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishNftCollection?: Maybe<NftCollection>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPage?: Maybe<Page>;
  /** Unpublish one tokenPrinterConfiguration from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTokenPrinterConfiguration?: Maybe<TokenPrinterConfiguration>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset?: Maybe<Asset>;
  /** Unpublish one chain from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishChain?: Maybe<Chain>;
  /** Unpublish one collectionCreator from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCollectionCreator?: Maybe<CollectionCreator>;
  /** Unpublish one ctaSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCtaSection?: Maybe<CtaSection>;
  /** Unpublish one fAQSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFAQSection?: Maybe<FaqSection>;
  /** Unpublish one featuredCollectionGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Unpublish one featuredCreatorGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Unpublish one homePageVideoHero from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishHomePageVideoHero?: Maybe<HomePageVideoHero>;
  /** Unpublish one landingPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishLandingPage?: Maybe<LandingPage>;
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: BatchPayload;
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: AssetConnection;
  /**
   * Unpublish many Chain documents
   * @deprecated Please use the new paginated many mutation (unpublishManyChainsConnection)
   */
  unpublishManyChains: BatchPayload;
  /** Find many Chain documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyChainsConnection: ChainConnection;
  /**
   * Unpublish many CollectionCreator documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCollectionCreatorsConnection)
   */
  unpublishManyCollectionCreators: BatchPayload;
  /** Find many CollectionCreator documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCollectionCreatorsConnection: CollectionCreatorConnection;
  /**
   * Unpublish many CtaSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCtaSectionsConnection)
   */
  unpublishManyCtaSections: BatchPayload;
  /** Find many CtaSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCtaSectionsConnection: CtaSectionConnection;
  /**
   * Unpublish many FAQSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFAQSectionsConnection)
   */
  unpublishManyFAQSections: BatchPayload;
  /** Find many FAQSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFAQSectionsConnection: FaqSectionConnection;
  /**
   * Unpublish many FeaturedCollectionGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFeaturedCollectionGroupsConnection)
   */
  unpublishManyFeaturedCollectionGroups: BatchPayload;
  /** Find many FeaturedCollectionGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFeaturedCollectionGroupsConnection: FeaturedCollectionGroupConnection;
  /**
   * Unpublish many FeaturedCreatorGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFeaturedCreatorGroupsConnection)
   */
  unpublishManyFeaturedCreatorGroups: BatchPayload;
  /** Find many FeaturedCreatorGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFeaturedCreatorGroupsConnection: FeaturedCreatorGroupConnection;
  /**
   * Unpublish many HomePageVideoHero documents
   * @deprecated Please use the new paginated many mutation (unpublishManyHomePageVideoHeroesConnection)
   */
  unpublishManyHomePageVideoHeroes: BatchPayload;
  /** Find many HomePageVideoHero documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyHomePageVideoHeroesConnection: HomePageVideoHeroConnection;
  /**
   * Unpublish many LandingPage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyLandingPagesConnection)
   */
  unpublishManyLandingPages: BatchPayload;
  /** Find many LandingPage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyLandingPagesConnection: LandingPageConnection;
  /**
   * Unpublish many Menu documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMenusConnection)
   */
  unpublishManyMenus: BatchPayload;
  /** Find many Menu documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMenusConnection: MenuConnection;
  /**
   * Unpublish many MiningContainer documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningContainersConnection)
   */
  unpublishManyMiningContainers: BatchPayload;
  /** Find many MiningContainer documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningContainersConnection: MiningContainerConnection;
  /**
   * Unpublish many MiningHeaderNotice documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningHeaderNoticesConnection)
   */
  unpublishManyMiningHeaderNotices: BatchPayload;
  /** Find many MiningHeaderNotice documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningHeaderNoticesConnection: MiningHeaderNoticeConnection;
  /**
   * Unpublish many MintingCurrency documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMintingCurrenciesConnection)
   */
  unpublishManyMintingCurrencies: BatchPayload;
  /** Find many MintingCurrency documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMintingCurrenciesConnection: MintingCurrencyConnection;
  /**
   * Unpublish many MintingProject documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMintingProjectsConnection)
   */
  unpublishManyMintingProjects: BatchPayload;
  /** Find many MintingProject documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMintingProjectsConnection: MintingProjectConnection;
  /**
   * Unpublish many MintingStat documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMintingStatsConnection)
   */
  unpublishManyMintingStats: BatchPayload;
  /** Find many MintingStat documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMintingStatsConnection: MintingStatConnection;
  /**
   * Unpublish many MrBurnzToken documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMrBurnzTokensConnection)
   */
  unpublishManyMrBurnzTokens: BatchPayload;
  /** Find many MrBurnzToken documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMrBurnzTokensConnection: MrBurnzTokenConnection;
  /**
   * Unpublish many MultiChainMintingProject documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMultiChainMintingProjectsConnection)
   */
  unpublishManyMultiChainMintingProjects: BatchPayload;
  /** Find many MultiChainMintingProject documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMultiChainMintingProjectsConnection: MultiChainMintingProjectConnection;
  /**
   * Unpublish many NftCollection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyNftCollectionsConnection)
   */
  unpublishManyNftCollections: BatchPayload;
  /** Find many NftCollection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyNftCollectionsConnection: NftCollectionConnection;
  /**
   * Unpublish many Page documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPagesConnection)
   */
  unpublishManyPages: BatchPayload;
  /** Find many Page documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPagesConnection: PageConnection;
  /**
   * Unpublish many TokenPrinterConfiguration documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTokenPrinterConfigurationsConnection)
   */
  unpublishManyTokenPrinterConfigurations: BatchPayload;
  /** Find many TokenPrinterConfiguration documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTokenPrinterConfigurationsConnection: TokenPrinterConfigurationConnection;
  /** Unpublish one menu from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMenu?: Maybe<Menu>;
  /** Unpublish one miningContainer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningContainer?: Maybe<MiningContainer>;
  /** Unpublish one miningHeaderNotice from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Unpublish one mintingCurrency from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMintingCurrency?: Maybe<MintingCurrency>;
  /** Unpublish one mintingProject from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMintingProject?: Maybe<MintingProject>;
  /** Unpublish one mintingStat from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMintingStat?: Maybe<MintingStat>;
  /** Unpublish one mrBurnzToken from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMrBurnzToken?: Maybe<MrBurnzToken>;
  /** Unpublish one multiChainMintingProject from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Unpublish one nftCollection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishNftCollection?: Maybe<NftCollection>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPage?: Maybe<Page>;
  /** Unpublish one tokenPrinterConfiguration from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTokenPrinterConfiguration?: Maybe<TokenPrinterConfiguration>;
  /** Update one asset */
  updateAsset?: Maybe<Asset>;
  /** Update one chain */
  updateChain?: Maybe<Chain>;
  /** Update one collectionCreator */
  updateCollectionCreator?: Maybe<CollectionCreator>;
  /** Update one ctaSection */
  updateCtaSection?: Maybe<CtaSection>;
  /** Update one fAQSection */
  updateFAQSection?: Maybe<FaqSection>;
  /** Update one featuredCollectionGroup */
  updateFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Update one featuredCreatorGroup */
  updateFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Update one homePageVideoHero */
  updateHomePageVideoHero?: Maybe<HomePageVideoHero>;
  /** Update one landingPage */
  updateLandingPage?: Maybe<LandingPage>;
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: BatchPayload;
  /** Update many Asset documents */
  updateManyAssetsConnection: AssetConnection;
  /**
   * Update many chains
   * @deprecated Please use the new paginated many mutation (updateManyChainsConnection)
   */
  updateManyChains: BatchPayload;
  /** Update many Chain documents */
  updateManyChainsConnection: ChainConnection;
  /**
   * Update many collectionCreators
   * @deprecated Please use the new paginated many mutation (updateManyCollectionCreatorsConnection)
   */
  updateManyCollectionCreators: BatchPayload;
  /** Update many CollectionCreator documents */
  updateManyCollectionCreatorsConnection: CollectionCreatorConnection;
  /**
   * Update many ctaSections
   * @deprecated Please use the new paginated many mutation (updateManyCtaSectionsConnection)
   */
  updateManyCtaSections: BatchPayload;
  /** Update many CtaSection documents */
  updateManyCtaSectionsConnection: CtaSectionConnection;
  /**
   * Update many fAQSections
   * @deprecated Please use the new paginated many mutation (updateManyFAQSectionsConnection)
   */
  updateManyFAQSections: BatchPayload;
  /** Update many FAQSection documents */
  updateManyFAQSectionsConnection: FaqSectionConnection;
  /**
   * Update many featuredCollectionGroups
   * @deprecated Please use the new paginated many mutation (updateManyFeaturedCollectionGroupsConnection)
   */
  updateManyFeaturedCollectionGroups: BatchPayload;
  /** Update many FeaturedCollectionGroup documents */
  updateManyFeaturedCollectionGroupsConnection: FeaturedCollectionGroupConnection;
  /**
   * Update many featuredCreatorGroups
   * @deprecated Please use the new paginated many mutation (updateManyFeaturedCreatorGroupsConnection)
   */
  updateManyFeaturedCreatorGroups: BatchPayload;
  /** Update many FeaturedCreatorGroup documents */
  updateManyFeaturedCreatorGroupsConnection: FeaturedCreatorGroupConnection;
  /**
   * Update many homePageVideoHeroes
   * @deprecated Please use the new paginated many mutation (updateManyHomePageVideoHeroesConnection)
   */
  updateManyHomePageVideoHeroes: BatchPayload;
  /** Update many HomePageVideoHero documents */
  updateManyHomePageVideoHeroesConnection: HomePageVideoHeroConnection;
  /**
   * Update many landingPages
   * @deprecated Please use the new paginated many mutation (updateManyLandingPagesConnection)
   */
  updateManyLandingPages: BatchPayload;
  /** Update many LandingPage documents */
  updateManyLandingPagesConnection: LandingPageConnection;
  /**
   * Update many menus
   * @deprecated Please use the new paginated many mutation (updateManyMenusConnection)
   */
  updateManyMenus: BatchPayload;
  /** Update many Menu documents */
  updateManyMenusConnection: MenuConnection;
  /**
   * Update many miningContainers
   * @deprecated Please use the new paginated many mutation (updateManyMiningContainersConnection)
   */
  updateManyMiningContainers: BatchPayload;
  /** Update many MiningContainer documents */
  updateManyMiningContainersConnection: MiningContainerConnection;
  /**
   * Update many miningHeaderNotices
   * @deprecated Please use the new paginated many mutation (updateManyMiningHeaderNoticesConnection)
   */
  updateManyMiningHeaderNotices: BatchPayload;
  /** Update many MiningHeaderNotice documents */
  updateManyMiningHeaderNoticesConnection: MiningHeaderNoticeConnection;
  /**
   * Update many mintingCurrencies
   * @deprecated Please use the new paginated many mutation (updateManyMintingCurrenciesConnection)
   */
  updateManyMintingCurrencies: BatchPayload;
  /** Update many MintingCurrency documents */
  updateManyMintingCurrenciesConnection: MintingCurrencyConnection;
  /**
   * Update many mintingProjects
   * @deprecated Please use the new paginated many mutation (updateManyMintingProjectsConnection)
   */
  updateManyMintingProjects: BatchPayload;
  /** Update many MintingProject documents */
  updateManyMintingProjectsConnection: MintingProjectConnection;
  /**
   * Update many mintingStats
   * @deprecated Please use the new paginated many mutation (updateManyMintingStatsConnection)
   */
  updateManyMintingStats: BatchPayload;
  /** Update many MintingStat documents */
  updateManyMintingStatsConnection: MintingStatConnection;
  /**
   * Update many mrBurnzTokens
   * @deprecated Please use the new paginated many mutation (updateManyMrBurnzTokensConnection)
   */
  updateManyMrBurnzTokens: BatchPayload;
  /** Update many MrBurnzToken documents */
  updateManyMrBurnzTokensConnection: MrBurnzTokenConnection;
  /**
   * Update many multiChainMintingProjects
   * @deprecated Please use the new paginated many mutation (updateManyMultiChainMintingProjectsConnection)
   */
  updateManyMultiChainMintingProjects: BatchPayload;
  /** Update many MultiChainMintingProject documents */
  updateManyMultiChainMintingProjectsConnection: MultiChainMintingProjectConnection;
  /**
   * Update many nftCollections
   * @deprecated Please use the new paginated many mutation (updateManyNftCollectionsConnection)
   */
  updateManyNftCollections: BatchPayload;
  /** Update many NftCollection documents */
  updateManyNftCollectionsConnection: NftCollectionConnection;
  /**
   * Update many pages
   * @deprecated Please use the new paginated many mutation (updateManyPagesConnection)
   */
  updateManyPages: BatchPayload;
  /** Update many Page documents */
  updateManyPagesConnection: PageConnection;
  /**
   * Update many tokenPrinterConfigurations
   * @deprecated Please use the new paginated many mutation (updateManyTokenPrinterConfigurationsConnection)
   */
  updateManyTokenPrinterConfigurations: BatchPayload;
  /** Update many TokenPrinterConfiguration documents */
  updateManyTokenPrinterConfigurationsConnection: TokenPrinterConfigurationConnection;
  /** Update one menu */
  updateMenu?: Maybe<Menu>;
  /** Update one miningContainer */
  updateMiningContainer?: Maybe<MiningContainer>;
  /** Update one miningHeaderNotice */
  updateMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Update one mintingCurrency */
  updateMintingCurrency?: Maybe<MintingCurrency>;
  /** Update one mintingProject */
  updateMintingProject?: Maybe<MintingProject>;
  /** Update one mintingStat */
  updateMintingStat?: Maybe<MintingStat>;
  /** Update one mrBurnzToken */
  updateMrBurnzToken?: Maybe<MrBurnzToken>;
  /** Update one multiChainMintingProject */
  updateMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Update one nftCollection */
  updateNftCollection?: Maybe<NftCollection>;
  /** Update one page */
  updatePage?: Maybe<Page>;
  /** Update one scheduledRelease */
  updateScheduledRelease?: Maybe<ScheduledRelease>;
  /** Update one tokenPrinterConfiguration */
  updateTokenPrinterConfiguration?: Maybe<TokenPrinterConfiguration>;
  /** Upsert one asset */
  upsertAsset?: Maybe<Asset>;
  /** Upsert one chain */
  upsertChain?: Maybe<Chain>;
  /** Upsert one collectionCreator */
  upsertCollectionCreator?: Maybe<CollectionCreator>;
  /** Upsert one ctaSection */
  upsertCtaSection?: Maybe<CtaSection>;
  /** Upsert one fAQSection */
  upsertFAQSection?: Maybe<FaqSection>;
  /** Upsert one featuredCollectionGroup */
  upsertFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Upsert one featuredCreatorGroup */
  upsertFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Upsert one homePageVideoHero */
  upsertHomePageVideoHero?: Maybe<HomePageVideoHero>;
  /** Upsert one landingPage */
  upsertLandingPage?: Maybe<LandingPage>;
  /** Upsert one menu */
  upsertMenu?: Maybe<Menu>;
  /** Upsert one miningContainer */
  upsertMiningContainer?: Maybe<MiningContainer>;
  /** Upsert one miningHeaderNotice */
  upsertMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Upsert one mintingCurrency */
  upsertMintingCurrency?: Maybe<MintingCurrency>;
  /** Upsert one mintingProject */
  upsertMintingProject?: Maybe<MintingProject>;
  /** Upsert one mintingStat */
  upsertMintingStat?: Maybe<MintingStat>;
  /** Upsert one mrBurnzToken */
  upsertMrBurnzToken?: Maybe<MrBurnzToken>;
  /** Upsert one multiChainMintingProject */
  upsertMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Upsert one nftCollection */
  upsertNftCollection?: Maybe<NftCollection>;
  /** Upsert one page */
  upsertPage?: Maybe<Page>;
  /** Upsert one tokenPrinterConfiguration */
  upsertTokenPrinterConfiguration?: Maybe<TokenPrinterConfiguration>;
};

export type MutationCreateAssetArgs = {
  data: AssetCreateInput;
};

export type MutationCreateChainArgs = {
  data: ChainCreateInput;
};

export type MutationCreateCollectionCreatorArgs = {
  data: CollectionCreatorCreateInput;
};

export type MutationCreateCtaSectionArgs = {
  data: CtaSectionCreateInput;
};

export type MutationCreateFaqSectionArgs = {
  data: FaqSectionCreateInput;
};

export type MutationCreateFeaturedCollectionGroupArgs = {
  data: FeaturedCollectionGroupCreateInput;
};

export type MutationCreateFeaturedCreatorGroupArgs = {
  data: FeaturedCreatorGroupCreateInput;
};

export type MutationCreateHomePageVideoHeroArgs = {
  data: HomePageVideoHeroCreateInput;
};

export type MutationCreateLandingPageArgs = {
  data: LandingPageCreateInput;
};

export type MutationCreateMenuArgs = {
  data: MenuCreateInput;
};

export type MutationCreateMiningContainerArgs = {
  data: MiningContainerCreateInput;
};

export type MutationCreateMiningHeaderNoticeArgs = {
  data: MiningHeaderNoticeCreateInput;
};

export type MutationCreateMintingCurrencyArgs = {
  data: MintingCurrencyCreateInput;
};

export type MutationCreateMintingProjectArgs = {
  data: MintingProjectCreateInput;
};

export type MutationCreateMintingStatArgs = {
  data: MintingStatCreateInput;
};

export type MutationCreateMrBurnzTokenArgs = {
  data: MrBurnzTokenCreateInput;
};

export type MutationCreateMultiChainMintingProjectArgs = {
  data: MultiChainMintingProjectCreateInput;
};

export type MutationCreateNftCollectionArgs = {
  data: NftCollectionCreateInput;
};

export type MutationCreatePageArgs = {
  data: PageCreateInput;
};

export type MutationCreateScheduledReleaseArgs = {
  data: ScheduledReleaseCreateInput;
};

export type MutationCreateTokenPrinterConfigurationArgs = {
  data: TokenPrinterConfigurationCreateInput;
};

export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput;
};

export type MutationDeleteChainArgs = {
  where: ChainWhereUniqueInput;
};

export type MutationDeleteCollectionCreatorArgs = {
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationDeleteCtaSectionArgs = {
  where: CtaSectionWhereUniqueInput;
};

export type MutationDeleteFaqSectionArgs = {
  where: FaqSectionWhereUniqueInput;
};

export type MutationDeleteFeaturedCollectionGroupArgs = {
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationDeleteFeaturedCreatorGroupArgs = {
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationDeleteHomePageVideoHeroArgs = {
  where: HomePageVideoHeroWhereUniqueInput;
};

export type MutationDeleteLandingPageArgs = {
  where: LandingPageWhereUniqueInput;
};

export type MutationDeleteManyAssetsArgs = {
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyChainsArgs = {
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationDeleteManyChainsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationDeleteManyCollectionCreatorsArgs = {
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationDeleteManyCollectionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationDeleteManyCtaSectionsArgs = {
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationDeleteManyCtaSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationDeleteManyFaqSectionsArgs = {
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationDeleteManyFaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationDeleteManyFeaturedCollectionGroupsArgs = {
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationDeleteManyFeaturedCollectionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationDeleteManyFeaturedCreatorGroupsArgs = {
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationDeleteManyFeaturedCreatorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationDeleteManyHomePageVideoHeroesArgs = {
  where?: InputMaybe<HomePageVideoHeroManyWhereInput>;
};

export type MutationDeleteManyHomePageVideoHeroesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<HomePageVideoHeroManyWhereInput>;
};

export type MutationDeleteManyLandingPagesArgs = {
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationDeleteManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationDeleteManyMenusArgs = {
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationDeleteManyMenusConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationDeleteManyMiningContainersArgs = {
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationDeleteManyMiningContainersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationDeleteManyMiningHeaderNoticesArgs = {
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationDeleteManyMiningHeaderNoticesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationDeleteManyMintingCurrenciesArgs = {
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationDeleteManyMintingCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationDeleteManyMintingProjectsArgs = {
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationDeleteManyMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationDeleteManyMintingStatsArgs = {
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationDeleteManyMintingStatsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationDeleteManyMrBurnzTokensArgs = {
  where?: InputMaybe<MrBurnzTokenManyWhereInput>;
};

export type MutationDeleteManyMrBurnzTokensConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MrBurnzTokenManyWhereInput>;
};

export type MutationDeleteManyMultiChainMintingProjectsArgs = {
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationDeleteManyMultiChainMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationDeleteManyNftCollectionsArgs = {
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationDeleteManyNftCollectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationDeleteManyPagesArgs = {
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationDeleteManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationDeleteManyTokenPrinterConfigurationsArgs = {
  where?: InputMaybe<TokenPrinterConfigurationManyWhereInput>;
};

export type MutationDeleteManyTokenPrinterConfigurationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TokenPrinterConfigurationManyWhereInput>;
};

export type MutationDeleteMenuArgs = {
  where: MenuWhereUniqueInput;
};

export type MutationDeleteMiningContainerArgs = {
  where: MiningContainerWhereUniqueInput;
};

export type MutationDeleteMiningHeaderNoticeArgs = {
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationDeleteMintingCurrencyArgs = {
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationDeleteMintingProjectArgs = {
  where: MintingProjectWhereUniqueInput;
};

export type MutationDeleteMintingStatArgs = {
  where: MintingStatWhereUniqueInput;
};

export type MutationDeleteMrBurnzTokenArgs = {
  where: MrBurnzTokenWhereUniqueInput;
};

export type MutationDeleteMultiChainMintingProjectArgs = {
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationDeleteNftCollectionArgs = {
  where: NftCollectionWhereUniqueInput;
};

export type MutationDeletePageArgs = {
  where: PageWhereUniqueInput;
};

export type MutationDeleteScheduledOperationArgs = {
  where: ScheduledOperationWhereUniqueInput;
};

export type MutationDeleteScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput;
};

export type MutationDeleteTokenPrinterConfigurationArgs = {
  where: TokenPrinterConfigurationWhereUniqueInput;
};

export type MutationPublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationPublishChainArgs = {
  to?: Array<Stage>;
  where: ChainWhereUniqueInput;
};

export type MutationPublishCollectionCreatorArgs = {
  to?: Array<Stage>;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationPublishCtaSectionArgs = {
  to?: Array<Stage>;
  where: CtaSectionWhereUniqueInput;
};

export type MutationPublishFaqSectionArgs = {
  to?: Array<Stage>;
  where: FaqSectionWhereUniqueInput;
};

export type MutationPublishFeaturedCollectionGroupArgs = {
  to?: Array<Stage>;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationPublishFeaturedCreatorGroupArgs = {
  to?: Array<Stage>;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationPublishHomePageVideoHeroArgs = {
  to?: Array<Stage>;
  where: HomePageVideoHeroWhereUniqueInput;
};

export type MutationPublishLandingPageArgs = {
  to?: Array<Stage>;
  where: LandingPageWhereUniqueInput;
};

export type MutationPublishManyAssetsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationPublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationPublishManyChainsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationPublishManyChainsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationPublishManyCollectionCreatorsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationPublishManyCollectionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationPublishManyCtaSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationPublishManyCtaSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationPublishManyFaqSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationPublishManyFaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationPublishManyFeaturedCollectionGroupsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationPublishManyFeaturedCollectionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationPublishManyFeaturedCreatorGroupsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationPublishManyFeaturedCreatorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationPublishManyHomePageVideoHeroesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<HomePageVideoHeroManyWhereInput>;
};

export type MutationPublishManyHomePageVideoHeroesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<HomePageVideoHeroManyWhereInput>;
};

export type MutationPublishManyLandingPagesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationPublishManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationPublishManyMenusArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationPublishManyMenusConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationPublishManyMiningContainersArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationPublishManyMiningContainersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationPublishManyMiningHeaderNoticesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationPublishManyMiningHeaderNoticesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationPublishManyMintingCurrenciesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationPublishManyMintingCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationPublishManyMintingProjectsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationPublishManyMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationPublishManyMintingStatsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationPublishManyMintingStatsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationPublishManyMrBurnzTokensArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MrBurnzTokenManyWhereInput>;
};

export type MutationPublishManyMrBurnzTokensConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MrBurnzTokenManyWhereInput>;
};

export type MutationPublishManyMultiChainMintingProjectsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationPublishManyMultiChainMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationPublishManyNftCollectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationPublishManyNftCollectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationPublishManyPagesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationPublishManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationPublishManyTokenPrinterConfigurationsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<TokenPrinterConfigurationManyWhereInput>;
};

export type MutationPublishManyTokenPrinterConfigurationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<TokenPrinterConfigurationManyWhereInput>;
};

export type MutationPublishMenuArgs = {
  to?: Array<Stage>;
  where: MenuWhereUniqueInput;
};

export type MutationPublishMiningContainerArgs = {
  to?: Array<Stage>;
  where: MiningContainerWhereUniqueInput;
};

export type MutationPublishMiningHeaderNoticeArgs = {
  to?: Array<Stage>;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationPublishMintingCurrencyArgs = {
  to?: Array<Stage>;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationPublishMintingProjectArgs = {
  to?: Array<Stage>;
  where: MintingProjectWhereUniqueInput;
};

export type MutationPublishMintingStatArgs = {
  to?: Array<Stage>;
  where: MintingStatWhereUniqueInput;
};

export type MutationPublishMrBurnzTokenArgs = {
  to?: Array<Stage>;
  where: MrBurnzTokenWhereUniqueInput;
};

export type MutationPublishMultiChainMintingProjectArgs = {
  to?: Array<Stage>;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationPublishNftCollectionArgs = {
  to?: Array<Stage>;
  where: NftCollectionWhereUniqueInput;
};

export type MutationPublishPageArgs = {
  to?: Array<Stage>;
  where: PageWhereUniqueInput;
};

export type MutationPublishTokenPrinterConfigurationArgs = {
  to?: Array<Stage>;
  where: TokenPrinterConfigurationWhereUniqueInput;
};

export type MutationSchedulePublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationSchedulePublishChainArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: ChainWhereUniqueInput;
};

export type MutationSchedulePublishCollectionCreatorArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationSchedulePublishCtaSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: CtaSectionWhereUniqueInput;
};

export type MutationSchedulePublishFaqSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: FaqSectionWhereUniqueInput;
};

export type MutationSchedulePublishFeaturedCollectionGroupArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationSchedulePublishFeaturedCreatorGroupArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationSchedulePublishHomePageVideoHeroArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: HomePageVideoHeroWhereUniqueInput;
};

export type MutationSchedulePublishLandingPageArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: LandingPageWhereUniqueInput;
};

export type MutationSchedulePublishMenuArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MenuWhereUniqueInput;
};

export type MutationSchedulePublishMiningContainerArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningContainerWhereUniqueInput;
};

export type MutationSchedulePublishMiningHeaderNoticeArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationSchedulePublishMintingCurrencyArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationSchedulePublishMintingProjectArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MintingProjectWhereUniqueInput;
};

export type MutationSchedulePublishMintingStatArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MintingStatWhereUniqueInput;
};

export type MutationSchedulePublishMrBurnzTokenArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MrBurnzTokenWhereUniqueInput;
};

export type MutationSchedulePublishMultiChainMintingProjectArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationSchedulePublishNftCollectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: NftCollectionWhereUniqueInput;
};

export type MutationSchedulePublishPageArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: PageWhereUniqueInput;
};

export type MutationSchedulePublishTokenPrinterConfigurationArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: TokenPrinterConfigurationWhereUniqueInput;
};

export type MutationScheduleUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  where: AssetWhereUniqueInput;
};

export type MutationScheduleUnpublishChainArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: ChainWhereUniqueInput;
};

export type MutationScheduleUnpublishCollectionCreatorArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationScheduleUnpublishCtaSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: CtaSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishFaqSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: FaqSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishFeaturedCollectionGroupArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationScheduleUnpublishFeaturedCreatorGroupArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationScheduleUnpublishHomePageVideoHeroArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: HomePageVideoHeroWhereUniqueInput;
};

export type MutationScheduleUnpublishLandingPageArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: LandingPageWhereUniqueInput;
};

export type MutationScheduleUnpublishMenuArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MenuWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningContainerArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningContainerWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningHeaderNoticeArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationScheduleUnpublishMintingCurrencyArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationScheduleUnpublishMintingProjectArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MintingProjectWhereUniqueInput;
};

export type MutationScheduleUnpublishMintingStatArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MintingStatWhereUniqueInput;
};

export type MutationScheduleUnpublishMrBurnzTokenArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MrBurnzTokenWhereUniqueInput;
};

export type MutationScheduleUnpublishMultiChainMintingProjectArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationScheduleUnpublishNftCollectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: NftCollectionWhereUniqueInput;
};

export type MutationScheduleUnpublishPageArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: PageWhereUniqueInput;
};

export type MutationScheduleUnpublishTokenPrinterConfigurationArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: TokenPrinterConfigurationWhereUniqueInput;
};

export type MutationUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  where: AssetWhereUniqueInput;
};

export type MutationUnpublishChainArgs = {
  from?: Array<Stage>;
  where: ChainWhereUniqueInput;
};

export type MutationUnpublishCollectionCreatorArgs = {
  from?: Array<Stage>;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationUnpublishCtaSectionArgs = {
  from?: Array<Stage>;
  where: CtaSectionWhereUniqueInput;
};

export type MutationUnpublishFaqSectionArgs = {
  from?: Array<Stage>;
  where: FaqSectionWhereUniqueInput;
};

export type MutationUnpublishFeaturedCollectionGroupArgs = {
  from?: Array<Stage>;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationUnpublishFeaturedCreatorGroupArgs = {
  from?: Array<Stage>;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationUnpublishHomePageVideoHeroArgs = {
  from?: Array<Stage>;
  where: HomePageVideoHeroWhereUniqueInput;
};

export type MutationUnpublishLandingPageArgs = {
  from?: Array<Stage>;
  where: LandingPageWhereUniqueInput;
};

export type MutationUnpublishManyAssetsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyChainsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationUnpublishManyChainsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationUnpublishManyCollectionCreatorsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationUnpublishManyCollectionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationUnpublishManyCtaSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationUnpublishManyCtaSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationUnpublishManyFaqSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationUnpublishManyFaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationUnpublishManyFeaturedCollectionGroupsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationUnpublishManyFeaturedCollectionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationUnpublishManyFeaturedCreatorGroupsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationUnpublishManyFeaturedCreatorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationUnpublishManyHomePageVideoHeroesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<HomePageVideoHeroManyWhereInput>;
};

export type MutationUnpublishManyHomePageVideoHeroesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<HomePageVideoHeroManyWhereInput>;
};

export type MutationUnpublishManyLandingPagesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUnpublishManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUnpublishManyMenusArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationUnpublishManyMenusConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationUnpublishManyMiningContainersArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationUnpublishManyMiningContainersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationUnpublishManyMiningHeaderNoticesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationUnpublishManyMiningHeaderNoticesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationUnpublishManyMintingCurrenciesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationUnpublishManyMintingCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationUnpublishManyMintingProjectsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationUnpublishManyMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationUnpublishManyMintingStatsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationUnpublishManyMintingStatsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationUnpublishManyMrBurnzTokensArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MrBurnzTokenManyWhereInput>;
};

export type MutationUnpublishManyMrBurnzTokensConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MrBurnzTokenManyWhereInput>;
};

export type MutationUnpublishManyMultiChainMintingProjectsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationUnpublishManyMultiChainMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationUnpublishManyNftCollectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationUnpublishManyNftCollectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationUnpublishManyPagesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUnpublishManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUnpublishManyTokenPrinterConfigurationsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<TokenPrinterConfigurationManyWhereInput>;
};

export type MutationUnpublishManyTokenPrinterConfigurationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<TokenPrinterConfigurationManyWhereInput>;
};

export type MutationUnpublishMenuArgs = {
  from?: Array<Stage>;
  where: MenuWhereUniqueInput;
};

export type MutationUnpublishMiningContainerArgs = {
  from?: Array<Stage>;
  where: MiningContainerWhereUniqueInput;
};

export type MutationUnpublishMiningHeaderNoticeArgs = {
  from?: Array<Stage>;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationUnpublishMintingCurrencyArgs = {
  from?: Array<Stage>;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationUnpublishMintingProjectArgs = {
  from?: Array<Stage>;
  where: MintingProjectWhereUniqueInput;
};

export type MutationUnpublishMintingStatArgs = {
  from?: Array<Stage>;
  where: MintingStatWhereUniqueInput;
};

export type MutationUnpublishMrBurnzTokenArgs = {
  from?: Array<Stage>;
  where: MrBurnzTokenWhereUniqueInput;
};

export type MutationUnpublishMultiChainMintingProjectArgs = {
  from?: Array<Stage>;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationUnpublishNftCollectionArgs = {
  from?: Array<Stage>;
  where: NftCollectionWhereUniqueInput;
};

export type MutationUnpublishPageArgs = {
  from?: Array<Stage>;
  where: PageWhereUniqueInput;
};

export type MutationUnpublishTokenPrinterConfigurationArgs = {
  from?: Array<Stage>;
  where: TokenPrinterConfigurationWhereUniqueInput;
};

export type MutationUpdateAssetArgs = {
  data: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpdateChainArgs = {
  data: ChainUpdateInput;
  where: ChainWhereUniqueInput;
};

export type MutationUpdateCollectionCreatorArgs = {
  data: CollectionCreatorUpdateInput;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationUpdateCtaSectionArgs = {
  data: CtaSectionUpdateInput;
  where: CtaSectionWhereUniqueInput;
};

export type MutationUpdateFaqSectionArgs = {
  data: FaqSectionUpdateInput;
  where: FaqSectionWhereUniqueInput;
};

export type MutationUpdateFeaturedCollectionGroupArgs = {
  data: FeaturedCollectionGroupUpdateInput;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationUpdateFeaturedCreatorGroupArgs = {
  data: FeaturedCreatorGroupUpdateInput;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationUpdateHomePageVideoHeroArgs = {
  data: HomePageVideoHeroUpdateInput;
  where: HomePageVideoHeroWhereUniqueInput;
};

export type MutationUpdateLandingPageArgs = {
  data: LandingPageUpdateInput;
  where: LandingPageWhereUniqueInput;
};

export type MutationUpdateManyAssetsArgs = {
  data: AssetUpdateManyInput;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: AssetUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyChainsArgs = {
  data: ChainUpdateManyInput;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationUpdateManyChainsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: ChainUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationUpdateManyCollectionCreatorsArgs = {
  data: CollectionCreatorUpdateManyInput;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationUpdateManyCollectionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: CollectionCreatorUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationUpdateManyCtaSectionsArgs = {
  data: CtaSectionUpdateManyInput;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationUpdateManyCtaSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: CtaSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationUpdateManyFaqSectionsArgs = {
  data: FaqSectionUpdateManyInput;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationUpdateManyFaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: FaqSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationUpdateManyFeaturedCollectionGroupsArgs = {
  data: FeaturedCollectionGroupUpdateManyInput;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationUpdateManyFeaturedCollectionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: FeaturedCollectionGroupUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationUpdateManyFeaturedCreatorGroupsArgs = {
  data: FeaturedCreatorGroupUpdateManyInput;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationUpdateManyFeaturedCreatorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: FeaturedCreatorGroupUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationUpdateManyHomePageVideoHeroesArgs = {
  data: HomePageVideoHeroUpdateManyInput;
  where?: InputMaybe<HomePageVideoHeroManyWhereInput>;
};

export type MutationUpdateManyHomePageVideoHeroesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: HomePageVideoHeroUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<HomePageVideoHeroManyWhereInput>;
};

export type MutationUpdateManyLandingPagesArgs = {
  data: LandingPageUpdateManyInput;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUpdateManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: LandingPageUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUpdateManyMenusArgs = {
  data: MenuUpdateManyInput;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationUpdateManyMenusConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MenuUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationUpdateManyMiningContainersArgs = {
  data: MiningContainerUpdateManyInput;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationUpdateManyMiningContainersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningContainerUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationUpdateManyMiningHeaderNoticesArgs = {
  data: MiningHeaderNoticeUpdateManyInput;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationUpdateManyMiningHeaderNoticesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningHeaderNoticeUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationUpdateManyMintingCurrenciesArgs = {
  data: MintingCurrencyUpdateManyInput;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationUpdateManyMintingCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MintingCurrencyUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationUpdateManyMintingProjectsArgs = {
  data: MintingProjectUpdateManyInput;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationUpdateManyMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MintingProjectUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationUpdateManyMintingStatsArgs = {
  data: MintingStatUpdateManyInput;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationUpdateManyMintingStatsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MintingStatUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationUpdateManyMrBurnzTokensArgs = {
  data: MrBurnzTokenUpdateManyInput;
  where?: InputMaybe<MrBurnzTokenManyWhereInput>;
};

export type MutationUpdateManyMrBurnzTokensConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MrBurnzTokenUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MrBurnzTokenManyWhereInput>;
};

export type MutationUpdateManyMultiChainMintingProjectsArgs = {
  data: MultiChainMintingProjectUpdateManyInput;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationUpdateManyMultiChainMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MultiChainMintingProjectUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationUpdateManyNftCollectionsArgs = {
  data: NftCollectionUpdateManyInput;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationUpdateManyNftCollectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: NftCollectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationUpdateManyPagesArgs = {
  data: PageUpdateManyInput;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUpdateManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: PageUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUpdateManyTokenPrinterConfigurationsArgs = {
  data: TokenPrinterConfigurationUpdateManyInput;
  where?: InputMaybe<TokenPrinterConfigurationManyWhereInput>;
};

export type MutationUpdateManyTokenPrinterConfigurationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: TokenPrinterConfigurationUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TokenPrinterConfigurationManyWhereInput>;
};

export type MutationUpdateMenuArgs = {
  data: MenuUpdateInput;
  where: MenuWhereUniqueInput;
};

export type MutationUpdateMiningContainerArgs = {
  data: MiningContainerUpdateInput;
  where: MiningContainerWhereUniqueInput;
};

export type MutationUpdateMiningHeaderNoticeArgs = {
  data: MiningHeaderNoticeUpdateInput;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationUpdateMintingCurrencyArgs = {
  data: MintingCurrencyUpdateInput;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationUpdateMintingProjectArgs = {
  data: MintingProjectUpdateInput;
  where: MintingProjectWhereUniqueInput;
};

export type MutationUpdateMintingStatArgs = {
  data: MintingStatUpdateInput;
  where: MintingStatWhereUniqueInput;
};

export type MutationUpdateMrBurnzTokenArgs = {
  data: MrBurnzTokenUpdateInput;
  where: MrBurnzTokenWhereUniqueInput;
};

export type MutationUpdateMultiChainMintingProjectArgs = {
  data: MultiChainMintingProjectUpdateInput;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationUpdateNftCollectionArgs = {
  data: NftCollectionUpdateInput;
  where: NftCollectionWhereUniqueInput;
};

export type MutationUpdatePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};

export type MutationUpdateScheduledReleaseArgs = {
  data: ScheduledReleaseUpdateInput;
  where: ScheduledReleaseWhereUniqueInput;
};

export type MutationUpdateTokenPrinterConfigurationArgs = {
  data: TokenPrinterConfigurationUpdateInput;
  where: TokenPrinterConfigurationWhereUniqueInput;
};

export type MutationUpsertAssetArgs = {
  upsert: AssetUpsertInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpsertChainArgs = {
  upsert: ChainUpsertInput;
  where: ChainWhereUniqueInput;
};

export type MutationUpsertCollectionCreatorArgs = {
  upsert: CollectionCreatorUpsertInput;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationUpsertCtaSectionArgs = {
  upsert: CtaSectionUpsertInput;
  where: CtaSectionWhereUniqueInput;
};

export type MutationUpsertFaqSectionArgs = {
  upsert: FaqSectionUpsertInput;
  where: FaqSectionWhereUniqueInput;
};

export type MutationUpsertFeaturedCollectionGroupArgs = {
  upsert: FeaturedCollectionGroupUpsertInput;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationUpsertFeaturedCreatorGroupArgs = {
  upsert: FeaturedCreatorGroupUpsertInput;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationUpsertHomePageVideoHeroArgs = {
  upsert: HomePageVideoHeroUpsertInput;
  where: HomePageVideoHeroWhereUniqueInput;
};

export type MutationUpsertLandingPageArgs = {
  upsert: LandingPageUpsertInput;
  where: LandingPageWhereUniqueInput;
};

export type MutationUpsertMenuArgs = {
  upsert: MenuUpsertInput;
  where: MenuWhereUniqueInput;
};

export type MutationUpsertMiningContainerArgs = {
  upsert: MiningContainerUpsertInput;
  where: MiningContainerWhereUniqueInput;
};

export type MutationUpsertMiningHeaderNoticeArgs = {
  upsert: MiningHeaderNoticeUpsertInput;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationUpsertMintingCurrencyArgs = {
  upsert: MintingCurrencyUpsertInput;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationUpsertMintingProjectArgs = {
  upsert: MintingProjectUpsertInput;
  where: MintingProjectWhereUniqueInput;
};

export type MutationUpsertMintingStatArgs = {
  upsert: MintingStatUpsertInput;
  where: MintingStatWhereUniqueInput;
};

export type MutationUpsertMrBurnzTokenArgs = {
  upsert: MrBurnzTokenUpsertInput;
  where: MrBurnzTokenWhereUniqueInput;
};

export type MutationUpsertMultiChainMintingProjectArgs = {
  upsert: MultiChainMintingProjectUpsertInput;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationUpsertNftCollectionArgs = {
  upsert: NftCollectionUpsertInput;
  where: NftCollectionWhereUniqueInput;
};

export type MutationUpsertPageArgs = {
  upsert: PageUpsertInput;
  where: PageWhereUniqueInput;
};

export type MutationUpsertTokenPrinterConfigurationArgs = {
  upsert: TokenPrinterConfigurationUpsertInput;
  where: TokenPrinterConfigurationWhereUniqueInput;
};

export type NavigationItem = Entity & {
  __typename?: "NavigationItem";
  /**
   * This is required in order to handle rewrites in the system to connect the different apps together seamlessly for the user.
   *
   * For Rewards, Minting, Landing Page, select MarketplaceLegacy.
   *
   * For anything collection or marketplace related, select MarketplaceNext
   *
   * For external links, select Global
   */
  destinationAppId: AppId;
  externalLink?: Maybe<Scalars["String"]["output"]>;
  iconComponent?: Maybe<HeroMenuIcons>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  internalSlug?: Maybe<Scalars["String"]["output"]>;
  navigationItemText?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
};

export type NavigationItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: NavigationItemWhereUniqueInput;
};

/** A connection to a list of items. */
export type NavigationItemConnection = {
  __typename?: "NavigationItemConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<NavigationItemEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type NavigationItemCreateInput = {
  destinationAppId: AppId;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  iconComponent?: InputMaybe<HeroMenuIcons>;
  internalSlug?: InputMaybe<Scalars["String"]["input"]>;
  navigationItemText?: InputMaybe<Scalars["String"]["input"]>;
};

export type NavigationItemCreateManyInlineInput = {
  /** Create and connect multiple existing NavigationItem documents */
  create?: InputMaybe<Array<NavigationItemCreateInput>>;
};

export type NavigationItemCreateOneInlineInput = {
  /** Create and connect one NavigationItem document */
  create?: InputMaybe<NavigationItemCreateInput>;
};

export type NavigationItemCreateWithPositionInput = {
  /** Document to create */
  data: NavigationItemCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type NavigationItemEdge = {
  __typename?: "NavigationItemEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: NavigationItem;
};

/** Identifies documents */
export type NavigationItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  destinationAppId?: InputMaybe<AppId>;
  /** All values that are contained in given list. */
  destinationAppId_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  /** Any other value that exists and is not equal to the given value. */
  destinationAppId_not?: InputMaybe<AppId>;
  /** All values that are not contained in given list. */
  destinationAppId_not_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  externalLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  externalLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  externalLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  externalLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  externalLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  externalLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  externalLink_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  externalLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  iconComponent?: InputMaybe<HeroMenuIcons>;
  /** All values that are contained in given list. */
  iconComponent_in?: InputMaybe<Array<InputMaybe<HeroMenuIcons>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconComponent_not?: InputMaybe<HeroMenuIcons>;
  /** All values that are not contained in given list. */
  iconComponent_not_in?: InputMaybe<Array<InputMaybe<HeroMenuIcons>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  internalSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  internalSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  internalSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  internalSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  internalSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  internalSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  internalSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  internalSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  internalSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  navigationItemText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  navigationItemText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  navigationItemText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  navigationItemText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  navigationItemText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  navigationItemText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  navigationItemText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  navigationItemText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  navigationItemText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  navigationItemText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum NavigationItemOrderByInput {
  destinationAppId_ASC = "destinationAppId_ASC",
  destinationAppId_DESC = "destinationAppId_DESC",
  externalLink_ASC = "externalLink_ASC",
  externalLink_DESC = "externalLink_DESC",
  iconComponent_ASC = "iconComponent_ASC",
  iconComponent_DESC = "iconComponent_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  internalSlug_ASC = "internalSlug_ASC",
  internalSlug_DESC = "internalSlug_DESC",
  navigationItemText_ASC = "navigationItemText_ASC",
  navigationItemText_DESC = "navigationItemText_DESC",
}

export type NavigationItemParent = Menu;

export type NavigationItemParentConnectInput = {
  Menu?: InputMaybe<MenuConnectInput>;
};

export type NavigationItemParentCreateInput = {
  Menu?: InputMaybe<MenuCreateInput>;
};

export type NavigationItemParentCreateManyInlineInput = {
  /** Connect multiple existing NavigationItemParent documents */
  connect?: InputMaybe<Array<NavigationItemParentWhereUniqueInput>>;
  /** Create and connect multiple existing NavigationItemParent documents */
  create?: InputMaybe<Array<NavigationItemParentCreateInput>>;
};

export type NavigationItemParentCreateOneInlineInput = {
  /** Connect one existing NavigationItemParent document */
  connect?: InputMaybe<NavigationItemParentWhereUniqueInput>;
  /** Create and connect one NavigationItemParent document */
  create?: InputMaybe<NavigationItemParentCreateInput>;
};

export type NavigationItemParentUpdateInput = {
  Menu?: InputMaybe<MenuUpdateInput>;
};

export type NavigationItemParentUpdateManyInlineInput = {
  /** Connect multiple existing NavigationItemParent documents */
  connect?: InputMaybe<Array<NavigationItemParentConnectInput>>;
  /** Create and connect multiple NavigationItemParent documents */
  create?: InputMaybe<Array<NavigationItemParentCreateInput>>;
  /** Delete multiple NavigationItemParent documents */
  delete?: InputMaybe<Array<NavigationItemParentWhereUniqueInput>>;
  /** Disconnect multiple NavigationItemParent documents */
  disconnect?: InputMaybe<Array<NavigationItemParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing NavigationItemParent documents */
  set?: InputMaybe<Array<NavigationItemParentWhereUniqueInput>>;
  /** Update multiple NavigationItemParent documents */
  update?: InputMaybe<
    Array<NavigationItemParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple NavigationItemParent documents */
  upsert?: InputMaybe<
    Array<NavigationItemParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type NavigationItemParentUpdateManyWithNestedWhereInput = {
  Menu?: InputMaybe<MenuUpdateManyWithNestedWhereInput>;
};

export type NavigationItemParentUpdateOneInlineInput = {
  /** Connect existing NavigationItemParent document */
  connect?: InputMaybe<NavigationItemParentWhereUniqueInput>;
  /** Create and connect one NavigationItemParent document */
  create?: InputMaybe<NavigationItemParentCreateInput>;
  /** Delete currently connected NavigationItemParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected NavigationItemParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single NavigationItemParent document */
  update?: InputMaybe<NavigationItemParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single NavigationItemParent document */
  upsert?: InputMaybe<NavigationItemParentUpsertWithNestedWhereUniqueInput>;
};

export type NavigationItemParentUpdateWithNestedWhereUniqueInput = {
  Menu?: InputMaybe<MenuUpdateWithNestedWhereUniqueInput>;
};

export type NavigationItemParentUpsertWithNestedWhereUniqueInput = {
  Menu?: InputMaybe<MenuUpsertWithNestedWhereUniqueInput>;
};

export type NavigationItemParentWhereInput = {
  Menu?: InputMaybe<MenuWhereInput>;
};

export type NavigationItemParentWhereUniqueInput = {
  Menu?: InputMaybe<MenuWhereUniqueInput>;
};

export type NavigationItemUpdateInput = {
  destinationAppId?: InputMaybe<AppId>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  iconComponent?: InputMaybe<HeroMenuIcons>;
  internalSlug?: InputMaybe<Scalars["String"]["input"]>;
  navigationItemText?: InputMaybe<Scalars["String"]["input"]>;
};

export type NavigationItemUpdateManyInlineInput = {
  /** Create and connect multiple NavigationItem component instances */
  create?: InputMaybe<Array<NavigationItemCreateWithPositionInput>>;
  /** Delete multiple NavigationItem documents */
  delete?: InputMaybe<Array<NavigationItemWhereUniqueInput>>;
  /** Update multiple NavigationItem component instances */
  update?: InputMaybe<
    Array<NavigationItemUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple NavigationItem component instances */
  upsert?: InputMaybe<
    Array<NavigationItemUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type NavigationItemUpdateManyInput = {
  destinationAppId?: InputMaybe<AppId>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  iconComponent?: InputMaybe<HeroMenuIcons>;
  internalSlug?: InputMaybe<Scalars["String"]["input"]>;
  navigationItemText?: InputMaybe<Scalars["String"]["input"]>;
};

export type NavigationItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: NavigationItemUpdateManyInput;
  /** Document search */
  where: NavigationItemWhereInput;
};

export type NavigationItemUpdateOneInlineInput = {
  /** Create and connect one NavigationItem document */
  create?: InputMaybe<NavigationItemCreateInput>;
  /** Delete currently connected NavigationItem document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single NavigationItem document */
  update?: InputMaybe<NavigationItemUpdateWithNestedWhereUniqueInput>;
  /** Upsert single NavigationItem document */
  upsert?: InputMaybe<NavigationItemUpsertWithNestedWhereUniqueInput>;
};

export type NavigationItemUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<NavigationItemUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: NavigationItemWhereUniqueInput;
};

export type NavigationItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: NavigationItemUpdateInput;
  /** Unique document search */
  where: NavigationItemWhereUniqueInput;
};

export type NavigationItemUpsertInput = {
  /** Create document if it didn't exist */
  create: NavigationItemCreateInput;
  /** Update document if it exists */
  update: NavigationItemUpdateInput;
};

export type NavigationItemUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<NavigationItemUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: NavigationItemWhereUniqueInput;
};

export type NavigationItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: NavigationItemUpsertInput;
  /** Unique document search */
  where: NavigationItemWhereUniqueInput;
};

/** Identifies documents */
export type NavigationItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  destinationAppId?: InputMaybe<AppId>;
  /** All values that are contained in given list. */
  destinationAppId_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  /** Any other value that exists and is not equal to the given value. */
  destinationAppId_not?: InputMaybe<AppId>;
  /** All values that are not contained in given list. */
  destinationAppId_not_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  externalLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  externalLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  externalLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  externalLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  externalLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  externalLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  externalLink_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  externalLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  iconComponent?: InputMaybe<HeroMenuIcons>;
  /** All values that are contained in given list. */
  iconComponent_in?: InputMaybe<Array<InputMaybe<HeroMenuIcons>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconComponent_not?: InputMaybe<HeroMenuIcons>;
  /** All values that are not contained in given list. */
  iconComponent_not_in?: InputMaybe<Array<InputMaybe<HeroMenuIcons>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  internalSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  internalSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  internalSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  internalSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  internalSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  internalSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  internalSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  internalSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  internalSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  navigationItemText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  navigationItemText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  navigationItemText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  navigationItemText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  navigationItemText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  navigationItemText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  navigationItemText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  navigationItemText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  navigationItemText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  navigationItemText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References NavigationItem record uniquely */
export type NavigationItemWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type NftCollection = Entity &
  Node & {
    __typename?: "NftCollection";
    bundleName?: Maybe<Scalars["String"]["output"]>;
    bundledCollections: Array<NftCollection>;
    chain?: Maybe<Chain>;
    collectionBannerImage?: Maybe<Asset>;
    collectionCategories: Array<CollectionCategories>;
    collectionContractAddress: Scalars["String"]["output"];
    collectionCreator?: Maybe<CollectionCreator>;
    collectionDescription?: Maybe<Scalars["String"]["output"]>;
    collectionDiscordServer?: Maybe<Scalars["String"]["output"]>;
    collectionFeaturedImage: Array<Asset>;
    collectionHomePage?: Maybe<Scalars["String"]["output"]>;
    collectionInstagramProfile?: Maybe<Scalars["String"]["output"]>;
    collectionListingDate?: Maybe<Scalars["Date"]["output"]>;
    collectionMediumHome?: Maybe<Scalars["String"]["output"]>;
    /**
     * This field is for the amount in BPS - example, for 10% royalty, use:
     *
     * 1000
     *
     * for 5% royalty use:
     * 500
     */
    collectionRoyaltyFee?: Maybe<Scalars["Float"]["output"]>;
    collectionRoyaltyRecipientAddress?: Maybe<Scalars["String"]["output"]>;
    collectionThumbnail?: Maybe<Asset>;
    collectionTitle?: Maybe<Scalars["String"]["output"]>;
    collectionTotalSupply?: Maybe<Scalars["Int"]["output"]>;
    collectionTwitterProfile?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    currency?: Maybe<Currencies>;
    /** Get the document in other stages */
    documentInStages: Array<NftCollection>;
    /** List of NftCollection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    mintingProject?: Maybe<MintingProject>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    tradable: Scalars["Boolean"]["output"];
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type NftCollectionBundledCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type NftCollectionChainArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionCollectionBannerImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionCollectionCreatorArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionCollectionFeaturedImageArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type NftCollectionCollectionThumbnailArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type NftCollectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type NftCollectionMintingProjectArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type NftCollectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: NftCollectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type NftCollectionConnection = {
  __typename?: "NftCollectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<NftCollectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type NftCollectionCreateInput = {
  bundleName?: InputMaybe<Scalars["String"]["input"]>;
  bundledCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  chain?: InputMaybe<ChainCreateOneInlineInput>;
  clp8xmmiv2ckc01um6dgqfr9c?: InputMaybe<NftCollectionCreateManyInlineInput>;
  clp90v1ga2ol701t95b1qdth4?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  clrntvrdl0h1d01kagy8uaje1?: InputMaybe<FeaturedCollectionGroupCreateManyInlineInput>;
  collectionBannerImage?: InputMaybe<AssetCreateOneInlineInput>;
  collectionCategories?: InputMaybe<Array<CollectionCategories>>;
  collectionContractAddress: Scalars["String"]["input"];
  collectionCreator?: InputMaybe<CollectionCreatorCreateOneInlineInput>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  collectionDiscordServer?: InputMaybe<Scalars["String"]["input"]>;
  collectionFeaturedImage?: InputMaybe<AssetCreateManyInlineInput>;
  collectionHomePage?: InputMaybe<Scalars["String"]["input"]>;
  collectionInstagramProfile?: InputMaybe<Scalars["String"]["input"]>;
  collectionListingDate?: InputMaybe<Scalars["Date"]["input"]>;
  collectionMediumHome?: InputMaybe<Scalars["String"]["input"]>;
  collectionRoyaltyFee?: InputMaybe<Scalars["Float"]["input"]>;
  collectionRoyaltyRecipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  collectionThumbnail?: InputMaybe<AssetCreateOneInlineInput>;
  collectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  collectionTotalSupply?: InputMaybe<Scalars["Int"]["input"]>;
  collectionTwitterProfile?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  currency?: InputMaybe<Currencies>;
  mintingProject?: InputMaybe<MintingProjectCreateOneInlineInput>;
  tradable: Scalars["Boolean"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type NftCollectionCreateManyInlineInput = {
  /** Connect multiple existing NftCollection documents */
  connect?: InputMaybe<Array<NftCollectionWhereUniqueInput>>;
  /** Create and connect multiple existing NftCollection documents */
  create?: InputMaybe<Array<NftCollectionCreateInput>>;
};

export type NftCollectionCreateOneInlineInput = {
  /** Connect one existing NftCollection document */
  connect?: InputMaybe<NftCollectionWhereUniqueInput>;
  /** Create and connect one NftCollection document */
  create?: InputMaybe<NftCollectionCreateInput>;
};

/** An edge in a connection. */
export type NftCollectionEdge = {
  __typename?: "NftCollectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: NftCollection;
};

/** Identifies documents */
export type NftCollectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  bundleName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  bundleName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  bundleName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  bundleName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  bundleName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  bundleName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  bundleName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  bundleName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  bundleName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  bundleName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  bundledCollections_every?: InputMaybe<NftCollectionWhereInput>;
  bundledCollections_none?: InputMaybe<NftCollectionWhereInput>;
  bundledCollections_some?: InputMaybe<NftCollectionWhereInput>;
  chain?: InputMaybe<ChainWhereInput>;
  collectionBannerImage?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  collectionCategories?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains *all* items provided to the filter */
  collectionCategories_contains_all?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  collectionCategories_contains_none?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains at least one item provided to the filter */
  collectionCategories_contains_some?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  collectionCategories_not?: InputMaybe<Array<CollectionCategories>>;
  collectionContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionContractAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionContractAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionContractAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionCreator?: InputMaybe<CollectionCreatorWhereInput>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDescription_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionDiscordServer?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDiscordServer_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDiscordServer_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDiscordServer_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDiscordServer_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDiscordServer_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDiscordServer_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionDiscordServer_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDiscordServer_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDiscordServer_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionFeaturedImage_every?: InputMaybe<AssetWhereInput>;
  collectionFeaturedImage_none?: InputMaybe<AssetWhereInput>;
  collectionFeaturedImage_some?: InputMaybe<AssetWhereInput>;
  collectionHomePage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionHomePage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionHomePage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionHomePage_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionHomePage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionHomePage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionHomePage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionHomePage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionHomePage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionHomePage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionInstagramProfile?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionInstagramProfile_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionInstagramProfile_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionInstagramProfile_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionInstagramProfile_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionInstagramProfile_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionInstagramProfile_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionInstagramProfile_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionInstagramProfile_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionInstagramProfile_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionListingDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than the given value. */
  collectionListingDate_gt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionListingDate_gte?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are contained in given list. */
  collectionListingDate_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionListingDate_lt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values less than or equal the given value. */
  collectionListingDate_lte?: InputMaybe<Scalars["Date"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionListingDate_not?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are not contained in given list. */
  collectionListingDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  collectionMediumHome?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionMediumHome_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionMediumHome_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionMediumHome_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionMediumHome_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionMediumHome_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionMediumHome_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionMediumHome_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionMediumHome_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionMediumHome_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionRoyaltyFee?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  collectionRoyaltyFee_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionRoyaltyFee_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  collectionRoyaltyFee_in?: InputMaybe<
    Array<InputMaybe<Scalars["Float"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionRoyaltyFee_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  collectionRoyaltyFee_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionRoyaltyFee_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  collectionRoyaltyFee_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Float"]["input"]>>
  >;
  collectionRoyaltyRecipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionRoyaltyRecipientAddress_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  collectionRoyaltyRecipientAddress_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  collectionRoyaltyRecipientAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionRoyaltyRecipientAddress_not?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not containing the given string. */
  collectionRoyaltyRecipientAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionRoyaltyRecipientAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionRoyaltyRecipientAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionRoyaltyRecipientAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionRoyaltyRecipientAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionThumbnail?: InputMaybe<AssetWhereInput>;
  collectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionTotalSupply?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  collectionTotalSupply_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionTotalSupply_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  collectionTotalSupply_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionTotalSupply_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  collectionTotalSupply_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionTotalSupply_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  collectionTotalSupply_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  collectionTwitterProfile?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionTwitterProfile_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionTwitterProfile_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionTwitterProfile_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionTwitterProfile_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionTwitterProfile_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionTwitterProfile_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionTwitterProfile_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionTwitterProfile_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionTwitterProfile_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  currency?: InputMaybe<Currencies>;
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Currencies>;
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  documentInStages_every?: InputMaybe<NftCollectionWhereStageInput>;
  documentInStages_none?: InputMaybe<NftCollectionWhereStageInput>;
  documentInStages_some?: InputMaybe<NftCollectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mintingProject?: InputMaybe<MintingProjectWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tradable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  tradable_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum NftCollectionOrderByInput {
  bundleName_ASC = "bundleName_ASC",
  bundleName_DESC = "bundleName_DESC",
  collectionCategories_ASC = "collectionCategories_ASC",
  collectionCategories_DESC = "collectionCategories_DESC",
  collectionContractAddress_ASC = "collectionContractAddress_ASC",
  collectionContractAddress_DESC = "collectionContractAddress_DESC",
  collectionDescription_ASC = "collectionDescription_ASC",
  collectionDescription_DESC = "collectionDescription_DESC",
  collectionDiscordServer_ASC = "collectionDiscordServer_ASC",
  collectionDiscordServer_DESC = "collectionDiscordServer_DESC",
  collectionHomePage_ASC = "collectionHomePage_ASC",
  collectionHomePage_DESC = "collectionHomePage_DESC",
  collectionInstagramProfile_ASC = "collectionInstagramProfile_ASC",
  collectionInstagramProfile_DESC = "collectionInstagramProfile_DESC",
  collectionListingDate_ASC = "collectionListingDate_ASC",
  collectionListingDate_DESC = "collectionListingDate_DESC",
  collectionMediumHome_ASC = "collectionMediumHome_ASC",
  collectionMediumHome_DESC = "collectionMediumHome_DESC",
  collectionRoyaltyFee_ASC = "collectionRoyaltyFee_ASC",
  collectionRoyaltyFee_DESC = "collectionRoyaltyFee_DESC",
  collectionRoyaltyRecipientAddress_ASC = "collectionRoyaltyRecipientAddress_ASC",
  collectionRoyaltyRecipientAddress_DESC = "collectionRoyaltyRecipientAddress_DESC",
  collectionTitle_ASC = "collectionTitle_ASC",
  collectionTitle_DESC = "collectionTitle_DESC",
  collectionTotalSupply_ASC = "collectionTotalSupply_ASC",
  collectionTotalSupply_DESC = "collectionTotalSupply_DESC",
  collectionTwitterProfile_ASC = "collectionTwitterProfile_ASC",
  collectionTwitterProfile_DESC = "collectionTwitterProfile_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  currency_ASC = "currency_ASC",
  currency_DESC = "currency_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  tradable_ASC = "tradable_ASC",
  tradable_DESC = "tradable_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type NftCollectionUpdateInput = {
  bundleName?: InputMaybe<Scalars["String"]["input"]>;
  bundledCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  chain?: InputMaybe<ChainUpdateOneInlineInput>;
  clp8xmmiv2ckc01um6dgqfr9c?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  clp90v1ga2ol701t95b1qdth4?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  clrntvrdl0h1d01kagy8uaje1?: InputMaybe<FeaturedCollectionGroupUpdateManyInlineInput>;
  collectionBannerImage?: InputMaybe<AssetUpdateOneInlineInput>;
  collectionCategories?: InputMaybe<Array<CollectionCategories>>;
  collectionContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreator?: InputMaybe<CollectionCreatorUpdateOneInlineInput>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  collectionDiscordServer?: InputMaybe<Scalars["String"]["input"]>;
  collectionFeaturedImage?: InputMaybe<AssetUpdateManyInlineInput>;
  collectionHomePage?: InputMaybe<Scalars["String"]["input"]>;
  collectionInstagramProfile?: InputMaybe<Scalars["String"]["input"]>;
  collectionListingDate?: InputMaybe<Scalars["Date"]["input"]>;
  collectionMediumHome?: InputMaybe<Scalars["String"]["input"]>;
  collectionRoyaltyFee?: InputMaybe<Scalars["Float"]["input"]>;
  collectionRoyaltyRecipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  collectionThumbnail?: InputMaybe<AssetUpdateOneInlineInput>;
  collectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  collectionTotalSupply?: InputMaybe<Scalars["Int"]["input"]>;
  collectionTwitterProfile?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Currencies>;
  mintingProject?: InputMaybe<MintingProjectUpdateOneInlineInput>;
  tradable?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type NftCollectionUpdateManyInlineInput = {
  /** Connect multiple existing NftCollection documents */
  connect?: InputMaybe<Array<NftCollectionConnectInput>>;
  /** Create and connect multiple NftCollection documents */
  create?: InputMaybe<Array<NftCollectionCreateInput>>;
  /** Delete multiple NftCollection documents */
  delete?: InputMaybe<Array<NftCollectionWhereUniqueInput>>;
  /** Disconnect multiple NftCollection documents */
  disconnect?: InputMaybe<Array<NftCollectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing NftCollection documents */
  set?: InputMaybe<Array<NftCollectionWhereUniqueInput>>;
  /** Update multiple NftCollection documents */
  update?: InputMaybe<Array<NftCollectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple NftCollection documents */
  upsert?: InputMaybe<Array<NftCollectionUpsertWithNestedWhereUniqueInput>>;
};

export type NftCollectionUpdateManyInput = {
  bundleName?: InputMaybe<Scalars["String"]["input"]>;
  collectionCategories?: InputMaybe<Array<CollectionCategories>>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  collectionDiscordServer?: InputMaybe<Scalars["String"]["input"]>;
  collectionHomePage?: InputMaybe<Scalars["String"]["input"]>;
  collectionInstagramProfile?: InputMaybe<Scalars["String"]["input"]>;
  collectionListingDate?: InputMaybe<Scalars["Date"]["input"]>;
  collectionMediumHome?: InputMaybe<Scalars["String"]["input"]>;
  collectionRoyaltyFee?: InputMaybe<Scalars["Float"]["input"]>;
  collectionRoyaltyRecipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  collectionTotalSupply?: InputMaybe<Scalars["Int"]["input"]>;
  collectionTwitterProfile?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Currencies>;
  tradable?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type NftCollectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: NftCollectionUpdateManyInput;
  /** Document search */
  where: NftCollectionWhereInput;
};

export type NftCollectionUpdateOneInlineInput = {
  /** Connect existing NftCollection document */
  connect?: InputMaybe<NftCollectionWhereUniqueInput>;
  /** Create and connect one NftCollection document */
  create?: InputMaybe<NftCollectionCreateInput>;
  /** Delete currently connected NftCollection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected NftCollection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single NftCollection document */
  update?: InputMaybe<NftCollectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single NftCollection document */
  upsert?: InputMaybe<NftCollectionUpsertWithNestedWhereUniqueInput>;
};

export type NftCollectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: NftCollectionUpdateInput;
  /** Unique document search */
  where: NftCollectionWhereUniqueInput;
};

export type NftCollectionUpsertInput = {
  /** Create document if it didn't exist */
  create: NftCollectionCreateInput;
  /** Update document if it exists */
  update: NftCollectionUpdateInput;
};

export type NftCollectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: NftCollectionUpsertInput;
  /** Unique document search */
  where: NftCollectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type NftCollectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type NftCollectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  bundleName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  bundleName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  bundleName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  bundleName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  bundleName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  bundleName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  bundleName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  bundleName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  bundleName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  bundleName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  bundledCollections_every?: InputMaybe<NftCollectionWhereInput>;
  bundledCollections_none?: InputMaybe<NftCollectionWhereInput>;
  bundledCollections_some?: InputMaybe<NftCollectionWhereInput>;
  chain?: InputMaybe<ChainWhereInput>;
  collectionBannerImage?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  collectionCategories?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains *all* items provided to the filter */
  collectionCategories_contains_all?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  collectionCategories_contains_none?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains at least one item provided to the filter */
  collectionCategories_contains_some?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  collectionCategories_not?: InputMaybe<Array<CollectionCategories>>;
  collectionContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionContractAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionContractAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionContractAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionCreator?: InputMaybe<CollectionCreatorWhereInput>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDescription_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionDiscordServer?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDiscordServer_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDiscordServer_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDiscordServer_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDiscordServer_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDiscordServer_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDiscordServer_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionDiscordServer_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDiscordServer_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDiscordServer_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionFeaturedImage_every?: InputMaybe<AssetWhereInput>;
  collectionFeaturedImage_none?: InputMaybe<AssetWhereInput>;
  collectionFeaturedImage_some?: InputMaybe<AssetWhereInput>;
  collectionHomePage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionHomePage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionHomePage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionHomePage_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionHomePage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionHomePage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionHomePage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionHomePage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionHomePage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionHomePage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionInstagramProfile?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionInstagramProfile_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionInstagramProfile_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionInstagramProfile_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionInstagramProfile_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionInstagramProfile_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionInstagramProfile_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionInstagramProfile_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionInstagramProfile_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionInstagramProfile_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionListingDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than the given value. */
  collectionListingDate_gt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionListingDate_gte?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are contained in given list. */
  collectionListingDate_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionListingDate_lt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values less than or equal the given value. */
  collectionListingDate_lte?: InputMaybe<Scalars["Date"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionListingDate_not?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are not contained in given list. */
  collectionListingDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  collectionMediumHome?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionMediumHome_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionMediumHome_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionMediumHome_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionMediumHome_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionMediumHome_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionMediumHome_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionMediumHome_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionMediumHome_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionMediumHome_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionRoyaltyFee?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  collectionRoyaltyFee_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionRoyaltyFee_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  collectionRoyaltyFee_in?: InputMaybe<
    Array<InputMaybe<Scalars["Float"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionRoyaltyFee_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  collectionRoyaltyFee_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionRoyaltyFee_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  collectionRoyaltyFee_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Float"]["input"]>>
  >;
  collectionRoyaltyRecipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionRoyaltyRecipientAddress_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  collectionRoyaltyRecipientAddress_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  collectionRoyaltyRecipientAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionRoyaltyRecipientAddress_not?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not containing the given string. */
  collectionRoyaltyRecipientAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionRoyaltyRecipientAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionRoyaltyRecipientAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionRoyaltyRecipientAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionRoyaltyRecipientAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionThumbnail?: InputMaybe<AssetWhereInput>;
  collectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionTotalSupply?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  collectionTotalSupply_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionTotalSupply_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  collectionTotalSupply_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionTotalSupply_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  collectionTotalSupply_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionTotalSupply_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  collectionTotalSupply_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  collectionTwitterProfile?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionTwitterProfile_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionTwitterProfile_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionTwitterProfile_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionTwitterProfile_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionTwitterProfile_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionTwitterProfile_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionTwitterProfile_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionTwitterProfile_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionTwitterProfile_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  currency?: InputMaybe<Currencies>;
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Currencies>;
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  documentInStages_every?: InputMaybe<NftCollectionWhereStageInput>;
  documentInStages_none?: InputMaybe<NftCollectionWhereStageInput>;
  documentInStages_some?: InputMaybe<NftCollectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mintingProject?: InputMaybe<MintingProjectWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tradable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  tradable_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type NftCollectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NftCollectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NftCollectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NftCollectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<NftCollectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References NftCollection record uniquely */
export type NftCollectionWhereUniqueInput = {
  collectionContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  collectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars["ID"]["output"];
  /** The Stage of an object */
  stage: Stage;
};

export type Page = Entity &
  Node & {
    __typename?: "Page";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    ctaSection?: Maybe<CtaSection>;
    /** Get the document in other stages */
    documentInStages: Array<Page>;
    fAQSection?: Maybe<FaqSection>;
    /** FAQ Sections */
    fAQSections: Array<FaqSection>;
    /** List of Page versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    mintingStat?: Maybe<MintingStat>;
    pageSlug?: Maybe<Scalars["String"]["output"]>;
    pageTitle?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type PageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageCtaSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type PageFAqSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageFAqSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqSectionWhereInput>;
};

export type PageHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type PageMintingStatArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type PageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PageWhereUniqueInput;
};

/** A connection to a list of items. */
export type PageConnection = {
  __typename?: "PageConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PageCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  ctaSection?: InputMaybe<CtaSectionCreateOneInlineInput>;
  fAQSection?: InputMaybe<FaqSectionCreateOneInlineInput>;
  fAQSections?: InputMaybe<FaqSectionCreateManyInlineInput>;
  mintingStat?: InputMaybe<MintingStatCreateOneInlineInput>;
  pageSlug?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PageCreateManyInlineInput = {
  /** Connect multiple existing Page documents */
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Create and connect multiple existing Page documents */
  create?: InputMaybe<Array<PageCreateInput>>;
};

export type PageCreateOneInlineInput = {
  /** Connect one existing Page document */
  connect?: InputMaybe<PageWhereUniqueInput>;
  /** Create and connect one Page document */
  create?: InputMaybe<PageCreateInput>;
};

/** An edge in a connection. */
export type PageEdge = {
  __typename?: "PageEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Page;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars["Int"]["output"]>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

/** Identifies documents */
export type PageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<PageWhereStageInput>;
  documentInStages_none?: InputMaybe<PageWhereStageInput>;
  documentInStages_some?: InputMaybe<PageWhereStageInput>;
  fAQSection?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_every?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_none?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_some?: InputMaybe<FaqSectionWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  pageSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PageOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  pageSlug_ASC = "pageSlug_ASC",
  pageSlug_DESC = "pageSlug_DESC",
  pageTitle_ASC = "pageTitle_ASC",
  pageTitle_DESC = "pageTitle_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type PageUpdateInput = {
  ctaSection?: InputMaybe<CtaSectionUpdateOneInlineInput>;
  fAQSection?: InputMaybe<FaqSectionUpdateOneInlineInput>;
  fAQSections?: InputMaybe<FaqSectionUpdateManyInlineInput>;
  mintingStat?: InputMaybe<MintingStatUpdateOneInlineInput>;
  pageSlug?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type PageUpdateManyInlineInput = {
  /** Connect multiple existing Page documents */
  connect?: InputMaybe<Array<PageConnectInput>>;
  /** Create and connect multiple Page documents */
  create?: InputMaybe<Array<PageCreateInput>>;
  /** Delete multiple Page documents */
  delete?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Disconnect multiple Page documents */
  disconnect?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Page documents */
  set?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Update multiple Page documents */
  update?: InputMaybe<Array<PageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Page documents */
  upsert?: InputMaybe<Array<PageUpsertWithNestedWhereUniqueInput>>;
};

export type PageUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars["String"]["input"]>;
};

export type PageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PageUpdateManyInput;
  /** Document search */
  where: PageWhereInput;
};

export type PageUpdateOneInlineInput = {
  /** Connect existing Page document */
  connect?: InputMaybe<PageWhereUniqueInput>;
  /** Create and connect one Page document */
  create?: InputMaybe<PageCreateInput>;
  /** Delete currently connected Page document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected Page document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Page document */
  update?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Page document */
  upsert?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type PageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PageUpdateInput;
  /** Unique document search */
  where: PageWhereUniqueInput;
};

export type PageUpsertInput = {
  /** Create document if it didn't exist */
  create: PageCreateInput;
  /** Update document if it exists */
  update: PageUpdateInput;
};

export type PageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PageUpsertInput;
  /** Unique document search */
  where: PageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type PageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<PageWhereStageInput>;
  documentInStages_none?: InputMaybe<PageWhereStageInput>;
  documentInStages_some?: InputMaybe<PageWhereStageInput>;
  fAQSection?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_every?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_none?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_some?: InputMaybe<FaqSectionWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  pageSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Page record uniquely */
export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  pageSlug?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ProjectSecurityTags {
  Audit = "Audit",
  Doxxed = "Doxxed",
  KYC = "KYC",
  MultiSig = "MultiSig",
}

export type PublishLocaleInput = {
  /** Locales to publish */
  locale: Locale;
  /** Stages to publish selected locales to */
  stages: Array<Stage>;
};

export type Query = {
  __typename?: "Query";
  /** Retrieve a single asset */
  asset?: Maybe<Asset>;
  /** Retrieve document version */
  assetVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple assets */
  assets: Array<Asset>;
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: AssetConnection;
  /** Retrieve a single chain */
  chain?: Maybe<Chain>;
  /** Retrieve document version */
  chainVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple chains */
  chains: Array<Chain>;
  /** Retrieve multiple chains using the Relay connection interface */
  chainsConnection: ChainConnection;
  /** Retrieve a single collectionCreator */
  collectionCreator?: Maybe<CollectionCreator>;
  /** Retrieve document version */
  collectionCreatorVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple collectionCreators */
  collectionCreators: Array<CollectionCreator>;
  /** Retrieve multiple collectionCreators using the Relay connection interface */
  collectionCreatorsConnection: CollectionCreatorConnection;
  /** Retrieve a single ctaSection */
  ctaSection?: Maybe<CtaSection>;
  /** Retrieve document version */
  ctaSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple ctaSections */
  ctaSections: Array<CtaSection>;
  /** Retrieve multiple ctaSections using the Relay connection interface */
  ctaSectionsConnection: CtaSectionConnection;
  /** Fetches an object given its ID */
  entities?: Maybe<Array<Entity>>;
  /** Retrieve a single fAQSection */
  fAQSection?: Maybe<FaqSection>;
  /** Retrieve document version */
  fAQSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple fAQSections */
  fAQSections: Array<FaqSection>;
  /** Retrieve multiple fAQSections using the Relay connection interface */
  fAQSectionsConnection: FaqSectionConnection;
  /** Retrieve a single featuredCollectionGroup */
  featuredCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Retrieve document version */
  featuredCollectionGroupVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple featuredCollectionGroups */
  featuredCollectionGroups: Array<FeaturedCollectionGroup>;
  /** Retrieve multiple featuredCollectionGroups using the Relay connection interface */
  featuredCollectionGroupsConnection: FeaturedCollectionGroupConnection;
  /** Retrieve a single featuredCreatorGroup */
  featuredCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Retrieve document version */
  featuredCreatorGroupVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple featuredCreatorGroups */
  featuredCreatorGroups: Array<FeaturedCreatorGroup>;
  /** Retrieve multiple featuredCreatorGroups using the Relay connection interface */
  featuredCreatorGroupsConnection: FeaturedCreatorGroupConnection;
  /** Retrieve a single homePageVideoHero */
  homePageVideoHero?: Maybe<HomePageVideoHero>;
  /** Retrieve document version */
  homePageVideoHeroVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple homePageVideoHeroes */
  homePageVideoHeroes: Array<HomePageVideoHero>;
  /** Retrieve multiple homePageVideoHeroes using the Relay connection interface */
  homePageVideoHeroesConnection: HomePageVideoHeroConnection;
  /** Retrieve a single landingPage */
  landingPage?: Maybe<LandingPage>;
  /** Retrieve document version */
  landingPageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple landingPages */
  landingPages: Array<LandingPage>;
  /** Retrieve multiple landingPages using the Relay connection interface */
  landingPagesConnection: LandingPageConnection;
  /** Retrieve a single menu */
  menu?: Maybe<Menu>;
  /** Retrieve document version */
  menuVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple menus */
  menus: Array<Menu>;
  /** Retrieve multiple menus using the Relay connection interface */
  menusConnection: MenuConnection;
  /** Retrieve a single miningContainer */
  miningContainer?: Maybe<MiningContainer>;
  /** Retrieve document version */
  miningContainerVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningContainers */
  miningContainers: Array<MiningContainer>;
  /** Retrieve multiple miningContainers using the Relay connection interface */
  miningContainersConnection: MiningContainerConnection;
  /** Retrieve a single miningHeaderNotice */
  miningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Retrieve document version */
  miningHeaderNoticeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningHeaderNotices */
  miningHeaderNotices: Array<MiningHeaderNotice>;
  /** Retrieve multiple miningHeaderNotices using the Relay connection interface */
  miningHeaderNoticesConnection: MiningHeaderNoticeConnection;
  /** Retrieve multiple mintingCurrencies */
  mintingCurrencies: Array<MintingCurrency>;
  /** Retrieve multiple mintingCurrencies using the Relay connection interface */
  mintingCurrenciesConnection: MintingCurrencyConnection;
  /** Retrieve a single mintingCurrency */
  mintingCurrency?: Maybe<MintingCurrency>;
  /** Retrieve document version */
  mintingCurrencyVersion?: Maybe<DocumentVersion>;
  /** Retrieve a single mintingProject */
  mintingProject?: Maybe<MintingProject>;
  /** Retrieve document version */
  mintingProjectVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple mintingProjects */
  mintingProjects: Array<MintingProject>;
  /** Retrieve multiple mintingProjects using the Relay connection interface */
  mintingProjectsConnection: MintingProjectConnection;
  /** Retrieve a single mintingStat */
  mintingStat?: Maybe<MintingStat>;
  /** Retrieve document version */
  mintingStatVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple mintingStats */
  mintingStats: Array<MintingStat>;
  /** Retrieve multiple mintingStats using the Relay connection interface */
  mintingStatsConnection: MintingStatConnection;
  /** Retrieve a single mrBurnzToken */
  mrBurnzToken?: Maybe<MrBurnzToken>;
  /** Retrieve document version */
  mrBurnzTokenVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple mrBurnzTokens */
  mrBurnzTokens: Array<MrBurnzToken>;
  /** Retrieve multiple mrBurnzTokens using the Relay connection interface */
  mrBurnzTokensConnection: MrBurnzTokenConnection;
  /** Retrieve a single multiChainMintingProject */
  multiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Retrieve document version */
  multiChainMintingProjectVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple multiChainMintingProjects */
  multiChainMintingProjects: Array<MultiChainMintingProject>;
  /** Retrieve multiple multiChainMintingProjects using the Relay connection interface */
  multiChainMintingProjectsConnection: MultiChainMintingProjectConnection;
  /** Retrieve a single nftCollection */
  nftCollection?: Maybe<NftCollection>;
  /** Retrieve document version */
  nftCollectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple nftCollections */
  nftCollections: Array<NftCollection>;
  /** Retrieve multiple nftCollections using the Relay connection interface */
  nftCollectionsConnection: NftCollectionConnection;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Retrieve a single page */
  page?: Maybe<Page>;
  /** Retrieve document version */
  pageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple pages */
  pages: Array<Page>;
  /** Retrieve multiple pages using the Relay connection interface */
  pagesConnection: PageConnection;
  /** Retrieve a single scheduledOperation */
  scheduledOperation?: Maybe<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations */
  scheduledOperations: Array<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  scheduledOperationsConnection: ScheduledOperationConnection;
  /** Retrieve a single scheduledRelease */
  scheduledRelease?: Maybe<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases */
  scheduledReleases: Array<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  scheduledReleasesConnection: ScheduledReleaseConnection;
  /** Retrieve a single tokenPrinterConfiguration */
  tokenPrinterConfiguration?: Maybe<TokenPrinterConfiguration>;
  /** Retrieve document version */
  tokenPrinterConfigurationVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple tokenPrinterConfigurations */
  tokenPrinterConfigurations: Array<TokenPrinterConfiguration>;
  /** Retrieve multiple tokenPrinterConfigurations using the Relay connection interface */
  tokenPrinterConfigurationsConnection: TokenPrinterConfigurationConnection;
  /** Retrieve a single user */
  user?: Maybe<User>;
  /** Retrieve multiple users */
  users: Array<User>;
  /** Retrieve multiple users using the Relay connection interface */
  usersConnection: UserConnection;
};

export type QueryAssetArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AssetWhereUniqueInput;
};

export type QueryAssetVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryChainArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ChainWhereUniqueInput;
};

export type QueryChainVersionArgs = {
  where: VersionWhereInput;
};

export type QueryChainsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ChainOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ChainWhereInput>;
};

export type QueryChainsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ChainOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ChainWhereInput>;
};

export type QueryCollectionCreatorArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CollectionCreatorWhereUniqueInput;
};

export type QueryCollectionCreatorVersionArgs = {
  where: VersionWhereInput;
};

export type QueryCollectionCreatorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CollectionCreatorOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<CollectionCreatorWhereInput>;
};

export type QueryCollectionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CollectionCreatorOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<CollectionCreatorWhereInput>;
};

export type QueryCtaSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CtaSectionWhereUniqueInput;
};

export type QueryCtaSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryCtaSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CtaSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<CtaSectionWhereInput>;
};

export type QueryCtaSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CtaSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<CtaSectionWhereInput>;
};

export type QueryEntitiesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  where: Array<EntityWhereInput>;
};

export type QueryFAqSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FaqSectionWhereUniqueInput;
};

export type QueryFAqSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryFAqSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FaqSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FaqSectionWhereInput>;
};

export type QueryFAqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FaqSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FaqSectionWhereInput>;
};

export type QueryFeaturedCollectionGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type QueryFeaturedCollectionGroupVersionArgs = {
  where: VersionWhereInput;
};

export type QueryFeaturedCollectionGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FeaturedCollectionGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FeaturedCollectionGroupWhereInput>;
};

export type QueryFeaturedCollectionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FeaturedCollectionGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FeaturedCollectionGroupWhereInput>;
};

export type QueryFeaturedCreatorGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type QueryFeaturedCreatorGroupVersionArgs = {
  where: VersionWhereInput;
};

export type QueryFeaturedCreatorGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FeaturedCreatorGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FeaturedCreatorGroupWhereInput>;
};

export type QueryFeaturedCreatorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FeaturedCreatorGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FeaturedCreatorGroupWhereInput>;
};

export type QueryHomePageVideoHeroArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: HomePageVideoHeroWhereUniqueInput;
};

export type QueryHomePageVideoHeroVersionArgs = {
  where: VersionWhereInput;
};

export type QueryHomePageVideoHeroesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<HomePageVideoHeroOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<HomePageVideoHeroWhereInput>;
};

export type QueryHomePageVideoHeroesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<HomePageVideoHeroOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<HomePageVideoHeroWhereInput>;
};

export type QueryLandingPageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: LandingPageWhereUniqueInput;
};

export type QueryLandingPageVersionArgs = {
  where: VersionWhereInput;
};

export type QueryLandingPagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<LandingPageWhereInput>;
};

export type QueryLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<LandingPageWhereInput>;
};

export type QueryMenuArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MenuWhereUniqueInput;
};

export type QueryMenuVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMenusArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MenuOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MenuWhereInput>;
};

export type QueryMenusConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MenuOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MenuWhereInput>;
};

export type QueryMiningContainerArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningContainerWhereUniqueInput;
};

export type QueryMiningContainerVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningContainersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningContainerOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningContainerWhereInput>;
};

export type QueryMiningContainersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningContainerOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningContainerWhereInput>;
};

export type QueryMiningHeaderNoticeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type QueryMiningHeaderNoticeVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningHeaderNoticesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningHeaderNoticeOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningHeaderNoticeWhereInput>;
};

export type QueryMiningHeaderNoticesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningHeaderNoticeOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningHeaderNoticeWhereInput>;
};

export type QueryMintingCurrenciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingCurrencyOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingCurrencyWhereInput>;
};

export type QueryMintingCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingCurrencyOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingCurrencyWhereInput>;
};

export type QueryMintingCurrencyArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MintingCurrencyWhereUniqueInput;
};

export type QueryMintingCurrencyVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMintingProjectArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MintingProjectWhereUniqueInput;
};

export type QueryMintingProjectVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMintingProjectsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingProjectWhereInput>;
};

export type QueryMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingProjectWhereInput>;
};

export type QueryMintingStatArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MintingStatWhereUniqueInput;
};

export type QueryMintingStatVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMintingStatsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingStatOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingStatWhereInput>;
};

export type QueryMintingStatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingStatOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingStatWhereInput>;
};

export type QueryMrBurnzTokenArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MrBurnzTokenWhereUniqueInput;
};

export type QueryMrBurnzTokenVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMrBurnzTokensArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MrBurnzTokenOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MrBurnzTokenWhereInput>;
};

export type QueryMrBurnzTokensConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MrBurnzTokenOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MrBurnzTokenWhereInput>;
};

export type QueryMultiChainMintingProjectArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type QueryMultiChainMintingProjectVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMultiChainMintingProjectsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MultiChainMintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type QueryMultiChainMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MultiChainMintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type QueryNftCollectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: NftCollectionWhereUniqueInput;
};

export type QueryNftCollectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type QueryNftCollectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars["ID"]["input"];
  locales?: Array<Locale>;
  stage?: Stage;
};

export type QueryPageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PageWhereUniqueInput;
};

export type QueryPageVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PageWhereInput>;
};

export type QueryPagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PageWhereInput>;
};

export type QueryScheduledOperationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledOperationWhereUniqueInput;
};

export type QueryScheduledOperationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledOperationsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledReleaseArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledReleaseWhereUniqueInput;
};

export type QueryScheduledReleasesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QueryScheduledReleasesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QueryTokenPrinterConfigurationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: TokenPrinterConfigurationWhereUniqueInput;
};

export type QueryTokenPrinterConfigurationVersionArgs = {
  where: VersionWhereInput;
};

export type QueryTokenPrinterConfigurationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<TokenPrinterConfigurationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<TokenPrinterConfigurationWhereInput>;
};

export type QueryTokenPrinterConfigurationsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<TokenPrinterConfigurationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<TokenPrinterConfigurationWhereInput>;
};

export type QueryUserArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
  __typename?: "RGBA";
  a: Scalars["RGBATransparency"]["output"];
  b: Scalars["RGBAHue"]["output"];
  g: Scalars["RGBAHue"]["output"];
  r: Scalars["RGBAHue"]["output"];
};

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  a: Scalars["RGBATransparency"]["input"];
  b: Scalars["RGBAHue"]["input"];
  g: Scalars["RGBAHue"]["input"];
  r: Scalars["RGBAHue"]["input"];
};

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
  __typename?: "RichText";
  /** Returns HTMl representation */
  html: Scalars["String"]["output"];
  /** Returns Markdown representation */
  markdown: Scalars["String"]["output"];
  /** Returns AST representation */
  raw: Scalars["RichTextAST"]["output"];
  /** Returns plain-text contents of RichText */
  text: Scalars["String"]["output"];
};

/** Scheduled Operation system model */
export type ScheduledOperation = Entity &
  Node & {
    __typename?: "ScheduledOperation";
    affectedDocuments: Array<ScheduledOperationAffectedDocument>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Operation description */
    description?: Maybe<Scalars["String"]["output"]>;
    /** Get the document in other stages */
    documentInStages: Array<ScheduledOperation>;
    /** Operation error message */
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    /** Raw operation payload including all details, this field is subject to change */
    rawPayload: Scalars["Json"]["output"];
    /** The release this operation is scheduled for */
    release?: Maybe<ScheduledRelease>;
    /** System stage field */
    stage: Stage;
    /** operation Status */
    status: ScheduledOperationStatus;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Scheduled Operation system model */
export type ScheduledOperationAffectedDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Scheduled Operation system model */
export type ScheduledOperationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

/** Scheduled Operation system model */
export type ScheduledOperationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationReleaseArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledOperationAffectedDocument =
  | Asset
  | Chain
  | CollectionCreator
  | CtaSection
  | FaqSection
  | FeaturedCollectionGroup
  | FeaturedCreatorGroup
  | HomePageVideoHero
  | LandingPage
  | Menu
  | MiningContainer
  | MiningHeaderNotice
  | MintingCurrency
  | MintingProject
  | MintingStat
  | MrBurnzToken
  | MultiChainMintingProject
  | NftCollection
  | Page
  | TokenPrinterConfiguration;

export type ScheduledOperationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledOperationWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledOperationConnection = {
  __typename?: "ScheduledOperationConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledOperationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
};

/** An edge in a connection. */
export type ScheduledOperationEdge = {
  __typename?: "ScheduledOperationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: ScheduledOperation;
};

/** Identifies documents */
export type ScheduledOperationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledOperationOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  description_ASC = "description_ASC",
  description_DESC = "description_DESC",
  errorMessage_ASC = "errorMessage_ASC",
  errorMessage_DESC = "errorMessage_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** System Scheduled Operation Status */
export enum ScheduledOperationStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export type ScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationConnectInput>>;
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type ScheduledOperationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledOperation record uniquely */
export type ScheduledOperationWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Scheduled Release system model */
export type ScheduledRelease = Entity &
  Node & {
    __typename?: "ScheduledRelease";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Release description */
    description?: Maybe<Scalars["String"]["output"]>;
    /** Get the document in other stages */
    documentInStages: Array<ScheduledRelease>;
    /** Release error message */
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** Whether scheduled release should be run */
    isActive: Scalars["Boolean"]["output"];
    /** Whether scheduled release is implicit */
    isImplicit: Scalars["Boolean"]["output"];
    /** Operations to run with this release */
    operations: Array<ScheduledOperation>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    /** Release date and time */
    releaseAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** System stage field */
    stage: Stage;
    /** Release Status */
    status: ScheduledReleaseStatus;
    /** Release Title */
    title?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Scheduled Release system model */
export type ScheduledReleaseCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

/** Scheduled Release system model */
export type ScheduledReleaseOperationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Scheduled Release system model */
export type ScheduledReleasePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledReleaseConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledReleaseWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledReleaseConnection = {
  __typename?: "ScheduledReleaseConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledReleaseEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledReleaseCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduledReleaseCreateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
};

export type ScheduledReleaseCreateOneInlineInput = {
  /** Connect one existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
};

/** An edge in a connection. */
export type ScheduledReleaseEdge = {
  __typename?: "ScheduledReleaseEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: ScheduledRelease;
};

/** Identifies documents */
export type ScheduledReleaseManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  isImplicit?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledReleaseOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  description_ASC = "description_ASC",
  description_DESC = "description_DESC",
  errorMessage_ASC = "errorMessage_ASC",
  errorMessage_DESC = "errorMessage_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isActive_ASC = "isActive_ASC",
  isActive_DESC = "isActive_DESC",
  isImplicit_ASC = "isImplicit_ASC",
  isImplicit_DESC = "isImplicit_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  releaseAt_ASC = "releaseAt_ASC",
  releaseAt_DESC = "releaseAt_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** System Scheduled Release Status */
export enum ScheduledReleaseStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export type ScheduledReleaseUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ScheduledReleaseUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseConnectInput>>;
  /** Create and connect multiple ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
  /** Delete multiple ScheduledRelease documents */
  delete?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Update multiple ScheduledRelease documents */
  update?: InputMaybe<Array<ScheduledReleaseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ScheduledRelease documents */
  upsert?: InputMaybe<Array<ScheduledReleaseUpsertWithNestedWhereUniqueInput>>;
};

export type ScheduledReleaseUpdateManyInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ScheduledReleaseUpdateManyInput;
  /** Document search */
  where: ScheduledReleaseWhereInput;
};

export type ScheduledReleaseUpdateOneInlineInput = {
  /** Connect existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
  /** Delete currently connected ScheduledRelease document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ScheduledRelease document */
  update?: InputMaybe<ScheduledReleaseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ScheduledRelease document */
  upsert?: InputMaybe<ScheduledReleaseUpsertWithNestedWhereUniqueInput>;
};

export type ScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ScheduledReleaseUpdateInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

export type ScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: ScheduledReleaseCreateInput;
  /** Update document if it exists */
  update: ScheduledReleaseUpdateInput;
};

export type ScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ScheduledReleaseUpsertInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

/** Identifies documents */
export type ScheduledReleaseWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  isImplicit?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledRelease record uniquely */
export type ScheduledReleaseWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SecurityInfo = Entity & {
  __typename?: "SecurityInfo";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  securityLineItem?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
};

export type SecurityInfoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SecurityInfoWhereUniqueInput;
};

/** A connection to a list of items. */
export type SecurityInfoConnection = {
  __typename?: "SecurityInfoConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SecurityInfoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SecurityInfoCreateInput = {
  securityLineItem?: InputMaybe<Scalars["String"]["input"]>;
};

export type SecurityInfoCreateManyInlineInput = {
  /** Create and connect multiple existing SecurityInfo documents */
  create?: InputMaybe<Array<SecurityInfoCreateInput>>;
};

export type SecurityInfoCreateOneInlineInput = {
  /** Create and connect one SecurityInfo document */
  create?: InputMaybe<SecurityInfoCreateInput>;
};

export type SecurityInfoCreateWithPositionInput = {
  /** Document to create */
  data: SecurityInfoCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SecurityInfoEdge = {
  __typename?: "SecurityInfoEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: SecurityInfo;
};

/** Identifies documents */
export type SecurityInfoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  securityLineItem?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  securityLineItem_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  securityLineItem_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  securityLineItem_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  securityLineItem_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  securityLineItem_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  securityLineItem_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  securityLineItem_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  securityLineItem_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  securityLineItem_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum SecurityInfoOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  securityLineItem_ASC = "securityLineItem_ASC",
  securityLineItem_DESC = "securityLineItem_DESC",
}

export type SecurityInfoParent = MintingProject | MultiChainMintingProject;

export type SecurityInfoParentConnectInput = {
  MintingProject?: InputMaybe<MintingProjectConnectInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectConnectInput>;
};

export type SecurityInfoParentCreateInput = {
  MintingProject?: InputMaybe<MintingProjectCreateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateInput>;
};

export type SecurityInfoParentCreateManyInlineInput = {
  /** Connect multiple existing SecurityInfoParent documents */
  connect?: InputMaybe<Array<SecurityInfoParentWhereUniqueInput>>;
  /** Create and connect multiple existing SecurityInfoParent documents */
  create?: InputMaybe<Array<SecurityInfoParentCreateInput>>;
};

export type SecurityInfoParentCreateOneInlineInput = {
  /** Connect one existing SecurityInfoParent document */
  connect?: InputMaybe<SecurityInfoParentWhereUniqueInput>;
  /** Create and connect one SecurityInfoParent document */
  create?: InputMaybe<SecurityInfoParentCreateInput>;
};

export type SecurityInfoParentUpdateInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateInput>;
};

export type SecurityInfoParentUpdateManyInlineInput = {
  /** Connect multiple existing SecurityInfoParent documents */
  connect?: InputMaybe<Array<SecurityInfoParentConnectInput>>;
  /** Create and connect multiple SecurityInfoParent documents */
  create?: InputMaybe<Array<SecurityInfoParentCreateInput>>;
  /** Delete multiple SecurityInfoParent documents */
  delete?: InputMaybe<Array<SecurityInfoParentWhereUniqueInput>>;
  /** Disconnect multiple SecurityInfoParent documents */
  disconnect?: InputMaybe<Array<SecurityInfoParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SecurityInfoParent documents */
  set?: InputMaybe<Array<SecurityInfoParentWhereUniqueInput>>;
  /** Update multiple SecurityInfoParent documents */
  update?: InputMaybe<
    Array<SecurityInfoParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple SecurityInfoParent documents */
  upsert?: InputMaybe<
    Array<SecurityInfoParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type SecurityInfoParentUpdateManyWithNestedWhereInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateManyWithNestedWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyWithNestedWhereInput>;
};

export type SecurityInfoParentUpdateOneInlineInput = {
  /** Connect existing SecurityInfoParent document */
  connect?: InputMaybe<SecurityInfoParentWhereUniqueInput>;
  /** Create and connect one SecurityInfoParent document */
  create?: InputMaybe<SecurityInfoParentCreateInput>;
  /** Delete currently connected SecurityInfoParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected SecurityInfoParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single SecurityInfoParent document */
  update?: InputMaybe<SecurityInfoParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SecurityInfoParent document */
  upsert?: InputMaybe<SecurityInfoParentUpsertWithNestedWhereUniqueInput>;
};

export type SecurityInfoParentUpdateWithNestedWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>;
};

export type SecurityInfoParentUpsertWithNestedWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectUpsertWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type SecurityInfoParentWhereInput = {
  MintingProject?: InputMaybe<MintingProjectWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type SecurityInfoParentWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
};

export type SecurityInfoUpdateInput = {
  securityLineItem?: InputMaybe<Scalars["String"]["input"]>;
};

export type SecurityInfoUpdateManyInlineInput = {
  /** Create and connect multiple SecurityInfo component instances */
  create?: InputMaybe<Array<SecurityInfoCreateWithPositionInput>>;
  /** Delete multiple SecurityInfo documents */
  delete?: InputMaybe<Array<SecurityInfoWhereUniqueInput>>;
  /** Update multiple SecurityInfo component instances */
  update?: InputMaybe<
    Array<SecurityInfoUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SecurityInfo component instances */
  upsert?: InputMaybe<
    Array<SecurityInfoUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SecurityInfoUpdateManyInput = {
  securityLineItem?: InputMaybe<Scalars["String"]["input"]>;
};

export type SecurityInfoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SecurityInfoUpdateManyInput;
  /** Document search */
  where: SecurityInfoWhereInput;
};

export type SecurityInfoUpdateOneInlineInput = {
  /** Create and connect one SecurityInfo document */
  create?: InputMaybe<SecurityInfoCreateInput>;
  /** Delete currently connected SecurityInfo document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single SecurityInfo document */
  update?: InputMaybe<SecurityInfoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SecurityInfo document */
  upsert?: InputMaybe<SecurityInfoUpsertWithNestedWhereUniqueInput>;
};

export type SecurityInfoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SecurityInfoUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SecurityInfoWhereUniqueInput;
};

export type SecurityInfoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SecurityInfoUpdateInput;
  /** Unique document search */
  where: SecurityInfoWhereUniqueInput;
};

export type SecurityInfoUpsertInput = {
  /** Create document if it didn't exist */
  create: SecurityInfoCreateInput;
  /** Update document if it exists */
  update: SecurityInfoUpdateInput;
};

export type SecurityInfoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SecurityInfoUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SecurityInfoWhereUniqueInput;
};

export type SecurityInfoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SecurityInfoUpsertInput;
  /** Unique document search */
  where: SecurityInfoWhereUniqueInput;
};

/** Identifies documents */
export type SecurityInfoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  securityLineItem?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  securityLineItem_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  securityLineItem_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  securityLineItem_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  securityLineItem_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  securityLineItem_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  securityLineItem_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  securityLineItem_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  securityLineItem_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  securityLineItem_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References SecurityInfo record uniquely */
export type SecurityInfoWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SmallIconHeadingText = Entity & {
  __typename?: "SmallIconHeadingText";
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Asset>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  richText?: Maybe<RichText>;
  /** System stage field */
  stage: Stage;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SmallIconHeadingTextIconArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SmallIconHeadingTextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SmallIconHeadingTextWhereUniqueInput;
};

/** A connection to a list of items. */
export type SmallIconHeadingTextConnection = {
  __typename?: "SmallIconHeadingTextConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SmallIconHeadingTextEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SmallIconHeadingTextCreateInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetCreateOneInlineInput>;
  richText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type SmallIconHeadingTextCreateManyInlineInput = {
  /** Create and connect multiple existing SmallIconHeadingText documents */
  create?: InputMaybe<Array<SmallIconHeadingTextCreateInput>>;
};

export type SmallIconHeadingTextCreateOneInlineInput = {
  /** Create and connect one SmallIconHeadingText document */
  create?: InputMaybe<SmallIconHeadingTextCreateInput>;
};

export type SmallIconHeadingTextCreateWithPositionInput = {
  /** Document to create */
  data: SmallIconHeadingTextCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SmallIconHeadingTextEdge = {
  __typename?: "SmallIconHeadingTextEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: SmallIconHeadingText;
};

/** Identifies documents */
export type SmallIconHeadingTextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum SmallIconHeadingTextOrderByInput {
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  text_ASC = "text_ASC",
  text_DESC = "text_DESC",
}

export type SmallIconHeadingTextUpdateInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetUpdateOneInlineInput>;
  richText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type SmallIconHeadingTextUpdateManyInlineInput = {
  /** Create and connect multiple SmallIconHeadingText component instances */
  create?: InputMaybe<Array<SmallIconHeadingTextCreateWithPositionInput>>;
  /** Delete multiple SmallIconHeadingText documents */
  delete?: InputMaybe<Array<SmallIconHeadingTextWhereUniqueInput>>;
  /** Update multiple SmallIconHeadingText component instances */
  update?: InputMaybe<
    Array<SmallIconHeadingTextUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SmallIconHeadingText component instances */
  upsert?: InputMaybe<
    Array<SmallIconHeadingTextUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SmallIconHeadingTextUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  richText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type SmallIconHeadingTextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SmallIconHeadingTextUpdateManyInput;
  /** Document search */
  where: SmallIconHeadingTextWhereInput;
};

export type SmallIconHeadingTextUpdateOneInlineInput = {
  /** Create and connect one SmallIconHeadingText document */
  create?: InputMaybe<SmallIconHeadingTextCreateInput>;
  /** Delete currently connected SmallIconHeadingText document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single SmallIconHeadingText document */
  update?: InputMaybe<SmallIconHeadingTextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SmallIconHeadingText document */
  upsert?: InputMaybe<SmallIconHeadingTextUpsertWithNestedWhereUniqueInput>;
};

export type SmallIconHeadingTextUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SmallIconHeadingTextUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SmallIconHeadingTextWhereUniqueInput;
};

export type SmallIconHeadingTextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SmallIconHeadingTextUpdateInput;
  /** Unique document search */
  where: SmallIconHeadingTextWhereUniqueInput;
};

export type SmallIconHeadingTextUpsertInput = {
  /** Create document if it didn't exist */
  create: SmallIconHeadingTextCreateInput;
  /** Update document if it exists */
  update: SmallIconHeadingTextUpdateInput;
};

export type SmallIconHeadingTextUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SmallIconHeadingTextUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SmallIconHeadingTextWhereUniqueInput;
};

export type SmallIconHeadingTextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SmallIconHeadingTextUpsertInput;
  /** Unique document search */
  where: SmallIconHeadingTextWhereUniqueInput;
};

/** Identifies documents */
export type SmallIconHeadingTextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References SmallIconHeadingText record uniquely */
export type SmallIconHeadingTextWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Stage system enumeration */
export enum Stage {
  /** The Draft is the default stage for all your content. */
  DRAFT = "DRAFT",
  /** The Published stage is where you can publish your content to. */
  PUBLISHED = "PUBLISHED",
}

export enum SystemDateTimeFieldVariation {
  BASE = "BASE",
  COMBINED = "COMBINED",
  LOCALIZATION = "LOCALIZATION",
}

export enum Tags {
  body1 = "body1",
  div = "div",
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  p = "p",
  span = "span",
}

export type TeamMemberImageNameTitle = Entity & {
  __typename?: "TeamMemberImageNameTitle";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  image?: Maybe<Asset>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
  title?: Maybe<Scalars["String"]["output"]>;
  truncatedBio?: Maybe<Scalars["String"]["output"]>;
};

export type TeamMemberImageNameTitleImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TeamMemberImageNameTitleConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TeamMemberImageNameTitleWhereUniqueInput;
};

/** A connection to a list of items. */
export type TeamMemberImageNameTitleConnection = {
  __typename?: "TeamMemberImageNameTitleConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TeamMemberImageNameTitleEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TeamMemberImageNameTitleCreateInput = {
  image?: InputMaybe<AssetCreateOneInlineInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  truncatedBio?: InputMaybe<Scalars["String"]["input"]>;
};

export type TeamMemberImageNameTitleCreateManyInlineInput = {
  /** Create and connect multiple existing TeamMemberImageNameTitle documents */
  create?: InputMaybe<Array<TeamMemberImageNameTitleCreateInput>>;
};

export type TeamMemberImageNameTitleCreateOneInlineInput = {
  /** Create and connect one TeamMemberImageNameTitle document */
  create?: InputMaybe<TeamMemberImageNameTitleCreateInput>;
};

export type TeamMemberImageNameTitleCreateWithPositionInput = {
  /** Document to create */
  data: TeamMemberImageNameTitleCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TeamMemberImageNameTitleEdge = {
  __typename?: "TeamMemberImageNameTitleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: TeamMemberImageNameTitle;
};

/** Identifies documents */
export type TeamMemberImageNameTitleManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  truncatedBio?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  truncatedBio_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  truncatedBio_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  truncatedBio_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  truncatedBio_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  truncatedBio_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  truncatedBio_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  truncatedBio_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  truncatedBio_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  truncatedBio_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum TeamMemberImageNameTitleOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  truncatedBio_ASC = "truncatedBio_ASC",
  truncatedBio_DESC = "truncatedBio_DESC",
}

export type TeamMemberImageNameTitleUpdateInput = {
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  truncatedBio?: InputMaybe<Scalars["String"]["input"]>;
};

export type TeamMemberImageNameTitleUpdateManyInlineInput = {
  /** Create and connect multiple TeamMemberImageNameTitle component instances */
  create?: InputMaybe<Array<TeamMemberImageNameTitleCreateWithPositionInput>>;
  /** Delete multiple TeamMemberImageNameTitle documents */
  delete?: InputMaybe<Array<TeamMemberImageNameTitleWhereUniqueInput>>;
  /** Update multiple TeamMemberImageNameTitle component instances */
  update?: InputMaybe<
    Array<TeamMemberImageNameTitleUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TeamMemberImageNameTitle component instances */
  upsert?: InputMaybe<
    Array<TeamMemberImageNameTitleUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TeamMemberImageNameTitleUpdateManyInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  truncatedBio?: InputMaybe<Scalars["String"]["input"]>;
};

export type TeamMemberImageNameTitleUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TeamMemberImageNameTitleUpdateManyInput;
  /** Document search */
  where: TeamMemberImageNameTitleWhereInput;
};

export type TeamMemberImageNameTitleUpdateOneInlineInput = {
  /** Create and connect one TeamMemberImageNameTitle document */
  create?: InputMaybe<TeamMemberImageNameTitleCreateInput>;
  /** Delete currently connected TeamMemberImageNameTitle document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single TeamMemberImageNameTitle document */
  update?: InputMaybe<TeamMemberImageNameTitleUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TeamMemberImageNameTitle document */
  upsert?: InputMaybe<TeamMemberImageNameTitleUpsertWithNestedWhereUniqueInput>;
};

export type TeamMemberImageNameTitleUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<TeamMemberImageNameTitleUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: TeamMemberImageNameTitleWhereUniqueInput;
  };

export type TeamMemberImageNameTitleUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TeamMemberImageNameTitleUpdateInput;
  /** Unique document search */
  where: TeamMemberImageNameTitleWhereUniqueInput;
};

export type TeamMemberImageNameTitleUpsertInput = {
  /** Create document if it didn't exist */
  create: TeamMemberImageNameTitleCreateInput;
  /** Update document if it exists */
  update: TeamMemberImageNameTitleUpdateInput;
};

export type TeamMemberImageNameTitleUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<TeamMemberImageNameTitleUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: TeamMemberImageNameTitleWhereUniqueInput;
  };

export type TeamMemberImageNameTitleUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TeamMemberImageNameTitleUpsertInput;
  /** Unique document search */
  where: TeamMemberImageNameTitleWhereUniqueInput;
};

/** Identifies documents */
export type TeamMemberImageNameTitleWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  truncatedBio?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  truncatedBio_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  truncatedBio_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  truncatedBio_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  truncatedBio_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  truncatedBio_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  truncatedBio_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  truncatedBio_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  truncatedBio_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  truncatedBio_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References TeamMemberImageNameTitle record uniquely */
export type TeamMemberImageNameTitleWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type TokenPrinterConfiguration = Entity &
  Node & {
    __typename?: "TokenPrinterConfiguration";
    chain?: Maybe<Chain>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<TokenPrinterConfiguration>;
    featured?: Maybe<Scalars["Boolean"]["output"]>;
    /** List of TokenPrinterConfiguration versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    tokenBanner?: Maybe<Asset>;
    tokenContractAddress?: Maybe<Scalars["String"]["output"]>;
    tokenDescription?: Maybe<RichText>;
    tokenDexscreener?: Maybe<Scalars["String"]["output"]>;
    tokenName?: Maybe<Scalars["String"]["output"]>;
    tokenSymbol?: Maybe<Scalars["String"]["output"]>;
    tokenTelegram?: Maybe<Scalars["String"]["output"]>;
    tokenThumbnail?: Maybe<Asset>;
    tokenTwitter?: Maybe<Scalars["String"]["output"]>;
    tokenWebsite?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    v3PoolFee?: Maybe<Scalars["Int"]["output"]>;
  };

export type TokenPrinterConfigurationChainArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TokenPrinterConfigurationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TokenPrinterConfigurationDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type TokenPrinterConfigurationHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type TokenPrinterConfigurationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TokenPrinterConfigurationScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type TokenPrinterConfigurationTokenBannerArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TokenPrinterConfigurationTokenThumbnailArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TokenPrinterConfigurationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TokenPrinterConfigurationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TokenPrinterConfigurationWhereUniqueInput;
};

/** A connection to a list of items. */
export type TokenPrinterConfigurationConnection = {
  __typename?: "TokenPrinterConfigurationConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TokenPrinterConfigurationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TokenPrinterConfigurationCreateInput = {
  chain?: InputMaybe<ChainCreateOneInlineInput>;
  cly1v63zk0htw0dkc87045ixr?: InputMaybe<MrBurnzTokenCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  tokenBanner?: InputMaybe<AssetCreateOneInlineInput>;
  tokenContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  tokenDescription?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  tokenDexscreener?: InputMaybe<Scalars["String"]["input"]>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  tokenTelegram?: InputMaybe<Scalars["String"]["input"]>;
  tokenThumbnail?: InputMaybe<AssetCreateOneInlineInput>;
  tokenTwitter?: InputMaybe<Scalars["String"]["input"]>;
  tokenWebsite?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  v3PoolFee?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TokenPrinterConfigurationCreateManyInlineInput = {
  /** Connect multiple existing TokenPrinterConfiguration documents */
  connect?: InputMaybe<Array<TokenPrinterConfigurationWhereUniqueInput>>;
  /** Create and connect multiple existing TokenPrinterConfiguration documents */
  create?: InputMaybe<Array<TokenPrinterConfigurationCreateInput>>;
};

export type TokenPrinterConfigurationCreateOneInlineInput = {
  /** Connect one existing TokenPrinterConfiguration document */
  connect?: InputMaybe<TokenPrinterConfigurationWhereUniqueInput>;
  /** Create and connect one TokenPrinterConfiguration document */
  create?: InputMaybe<TokenPrinterConfigurationCreateInput>;
};

/** An edge in a connection. */
export type TokenPrinterConfigurationEdge = {
  __typename?: "TokenPrinterConfigurationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: TokenPrinterConfiguration;
};

/** Identifies documents */
export type TokenPrinterConfigurationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TokenPrinterConfigurationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TokenPrinterConfigurationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TokenPrinterConfigurationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TokenPrinterConfigurationWhereStageInput>;
  documentInStages_none?: InputMaybe<TokenPrinterConfigurationWhereStageInput>;
  documentInStages_some?: InputMaybe<TokenPrinterConfigurationWhereStageInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  featured_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tokenBanner?: InputMaybe<AssetWhereInput>;
  tokenContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  tokenContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenContractAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenContractAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenContractAddress_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenContractAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenDexscreener?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenDexscreener_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenDexscreener_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenDexscreener_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  tokenDexscreener_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenDexscreener_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenDexscreener_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenDexscreener_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenDexscreener_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenDexscreener_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  tokenName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenSymbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenSymbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenSymbol_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenSymbol_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenSymbol_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenSymbol_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenSymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenSymbol_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenSymbol_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenTelegram?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenTelegram_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenTelegram_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenTelegram_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenTelegram_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenTelegram_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenTelegram_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenTelegram_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenTelegram_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenTelegram_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenThumbnail?: InputMaybe<AssetWhereInput>;
  tokenTwitter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenTwitter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenTwitter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenTwitter_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenTwitter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenTwitter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenTwitter_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenTwitter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenTwitter_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenTwitter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenWebsite?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenWebsite_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenWebsite_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenWebsite_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenWebsite_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenWebsite_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenWebsite_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenWebsite_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenWebsite_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenWebsite_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  v3PoolFee?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  v3PoolFee_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  v3PoolFee_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  v3PoolFee_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  v3PoolFee_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  v3PoolFee_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  v3PoolFee_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  v3PoolFee_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

export enum TokenPrinterConfigurationOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  featured_ASC = "featured_ASC",
  featured_DESC = "featured_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  tokenContractAddress_ASC = "tokenContractAddress_ASC",
  tokenContractAddress_DESC = "tokenContractAddress_DESC",
  tokenDexscreener_ASC = "tokenDexscreener_ASC",
  tokenDexscreener_DESC = "tokenDexscreener_DESC",
  tokenName_ASC = "tokenName_ASC",
  tokenName_DESC = "tokenName_DESC",
  tokenSymbol_ASC = "tokenSymbol_ASC",
  tokenSymbol_DESC = "tokenSymbol_DESC",
  tokenTelegram_ASC = "tokenTelegram_ASC",
  tokenTelegram_DESC = "tokenTelegram_DESC",
  tokenTwitter_ASC = "tokenTwitter_ASC",
  tokenTwitter_DESC = "tokenTwitter_DESC",
  tokenWebsite_ASC = "tokenWebsite_ASC",
  tokenWebsite_DESC = "tokenWebsite_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  v3PoolFee_ASC = "v3PoolFee_ASC",
  v3PoolFee_DESC = "v3PoolFee_DESC",
}

export type TokenPrinterConfigurationUpdateInput = {
  chain?: InputMaybe<ChainUpdateOneInlineInput>;
  cly1v63zk0htw0dkc87045ixr?: InputMaybe<MrBurnzTokenUpdateManyInlineInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  tokenBanner?: InputMaybe<AssetUpdateOneInlineInput>;
  tokenContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  tokenDescription?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  tokenDexscreener?: InputMaybe<Scalars["String"]["input"]>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  tokenTelegram?: InputMaybe<Scalars["String"]["input"]>;
  tokenThumbnail?: InputMaybe<AssetUpdateOneInlineInput>;
  tokenTwitter?: InputMaybe<Scalars["String"]["input"]>;
  tokenWebsite?: InputMaybe<Scalars["String"]["input"]>;
  v3PoolFee?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TokenPrinterConfigurationUpdateManyInlineInput = {
  /** Connect multiple existing TokenPrinterConfiguration documents */
  connect?: InputMaybe<Array<TokenPrinterConfigurationConnectInput>>;
  /** Create and connect multiple TokenPrinterConfiguration documents */
  create?: InputMaybe<Array<TokenPrinterConfigurationCreateInput>>;
  /** Delete multiple TokenPrinterConfiguration documents */
  delete?: InputMaybe<Array<TokenPrinterConfigurationWhereUniqueInput>>;
  /** Disconnect multiple TokenPrinterConfiguration documents */
  disconnect?: InputMaybe<Array<TokenPrinterConfigurationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TokenPrinterConfiguration documents */
  set?: InputMaybe<Array<TokenPrinterConfigurationWhereUniqueInput>>;
  /** Update multiple TokenPrinterConfiguration documents */
  update?: InputMaybe<
    Array<TokenPrinterConfigurationUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple TokenPrinterConfiguration documents */
  upsert?: InputMaybe<
    Array<TokenPrinterConfigurationUpsertWithNestedWhereUniqueInput>
  >;
};

export type TokenPrinterConfigurationUpdateManyInput = {
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  tokenContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  tokenDescription?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  v3PoolFee?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TokenPrinterConfigurationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TokenPrinterConfigurationUpdateManyInput;
  /** Document search */
  where: TokenPrinterConfigurationWhereInput;
};

export type TokenPrinterConfigurationUpdateOneInlineInput = {
  /** Connect existing TokenPrinterConfiguration document */
  connect?: InputMaybe<TokenPrinterConfigurationWhereUniqueInput>;
  /** Create and connect one TokenPrinterConfiguration document */
  create?: InputMaybe<TokenPrinterConfigurationCreateInput>;
  /** Delete currently connected TokenPrinterConfiguration document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected TokenPrinterConfiguration document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single TokenPrinterConfiguration document */
  update?: InputMaybe<TokenPrinterConfigurationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TokenPrinterConfiguration document */
  upsert?: InputMaybe<TokenPrinterConfigurationUpsertWithNestedWhereUniqueInput>;
};

export type TokenPrinterConfigurationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TokenPrinterConfigurationUpdateInput;
  /** Unique document search */
  where: TokenPrinterConfigurationWhereUniqueInput;
};

export type TokenPrinterConfigurationUpsertInput = {
  /** Create document if it didn't exist */
  create: TokenPrinterConfigurationCreateInput;
  /** Update document if it exists */
  update: TokenPrinterConfigurationUpdateInput;
};

export type TokenPrinterConfigurationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TokenPrinterConfigurationUpsertInput;
  /** Unique document search */
  where: TokenPrinterConfigurationWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type TokenPrinterConfigurationWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type TokenPrinterConfigurationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TokenPrinterConfigurationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TokenPrinterConfigurationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TokenPrinterConfigurationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TokenPrinterConfigurationWhereStageInput>;
  documentInStages_none?: InputMaybe<TokenPrinterConfigurationWhereStageInput>;
  documentInStages_some?: InputMaybe<TokenPrinterConfigurationWhereStageInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  featured_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tokenBanner?: InputMaybe<AssetWhereInput>;
  tokenContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  tokenContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenContractAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenContractAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenContractAddress_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenContractAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenDexscreener?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenDexscreener_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenDexscreener_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenDexscreener_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  tokenDexscreener_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenDexscreener_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenDexscreener_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenDexscreener_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenDexscreener_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenDexscreener_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  tokenName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenSymbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenSymbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenSymbol_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenSymbol_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenSymbol_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenSymbol_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenSymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenSymbol_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenSymbol_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenTelegram?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenTelegram_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenTelegram_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenTelegram_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenTelegram_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenTelegram_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenTelegram_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenTelegram_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenTelegram_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenTelegram_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenThumbnail?: InputMaybe<AssetWhereInput>;
  tokenTwitter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenTwitter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenTwitter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenTwitter_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenTwitter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenTwitter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenTwitter_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenTwitter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenTwitter_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenTwitter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenWebsite?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenWebsite_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenWebsite_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenWebsite_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenWebsite_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenWebsite_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenWebsite_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenWebsite_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenWebsite_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenWebsite_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  v3PoolFee?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  v3PoolFee_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  v3PoolFee_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  v3PoolFee_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  v3PoolFee_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  v3PoolFee_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  v3PoolFee_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  v3PoolFee_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TokenPrinterConfigurationWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TokenPrinterConfigurationWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TokenPrinterConfigurationWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TokenPrinterConfigurationWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TokenPrinterConfigurationWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References TokenPrinterConfiguration record uniquely */
export type TokenPrinterConfigurationWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  tokenDexscreener?: InputMaybe<Scalars["String"]["input"]>;
  tokenTelegram?: InputMaybe<Scalars["String"]["input"]>;
  tokenTwitter?: InputMaybe<Scalars["String"]["input"]>;
  tokenWebsite?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrendingCollectionsCarousel = Entity & {
  __typename?: "TrendingCollectionsCarousel";
  chains: Array<Chain>;
  heading?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  numberOfCollectionsPerChain?: Maybe<Scalars["Int"]["output"]>;
  /** System stage field */
  stage: Stage;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type TrendingCollectionsCarouselChainsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ChainOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChainWhereInput>;
};

export type TrendingCollectionsCarouselConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TrendingCollectionsCarouselWhereUniqueInput;
};

/** A connection to a list of items. */
export type TrendingCollectionsCarouselConnection = {
  __typename?: "TrendingCollectionsCarouselConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TrendingCollectionsCarouselEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TrendingCollectionsCarouselCreateInput = {
  chains?: InputMaybe<ChainCreateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  numberOfCollectionsPerChain?: InputMaybe<Scalars["Int"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrendingCollectionsCarouselCreateManyInlineInput = {
  /** Create and connect multiple existing TrendingCollectionsCarousel documents */
  create?: InputMaybe<Array<TrendingCollectionsCarouselCreateInput>>;
};

export type TrendingCollectionsCarouselCreateOneInlineInput = {
  /** Create and connect one TrendingCollectionsCarousel document */
  create?: InputMaybe<TrendingCollectionsCarouselCreateInput>;
};

export type TrendingCollectionsCarouselCreateWithPositionInput = {
  /** Document to create */
  data: TrendingCollectionsCarouselCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TrendingCollectionsCarouselEdge = {
  __typename?: "TrendingCollectionsCarouselEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: TrendingCollectionsCarousel;
};

/** Identifies documents */
export type TrendingCollectionsCarouselManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chains_every?: InputMaybe<ChainWhereInput>;
  chains_none?: InputMaybe<ChainWhereInput>;
  chains_some?: InputMaybe<ChainWhereInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  numberOfCollectionsPerChain?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  numberOfCollectionsPerChain_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  numberOfCollectionsPerChain_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  numberOfCollectionsPerChain_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  numberOfCollectionsPerChain_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  numberOfCollectionsPerChain_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  numberOfCollectionsPerChain_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  numberOfCollectionsPerChain_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum TrendingCollectionsCarouselOrderByInput {
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  numberOfCollectionsPerChain_ASC = "numberOfCollectionsPerChain_ASC",
  numberOfCollectionsPerChain_DESC = "numberOfCollectionsPerChain_DESC",
  subHeading_ASC = "subHeading_ASC",
  subHeading_DESC = "subHeading_DESC",
}

export type TrendingCollectionsCarouselUpdateInput = {
  chains?: InputMaybe<ChainUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  numberOfCollectionsPerChain?: InputMaybe<Scalars["Int"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrendingCollectionsCarouselUpdateManyInlineInput = {
  /** Create and connect multiple TrendingCollectionsCarousel component instances */
  create?: InputMaybe<
    Array<TrendingCollectionsCarouselCreateWithPositionInput>
  >;
  /** Delete multiple TrendingCollectionsCarousel documents */
  delete?: InputMaybe<Array<TrendingCollectionsCarouselWhereUniqueInput>>;
  /** Update multiple TrendingCollectionsCarousel component instances */
  update?: InputMaybe<
    Array<TrendingCollectionsCarouselUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TrendingCollectionsCarousel component instances */
  upsert?: InputMaybe<
    Array<TrendingCollectionsCarouselUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TrendingCollectionsCarouselUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  numberOfCollectionsPerChain?: InputMaybe<Scalars["Int"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrendingCollectionsCarouselUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TrendingCollectionsCarouselUpdateManyInput;
  /** Document search */
  where: TrendingCollectionsCarouselWhereInput;
};

export type TrendingCollectionsCarouselUpdateOneInlineInput = {
  /** Create and connect one TrendingCollectionsCarousel document */
  create?: InputMaybe<TrendingCollectionsCarouselCreateInput>;
  /** Delete currently connected TrendingCollectionsCarousel document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single TrendingCollectionsCarousel document */
  update?: InputMaybe<TrendingCollectionsCarouselUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TrendingCollectionsCarousel document */
  upsert?: InputMaybe<TrendingCollectionsCarouselUpsertWithNestedWhereUniqueInput>;
};

export type TrendingCollectionsCarouselUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<TrendingCollectionsCarouselUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: TrendingCollectionsCarouselWhereUniqueInput;
  };

export type TrendingCollectionsCarouselUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TrendingCollectionsCarouselUpdateInput;
  /** Unique document search */
  where: TrendingCollectionsCarouselWhereUniqueInput;
};

export type TrendingCollectionsCarouselUpsertInput = {
  /** Create document if it didn't exist */
  create: TrendingCollectionsCarouselCreateInput;
  /** Update document if it exists */
  update: TrendingCollectionsCarouselUpdateInput;
};

export type TrendingCollectionsCarouselUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<TrendingCollectionsCarouselUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: TrendingCollectionsCarouselWhereUniqueInput;
  };

export type TrendingCollectionsCarouselUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TrendingCollectionsCarouselUpsertInput;
  /** Unique document search */
  where: TrendingCollectionsCarouselWhereUniqueInput;
};

/** Identifies documents */
export type TrendingCollectionsCarouselWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chains_every?: InputMaybe<ChainWhereInput>;
  chains_none?: InputMaybe<ChainWhereInput>;
  chains_some?: InputMaybe<ChainWhereInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  numberOfCollectionsPerChain?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  numberOfCollectionsPerChain_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  numberOfCollectionsPerChain_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  numberOfCollectionsPerChain_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  numberOfCollectionsPerChain_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  numberOfCollectionsPerChain_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  numberOfCollectionsPerChain_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  numberOfCollectionsPerChain_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References TrendingCollectionsCarousel record uniquely */
export type TrendingCollectionsCarouselWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: Locale;
  /** Stages to unpublish selected locales from */
  stages: Array<Stage>;
};

/** User system model */
export type User = Entity &
  Node & {
    __typename?: "User";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** Get the document in other stages */
    documentInStages: Array<User>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** Flag to determine if user is active or not */
    isActive: Scalars["Boolean"]["output"];
    /** User Kind. Can be either MEMBER, PAT or PUBLIC */
    kind: UserKind;
    /** The username */
    name: Scalars["String"]["output"];
    /** Profile Picture url */
    picture?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
  };

/** User system model */
export type UserDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type UserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: UserWhereUniqueInput;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: "UserConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: "UserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: User;
};

/** System User Kind */
export enum UserKind {
  MEMBER = "MEMBER",
  PAT = "PAT",
  PUBLIC = "PUBLIC",
  WEBHOOK = "WEBHOOK",
}

/** Identifies documents */
export type UserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  picture?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
};

export enum UserOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isActive_ASC = "isActive_ASC",
  isActive_DESC = "isActive_DESC",
  kind_ASC = "kind_ASC",
  kind_DESC = "kind_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  picture_ASC = "picture_ASC",
  picture_DESC = "picture_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type UserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Disconnect multiple User documents */
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing User documents */
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
  /** Disconnect currently connected User document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** This contains a set of filters that can be used to compare values internally */
export type UserWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type UserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  picture?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UserWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<UserWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References User record uniquely */
export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Version = {
  __typename?: "Version";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  revision: Scalars["Int"]["output"];
  stage: Stage;
};

export type VersionWhereInput = {
  id: Scalars["ID"]["input"];
  revision: Scalars["Int"]["input"];
  stage: Stage;
};

export enum _FilterKind {
  AND = "AND",
  NOT = "NOT",
  OR = "OR",
  contains = "contains",
  contains_all = "contains_all",
  contains_none = "contains_none",
  contains_some = "contains_some",
  ends_with = "ends_with",
  eq = "eq",
  eq_not = "eq_not",
  gt = "gt",
  gte = "gte",
  in = "in",
  json_path_exists = "json_path_exists",
  json_value_recursive = "json_value_recursive",
  lt = "lt",
  lte = "lte",
  not_contains = "not_contains",
  not_ends_with = "not_ends_with",
  not_in = "not_in",
  not_starts_with = "not_starts_with",
  relational_every = "relational_every",
  relational_none = "relational_none",
  relational_single = "relational_single",
  relational_some = "relational_some",
  search = "search",
  starts_with = "starts_with",
  union_empty = "union_empty",
  union_every = "union_every",
  union_none = "union_none",
  union_single = "union_single",
  union_some = "union_some",
}

export enum _MutationInputFieldKind {
  enum = "enum",
  relation = "relation",
  richText = "richText",
  richTextWithEmbeds = "richTextWithEmbeds",
  scalar = "scalar",
  union = "union",
  virtual = "virtual",
}

export enum _MutationKind {
  create = "create",
  delete = "delete",
  deleteMany = "deleteMany",
  publish = "publish",
  publishMany = "publishMany",
  schedulePublish = "schedulePublish",
  scheduleUnpublish = "scheduleUnpublish",
  unpublish = "unpublish",
  unpublishMany = "unpublishMany",
  update = "update",
  updateMany = "updateMany",
  upsert = "upsert",
}

export enum _OrderDirection {
  asc = "asc",
  desc = "desc",
}

export enum _RelationInputCardinality {
  many = "many",
  one = "one",
}

export enum _RelationInputKind {
  create = "create",
  update = "update",
}

export enum _RelationKind {
  regular = "regular",
  union = "union",
}

export enum _SystemDateTimeFieldVariation {
  base = "base",
  combined = "combined",
  localization = "localization",
}

export type GetAlphaMintingProjectsCarouselQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAlphaMintingProjectsCarouselQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    id: string;
    slug?: string | null;
    currency?: Currencies | null;
    featured: boolean;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    paperCreditCardMintingLink?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    mintDate?: any | null;
    mintPrice?: number | null;
    projectName: string;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    mintingStat?: { __typename?: "MintingStat"; id: string } | null;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    nftCollection?: {
      __typename?: "NftCollection";
      tradable: boolean;
      currency?: Currencies | null;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
      } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    } | null;
  }>;
};

export type BrandAmbassadorCtaQueryVariables = Exact<{ [key: string]: never }>;

export type BrandAmbassadorCtaQuery = {
  __typename?: "Query";
  ctaSection?: {
    __typename?: "CtaSection";
    backgroundFrom?: Colors | null;
    backgroundTo?: Colors | null;
    image?: { __typename?: "Asset"; url: string } | null;
    text: Array<{
      __typename?: "ColoredText";
      id: string;
      tag?: Tags | null;
      text?: string | null;
      textColor?: Colors | null;
    }>;
    mainButton?: {
      __typename?: "Button";
      buttonLink?: string | null;
      buttonText?: string | null;
      buttonVariant?: ButtonVariants | null;
      externalLink?: boolean | null;
      id: string;
    } | null;
  } | null;
};

export type FeaturedCollectionGroupQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type FeaturedCollectionGroupQuery = {
  __typename?: "Query";
  featuredCollectionGroup?: {
    __typename?: "FeaturedCollectionGroup";
    nftCollections: Array<{
      __typename?: "NftCollection";
      id: string;
      collectionTitle?: string | null;
      collectionContractAddress: string;
      collectionCategories: Array<CollectionCategories>;
      collectionDescription?: string | null;
      collectionTotalSupply?: number | null;
      collectionListingDate?: any | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainSlug: string;
      } | null;
    }>;
  } | null;
};

export type GetCollectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCollectionsQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    id: string;
    collectionTitle?: string | null;
    collectionContractAddress: string;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
  }>;
};

export type GetCollectionsByCategoryQueryVariables = Exact<{
  collectionCategories_contains_all?: InputMaybe<
    Array<CollectionCategories> | CollectionCategories
  >;
}>;

export type GetCollectionsByCategoryQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    id: string;
    collectionTitle?: string | null;
    collectionContractAddress: string;
    collectionCategories: Array<CollectionCategories>;
    collectionDescription?: string | null;
    collectionListingDate?: any | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
  }>;
};

export type GetHeroCollectionsByCategoryQueryVariables = Exact<{
  collectionCategories_contains_all?: InputMaybe<
    Array<CollectionCategories> | CollectionCategories
  >;
}>;

export type GetHeroCollectionsByCategoryQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    id: string;
    collectionTitle?: string | null;
    collectionContractAddress: string;
    collectionCategories: Array<CollectionCategories>;
    collectionDescription?: string | null;
    collectionTotalSupply?: number | null;
    collectionListingDate?: any | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    chain?: {
      __typename?: "Chain";
      chainId?: number | null;
      chainSlug: string;
    } | null;
  }>;
};

export type GetCollectionCreatorBySlugQueryVariables = Exact<{
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCollectionCreatorBySlugQuery = {
  __typename?: "Query";
  collectionCreator?: {
    __typename?: "CollectionCreator";
    collectionCreatorBio?: string | null;
    collectionCreatorName?: string | null;
    collectionCreatorSlug?: string | null;
    collectionCreatorUrl?: string | null;
    categories: Array<CollectionCategories>;
    collectionCreatorThumbnailImage?: {
      __typename?: "Asset";
      url: string;
    } | null;
    collectionCreatorBannerImage?: { __typename?: "Asset"; url: string } | null;
    nftCollections: Array<{
      __typename?: "NftCollection";
      id: string;
      collectionContractAddress: string;
      collectionCategories: Array<CollectionCategories>;
      collectionDescription?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
      } | null;
      collectionFeaturedImage: Array<{ __typename?: "Asset"; url: string }>;
    }>;
  } | null;
};

export type CollectionCreatorsQueryVariables = Exact<{ [key: string]: never }>;

export type CollectionCreatorsQuery = {
  __typename?: "Query";
  collectionCreators: Array<{
    __typename?: "CollectionCreator";
    collectionCreatorBio?: string | null;
    collectionCreatorName?: string | null;
    collectionCreatorSlug?: string | null;
    collectionCreatorUrl?: string | null;
    categories: Array<CollectionCategories>;
    collectionCreatorThumbnailImage?: {
      __typename?: "Asset";
      url: string;
    } | null;
    collectionCreatorBannerImage?: { __typename?: "Asset"; url: string } | null;
  }>;
};

export type FeaturedCreatorGroupQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type FeaturedCreatorGroupQuery = {
  __typename?: "Query";
  featuredCreatorGroup?: {
    __typename?: "FeaturedCreatorGroup";
    collectionCreators: Array<{
      __typename?: "CollectionCreator";
      categories: Array<CollectionCategories>;
      collectionCreatorBio?: string | null;
      collectionCreatorName?: string | null;
      collectionCreatorSlug?: string | null;
      collectionCreatorUrl?: string | null;
      collectionCreatorBannerImage?: {
        __typename?: "Asset";
        url: string;
      } | null;
      collectionCreatorThumbnailImage?: {
        __typename?: "Asset";
        url: string;
      } | null;
    }>;
  } | null;
};

export type GetNftCollectionAddressesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNftCollectionAddressesQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    collectionContractAddress: string;
  }>;
};

export type GetSidebarNavigationQueryVariables = Exact<{
  applicationId?: InputMaybe<AppId>;
}>;

export type GetSidebarNavigationQuery = {
  __typename?: "Query";
  menus: Array<{
    __typename?: "Menu";
    id: string;
    menuGroupName?: string | null;
    menuSection: MenuType;
    navigationItems: Array<{
      __typename?: "NavigationItem";
      iconComponent?: HeroMenuIcons | null;
      id: string;
      internalSlug?: string | null;
      navigationItemText?: string | null;
      externalLink?: string | null;
      destinationAppId: AppId;
    }>;
  }>;
  menu?: {
    __typename?: "Menu";
    id: string;
    menuGroupName?: string | null;
    menuSection: MenuType;
    navigationItems: Array<{
      __typename?: "NavigationItem";
      externalLink?: string | null;
      iconComponent?: HeroMenuIcons | null;
      id: string;
      internalSlug?: string | null;
      navigationItemText?: string | null;
      destinationAppId: AppId;
    }>;
  } | null;
};

export type HelpCenterQueryVariables = Exact<{ [key: string]: never }>;

export type HelpCenterQuery = {
  __typename?: "Query";
  page?: {
    __typename?: "Page";
    pageTitle?: string | null;
    pageSlug?: string | null;
    fAQSections: Array<{
      __typename?: "FAQSection";
      id: string;
      heading?: string | null;
      questions: Array<{
        __typename?: "Faq";
        question?: string | null;
        answer?: string | null;
        id: string;
      }>;
    }>;
  } | null;
};

export type HomePageQueryVariables = Exact<{ [key: string]: never }>;

export type HomePageQuery = {
  __typename?: "Query";
  page?: {
    __typename?: "Page";
    pageTitle?: string | null;
    pageSlug?: string | null;
    ctaSection?: {
      __typename?: "CtaSection";
      backgroundFrom?: Colors | null;
      backgroundTo?: Colors | null;
      image?: { __typename?: "Asset"; url: string } | null;
      text: Array<{
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      }>;
      mainButton?: {
        __typename?: "Button";
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
        id: string;
      } | null;
    } | null;
    fAQSection?: {
      __typename?: "FAQSection";
      heading?: string | null;
      questions: Array<{
        __typename?: "Faq";
        question?: string | null;
        answer?: string | null;
        id: string;
      }>;
    } | null;
  } | null;
};

export type MetaFuseProjectsWithRevealOnMintQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MetaFuseProjectsWithRevealOnMintQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    metafuseProjectId?: string | null;
    shouldMetafuseRevealOnMint: boolean;
    maxMintPerTransaction: number;
    nftCollection?: {
      __typename?: "NftCollection";
      collectionContractAddress: string;
      collectionTotalSupply?: number | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainEnum?: ChainIDs | null;
        chainName?: string | null;
      } | null;
    } | null;
  }>;
};

export type GetAllMintingProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllMintingProjectsQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    id: string;
    slug?: string | null;
    currency?: Currencies | null;
    featured: boolean;
    soldOut?: boolean | null;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    paperCreditCardMintingLink?: string | null;
    mintDate?: any | null;
    mintPrice?: number | null;
    projectName: string;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    mintingStat?: { __typename?: "MintingStat"; id: string } | null;
    mintingCurrencyInformation?: {
      __typename?: "MintingCurrency";
      tokenIcon?: { __typename?: "Asset"; url: string } | null;
    } | null;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    nftCollection?: {
      __typename?: "NftCollection";
      tradable: boolean;
      currency?: Currencies | null;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
      } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    } | null;
  }>;
};

export type GetMintingProjectByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetMintingProjectByIdQuery = {
  __typename?: "Query";
  mintingProject?: {
    __typename?: "MintingProject";
    id: string;
    slug?: string | null;
    presaleHeroSectionCopy?: string | null;
    metafuseProjectId?: string | null;
    shouldMetafuseRevealOnMint: boolean;
    hasDynamicPricing: boolean;
    dynamicPricingGetterFunction?: string | null;
    allocation?: number | null;
    paperCreditCardMintingLink?: string | null;
    mintingContractAbi?: any | null;
    presaleCheckerFunction?: string | null;
    priceQueryFunctionName?: string | null;
    presalePriceGetter?: string | null;
    presaleMintingFunctionName?: string | null;
    mintingFunctionName?: string | null;
    mintingCurrencyDecimals?: number | null;
    presaleMaxMintGetter?: string | null;
    publicSaleMaxMintGetter?: string | null;
    priceQueryRequiresAccount: boolean;
    currency?: Currencies | null;
    mintDate?: any | null;
    soldOut?: boolean | null;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    whitelistChecker?: string | null;
    mintPrice?: number | null;
    projectName: string;
    aboutSectionHeader?: string | null;
    aboutSectionText?: string | null;
    mintedNfTsCarouselHeader?: string | null;
    mintedNfTsCarouselSubHeader?: string | null;
    fAQSection?: {
      __typename?: "FAQSection";
      heading?: string | null;
      questions: Array<{
        __typename?: "Faq";
        question?: string | null;
        id: string;
        answer?: string | null;
      }>;
    } | null;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    ctaSection?: {
      __typename?: "CtaSection";
      backgroundFrom?: Colors | null;
      backgroundTo?: Colors | null;
      image?: { __typename?: "Asset"; url: string } | null;
      text: Array<{
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      }>;
      mainButton?: {
        __typename?: "Button";
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
        id: string;
      } | null;
    } | null;
    mintInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      name?: string | null;
      value?: string | null;
      id: string;
    }>;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    collectionSampleImages: Array<{ __typename?: "Asset"; url: string }>;
    collectionCreator?: {
      __typename?: "CollectionCreator";
      collectionCreatorBio?: string | null;
      collectionCreatorName?: string | null;
      collectionCreatorUrl?: string | null;
    } | null;
    nftCollection?: {
      __typename?: "NftCollection";
      currency?: Currencies | null;
      tradable: boolean;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
        chainSlug: string;
      } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    } | null;
    aboutSectionAccordions: Array<{
      __typename?: "Faq";
      answer?: string | null;
      id: string;
      question?: string | null;
    }>;
    mintingStat?: {
      __typename?: "MintingStat";
      heading?: string | null;
      subHeading?: string | null;
      feesInformationText?: string | null;
      inProgressText?: string | null;
      mintedCountText?: string | null;
      backgroundImage?: { __typename?: "Asset"; url: string } | null;
      button?: {
        __typename?: "Button";
        buttonText?: string | null;
        buttonLink?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
      } | null;
      flexibleText?: {
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      } | null;
      heroImage?: { __typename?: "Asset"; url: string } | null;
      heroImageLeft?: { __typename?: "Asset"; url: string } | null;
    } | null;
  } | null;
};

export type GetMintingProjectBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;

export type GetMintingProjectBySlugQuery = {
  __typename?: "Query";
  mintingProject?: {
    __typename?: "MintingProject";
    presaleHeroSectionCopy?: string | null;
    metafuseProjectId?: string | null;
    shouldMetafuseRevealOnMint: boolean;
    hasDynamicPricing: boolean;
    dynamicPricingGetterFunction?: string | null;
    allocation?: number | null;
    paperCreditCardMintingLink?: string | null;
    mintingContractAbi?: any | null;
    presaleCheckerFunction?: string | null;
    priceQueryFunctionName?: string | null;
    presalePriceGetter?: string | null;
    presaleMintingFunctionName?: string | null;
    mintingFunctionName?: string | null;
    mintingCurrencyDecimals?: number | null;
    presaleMaxMintGetter?: string | null;
    publicSaleMaxMintGetter?: string | null;
    priceQueryRequiresAccount: boolean;
    currency?: Currencies | null;
    mintDate?: any | null;
    soldOut?: boolean | null;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    whitelistChecker?: string | null;
    mintPrice?: number | null;
    projectName: string;
    aboutSectionHeader?: string | null;
    aboutSectionText?: string | null;
    mintedNfTsCarouselHeader?: string | null;
    mintedNfTsCarouselSubHeader?: string | null;
    fAQSection?: {
      __typename?: "FAQSection";
      heading?: string | null;
      questions: Array<{
        __typename?: "Faq";
        question?: string | null;
        id: string;
        answer?: string | null;
      }>;
    } | null;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    ctaSection?: {
      __typename?: "CtaSection";
      backgroundFrom?: Colors | null;
      backgroundTo?: Colors | null;
      image?: { __typename?: "Asset"; url: string } | null;
      text: Array<{
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      }>;
      mainButton?: {
        __typename?: "Button";
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
        id: string;
      } | null;
    } | null;
    mintInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      name?: string | null;
      value?: string | null;
      id: string;
    }>;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    collectionSampleImages: Array<{ __typename?: "Asset"; url: string }>;
    collectionCreator?: {
      __typename?: "CollectionCreator";
      collectionCreatorBio?: string | null;
      collectionCreatorName?: string | null;
      collectionCreatorUrl?: string | null;
    } | null;
    nftCollection?: {
      __typename?: "NftCollection";
      currency?: Currencies | null;
      tradable: boolean;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
        chainSlug: string;
      } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    } | null;
    aboutSectionAccordions: Array<{
      __typename?: "Faq";
      answer?: string | null;
      id: string;
      question?: string | null;
    }>;
    mintingStat?: {
      __typename?: "MintingStat";
      heading?: string | null;
      subHeading?: string | null;
      feesInformationText?: string | null;
      inProgressText?: string | null;
      mintedCountText?: string | null;
      backgroundImage?: { __typename?: "Asset"; url: string } | null;
      button?: {
        __typename?: "Button";
        buttonText?: string | null;
        buttonLink?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
      } | null;
      flexibleText?: {
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      } | null;
      heroImage?: { __typename?: "Asset"; url: string } | null;
      heroImageLeft?: { __typename?: "Asset"; url: string } | null;
    } | null;
  } | null;
};

export type GetMintingProjectSlugsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMintingProjectSlugsQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    slug?: string | null;
  }>;
};

export type GetMintingProjectsQueryVariables = Exact<{
  chainEnum?: InputMaybe<ChainIDs>;
}>;

export type GetMintingProjectsQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    id: string;
    slug?: string | null;
    currency?: Currencies | null;
    featured: boolean;
    soldOut?: boolean | null;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    paperCreditCardMintingLink?: string | null;
    mintDate?: any | null;
    mintPrice?: number | null;
    projectName: string;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    mintingStat?: { __typename?: "MintingStat"; id: string } | null;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    nftCollection?: {
      __typename?: "NftCollection";
      tradable: boolean;
      currency?: Currencies | null;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
        chainSlug: string;
      } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    } | null;
  }>;
};

export type GetMintingProjectsCarouselQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMintingProjectsCarouselQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    id: string;
    slug?: string | null;
    currency?: Currencies | null;
    featured: boolean;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    paperCreditCardMintingLink?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    mintDate?: any | null;
    mintPrice?: number | null;
    projectName: string;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    mintingStat?: { __typename?: "MintingStat"; id: string } | null;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    nftCollection?: {
      __typename?: "NftCollection";
      tradable: boolean;
      currency?: Currencies | null;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
        chainSlug: string;
      } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    } | null;
  }>;
};

export type NftCollectionByAddressQueryVariables = Exact<{
  collectionContractAddress: Scalars["String"]["input"];
}>;

export type NftCollectionByAddressQuery = {
  __typename?: "Query";
  nftCollection?: {
    __typename?: "NftCollection";
    currency?: Currencies | null;
    collectionHomePage?: string | null;
    collectionTitle?: string | null;
    collectionTwitterProfile?: string | null;
    collectionCategories: Array<CollectionCategories>;
    collectionRoyaltyFee?: number | null;
    collectionRoyaltyRecipientAddress?: string | null;
    collectionTotalSupply?: number | null;
    collectionContractAddress: string;
    collectionDescription?: string | null;
    collectionDiscordServer?: string | null;
    collectionInstagramProfile?: string | null;
    collectionMediumHome?: string | null;
    collectionListingDate?: any | null;
    chain?: {
      __typename?: "Chain";
      chainId?: number | null;
      chainName?: string | null;
      chainSlug: string;
    } | null;
    collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    collectionFeaturedImage: Array<{ __typename?: "Asset"; url: string }>;
  } | null;
};

export type NftCollectionsByNameSearchQueryVariables = Exact<{
  collectionNameSearch: Scalars["String"]["input"];
  chainId: Scalars["Int"]["input"];
}>;

export type NftCollectionsByNameSearchQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    currency?: Currencies | null;
    collectionHomePage?: string | null;
    collectionTitle?: string | null;
    collectionTwitterProfile?: string | null;
    collectionCategories: Array<CollectionCategories>;
    collectionRoyaltyFee?: number | null;
    collectionTotalSupply?: number | null;
    collectionContractAddress: string;
    collectionDescription?: string | null;
    collectionDiscordServer?: string | null;
    collectionInstagramProfile?: string | null;
    collectionMediumHome?: string | null;
    collectionListingDate?: any | null;
    chain?: {
      __typename?: "Chain";
      chainId?: number | null;
      chainName?: string | null;
    } | null;
    collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    collectionFeaturedImage: Array<{ __typename?: "Asset"; url: string }>;
  }>;
};

export type GetFooterNavigationQueryVariables = Exact<{
  applicationId?: InputMaybe<AppId>;
}>;

export type GetFooterNavigationQuery = {
  __typename?: "Query";
  menus: Array<{
    __typename?: "Menu";
    id: string;
    menuGroupName?: string | null;
    menuSection: MenuType;
    navigationItems: Array<{
      __typename?: "NavigationItem";
      iconComponent?: HeroMenuIcons | null;
      id: string;
      internalSlug?: string | null;
      navigationItemText?: string | null;
      externalLink?: string | null;
      destinationAppId: AppId;
    }>;
  }>;
};

export type GetHomePageVideoHeroesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetHomePageVideoHeroesQuery = {
  __typename?: "Query";
  homePageVideoHeroes: Array<{
    __typename?: "HomePageVideoHero";
    video: { __typename?: "Asset"; url: string };
    videoPosterImage: { __typename?: "Asset"; url: string };
  }>;
};

export type AllCollectionVolumeQueryVariables = Exact<{ [key: string]: never }>;

export type AllCollectionVolumeQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    id: string;
    collectionContractAddress: string;
    collectionDescription?: string | null;
    collectionTitle?: string | null;
    collectionTotalSupply?: number | null;
    currency?: Currencies | null;
    chain?: {
      __typename?: "Chain";
      chainName?: string | null;
      chainEnum?: ChainIDs | null;
      chainId?: number | null;
    } | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
  }>;
};

export type CollectionVolumeByNetworkQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CollectionVolumeByNetworkQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    collectionContractAddress: string;
    currency?: Currencies | null;
    collectionDescription?: string | null;
    collectionTitle?: string | null;
    collectionTotalSupply?: number | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    chain?: { __typename?: "Chain"; chainId?: number | null } | null;
  }>;
};

export type AllCollectionVolumePaginatedQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type AllCollectionVolumePaginatedQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    id: string;
    collectionContractAddress: string;
    collectionDescription?: string | null;
    collectionTitle?: string | null;
    collectionTotalSupply?: number | null;
    currency?: Currencies | null;
    chain?: {
      __typename?: "Chain";
      chainName?: string | null;
      chainEnum?: ChainIDs | null;
      chainId?: number | null;
    } | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
  }>;
};

export const GetAlphaMintingProjectsCarouselDocument = `
    query getAlphaMintingProjectsCarousel {
  mintingProjects(
    stage: PUBLISHED
    orderBy: mintDate_DESC
    where: {alphaSharesProject: true}
  ) {
    id
    slug
    currency
    featured
    securityLineItems {
      securityLineItem
      id
    }
    mintingStat {
      id
    }
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    paperCreditCardMintingLink
    maxMintPerTransaction
    mintingContractAddress
    mintDate
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    nftCollection {
      tradable
      currency
      chain {
        chainId
        chainName
        chainEnum
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
      collectionBannerImage {
        url
      }
    }
  }
}
    `;
export const useGetAlphaMintingProjectsCarouselQuery = <
  TData = GetAlphaMintingProjectsCarouselQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetAlphaMintingProjectsCarouselQueryVariables,
  options?: UseQueryOptions<
    GetAlphaMintingProjectsCarouselQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetAlphaMintingProjectsCarouselQuery, TError, TData>(
    variables === undefined
      ? ["getAlphaMintingProjectsCarousel"]
      : ["getAlphaMintingProjectsCarousel", variables],
    fetcher<
      GetAlphaMintingProjectsCarouselQuery,
      GetAlphaMintingProjectsCarouselQueryVariables
    >(client, GetAlphaMintingProjectsCarouselDocument, variables, headers),
    options,
  );

useGetAlphaMintingProjectsCarouselQuery.getKey = (
  variables?: GetAlphaMintingProjectsCarouselQueryVariables,
) =>
  variables === undefined
    ? ["getAlphaMintingProjectsCarousel"]
    : ["getAlphaMintingProjectsCarousel", variables];
export const useInfiniteGetAlphaMintingProjectsCarouselQuery = <
  TData = GetAlphaMintingProjectsCarouselQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetAlphaMintingProjectsCarouselQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetAlphaMintingProjectsCarouselQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetAlphaMintingProjectsCarouselQuery, TError, TData>(
    variables === undefined
      ? ["getAlphaMintingProjectsCarousel.infinite"]
      : ["getAlphaMintingProjectsCarousel.infinite", variables],
    (metaData) =>
      fetcher<
        GetAlphaMintingProjectsCarouselQuery,
        GetAlphaMintingProjectsCarouselQueryVariables
      >(
        client,
        GetAlphaMintingProjectsCarouselDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetAlphaMintingProjectsCarouselQuery.getKey = (
  variables?: GetAlphaMintingProjectsCarouselQueryVariables,
) =>
  variables === undefined
    ? ["getAlphaMintingProjectsCarousel.infinite"]
    : ["getAlphaMintingProjectsCarousel.infinite", variables];
export const BrandAmbassadorCtaDocument = `
    query brandAmbassadorCTA {
  ctaSection(where: {id: "cl83guu3i2f6y0ck2v3loyeg4"}) {
    backgroundFrom
    backgroundTo
    image {
      url
    }
    text {
      ... on ColoredText {
        id
        tag
        text
        textColor
      }
    }
    mainButton {
      buttonLink
      buttonText
      buttonVariant
      externalLink
      id
    }
  }
}
    `;
export const useBrandAmbassadorCtaQuery = <
  TData = BrandAmbassadorCtaQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: BrandAmbassadorCtaQueryVariables,
  options?: UseQueryOptions<BrandAmbassadorCtaQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<BrandAmbassadorCtaQuery, TError, TData>(
    variables === undefined
      ? ["brandAmbassadorCTA"]
      : ["brandAmbassadorCTA", variables],
    fetcher<BrandAmbassadorCtaQuery, BrandAmbassadorCtaQueryVariables>(
      client,
      BrandAmbassadorCtaDocument,
      variables,
      headers,
    ),
    options,
  );

useBrandAmbassadorCtaQuery.getKey = (
  variables?: BrandAmbassadorCtaQueryVariables,
) =>
  variables === undefined
    ? ["brandAmbassadorCTA"]
    : ["brandAmbassadorCTA", variables];
export const useInfiniteBrandAmbassadorCtaQuery = <
  TData = BrandAmbassadorCtaQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: BrandAmbassadorCtaQueryVariables,
  options?: UseInfiniteQueryOptions<BrandAmbassadorCtaQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<BrandAmbassadorCtaQuery, TError, TData>(
    variables === undefined
      ? ["brandAmbassadorCTA.infinite"]
      : ["brandAmbassadorCTA.infinite", variables],
    (metaData) =>
      fetcher<BrandAmbassadorCtaQuery, BrandAmbassadorCtaQueryVariables>(
        client,
        BrandAmbassadorCtaDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteBrandAmbassadorCtaQuery.getKey = (
  variables?: BrandAmbassadorCtaQueryVariables,
) =>
  variables === undefined
    ? ["brandAmbassadorCTA.infinite"]
    : ["brandAmbassadorCTA.infinite", variables];
export const FeaturedCollectionGroupDocument = `
    query featuredCollectionGroup($id: ID = "clrnu0zd7w0hp0bivbemr9pga") {
  featuredCollectionGroup(where: {id: $id}) {
    nftCollections {
      id
      collectionTitle
      collectionContractAddress
      collectionCategories
      collectionDescription
      collectionTotalSupply
      collectionListingDate
      collectionThumbnail {
        url
      }
      collectionBannerImage {
        url
      }
      chain {
        chainId
        chainSlug
      }
    }
  }
}
    `;
export const useFeaturedCollectionGroupQuery = <
  TData = FeaturedCollectionGroupQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: FeaturedCollectionGroupQueryVariables,
  options?: UseQueryOptions<FeaturedCollectionGroupQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<FeaturedCollectionGroupQuery, TError, TData>(
    variables === undefined
      ? ["featuredCollectionGroup"]
      : ["featuredCollectionGroup", variables],
    fetcher<
      FeaturedCollectionGroupQuery,
      FeaturedCollectionGroupQueryVariables
    >(client, FeaturedCollectionGroupDocument, variables, headers),
    options,
  );

useFeaturedCollectionGroupQuery.getKey = (
  variables?: FeaturedCollectionGroupQueryVariables,
) =>
  variables === undefined
    ? ["featuredCollectionGroup"]
    : ["featuredCollectionGroup", variables];
export const useInfiniteFeaturedCollectionGroupQuery = <
  TData = FeaturedCollectionGroupQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: FeaturedCollectionGroupQueryVariables,
  options?: UseInfiniteQueryOptions<
    FeaturedCollectionGroupQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<FeaturedCollectionGroupQuery, TError, TData>(
    variables === undefined
      ? ["featuredCollectionGroup.infinite"]
      : ["featuredCollectionGroup.infinite", variables],
    (metaData) =>
      fetcher<
        FeaturedCollectionGroupQuery,
        FeaturedCollectionGroupQueryVariables
      >(
        client,
        FeaturedCollectionGroupDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteFeaturedCollectionGroupQuery.getKey = (
  variables?: FeaturedCollectionGroupQueryVariables,
) =>
  variables === undefined
    ? ["featuredCollectionGroup.infinite"]
    : ["featuredCollectionGroup.infinite", variables];
export const GetCollectionsDocument = `
    query getCollections {
  nftCollections(where: {tradable: true}, first: 1000) {
    id
    collectionTitle
    collectionContractAddress
    collectionThumbnail {
      url
    }
  }
}
    `;
export const useGetCollectionsQuery = <
  TData = GetCollectionsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionsQueryVariables,
  options?: UseQueryOptions<GetCollectionsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetCollectionsQuery, TError, TData>(
    variables === undefined
      ? ["getCollections"]
      : ["getCollections", variables],
    fetcher<GetCollectionsQuery, GetCollectionsQueryVariables>(
      client,
      GetCollectionsDocument,
      variables,
      headers,
    ),
    options,
  );

useGetCollectionsQuery.getKey = (variables?: GetCollectionsQueryVariables) =>
  variables === undefined ? ["getCollections"] : ["getCollections", variables];
export const useInfiniteGetCollectionsQuery = <
  TData = GetCollectionsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionsQueryVariables,
  options?: UseInfiniteQueryOptions<GetCollectionsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetCollectionsQuery, TError, TData>(
    variables === undefined
      ? ["getCollections.infinite"]
      : ["getCollections.infinite", variables],
    (metaData) =>
      fetcher<GetCollectionsQuery, GetCollectionsQueryVariables>(
        client,
        GetCollectionsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetCollectionsQuery.getKey = (
  variables?: GetCollectionsQueryVariables,
) =>
  variables === undefined
    ? ["getCollections.infinite"]
    : ["getCollections.infinite", variables];
export const GetCollectionsByCategoryDocument = `
    query getCollectionsByCategory($collectionCategories_contains_all: [CollectionCategories!]) {
  nftCollections(
    where: {collectionCategories_contains_all: $collectionCategories_contains_all, tradable: true}
  ) {
    id
    collectionTitle
    collectionContractAddress
    collectionCategories
    collectionDescription
    collectionThumbnail {
      url
    }
    collectionListingDate
  }
}
    `;
export const useGetCollectionsByCategoryQuery = <
  TData = GetCollectionsByCategoryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionsByCategoryQueryVariables,
  options?: UseQueryOptions<GetCollectionsByCategoryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetCollectionsByCategoryQuery, TError, TData>(
    variables === undefined
      ? ["getCollectionsByCategory"]
      : ["getCollectionsByCategory", variables],
    fetcher<
      GetCollectionsByCategoryQuery,
      GetCollectionsByCategoryQueryVariables
    >(client, GetCollectionsByCategoryDocument, variables, headers),
    options,
  );

useGetCollectionsByCategoryQuery.getKey = (
  variables?: GetCollectionsByCategoryQueryVariables,
) =>
  variables === undefined
    ? ["getCollectionsByCategory"]
    : ["getCollectionsByCategory", variables];
export const useInfiniteGetCollectionsByCategoryQuery = <
  TData = GetCollectionsByCategoryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionsByCategoryQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetCollectionsByCategoryQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetCollectionsByCategoryQuery, TError, TData>(
    variables === undefined
      ? ["getCollectionsByCategory.infinite"]
      : ["getCollectionsByCategory.infinite", variables],
    (metaData) =>
      fetcher<
        GetCollectionsByCategoryQuery,
        GetCollectionsByCategoryQueryVariables
      >(
        client,
        GetCollectionsByCategoryDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetCollectionsByCategoryQuery.getKey = (
  variables?: GetCollectionsByCategoryQueryVariables,
) =>
  variables === undefined
    ? ["getCollectionsByCategory.infinite"]
    : ["getCollectionsByCategory.infinite", variables];
export const GetHeroCollectionsByCategoryDocument = `
    query getHeroCollectionsByCategory($collectionCategories_contains_all: [CollectionCategories!]) {
  nftCollections(
    where: {collectionCategories_contains_all: $collectionCategories_contains_all}
  ) {
    id
    collectionTitle
    collectionContractAddress
    collectionCategories
    collectionDescription
    collectionTotalSupply
    collectionListingDate
    collectionThumbnail {
      url
    }
    collectionBannerImage {
      url
    }
    chain {
      chainId
      chainSlug
    }
  }
}
    `;
export const useGetHeroCollectionsByCategoryQuery = <
  TData = GetHeroCollectionsByCategoryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetHeroCollectionsByCategoryQueryVariables,
  options?: UseQueryOptions<GetHeroCollectionsByCategoryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetHeroCollectionsByCategoryQuery, TError, TData>(
    variables === undefined
      ? ["getHeroCollectionsByCategory"]
      : ["getHeroCollectionsByCategory", variables],
    fetcher<
      GetHeroCollectionsByCategoryQuery,
      GetHeroCollectionsByCategoryQueryVariables
    >(client, GetHeroCollectionsByCategoryDocument, variables, headers),
    options,
  );

useGetHeroCollectionsByCategoryQuery.getKey = (
  variables?: GetHeroCollectionsByCategoryQueryVariables,
) =>
  variables === undefined
    ? ["getHeroCollectionsByCategory"]
    : ["getHeroCollectionsByCategory", variables];
export const useInfiniteGetHeroCollectionsByCategoryQuery = <
  TData = GetHeroCollectionsByCategoryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetHeroCollectionsByCategoryQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetHeroCollectionsByCategoryQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetHeroCollectionsByCategoryQuery, TError, TData>(
    variables === undefined
      ? ["getHeroCollectionsByCategory.infinite"]
      : ["getHeroCollectionsByCategory.infinite", variables],
    (metaData) =>
      fetcher<
        GetHeroCollectionsByCategoryQuery,
        GetHeroCollectionsByCategoryQueryVariables
      >(
        client,
        GetHeroCollectionsByCategoryDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetHeroCollectionsByCategoryQuery.getKey = (
  variables?: GetHeroCollectionsByCategoryQueryVariables,
) =>
  variables === undefined
    ? ["getHeroCollectionsByCategory.infinite"]
    : ["getHeroCollectionsByCategory.infinite", variables];
export const GetCollectionCreatorBySlugDocument = `
    query GetCollectionCreatorBySlug($collectionCreatorSlug: String = "") {
  collectionCreator(where: {collectionCreatorSlug: $collectionCreatorSlug}) {
    collectionCreatorBio
    collectionCreatorName
    collectionCreatorSlug
    collectionCreatorUrl
    collectionCreatorThumbnailImage {
      url
    }
    collectionCreatorBannerImage {
      url
    }
    categories
    nftCollections {
      id
      collectionContractAddress
      collectionCategories
      collectionDescription
      collectionTitle
      collectionBannerImage {
        url
      }
      collectionThumbnail {
        url
      }
      chain {
        chainId
        chainName
        chainEnum
      }
      collectionTotalSupply
      collectionFeaturedImage {
        url
      }
    }
  }
}
    `;
export const useGetCollectionCreatorBySlugQuery = <
  TData = GetCollectionCreatorBySlugQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionCreatorBySlugQueryVariables,
  options?: UseQueryOptions<GetCollectionCreatorBySlugQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetCollectionCreatorBySlugQuery, TError, TData>(
    variables === undefined
      ? ["GetCollectionCreatorBySlug"]
      : ["GetCollectionCreatorBySlug", variables],
    fetcher<
      GetCollectionCreatorBySlugQuery,
      GetCollectionCreatorBySlugQueryVariables
    >(client, GetCollectionCreatorBySlugDocument, variables, headers),
    options,
  );

useGetCollectionCreatorBySlugQuery.getKey = (
  variables?: GetCollectionCreatorBySlugQueryVariables,
) =>
  variables === undefined
    ? ["GetCollectionCreatorBySlug"]
    : ["GetCollectionCreatorBySlug", variables];
export const useInfiniteGetCollectionCreatorBySlugQuery = <
  TData = GetCollectionCreatorBySlugQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionCreatorBySlugQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetCollectionCreatorBySlugQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetCollectionCreatorBySlugQuery, TError, TData>(
    variables === undefined
      ? ["GetCollectionCreatorBySlug.infinite"]
      : ["GetCollectionCreatorBySlug.infinite", variables],
    (metaData) =>
      fetcher<
        GetCollectionCreatorBySlugQuery,
        GetCollectionCreatorBySlugQueryVariables
      >(
        client,
        GetCollectionCreatorBySlugDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetCollectionCreatorBySlugQuery.getKey = (
  variables?: GetCollectionCreatorBySlugQueryVariables,
) =>
  variables === undefined
    ? ["GetCollectionCreatorBySlug.infinite"]
    : ["GetCollectionCreatorBySlug.infinite", variables];
export const CollectionCreatorsDocument = `
    query CollectionCreators {
  collectionCreators {
    collectionCreatorBio
    collectionCreatorName
    collectionCreatorSlug
    collectionCreatorUrl
    collectionCreatorThumbnailImage {
      url
    }
    collectionCreatorBannerImage {
      url
    }
    categories
  }
}
    `;
export const useCollectionCreatorsQuery = <
  TData = CollectionCreatorsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: CollectionCreatorsQueryVariables,
  options?: UseQueryOptions<CollectionCreatorsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<CollectionCreatorsQuery, TError, TData>(
    variables === undefined
      ? ["CollectionCreators"]
      : ["CollectionCreators", variables],
    fetcher<CollectionCreatorsQuery, CollectionCreatorsQueryVariables>(
      client,
      CollectionCreatorsDocument,
      variables,
      headers,
    ),
    options,
  );

useCollectionCreatorsQuery.getKey = (
  variables?: CollectionCreatorsQueryVariables,
) =>
  variables === undefined
    ? ["CollectionCreators"]
    : ["CollectionCreators", variables];
export const useInfiniteCollectionCreatorsQuery = <
  TData = CollectionCreatorsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: CollectionCreatorsQueryVariables,
  options?: UseInfiniteQueryOptions<CollectionCreatorsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<CollectionCreatorsQuery, TError, TData>(
    variables === undefined
      ? ["CollectionCreators.infinite"]
      : ["CollectionCreators.infinite", variables],
    (metaData) =>
      fetcher<CollectionCreatorsQuery, CollectionCreatorsQueryVariables>(
        client,
        CollectionCreatorsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteCollectionCreatorsQuery.getKey = (
  variables?: CollectionCreatorsQueryVariables,
) =>
  variables === undefined
    ? ["CollectionCreators.infinite"]
    : ["CollectionCreators.infinite", variables];
export const FeaturedCreatorGroupDocument = `
    query featuredCreatorGroup($id: ID = "clrnt8dugvvn10ait5xxwtrn1") {
  featuredCreatorGroup(where: {id: $id}) {
    collectionCreators {
      collectionCreatorBannerImage {
        url
      }
      categories
      collectionCreatorBio
      collectionCreatorName
      collectionCreatorSlug
      collectionCreatorUrl
      collectionCreatorThumbnailImage {
        url
      }
    }
  }
}
    `;
export const useFeaturedCreatorGroupQuery = <
  TData = FeaturedCreatorGroupQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: FeaturedCreatorGroupQueryVariables,
  options?: UseQueryOptions<FeaturedCreatorGroupQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<FeaturedCreatorGroupQuery, TError, TData>(
    variables === undefined
      ? ["featuredCreatorGroup"]
      : ["featuredCreatorGroup", variables],
    fetcher<FeaturedCreatorGroupQuery, FeaturedCreatorGroupQueryVariables>(
      client,
      FeaturedCreatorGroupDocument,
      variables,
      headers,
    ),
    options,
  );

useFeaturedCreatorGroupQuery.getKey = (
  variables?: FeaturedCreatorGroupQueryVariables,
) =>
  variables === undefined
    ? ["featuredCreatorGroup"]
    : ["featuredCreatorGroup", variables];
export const useInfiniteFeaturedCreatorGroupQuery = <
  TData = FeaturedCreatorGroupQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: FeaturedCreatorGroupQueryVariables,
  options?: UseInfiniteQueryOptions<FeaturedCreatorGroupQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<FeaturedCreatorGroupQuery, TError, TData>(
    variables === undefined
      ? ["featuredCreatorGroup.infinite"]
      : ["featuredCreatorGroup.infinite", variables],
    (metaData) =>
      fetcher<FeaturedCreatorGroupQuery, FeaturedCreatorGroupQueryVariables>(
        client,
        FeaturedCreatorGroupDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteFeaturedCreatorGroupQuery.getKey = (
  variables?: FeaturedCreatorGroupQueryVariables,
) =>
  variables === undefined
    ? ["featuredCreatorGroup.infinite"]
    : ["featuredCreatorGroup.infinite", variables];
export const GetNftCollectionAddressesDocument = `
    query getNFTCollectionAddresses {
  nftCollections(where: {tradable: true}) {
    collectionContractAddress
  }
}
    `;
export const useGetNftCollectionAddressesQuery = <
  TData = GetNftCollectionAddressesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetNftCollectionAddressesQueryVariables,
  options?: UseQueryOptions<GetNftCollectionAddressesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetNftCollectionAddressesQuery, TError, TData>(
    variables === undefined
      ? ["getNFTCollectionAddresses"]
      : ["getNFTCollectionAddresses", variables],
    fetcher<
      GetNftCollectionAddressesQuery,
      GetNftCollectionAddressesQueryVariables
    >(client, GetNftCollectionAddressesDocument, variables, headers),
    options,
  );

useGetNftCollectionAddressesQuery.getKey = (
  variables?: GetNftCollectionAddressesQueryVariables,
) =>
  variables === undefined
    ? ["getNFTCollectionAddresses"]
    : ["getNFTCollectionAddresses", variables];
export const useInfiniteGetNftCollectionAddressesQuery = <
  TData = GetNftCollectionAddressesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetNftCollectionAddressesQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetNftCollectionAddressesQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetNftCollectionAddressesQuery, TError, TData>(
    variables === undefined
      ? ["getNFTCollectionAddresses.infinite"]
      : ["getNFTCollectionAddresses.infinite", variables],
    (metaData) =>
      fetcher<
        GetNftCollectionAddressesQuery,
        GetNftCollectionAddressesQueryVariables
      >(
        client,
        GetNftCollectionAddressesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetNftCollectionAddressesQuery.getKey = (
  variables?: GetNftCollectionAddressesQueryVariables,
) =>
  variables === undefined
    ? ["getNFTCollectionAddresses.infinite"]
    : ["getNFTCollectionAddresses.infinite", variables];
export const GetSidebarNavigationDocument = `
    query getSidebarNavigation($applicationId: AppId = MarketplaceNext) {
  menus(where: {applicationId: $applicationId}) {
    id
    navigationItems {
      iconComponent
      id
      internalSlug
      navigationItemText
      externalLink
      destinationAppId
    }
    menuGroupName
    menuSection
  }
  menu(where: {id: "clq2n9klmuzsm0ak401uwbbqw"}) {
    id
    menuGroupName
    menuSection
    navigationItems {
      externalLink
      iconComponent
      id
      internalSlug
      navigationItemText
      destinationAppId
    }
  }
}
    `;
export const useGetSidebarNavigationQuery = <
  TData = GetSidebarNavigationQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetSidebarNavigationQueryVariables,
  options?: UseQueryOptions<GetSidebarNavigationQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetSidebarNavigationQuery, TError, TData>(
    variables === undefined
      ? ["getSidebarNavigation"]
      : ["getSidebarNavigation", variables],
    fetcher<GetSidebarNavigationQuery, GetSidebarNavigationQueryVariables>(
      client,
      GetSidebarNavigationDocument,
      variables,
      headers,
    ),
    options,
  );

useGetSidebarNavigationQuery.getKey = (
  variables?: GetSidebarNavigationQueryVariables,
) =>
  variables === undefined
    ? ["getSidebarNavigation"]
    : ["getSidebarNavigation", variables];
export const useInfiniteGetSidebarNavigationQuery = <
  TData = GetSidebarNavigationQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetSidebarNavigationQueryVariables,
  options?: UseInfiniteQueryOptions<GetSidebarNavigationQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetSidebarNavigationQuery, TError, TData>(
    variables === undefined
      ? ["getSidebarNavigation.infinite"]
      : ["getSidebarNavigation.infinite", variables],
    (metaData) =>
      fetcher<GetSidebarNavigationQuery, GetSidebarNavigationQueryVariables>(
        client,
        GetSidebarNavigationDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetSidebarNavigationQuery.getKey = (
  variables?: GetSidebarNavigationQueryVariables,
) =>
  variables === undefined
    ? ["getSidebarNavigation.infinite"]
    : ["getSidebarNavigation.infinite", variables];
export const HelpCenterDocument = `
    query HelpCenter {
  page(where: {pageTitle: "Help Center"}) {
    pageTitle
    pageSlug
    fAQSections {
      id
      heading
      questions {
        question
        answer
        id
      }
    }
  }
}
    `;
export const useHelpCenterQuery = <TData = HelpCenterQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: HelpCenterQueryVariables,
  options?: UseQueryOptions<HelpCenterQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<HelpCenterQuery, TError, TData>(
    variables === undefined ? ["HelpCenter"] : ["HelpCenter", variables],
    fetcher<HelpCenterQuery, HelpCenterQueryVariables>(
      client,
      HelpCenterDocument,
      variables,
      headers,
    ),
    options,
  );

useHelpCenterQuery.getKey = (variables?: HelpCenterQueryVariables) =>
  variables === undefined ? ["HelpCenter"] : ["HelpCenter", variables];
export const useInfiniteHelpCenterQuery = <
  TData = HelpCenterQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: HelpCenterQueryVariables,
  options?: UseInfiniteQueryOptions<HelpCenterQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<HelpCenterQuery, TError, TData>(
    variables === undefined
      ? ["HelpCenter.infinite"]
      : ["HelpCenter.infinite", variables],
    (metaData) =>
      fetcher<HelpCenterQuery, HelpCenterQueryVariables>(
        client,
        HelpCenterDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteHelpCenterQuery.getKey = (variables?: HelpCenterQueryVariables) =>
  variables === undefined
    ? ["HelpCenter.infinite"]
    : ["HelpCenter.infinite", variables];
export const HomePageDocument = `
    query homePage {
  page(where: {pageTitle: "Home Page"}) {
    pageTitle
    pageSlug
    ctaSection {
      backgroundFrom
      backgroundTo
      image {
        url
      }
      text {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      mainButton {
        buttonLink
        buttonText
        buttonVariant
        externalLink
        id
      }
    }
    fAQSection {
      heading
      questions {
        question
        answer
        id
      }
    }
  }
}
    `;
export const useHomePageQuery = <TData = HomePageQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: HomePageQueryVariables,
  options?: UseQueryOptions<HomePageQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<HomePageQuery, TError, TData>(
    variables === undefined ? ["homePage"] : ["homePage", variables],
    fetcher<HomePageQuery, HomePageQueryVariables>(
      client,
      HomePageDocument,
      variables,
      headers,
    ),
    options,
  );

useHomePageQuery.getKey = (variables?: HomePageQueryVariables) =>
  variables === undefined ? ["homePage"] : ["homePage", variables];
export const useInfiniteHomePageQuery = <
  TData = HomePageQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: HomePageQueryVariables,
  options?: UseInfiniteQueryOptions<HomePageQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<HomePageQuery, TError, TData>(
    variables === undefined
      ? ["homePage.infinite"]
      : ["homePage.infinite", variables],
    (metaData) =>
      fetcher<HomePageQuery, HomePageQueryVariables>(
        client,
        HomePageDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteHomePageQuery.getKey = (variables?: HomePageQueryVariables) =>
  variables === undefined
    ? ["homePage.infinite"]
    : ["homePage.infinite", variables];
export const MetaFuseProjectsWithRevealOnMintDocument = `
    query MetaFuseProjectsWithRevealOnMint {
  mintingProjects(where: {shouldMetafuseRevealOnMint: true}) {
    metafuseProjectId
    shouldMetafuseRevealOnMint
    maxMintPerTransaction
    nftCollection {
      collectionContractAddress
      collectionTotalSupply
      chain {
        chainId
        chainEnum
        chainName
      }
    }
  }
}
    `;
export const useMetaFuseProjectsWithRevealOnMintQuery = <
  TData = MetaFuseProjectsWithRevealOnMintQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MetaFuseProjectsWithRevealOnMintQueryVariables,
  options?: UseQueryOptions<
    MetaFuseProjectsWithRevealOnMintQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useQuery<MetaFuseProjectsWithRevealOnMintQuery, TError, TData>(
    variables === undefined
      ? ["MetaFuseProjectsWithRevealOnMint"]
      : ["MetaFuseProjectsWithRevealOnMint", variables],
    fetcher<
      MetaFuseProjectsWithRevealOnMintQuery,
      MetaFuseProjectsWithRevealOnMintQueryVariables
    >(client, MetaFuseProjectsWithRevealOnMintDocument, variables, headers),
    options,
  );

useMetaFuseProjectsWithRevealOnMintQuery.getKey = (
  variables?: MetaFuseProjectsWithRevealOnMintQueryVariables,
) =>
  variables === undefined
    ? ["MetaFuseProjectsWithRevealOnMint"]
    : ["MetaFuseProjectsWithRevealOnMint", variables];
export const useInfiniteMetaFuseProjectsWithRevealOnMintQuery = <
  TData = MetaFuseProjectsWithRevealOnMintQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MetaFuseProjectsWithRevealOnMintQueryVariables,
  options?: UseInfiniteQueryOptions<
    MetaFuseProjectsWithRevealOnMintQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<MetaFuseProjectsWithRevealOnMintQuery, TError, TData>(
    variables === undefined
      ? ["MetaFuseProjectsWithRevealOnMint.infinite"]
      : ["MetaFuseProjectsWithRevealOnMint.infinite", variables],
    (metaData) =>
      fetcher<
        MetaFuseProjectsWithRevealOnMintQuery,
        MetaFuseProjectsWithRevealOnMintQueryVariables
      >(
        client,
        MetaFuseProjectsWithRevealOnMintDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteMetaFuseProjectsWithRevealOnMintQuery.getKey = (
  variables?: MetaFuseProjectsWithRevealOnMintQueryVariables,
) =>
  variables === undefined
    ? ["MetaFuseProjectsWithRevealOnMint.infinite"]
    : ["MetaFuseProjectsWithRevealOnMint.infinite", variables];
export const GetAllMintingProjectsDocument = `
    query getAllMintingProjects {
  mintingProjects(
    stage: PUBLISHED
    orderBy: mintDate_DESC
    where: {featured: true, AND: {soldOut: false}}
  ) {
    id
    slug
    currency
    featured
    securityLineItems {
      securityLineItem
      id
    }
    mintingStat {
      id
    }
    mintingCurrencyInformation {
      tokenIcon {
        url
      }
    }
    soldOut
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    maxMintPerTransaction
    mintingContractAddress
    paperCreditCardMintingLink
    mintDate
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    nftCollection {
      tradable
      currency
      chain {
        chainId
        chainName
        chainEnum
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
      collectionBannerImage {
        url
      }
    }
  }
}
    `;
export const useGetAllMintingProjectsQuery = <
  TData = GetAllMintingProjectsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetAllMintingProjectsQueryVariables,
  options?: UseQueryOptions<GetAllMintingProjectsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetAllMintingProjectsQuery, TError, TData>(
    variables === undefined
      ? ["getAllMintingProjects"]
      : ["getAllMintingProjects", variables],
    fetcher<GetAllMintingProjectsQuery, GetAllMintingProjectsQueryVariables>(
      client,
      GetAllMintingProjectsDocument,
      variables,
      headers,
    ),
    options,
  );

useGetAllMintingProjectsQuery.getKey = (
  variables?: GetAllMintingProjectsQueryVariables,
) =>
  variables === undefined
    ? ["getAllMintingProjects"]
    : ["getAllMintingProjects", variables];
export const useInfiniteGetAllMintingProjectsQuery = <
  TData = GetAllMintingProjectsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetAllMintingProjectsQueryVariables,
  options?: UseInfiniteQueryOptions<GetAllMintingProjectsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetAllMintingProjectsQuery, TError, TData>(
    variables === undefined
      ? ["getAllMintingProjects.infinite"]
      : ["getAllMintingProjects.infinite", variables],
    (metaData) =>
      fetcher<GetAllMintingProjectsQuery, GetAllMintingProjectsQueryVariables>(
        client,
        GetAllMintingProjectsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetAllMintingProjectsQuery.getKey = (
  variables?: GetAllMintingProjectsQueryVariables,
) =>
  variables === undefined
    ? ["getAllMintingProjects.infinite"]
    : ["getAllMintingProjects.infinite", variables];
export const GetMintingProjectByIdDocument = `
    query getMintingProjectById($id: ID!) {
  mintingProject(where: {id: $id}) {
    id
    slug
    presaleHeroSectionCopy
    metafuseProjectId
    shouldMetafuseRevealOnMint
    hasDynamicPricing
    dynamicPricingGetterFunction
    allocation
    paperCreditCardMintingLink
    mintingContractAbi
    presaleCheckerFunction
    priceQueryFunctionName
    presalePriceGetter
    presaleMintingFunctionName
    mintingFunctionName
    mintingCurrencyDecimals
    presaleMaxMintGetter
    publicSaleMaxMintGetter
    priceQueryRequiresAccount
    currency
    mintDate
    soldOut
    fAQSection {
      heading
      questions {
        question
        id
        answer
      }
    }
    securityLineItems {
      securityLineItem
      id
    }
    ctaSection {
      backgroundFrom
      backgroundTo
      image {
        url
      }
      text {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      mainButton {
        buttonLink
        buttonText
        buttonVariant
        externalLink
        id
      }
    }
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    maxMintPerTransaction
    mintingContractAbi
    mintingContractAddress
    whitelistChecker
    mintDate
    mintInfoTable {
      name
      value
      id
    }
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    collectionSampleImages {
      url
    }
    collectionCreator {
      collectionCreatorBio
      collectionCreatorName
      collectionCreatorUrl
    }
    nftCollection {
      currency
      tradable
      chain {
        chainId
        chainName
        chainEnum
        chainSlug
      }
      collectionBannerImage {
        url
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
    }
    aboutSectionHeader
    aboutSectionText
    aboutSectionAccordions {
      answer
      id
      question
    }
    mintedNfTsCarouselHeader
    mintedNfTsCarouselSubHeader
    mintingStat {
      heading
      subHeading
      backgroundImage {
        url
      }
      button {
        buttonText
        buttonLink
        buttonVariant
        externalLink
      }
      feesInformationText
      flexibleText {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      heroImage {
        url
      }
      heroImageLeft {
        url
      }
      inProgressText
      mintedCountText
    }
  }
}
    `;
export const useGetMintingProjectByIdQuery = <
  TData = GetMintingProjectByIdQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetMintingProjectByIdQueryVariables,
  options?: UseQueryOptions<GetMintingProjectByIdQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetMintingProjectByIdQuery, TError, TData>(
    ["getMintingProjectById", variables],
    fetcher<GetMintingProjectByIdQuery, GetMintingProjectByIdQueryVariables>(
      client,
      GetMintingProjectByIdDocument,
      variables,
      headers,
    ),
    options,
  );

useGetMintingProjectByIdQuery.getKey = (
  variables: GetMintingProjectByIdQueryVariables,
) => ["getMintingProjectById", variables];
export const useInfiniteGetMintingProjectByIdQuery = <
  TData = GetMintingProjectByIdQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetMintingProjectByIdQueryVariables,
  options?: UseInfiniteQueryOptions<GetMintingProjectByIdQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetMintingProjectByIdQuery, TError, TData>(
    ["getMintingProjectById.infinite", variables],
    (metaData) =>
      fetcher<GetMintingProjectByIdQuery, GetMintingProjectByIdQueryVariables>(
        client,
        GetMintingProjectByIdDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetMintingProjectByIdQuery.getKey = (
  variables: GetMintingProjectByIdQueryVariables,
) => ["getMintingProjectById.infinite", variables];
export const GetMintingProjectBySlugDocument = `
    query getMintingProjectBySlug($slug: String!) {
  mintingProject(where: {slug: $slug}) {
    presaleHeroSectionCopy
    metafuseProjectId
    shouldMetafuseRevealOnMint
    hasDynamicPricing
    dynamicPricingGetterFunction
    allocation
    paperCreditCardMintingLink
    mintingContractAbi
    presaleCheckerFunction
    priceQueryFunctionName
    presalePriceGetter
    presaleMintingFunctionName
    mintingFunctionName
    mintingCurrencyDecimals
    presaleMaxMintGetter
    publicSaleMaxMintGetter
    priceQueryRequiresAccount
    currency
    mintDate
    soldOut
    fAQSection {
      heading
      questions {
        question
        id
        answer
      }
    }
    securityLineItems {
      securityLineItem
      id
    }
    ctaSection {
      backgroundFrom
      backgroundTo
      image {
        url
      }
      text {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      mainButton {
        buttonLink
        buttonText
        buttonVariant
        externalLink
        id
      }
    }
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    maxMintPerTransaction
    mintingContractAbi
    mintingContractAddress
    whitelistChecker
    mintDate
    mintInfoTable {
      name
      value
      id
    }
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    collectionSampleImages {
      url
    }
    collectionCreator {
      collectionCreatorBio
      collectionCreatorName
      collectionCreatorUrl
    }
    nftCollection {
      currency
      tradable
      chain {
        chainId
        chainName
        chainEnum
        chainSlug
      }
      collectionBannerImage {
        url
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
    }
    aboutSectionHeader
    aboutSectionText
    aboutSectionAccordions {
      answer
      id
      question
    }
    mintedNfTsCarouselHeader
    mintedNfTsCarouselSubHeader
    mintingStat {
      heading
      subHeading
      backgroundImage {
        url
      }
      button {
        buttonText
        buttonLink
        buttonVariant
        externalLink
      }
      feesInformationText
      flexibleText {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      heroImage {
        url
      }
      heroImageLeft {
        url
      }
      inProgressText
      mintedCountText
    }
  }
}
    `;
export const useGetMintingProjectBySlugQuery = <
  TData = GetMintingProjectBySlugQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetMintingProjectBySlugQueryVariables,
  options?: UseQueryOptions<GetMintingProjectBySlugQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetMintingProjectBySlugQuery, TError, TData>(
    ["getMintingProjectBySlug", variables],
    fetcher<
      GetMintingProjectBySlugQuery,
      GetMintingProjectBySlugQueryVariables
    >(client, GetMintingProjectBySlugDocument, variables, headers),
    options,
  );

useGetMintingProjectBySlugQuery.getKey = (
  variables: GetMintingProjectBySlugQueryVariables,
) => ["getMintingProjectBySlug", variables];
export const useInfiniteGetMintingProjectBySlugQuery = <
  TData = GetMintingProjectBySlugQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetMintingProjectBySlugQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetMintingProjectBySlugQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetMintingProjectBySlugQuery, TError, TData>(
    ["getMintingProjectBySlug.infinite", variables],
    (metaData) =>
      fetcher<
        GetMintingProjectBySlugQuery,
        GetMintingProjectBySlugQueryVariables
      >(
        client,
        GetMintingProjectBySlugDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetMintingProjectBySlugQuery.getKey = (
  variables: GetMintingProjectBySlugQueryVariables,
) => ["getMintingProjectBySlug.infinite", variables];
export const GetMintingProjectSlugsDocument = `
    query getMintingProjectSlugs {
  mintingProjects(stage: PUBLISHED) {
    slug
  }
}
    `;
export const useGetMintingProjectSlugsQuery = <
  TData = GetMintingProjectSlugsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectSlugsQueryVariables,
  options?: UseQueryOptions<GetMintingProjectSlugsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetMintingProjectSlugsQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjectSlugs"]
      : ["getMintingProjectSlugs", variables],
    fetcher<GetMintingProjectSlugsQuery, GetMintingProjectSlugsQueryVariables>(
      client,
      GetMintingProjectSlugsDocument,
      variables,
      headers,
    ),
    options,
  );

useGetMintingProjectSlugsQuery.getKey = (
  variables?: GetMintingProjectSlugsQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjectSlugs"]
    : ["getMintingProjectSlugs", variables];
export const useInfiniteGetMintingProjectSlugsQuery = <
  TData = GetMintingProjectSlugsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectSlugsQueryVariables,
  options?: UseInfiniteQueryOptions<GetMintingProjectSlugsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetMintingProjectSlugsQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjectSlugs.infinite"]
      : ["getMintingProjectSlugs.infinite", variables],
    (metaData) =>
      fetcher<
        GetMintingProjectSlugsQuery,
        GetMintingProjectSlugsQueryVariables
      >(
        client,
        GetMintingProjectSlugsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetMintingProjectSlugsQuery.getKey = (
  variables?: GetMintingProjectSlugsQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjectSlugs.infinite"]
    : ["getMintingProjectSlugs.infinite", variables];
export const GetMintingProjectsDocument = `
    query getMintingProjects($chainEnum: ChainIDs = AVAX) {
  mintingProjects(
    stage: PUBLISHED
    orderBy: mintDate_DESC
    where: {nftCollection: {chain: {chainEnum: $chainEnum}}}
  ) {
    id
    slug
    currency
    featured
    securityLineItems {
      securityLineItem
      id
    }
    mintingStat {
      id
    }
    soldOut
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    maxMintPerTransaction
    mintingContractAddress
    paperCreditCardMintingLink
    mintDate
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    nftCollection {
      tradable
      currency
      chain {
        chainId
        chainName
        chainEnum
        chainSlug
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
      collectionBannerImage {
        url
      }
    }
  }
}
    `;
export const useGetMintingProjectsQuery = <
  TData = GetMintingProjectsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectsQueryVariables,
  options?: UseQueryOptions<GetMintingProjectsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetMintingProjectsQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjects"]
      : ["getMintingProjects", variables],
    fetcher<GetMintingProjectsQuery, GetMintingProjectsQueryVariables>(
      client,
      GetMintingProjectsDocument,
      variables,
      headers,
    ),
    options,
  );

useGetMintingProjectsQuery.getKey = (
  variables?: GetMintingProjectsQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjects"]
    : ["getMintingProjects", variables];
export const useInfiniteGetMintingProjectsQuery = <
  TData = GetMintingProjectsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectsQueryVariables,
  options?: UseInfiniteQueryOptions<GetMintingProjectsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetMintingProjectsQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjects.infinite"]
      : ["getMintingProjects.infinite", variables],
    (metaData) =>
      fetcher<GetMintingProjectsQuery, GetMintingProjectsQueryVariables>(
        client,
        GetMintingProjectsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetMintingProjectsQuery.getKey = (
  variables?: GetMintingProjectsQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjects.infinite"]
    : ["getMintingProjects.infinite", variables];
export const GetMintingProjectsCarouselDocument = `
    query getMintingProjectsCarousel {
  mintingProjects(
    stage: PUBLISHED
    orderBy: mintDate_DESC
    where: {alphaSharesProject: false}
  ) {
    id
    slug
    currency
    featured
    securityLineItems {
      securityLineItem
      id
    }
    mintingStat {
      id
    }
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    paperCreditCardMintingLink
    maxMintPerTransaction
    mintingContractAddress
    mintDate
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    nftCollection {
      tradable
      currency
      chain {
        chainId
        chainName
        chainEnum
        chainSlug
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
      collectionBannerImage {
        url
      }
    }
  }
}
    `;
export const useGetMintingProjectsCarouselQuery = <
  TData = GetMintingProjectsCarouselQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectsCarouselQueryVariables,
  options?: UseQueryOptions<GetMintingProjectsCarouselQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetMintingProjectsCarouselQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjectsCarousel"]
      : ["getMintingProjectsCarousel", variables],
    fetcher<
      GetMintingProjectsCarouselQuery,
      GetMintingProjectsCarouselQueryVariables
    >(client, GetMintingProjectsCarouselDocument, variables, headers),
    options,
  );

useGetMintingProjectsCarouselQuery.getKey = (
  variables?: GetMintingProjectsCarouselQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjectsCarousel"]
    : ["getMintingProjectsCarousel", variables];
export const useInfiniteGetMintingProjectsCarouselQuery = <
  TData = GetMintingProjectsCarouselQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectsCarouselQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetMintingProjectsCarouselQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetMintingProjectsCarouselQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjectsCarousel.infinite"]
      : ["getMintingProjectsCarousel.infinite", variables],
    (metaData) =>
      fetcher<
        GetMintingProjectsCarouselQuery,
        GetMintingProjectsCarouselQueryVariables
      >(
        client,
        GetMintingProjectsCarouselDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetMintingProjectsCarouselQuery.getKey = (
  variables?: GetMintingProjectsCarouselQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjectsCarousel.infinite"]
    : ["getMintingProjectsCarousel.infinite", variables];
export const NftCollectionByAddressDocument = `
    query nftCollectionByAddress($collectionContractAddress: String!) {
  nftCollection(where: {collectionContractAddress: $collectionContractAddress}) {
    currency
    collectionHomePage
    collectionTitle
    collectionTwitterProfile
    collectionCategories
    collectionRoyaltyFee
    collectionRoyaltyRecipientAddress
    collectionTotalSupply
    collectionContractAddress
    collectionDescription
    collectionDiscordServer
    collectionInstagramProfile
    collectionMediumHome
    collectionListingDate
    chain {
      chainId
      chainName
      chainSlug
    }
    collectionBannerImage {
      url
    }
    collectionThumbnail {
      url
    }
    collectionFeaturedImage {
      url
    }
  }
}
    `;
export const useNftCollectionByAddressQuery = <
  TData = NftCollectionByAddressQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: NftCollectionByAddressQueryVariables,
  options?: UseQueryOptions<NftCollectionByAddressQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<NftCollectionByAddressQuery, TError, TData>(
    ["nftCollectionByAddress", variables],
    fetcher<NftCollectionByAddressQuery, NftCollectionByAddressQueryVariables>(
      client,
      NftCollectionByAddressDocument,
      variables,
      headers,
    ),
    options,
  );

useNftCollectionByAddressQuery.getKey = (
  variables: NftCollectionByAddressQueryVariables,
) => ["nftCollectionByAddress", variables];
export const useInfiniteNftCollectionByAddressQuery = <
  TData = NftCollectionByAddressQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: NftCollectionByAddressQueryVariables,
  options?: UseInfiniteQueryOptions<NftCollectionByAddressQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<NftCollectionByAddressQuery, TError, TData>(
    ["nftCollectionByAddress.infinite", variables],
    (metaData) =>
      fetcher<
        NftCollectionByAddressQuery,
        NftCollectionByAddressQueryVariables
      >(
        client,
        NftCollectionByAddressDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteNftCollectionByAddressQuery.getKey = (
  variables: NftCollectionByAddressQueryVariables,
) => ["nftCollectionByAddress.infinite", variables];
export const NftCollectionsByNameSearchDocument = `
    query nftCollectionsByNameSearch($collectionNameSearch: String!, $chainId: Int!) {
  nftCollections(
    where: {collectionTitle_contains: $collectionNameSearch, chain: {AND: {chainId: $chainId}}}
  ) {
    currency
    collectionHomePage
    collectionTitle
    collectionTwitterProfile
    collectionCategories
    collectionRoyaltyFee
    collectionTotalSupply
    collectionContractAddress
    collectionDescription
    collectionDiscordServer
    collectionInstagramProfile
    collectionMediumHome
    collectionListingDate
    chain {
      chainId
      chainName
    }
    collectionBannerImage {
      url
    }
    collectionFeaturedImage {
      url
    }
  }
}
    `;
export const useNftCollectionsByNameSearchQuery = <
  TData = NftCollectionsByNameSearchQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: NftCollectionsByNameSearchQueryVariables,
  options?: UseQueryOptions<NftCollectionsByNameSearchQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<NftCollectionsByNameSearchQuery, TError, TData>(
    ["nftCollectionsByNameSearch", variables],
    fetcher<
      NftCollectionsByNameSearchQuery,
      NftCollectionsByNameSearchQueryVariables
    >(client, NftCollectionsByNameSearchDocument, variables, headers),
    options,
  );

useNftCollectionsByNameSearchQuery.getKey = (
  variables: NftCollectionsByNameSearchQueryVariables,
) => ["nftCollectionsByNameSearch", variables];
export const useInfiniteNftCollectionsByNameSearchQuery = <
  TData = NftCollectionsByNameSearchQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: NftCollectionsByNameSearchQueryVariables,
  options?: UseInfiniteQueryOptions<
    NftCollectionsByNameSearchQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<NftCollectionsByNameSearchQuery, TError, TData>(
    ["nftCollectionsByNameSearch.infinite", variables],
    (metaData) =>
      fetcher<
        NftCollectionsByNameSearchQuery,
        NftCollectionsByNameSearchQueryVariables
      >(
        client,
        NftCollectionsByNameSearchDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteNftCollectionsByNameSearchQuery.getKey = (
  variables: NftCollectionsByNameSearchQueryVariables,
) => ["nftCollectionsByNameSearch.infinite", variables];
export const GetFooterNavigationDocument = `
    query getFooterNavigation($applicationId: AppId = Global) {
  menus(where: {applicationId: $applicationId}) {
    id
    navigationItems {
      iconComponent
      id
      internalSlug
      navigationItemText
      externalLink
      destinationAppId
    }
    menuGroupName
    menuSection
  }
}
    `;
export const useGetFooterNavigationQuery = <
  TData = GetFooterNavigationQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetFooterNavigationQueryVariables,
  options?: UseQueryOptions<GetFooterNavigationQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetFooterNavigationQuery, TError, TData>(
    variables === undefined
      ? ["getFooterNavigation"]
      : ["getFooterNavigation", variables],
    fetcher<GetFooterNavigationQuery, GetFooterNavigationQueryVariables>(
      client,
      GetFooterNavigationDocument,
      variables,
      headers,
    ),
    options,
  );

useGetFooterNavigationQuery.getKey = (
  variables?: GetFooterNavigationQueryVariables,
) =>
  variables === undefined
    ? ["getFooterNavigation"]
    : ["getFooterNavigation", variables];
export const useInfiniteGetFooterNavigationQuery = <
  TData = GetFooterNavigationQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetFooterNavigationQueryVariables,
  options?: UseInfiniteQueryOptions<GetFooterNavigationQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetFooterNavigationQuery, TError, TData>(
    variables === undefined
      ? ["getFooterNavigation.infinite"]
      : ["getFooterNavigation.infinite", variables],
    (metaData) =>
      fetcher<GetFooterNavigationQuery, GetFooterNavigationQueryVariables>(
        client,
        GetFooterNavigationDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetFooterNavigationQuery.getKey = (
  variables?: GetFooterNavigationQueryVariables,
) =>
  variables === undefined
    ? ["getFooterNavigation.infinite"]
    : ["getFooterNavigation.infinite", variables];
export const GetHomePageVideoHeroesDocument = `
    query getHomePageVideoHeroes {
  homePageVideoHeroes {
    video {
      url
    }
    videoPosterImage {
      url
    }
  }
}
    `;
export const useGetHomePageVideoHeroesQuery = <
  TData = GetHomePageVideoHeroesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetHomePageVideoHeroesQueryVariables,
  options?: UseQueryOptions<GetHomePageVideoHeroesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetHomePageVideoHeroesQuery, TError, TData>(
    variables === undefined
      ? ["getHomePageVideoHeroes"]
      : ["getHomePageVideoHeroes", variables],
    fetcher<GetHomePageVideoHeroesQuery, GetHomePageVideoHeroesQueryVariables>(
      client,
      GetHomePageVideoHeroesDocument,
      variables,
      headers,
    ),
    options,
  );

useGetHomePageVideoHeroesQuery.getKey = (
  variables?: GetHomePageVideoHeroesQueryVariables,
) =>
  variables === undefined
    ? ["getHomePageVideoHeroes"]
    : ["getHomePageVideoHeroes", variables];
export const useInfiniteGetHomePageVideoHeroesQuery = <
  TData = GetHomePageVideoHeroesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetHomePageVideoHeroesQueryVariables,
  options?: UseInfiniteQueryOptions<GetHomePageVideoHeroesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetHomePageVideoHeroesQuery, TError, TData>(
    variables === undefined
      ? ["getHomePageVideoHeroes.infinite"]
      : ["getHomePageVideoHeroes.infinite", variables],
    (metaData) =>
      fetcher<
        GetHomePageVideoHeroesQuery,
        GetHomePageVideoHeroesQueryVariables
      >(
        client,
        GetHomePageVideoHeroesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetHomePageVideoHeroesQuery.getKey = (
  variables?: GetHomePageVideoHeroesQueryVariables,
) =>
  variables === undefined
    ? ["getHomePageVideoHeroes.infinite"]
    : ["getHomePageVideoHeroes.infinite", variables];
export const AllCollectionVolumeDocument = `
    query allCollectionVolume {
  nftCollections(where: {tradable: true}, first: 1000) {
    id
    chain {
      chainName
      chainEnum
      chainId
    }
    collectionContractAddress
    collectionDescription
    collectionThumbnail {
      url
    }
    collectionTitle
    collectionTotalSupply
    currency
  }
}
    `;
export const useAllCollectionVolumeQuery = <
  TData = AllCollectionVolumeQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: AllCollectionVolumeQueryVariables,
  options?: UseQueryOptions<AllCollectionVolumeQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<AllCollectionVolumeQuery, TError, TData>(
    variables === undefined
      ? ["allCollectionVolume"]
      : ["allCollectionVolume", variables],
    fetcher<AllCollectionVolumeQuery, AllCollectionVolumeQueryVariables>(
      client,
      AllCollectionVolumeDocument,
      variables,
      headers,
    ),
    options,
  );

useAllCollectionVolumeQuery.getKey = (
  variables?: AllCollectionVolumeQueryVariables,
) =>
  variables === undefined
    ? ["allCollectionVolume"]
    : ["allCollectionVolume", variables];
export const useInfiniteAllCollectionVolumeQuery = <
  TData = AllCollectionVolumeQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: AllCollectionVolumeQueryVariables,
  options?: UseInfiniteQueryOptions<AllCollectionVolumeQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<AllCollectionVolumeQuery, TError, TData>(
    variables === undefined
      ? ["allCollectionVolume.infinite"]
      : ["allCollectionVolume.infinite", variables],
    (metaData) =>
      fetcher<AllCollectionVolumeQuery, AllCollectionVolumeQueryVariables>(
        client,
        AllCollectionVolumeDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteAllCollectionVolumeQuery.getKey = (
  variables?: AllCollectionVolumeQueryVariables,
) =>
  variables === undefined
    ? ["allCollectionVolume.infinite"]
    : ["allCollectionVolume.infinite", variables];
export const CollectionVolumeByNetworkDocument = `
    query collectionVolumeByNetwork($chainId: Int) {
  nftCollections(where: {chain: {chainId: $chainId}}) {
    collectionContractAddress
    collectionThumbnail {
      url
    }
    currency
    chain {
      chainId
    }
    collectionDescription
    collectionTitle
    collectionTotalSupply
  }
}
    `;
export const useCollectionVolumeByNetworkQuery = <
  TData = CollectionVolumeByNetworkQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: CollectionVolumeByNetworkQueryVariables,
  options?: UseQueryOptions<CollectionVolumeByNetworkQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<CollectionVolumeByNetworkQuery, TError, TData>(
    variables === undefined
      ? ["collectionVolumeByNetwork"]
      : ["collectionVolumeByNetwork", variables],
    fetcher<
      CollectionVolumeByNetworkQuery,
      CollectionVolumeByNetworkQueryVariables
    >(client, CollectionVolumeByNetworkDocument, variables, headers),
    options,
  );

useCollectionVolumeByNetworkQuery.getKey = (
  variables?: CollectionVolumeByNetworkQueryVariables,
) =>
  variables === undefined
    ? ["collectionVolumeByNetwork"]
    : ["collectionVolumeByNetwork", variables];
export const useInfiniteCollectionVolumeByNetworkQuery = <
  TData = CollectionVolumeByNetworkQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: CollectionVolumeByNetworkQueryVariables,
  options?: UseInfiniteQueryOptions<
    CollectionVolumeByNetworkQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<CollectionVolumeByNetworkQuery, TError, TData>(
    variables === undefined
      ? ["collectionVolumeByNetwork.infinite"]
      : ["collectionVolumeByNetwork.infinite", variables],
    (metaData) =>
      fetcher<
        CollectionVolumeByNetworkQuery,
        CollectionVolumeByNetworkQueryVariables
      >(
        client,
        CollectionVolumeByNetworkDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteCollectionVolumeByNetworkQuery.getKey = (
  variables?: CollectionVolumeByNetworkQueryVariables,
) =>
  variables === undefined
    ? ["collectionVolumeByNetwork.infinite"]
    : ["collectionVolumeByNetwork.infinite", variables];
export const AllCollectionVolumePaginatedDocument = `
    query allCollectionVolumePaginated($first: Int, $skip: Int, $chainId: Int) {
  nftCollections(
    where: {tradable: true, AND: {chain: {chainId: $chainId}}}
    first: $first
    skip: $skip
  ) {
    id
    chain {
      chainName
      chainEnum
      chainId
    }
    collectionContractAddress
    collectionDescription
    collectionThumbnail {
      url
    }
    collectionTitle
    collectionTotalSupply
    currency
  }
}
    `;
export const useAllCollectionVolumePaginatedQuery = <
  TData = AllCollectionVolumePaginatedQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: AllCollectionVolumePaginatedQueryVariables,
  options?: UseQueryOptions<AllCollectionVolumePaginatedQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<AllCollectionVolumePaginatedQuery, TError, TData>(
    variables === undefined
      ? ["allCollectionVolumePaginated"]
      : ["allCollectionVolumePaginated", variables],
    fetcher<
      AllCollectionVolumePaginatedQuery,
      AllCollectionVolumePaginatedQueryVariables
    >(client, AllCollectionVolumePaginatedDocument, variables, headers),
    options,
  );

useAllCollectionVolumePaginatedQuery.getKey = (
  variables?: AllCollectionVolumePaginatedQueryVariables,
) =>
  variables === undefined
    ? ["allCollectionVolumePaginated"]
    : ["allCollectionVolumePaginated", variables];
export const useInfiniteAllCollectionVolumePaginatedQuery = <
  TData = AllCollectionVolumePaginatedQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: AllCollectionVolumePaginatedQueryVariables,
  options?: UseInfiniteQueryOptions<
    AllCollectionVolumePaginatedQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<AllCollectionVolumePaginatedQuery, TError, TData>(
    variables === undefined
      ? ["allCollectionVolumePaginated.infinite"]
      : ["allCollectionVolumePaginated.infinite", variables],
    (metaData) =>
      fetcher<
        AllCollectionVolumePaginatedQuery,
        AllCollectionVolumePaginatedQueryVariables
      >(
        client,
        AllCollectionVolumePaginatedDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteAllCollectionVolumePaginatedQuery.getKey = (
  variables?: AllCollectionVolumePaginatedQueryVariables,
) =>
  variables === undefined
    ? ["allCollectionVolumePaginated.infinite"]
    : ["allCollectionVolumePaginated.infinite", variables];
