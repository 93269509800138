/* eslint-disable react/no-array-index-key */
import React from 'react'
import Image from "next/image"

import Link from 'next/link'
import { ChevronRightIcon } from '@heroicons/react/outline'

import {
  Typography,
  MultiCarousel,
  Button
} from '../../../';

import { graphCMSClient } from '@alpha-shares/fetchers';

import Skeleton, { SkeletonTheme }from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { colors } from '@alpha-shares/theme/stitches.config'

import {
  type FeaturedCollectionGroupQueryVariables,
  useFeaturedCollectionGroupQuery
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

import { useMarketplaceRouteBasePath, useMounted } from '@alpha-shares/res-utils/hooks';


interface IAssetCarousel {
  title?: string;
  titleClass?: string;
  containerClasses?: string;
  HeadingLink?: JSX.Element;
  variables?: FeaturedCollectionGroupQueryVariables  
}

const LoadingState = () => (
  <div className="opacity-[30] animate animate-pulse mx-2 rounded-xl overflow-hidden mt-12">
  <SkeletonTheme baseColor={colors.dark[300]} highlightColor={colors.dark[50]}>    
    <div className="overflow-hidden"  style={{aspectRatio: '1 / 1'}}>
      <Skeleton style={{aspectRatio: '1 / 1'}} count={1} height={400} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50' />
    </div>          
  </SkeletonTheme>
  </div>
  )

export const HeroCarouselSquare:React.FC<IAssetCarousel> = ({
  title = 'Collections',
  containerClasses,
  titleClass = '',
  variables = { id: 'clrnu0zd7w0hp0bivbemr9pga' },
  HeadingLink,  
}) => {
  const mounted = useMounted()
  const marketplaceRootPath = useMarketplaceRouteBasePath();
  
  const { data, isFetching, isLoading, isFetched } = useFeaturedCollectionGroupQuery(graphCMSClient, variables)

  const responsive = [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 2,          
      }
    },
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,       
      }
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        centerPadding: '20px'
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '20px'
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '20px'
      }
    }      
  ]

  const collections = data?.featuredCollectionGroup?.nftCollections.filter((item) => 
    Boolean(
      item.collectionThumbnail?.url
      ))

  return (
    <MultiCarousel
      containerClasses="h-full relative"
      HeadingLink={HeadingLink}
      carouselTitle={'Featured Collections'}
      titleClass={'mb-8 lg:mb-16'}
      autoPlay={true}
      //@ts-ignore
      responsiveConfig={responsive}
    >
      {(!isFetched || isLoading || !mounted) && ([...new Array(12).fill('x')].map((_, index) => (
        <LoadingState key={index} />
      )))}
      {mounted && isFetched && collections && collections?.length > 4 && collections.map((item) => (
        <React.Fragment key={item.id}>
          <div className="relative px-3 overflow-hidden">
            <div className='transition-all bg-dark-550 overflow-hidden relative rounded-xl'>
            {item.collectionThumbnail?.url && (
              <>
                <div className="relative" style={{ aspectRatio: '1 / 1'}} >
                  <Image
                    src={item.collectionThumbnail?.url}
                    alt={item.collectionTitle ?? 'Collection Thumbnail'}
                    fill
                    className="h-full w-full object-cover rounded-b-xl"
                    style={{
                      maxWidth: "100%",
                      objectFit: "cover"
                    }} />
                </div>                
              </>
            )}
            <div className="absolute w-full bottom-0 mx-auto">
              <div className="bg-dark-550 bg-opacity-80  mx-auto p-2 xl:p-4 pr-6 rounded-b-xl md:rounded-b-xl">
                {item.chain && (
                <Link href={`${marketplaceRootPath}/${item.chain.chainSlug}/collection/${item.collectionContractAddress}`}>
                  <div className="flex items-center justify-between md:justify-center cursor-pointer">
                    <div className='hidden md:block '>
                      <Image
                        //@ts-ignore
                        src={item.collectionThumbnail.url}
                        width={50}
                        height={50}
                        //@ts-ignore
                        alt={item.collectionTitle}
                        className="rounded-xl"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          objectFit: "cover"
                        }} />
                    </div>
                    <div className="ml-2 flex flex-col justify-between">
                      <Typography variant="body1" className="!text-xs uppercase font-bold hidden md:block " color="text-gray-400">
                        Featured Collection
                      </Typography>
                      <Typography variant="p">
                        {item.collectionTitle}
                      </Typography>
                    </div>
                    <div className="ml-2 flex items-center">
                      <Button className="!p-1" variant="tertiary"><ChevronRightIcon className="w-4 h-4" /></Button>
                    </div>
                  </div>
                </Link>
                )}
              </div>
            </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </MultiCarousel>
  );
}
