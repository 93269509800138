import React, { Fragment } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { ChevronDownIcon, SearchIcon } from '@heroicons/react/solid'
import {
  FooterMedium, LeftSlideSidebar, MainLogoIcon, MainLogoText, 
  ConnectButtonRainbowkit,
  SidebarNavigation,
  LoadingGradientBar,
  ClientOnly,  
} from '@alpha-shares/react-components';

import GlobalSearch from '@alpha-shares/react-components/navbar/GlobalSearch';

import { MenuIcon } from '@heroicons/react/outline';

import { Box } from '../primitives'

import { useHotkeys } from 'react-hotkeys-hook'


import { useMounted } from '@alpha-shares/res-utils/hooks'
import { useAccount } from 'wagmi'
import CartButton from '../navbar/CartButton'
import { AccountSidebar } from '../navbar/AccountSidebar'

import { AppId } from '@alpha-shares/graphql-codegen/generated/graphCMS';

import { useMarketplaceRouteBasePath } from '@alpha-shares/res-utils/hooks'


import Skeleton, { SkeletonTheme }from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { colors } from '@alpha-shares/theme/stitches.config'

const SearchBarLoadingState:React.FC = () => (
  <div className='w-full border border-transparent hover:border-primary-500'>
    <SkeletonTheme baseColor={colors.dark[300]} highlightColor={colors.dark[50]}>
      <Skeleton count={1} height={40} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50 rounded-b-xl' />          
    </SkeletonTheme>
  </div>
)
interface IStandardLayoutProps {
  children: React.ReactNode;
  appId?: AppId;
}

export const StandardLayout:React.FC<IStandardLayoutProps> = ({
  children,
  appId = AppId.MarketplaceNext
}) => {
  const router = useRouter();
  
  const isMounted = useMounted()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [mobileSearchOpen, setMobileSearchOpen] = React.useState(false)
  
  let searchRef = React.useRef<HTMLInputElement>(null)
  
  const { isConnected } = useAccount()
  
  const handleRouteChangeStart = () => {
    setLoading(true)
  }

  const handleRouteChangeComplete = () => {
    setLoading(false)
    setOpen(false)
  }

  useHotkeys('meta+k', (e) => {
    e.preventDefault()
    if (searchRef?.current) {
      searchRef?.current?.focus()
    }
  })

  if (router?.events) {
    router?.events.on('routeChangeStart', () => handleRouteChangeStart());
    router?.events.on('routeChangeComplete', () => handleRouteChangeComplete());
  }

  React.useEffect(() => {
    handleRouteChangeComplete()   
  }, [router?.asPath])

  const trendingLinks = [
      { name: 'Collections', href: `/ethereum/collections/trending` },
      { name: 'Mints', href: `/ethereum/mints/trending` },
    ]
  
  
  return (
    <div>
      <div className="flex flex-row lg:justify-center items-center lg:items-stretch w-full top-0 z-[60] sticky">
        <div className="flex w-full xl:grid xl:grid-cols-3 h-20 shrink-0 items-center justify-between">
          
          <div className='flex items-center'>
            <div className='flex items-center pl-6'>
              <MenuIcon onClick={() => setOpen(!open)} className="mr-4 h-12 w-12 text-primary-500 cursor-pointer " />
              <Link href="/">
                <div className="flex items-center">
                  <MainLogoIcon colorClass="text-blue-light" widthClass="w-8" heightClass="h-8" />
                  <MainLogoText containerClass="ml-1 xl:ml-2 text-2xl" />
                </div>
              </Link>
            </div>

            <div className='flex items-center gap-2 ml-4 mt-1'>
              {/* <div className='hidden md:block'>                
                <Link href="/featured">
                  <Button variant='menu'>
                    Featured
                  </Button>      
                </Link>
              </div>
              {!isMounted && (
                <Button variant='menu'  className={`group 
                ${open ? 'text-black bg-primary-200' : ''}`}>
                  Trending
                  <ChevronDownIcon
                    className={`${open ? 'text-black' : 'text-primary-300/70'}
                      ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-black`}
                    aria-hidden="true"
                  />
                </Button>
              )} */}
              {/* {isMounted && (
                <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button>
                      <Button variant='menu'  className={`group 
                        ${open ? 'text-black bg-primary-200' : ''}`}>
                        Trending
                        <ChevronDownIcon
                          className={`${open ? 'text-black' : 'text-primary-300/70'}
                            ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-black`}
                          aria-hidden="true"
                        />
                      </Button>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 mt-3 max-w-sm transform -ml-8 md:ml-0 px-4 sm:px-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                          <div className="relative grid gap-2 bg-dark-400 shadow-xl p-2">
                            {routeBasePath === basePath && trendingLinks.map((item) => (
                              <Link
                                key={item.name}
                                href={item.href}
                                className="p-2 px-4 flex items-start rounded-lg hover:bg-primary-200 group transition-all "
                              > 
                                <Typography variant='h5' className="transition-all group-hover:text-black text-base font-medium">
                                  {item.name}
                                </Typography>                          
                              </Link>
                            ))}
                            {routeBasePath !== basePath && trendingLinks.map((item) => (
                              <a
                                key={item.name}
                                href={`/marketplace${item.href}`}
                                className="p-2 px-4 flex items-start rounded-lg hover:bg-primary-200 group transition-all "
                              > 
                                <Typography variant='h5' className="transition-all group-hover:text-black text-base font-medium">
                                  {item.name}
                                </Typography>                          
                              </a>
                            ))}
                          </div>                  
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
                </Popover>
              )} */}
              
              {/* <div className={`${mobileSearchOpen ? 'bg-primary-200 hover:bg-opacity-70' : 'bg-transparent'} rounded-md p-3 lg:hidden`}>
                <SearchIcon
                  onClick={() => setMobileSearchOpen(!mobileSearchOpen)}
                  className={`${mobileSearchOpen ? 'text-black' : 'text-primary-300/70'}
                    h-5 w-5 transition duration-150 ease-in-out group-hover:text-primary-300/80`}
                  aria-hidden="true"
                />   
              </div>         */}
            </div>
          </div>

          <div className="hidden lg:flex flex-grow lg:space-x-4 group justify-center w-full items-center">
            {!isMounted && (<SearchBarLoadingState />)}
            {isMounted && (
              <GlobalSearch
                ref={searchRef}
                placeholder="Search collections and addresses"
                containerCss={{ width: '100%' }}
                key={router.asPath}
              />
            )}
          </div>

          <div className="justify-end hidden lg:flex py-2 items-center gap-2 ml-6 pr-8">            
            <ClientOnly>
              <>
                {isConnected ? (
                  <AccountSidebar />
                ) : (
                  <Box css={{ maxWidth: '185px' }}>
                    <ConnectButtonRainbowkit />
                  </Box>
                )}
                <CartButton />
              </>
            </ClientOnly>        
          </div>
          
        </div>
        
        
        <LoadingGradientBar showing={loading} className='absolute bottom-0 h-1' />  
      </div>
      
      <main className="h-full w-full relative">      
        <LeftSlideSidebar open={open} setOpen={setOpen}>
          <SidebarNavigation appId={appId} setOpen={setOpen}>
            <div className='flex flex-col gap-6'>
              <ClientOnly>
                <GlobalSearch
                  ref={searchRef}
                  placeholder="Search collections and addresses"
                  containerCss={{ width: '100%' }}
                  key={router.asPath}
                />              
              </ClientOnly>
            </div>
          </SidebarNavigation>
        </LeftSlideSidebar>
        <article className='relative min-h-[90vh]'>
          {mobileSearchOpen && (
            <div className="absolute top-0 w-full z-10 bg-dark-400 shadow-xl p-2 lg:hidden">
              <ClientOnly>
                <GlobalSearch
                  ref={searchRef}
                  placeholder="Search collections and addresses"
                  containerCss={{ width: '100%' }}
                  key={router.asPath}
                />
              </ClientOnly>
            </div>   
          )}          
          {children}
        </article>
      </main>
      <ClientOnly>
        <FooterMedium className='mt-12 bg-dark-50' />
      </ClientOnly>
    </div>
  );
}
