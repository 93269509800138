/* eslint global-require: 0 */

export const tailwindConfig = {
  // darkMode: 'class',
  content: [
    '../apps/**/*.{js,ts,jsx,tsx}',
    '../packages/**/*.{js,ts,jsx,tsx}'
  ],
  mode: 'jit',
  theme: {
    screens: {
      xs: '321px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1288px',
      '2xl': '1919px',
      '3xl': '2199px',
    },
    extend: {
      colors: ({ colors }) => ({
        inherit: colors.inherit,
        current: colors.current,
        transparent: colors.transparent,
        black: colors.black,
        white: colors.white,
        slate: colors.slate,
        zinc: colors.zinc,
        neutral: colors.neutral,
        stone: colors.stone,
        red: colors.red,
        orange: colors.orange,
        amber: colors.amber,
        yellow: colors.yellow,
        lime: colors.lime,
        green: colors.green,
        emerald: colors.emerald,
        teal: colors.teal,
        cyan: colors.cyan,
        sky: colors.sky,
        indigo: colors.indigo,
        violet: colors.violet,
        purple: colors.purple,
        fuchsia: colors.sky,
        pink: colors.pink,
        rose: colors.rose,
        gray: {
          DEFAULT: '#71717A',
          50: '#FAFAFA',
          100: 'F4F4F5',
          200: '#E4E4E7',
          300: '#D4D4D8',
          400: '#A1A1AA',
          500: '#71717A',
          600: '#52525B',
          700: '#3F3F46',
          800: '#2727A',
          900: '#171717',
        },
        // dark: {
        //   50: '#3e6a94',
        //   100: '#224466',
        //   200: '#1c3752',
        //   300: '#162b40',
        //   400: '#132538',
        //   500: '#09111a',
        // },
        dark: {
          ...colors.slate,
          50: '#04061A',
          550: '#0C0D23',
          100: '#224466',
          200: '#1c3752',
          300: '#162b40',
          400: '#132538',
          500: '#09111a',
        },
        primary: {
          ...colors.cyan          
        },
        // darkdesaturated: {
        //   50: '#505262',
        //   100: '#6a6b80',
        //   200: '#5a5b6b',
        //   300: '#4b4c59',
        //   400: '#393a45',
        //   500: '#2b2b33',
        // },
        // dark: {
        //   // ...colors.slate,
        //   50: '#04061A',
        //   100: colors.black['600'],
        //   200: colors.black['700'],
        //   300: colors.black['800'],
        //   400: colors.black['900'],
        //   550: '#0C0D23'
        // },
        'primary-grad': {
          start: '#ff00ff',
          end: '#ff007f',
        },
        'secondary-grad': {
          start: '#00d4ff',
          end: '#0080ff',
        },
        accent: { ...colors.emerald },
        // accent: {
        //   DEFAULT: '#C700C7',
        //   50: '#FFE5FF',
        //   100: '#FFCCFF',
        //   200: '#FF99FF',
        //   300: '#FF66FF',
        //   400: '#FF33FF',
        //   500: '#FF00FF',
        //   600: '#C700C7',
        //   700: '#8F008F',
        //   800: '#570057',
        //   900: '#1F001F',
        // },
        secondary: {
          DEFAULT: '#00E705',
          50: '#E4FFE5',
          100: '#C8FFCA',
          200: '#90FF93',
          300: '#58FF5C',
          400: '#20FF25',
          500: '#00E705',
          600: '#00B404',
          700: '#008103',
          800: '#004E02',
          900: '#001B01',
        },
        tertiary: {
          DEFAULT: '#9500FF',
          50: '#F4E5FF',
          100: '#EACCFF',
          200: '#D499FF',
          300: '#BF66FF',
          400: '#AA33FF',
          500: '#9500FF',
          600: '#7400C7',
          700: '#53008F',
          800: '#330057',
          900: '#12001F',
        },
        // primary: {
        //   DEFAULT: '#00D4FF',
        //   50: '#E5FBFF',
        //   100: '#CCF6FF',
        //   200: '#99EEFF',
        //   300: '#66E5FF',
        //   400: '#33DDFF',
        //   500: '#00D4FF',
        //   600: '#00A6C7',
        //   700: '#00778F',
        //   800: '#004857',
        //   900: '#00191F',
        // },
        blue: {
          light: '#0ff2f2',
          dark: '#4a6ff5'
        }
      }),
      fontFamily: {
        sans: ['Manrope', 'sans-serif'],
      },
      fontSize: {
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '4.5rem',
        '7xl': '5.25rem',
        '8xl': '6rem',
        '9xl': '8rem',
      },
      borderRadius: {
        '6xl': '3rem',
        '8xl': '5rem',
      },
      transitionProperty: {
        height: 'height'
      },
      spacing: {
        assetMarginBottom: '20px',
      },
      height: {
        assetListItemHeight: '206px',
      },
      backgroundColor: {
        'blue-gradient': 'linear-gradient(90.34deg, #0FF2F2 -0.28%, #4A6FF5 115.8%)',
        'white-gradient': 'linear-gradient(90deg, #FFFFFF -0.7%, #40A2B8 217.41%)'
      }
    },
  },
  plugins: [
    require('@tailwindcss/typography')({
      className: 'wysiwyg',
    }),
    require('@tailwindcss/aspect-ratio'),
    require('tailwind-scrollbar'),
    require('@tailwindcss/forms')({
      strategy: 'class'
    })
  ],
};
