/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
  Typography,
} from '@alpha-shares/react-components';

import { graphCMSClient } from '@alpha-shares/fetchers';

import {
  useGetAllMintingProjectsQuery,
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

import { getDynamicGridCols } from '@alpha-shares/config';
import cn from '@alpha-shares/theme/utils/cn'

import { MintCard } from './MintItemCard'
import { MintItemLoadingState } from './MintItemLoadingState';

interface IAssetCarousel {
  title?: string;
  containerClasses?: string;
}

export const MintsGrid:React.FC<IAssetCarousel> = ({
  title = 'Current & Upcoming Mints',
  containerClasses
}) => {
  const { data, isFetched, isError, isLoading } = useGetAllMintingProjectsQuery(graphCMSClient)

  const makeColumnClasses = () => {
    if (!isFetched || isLoading) {
      return 'md:grid-cols-2'
    }

    if (data) {
      return getDynamicGridCols(data.mintingProjects.length)
    }

    return 'md:grid-cols-2'    
  }

  return (
    <div className={cn('container mx-auto my-8 py-8 max-w-[1920px] px-6', containerClasses)}>
      {/* <Typography variant="h4" as="div" className="text-center !text-primary-500 px-4">
        Early Access
      </Typography> */}
      <Typography variant="h1" as="div" className="text-center px-4">
        {title}
      </Typography>
      <div className={`mt-6 grid auto-rows-fr grid-cols-1 ${makeColumnClasses()} gap-12`}>
        {(!isFetched || isLoading) && (
          <>
            <MintItemLoadingState />
            <MintItemLoadingState />
            <MintItemLoadingState />
          </>
        )}
        {(isFetched && !isError && data?.mintingProjects) && data?.mintingProjects.map((item) => (
          <MintCard key={item.id} item={item} />
        ))}
      </div>
    </div>
  )
}
